/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { ImagePreset } from './ImagePreset';
import { PropertyCaption } from './PropertyCaption';
import { PropertySelect } from './PropertySelect';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import { ElementAnimationSettings } from './ElementAnimationSettings';
import { GenericAnimationSettings } from './GenericAnimationSettings';
import styled from 'styled-components';
import { numericalInputStyles } from '../../styles/mainStyle';
import { videoCreator } from '../../stores/VideoCreatorStore';

interface ImageSettingsProps {
  activeElement: ElementState;
}

export const ImageSettings: React.FC<ImageSettingsProps> = (props) => {

  return (
    <Fragment>
      <ImagePreset
        activeElement={props.activeElement}
        url={props.activeElement.source.source}
      />

      <ElementAnimationSettings activeElement={props.activeElement} />

      <BasicContent>
        <Item>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await videoCreator.resetCrossfadeOnVideo(
                props.activeElement.source.id,
                parseFloat(value),
                props.activeElement.duration
              )

              await videoCreator.renderer?.applyModifications({
                ...newVideoOverlays,
                [`${props.activeElement?.source.id}.${property}`]: value,
              });
              videoCreator.handleResetPhotoHighlight(props.activeElement, value)
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await videoCreator.resetCrossfadeOnVideo(
                props.activeElement.source.id,
                parseFloat(value),
                props.activeElement.source.time,
              )
              await videoCreator.renderer?.applyModifications({
                ...newVideoOverlays,
                [`${props.activeElement?.source.id}.${property}`]: value,
              });
              videoCreator.handleResetPhotoHighlight(props.activeElement, null, value)
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Fit</PropertyCaption>
          <PropertySelect
            activeElement={props.activeElement}
            propertyName="fit"
            defaultValue="cover"
            options={[
              { caption: 'Cover', value: 'cover' },
              { caption: 'Contain', value: 'contain' },
              { caption: 'Fill', value: 'fill' },
            ]}
          />
        </Item>

        <Item>
          <PropertyCaption>Opacity</PropertyCaption>
          <PropertySelect
            activeElement={props.activeElement}
            propertyName="color_overlay"
            defaultValue=""
            options={[
              { caption: 'None', value: '' },
              { caption: '20%', value: 'rgba(0,0,0,0.2)' },
              { caption: '40%', value: 'rgba(0,0,0,0.4)' },
              { caption: '60%', value: 'rgba(0,0,0,0.6)' },
              { caption: '80%', value: 'rgba(0,0,0,0.8)' },
            ]}
          />
        </Item>
      </BasicContent>

      <GenericAnimationSettings activeElement={props.activeElement} />
    </Fragment>
  );
};

// const Information = styled.div`
//   margin-top: 20px;
//   color: #a3a5a5;

//   a {
//     color: #a3a5a5;
//   }
// `;

const BasicContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`

const Item = styled.div`
`