import React, { useCallback, useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';
import { delay } from '../../utility/general';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { debounce } from 'lodash';
import { useAnimatedTimeChange } from './useAnimatedTimeChange';

type Props = {
  trackHeight: number
}
export const Playhead: React.FC<Props> = observer((props) => {
  const time = videoCreator.time;
  const duration = videoCreator.duration;
  const timelineScale = videoCreator.timelineScale;
  const [draggedX, setDraggedX] = React.useState(14);

  const scrubToTime = (time: number) => {
    runInAction(() => (videoCreator.isScrubbing = true));
    videoCreator
      .setTime(time)
      .then(() => runInAction(() => (videoCreator.isScrubbing = false)));
  };

  // eslint-disable-next-line
  const throttledScrubToTime = useCallback(throttle(scrubToTime, 15), []);
  const scrubToTimeDebounced = useCallback(debounce(scrubToTime, 500), []);

  const getCurrentTime = (
    e: DraggableEvent,
    data: DraggableData,
    context: any,
  ) => {
    return Math.min(
      duration,
      Math.max(
        context.startTime + (data.x - context.startX) / timelineScale,
        0,
      ),
    );
  };

  return (
    <Draggable
      onStart={(e, data) => {
        setDraggedX(data.x);
        videoCreator.isPlayheadDragging = true;
        return { startX: data.x, startTime: time };
      }}
      onDrag={(e, data, context) => {
        e.stopPropagation()
        setDraggedX(data.x);
        const time = getCurrentTime(e, data, context);
        videoCreator.tempDragTime = time;
      }}
      onStop={async (e, data, context) => {
        let time = getCurrentTime(e, data, context);
        videoCreator.setTime(videoCreator.tempDragTime || time, true);
        videoCreator.isPlayheadDragging = false;
        videoCreator.tempDragTime = null;
      }}
    >
      {(ref, context) => (
        <div ref={ref}>
          <PlayheadInner draggedX={draggedX} height={props.trackHeight} />
        </div>
      )}
    </Draggable>
  );
});

const PlayheadInner = observer(
  (props: any) => {
    const { draggedX } = props;
    const forcedTime = videoCreator.smootherTime;
    const timelineScale = videoCreator.timelineScale;
    const animatedRef = React.useRef<HTMLDivElement>(null);
    const duration = videoCreator.duration;
    const [startTime, setStartTime] = React.useState(0);

    useEffect(() => {
      setStartTime(videoCreator.snapTime(videoCreator.time) * 1000);
      animate(0)
    }, [videoCreator.isPlaying, timelineScale, forcedTime])

    const animate = (timestamp: number) => {
      if (animatedRef.current) {
        const ms = Math.min(videoCreator.snapTime(startTime + timestamp), videoCreator.snapTime(duration) * 1000);
        animatedRef.current.style.left = `${ms / 1000 * timelineScale + 14}px`;
      }
    }

    useAnimatedTimeChange(videoCreator.isPlaying && !videoCreator.isPlayheadDragging, startTime, (msElapsed) => {
      animate(msElapsed)
    });

    if (videoCreator.isPlayheadDragging && animatedRef.current) {
      animatedRef.current.style.left = `${draggedX}px`;
    }

    return (
      <Main
        ref={animatedRef}
      >
        <Head width="20" height="24" viewBox="0 0 20 24">
          <path d="M18,4.673c-0,-1.475 -1.198,-2.673 -2.673,-2.673l-10.654,0c-1.475,0 -2.673,1.198 -2.673,2.673l0,8.968l8,8.359l8,-8.359l-0,-8.968Z"></path>
        </Head>
        <Line height={props.height} />
      </Main>)

  })


const Main = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
`;

const Head = styled.svg`
  position: absolute;
  transform: translateX(-10px);
  path {
    fill: #f2d093;
  }
  cursor: ew-resize;
`;

const Line = styled.div<{height: number}>`
  position: absolute;
  top: 10px;
  bottom: 0px;
  width: 2px;
  transform: translateX(-1px);
  background: #f2d093;
  pointer-events: none;
  background: #f2d093;
  height: ${props => props.height}px;
`;
