import { useGesture } from '@use-gesture/react';
import MainMenu from '../components/MainMenu';
import React from 'react';
import { useClerk, useSession, RedirectToSignIn } from '@clerk/clerk-react';
import styled from 'styled-components';

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const ref = React.useRef<HTMLDivElement>(null);

  const clerk = useClerk();
  const session = useSession();

  const ctx =
    window.opener?.ctx ||
    JSON.parse(window.sessionStorage.getItem('ctx') || 'null');

  useGesture(
    {
      onPinch: () => { },
      onWheel: () => { },
    },
    { target: ref, eventOptions: { passive: false } },
  );

  if (
    clerk.loaded &&
    session.isLoaded &&
    !session?.session &&
    !ctx?.currentUser
  ) {
    return (
      <>
        <RedirectToSignIn />
      </>
    );
  }

  if ((clerk.loaded && session?.session) || ctx?.currentUser) {
    return (
      <div className="editor" ref={ref}>
        <MainMenu params={params} />
        {children}
      </div>
    );
  }

  return <LoadingScreen />;
};

const LoadingScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #03041a;
`;

export default RootLayout;
