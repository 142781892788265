import styled from 'styled-components';
import { ProduceIcon } from '../common/icons';
import { useEffect, useRef, useState } from 'react';
import { produceClips, supportedPresets } from '../../services/AIClipProducer';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SpinningLoading from '../SpinningLoading';
import Modal from '../common/Modal';
import AIClipProducerResults from '../sidepanel/AIClipProducerResults';
import SocialClipButtonList from '../common/SocialClipButtonList';

type Props = {
  closeModal: () => void;
};

const AIClipProducerModal = (props: Props) => {
  const [selectedPresets, setSelectedPresets] = useState<string[]>([]);
  const [selectedSocials, setSelectedSocials] = useState<string[]>([]);
  const [isProducing, setIsProducing] = useState(false);
  const [producedClips, setProducedClips] = useState<any[] | null>(null);
  const [showModal, setShowModal] = useState(false);

  const INPUT_PLACEHOLDER = `Customize (Type in what you'd like to see)`;
  const MAX_RETRIES = 3;

  const [presetInputValue, setPresetInputValue] = useState<string>('');

  const [retryCount, setRetryCount] = useState<number>(0);
  const retryCountRef = useRef(retryCount);
  retryCountRef.current = retryCount;

  const producerAbortController = useRef<{
    id: string;
    aborted: boolean;
  } | null>(null);

  const onPresetClick = (preset: string) => {
    if (selectedPresets.includes(preset)) {
      setSelectedPresets((prev) => prev.filter((p) => p !== preset));
    } else {
      setSelectedPresets((prev) => [preset]); //  [...prev, preset]);
      setPresetInputValue('');
    }
  };

  const closeProducingModal = () => {
    if (producerAbortController.current && isProducing) {
      producerAbortController.current.aborted = true;
    }
    setProducedClips(null);
    setIsProducing(false);
    setShowModal(false);
    props.closeModal();
  };

  const isProducingDisabled = () => {
    return (
      !(selectedPresets.length || presetInputValue) ||
      !selectedSocials.length ||
      isProducing ||
      !videoCreator.story?.originalVideo
    );
  };

  const onProduceButtonClick = async () => {
    if (
      !(selectedPresets.length || presetInputValue) ||
      !selectedSocials.length
    ) {
      return;
    }
    setIsProducing(true);
    setShowModal(true);

    producerAbortController.current = {
      id: Date.now().toString(),
      aborted: false,
    };

    const finalVideo = videoCreator.story?.finalVideo;

    if (finalVideo?.id && videoCreator.currentVideo?.id !== finalVideo.id) {
      await videoCreator.loadVideoWithoutRendering(finalVideo.id, true);
      videoCreator.duration = finalVideo.videoFilePrimary!.video.duration;
    }

    while (retryCountRef.current < MAX_RETRIES) {
      try {
        const matchedParts = await produceClips({
          originalVideo:
            videoCreator.currentVideo?.videoFilePrimary ||
            videoCreator.story!.originalVideo!,
          transcriptionText:
            videoCreator.videoTranscriptionProcessor.getFinalTranscriptionText(),
          transcriptionElements:
            (videoCreator.currentVideo?.videoFilePrimary
              ? videoCreator.finalTranscriptionElements
              : videoCreator.originalTranscription?.elements) || [],
          originalDuration: videoCreator.duration,
          theme: selectedPresets[0] || presetInputValue,
          platform: selectedSocials[0],
          onClipsProduced: (clips) => {
            setProducedClips(clips);
          },
          abortToken: producerAbortController.current,
        });
        console.log('ALL PRODUCED CLIPS', matchedParts);
        if (!matchedParts?.length) {
          setRetryCount((prev) => prev + 1);
        } else {
          setProducedClips(matchedParts);
          break;
        }
      } catch (err: any) {
        console.error('Clip producer error', err);
        if (err.message === 'Aborted') {
          return;
        }
        setRetryCount((prev) => prev + 1);
      }
    }
    producerAbortController.current = null;
    setRetryCount(0);
    setIsProducing(false);
  };

  return (
    <>
      <Modal isOpen={true} closeModal={closeProducingModal}>
        <Wrapper>
          <Content>
            {isProducing && (
              <div style={{ color: '#17c964' }}>
                <SpinningLoading
                  Ico={<ProduceIcon width={'24'} height={'24'} />}
                  text={`Generating clips${retryCount > 0 ? `. Retrying (${retryCount})` : ''
                    }`}
                />
              </div>
            )}
            <TopHeading>Let's produce some clips</TopHeading>

            <SocialMediaList>
              <Leading> Select social platform to optimize clips</Leading>
              <SocialClipButtonList
                setSelectedSocials={setSelectedSocials}
                selectedSocials={selectedSocials}
              />
            </SocialMediaList>

            <PresetsList>
              <Leading color="#AEAEAE">
                Find social clips based on theme
              </Leading>
              <div className="options-list">
                {supportedPresets.map((preset) => (
                  <PresetOption
                    key={preset}
                    active={selectedPresets.includes(preset)}
                    onClick={() => onPresetClick(preset)}
                  >
                    {preset}
                  </PresetOption>
                ))}
              </div>
              <div className="input">
                <PresentTextArea
                  placeholder={INPUT_PLACEHOLDER}
                  value={presetInputValue}
                  onChange={(e) => {
                    setPresetInputValue(e.target.value);
                    setSelectedPresets([]);
                  }}
                />
              </div>
            </PresetsList>

            <ProduceButton
              onClick={onProduceButtonClick}
              disabled={isProducingDisabled()}
            >
              <ProduceIcon /> Produce
            </ProduceButton>
          </Content>
        </Wrapper>
      </Modal>
      {showModal && Boolean(producedClips?.length) && (
        <Modal isOpen={true} closeModal={closeProducingModal}>
          <AIClipProducerResults
            hidePlatformName={true}
            sourcePlatform="content-studio"
            onClose={closeProducingModal}
            fragments={producedClips!}
            isProducing={isProducing}
          />
        </Modal>
      )}
    </>
  );
};

export default AIClipProducerModal;

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #03041a;
  width: 942px;
  border: 1px solid #484848;
`;

const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  background-color: #03041a;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  gap: 10px;
  padding: 50px 0;
`;

const TopHeading = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  text-align: center;
  color: #f3e9d7;
  margin: 0;
`;

const Leading = styled.div<{ color?: string }>`
  width: 100%;
  color: ${(props) => props.color ?? '#f3e9d7'};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
`;

const PresentTextArea = styled.textarea`
  box-sizing: border-box;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  font-size: 10px;
  line-height: 150%;
  background-color: transparent;
  color: #484848;
  width: 100%;
  resize: none;
  height: 70px;
  outline: none;
`;

const AspectRatioView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 20px;
  .aspect-ratios {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
`;

const AspectRatio = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  border: 1px solid ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
  color: ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
`;

const SocialMediaList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const LoadingModal = styled.div`
  width: 500px;
  height: 400px;
  color: #17c964;
  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const SocialButton = styled.div.attrs((props: { active: boolean }) => props)`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  align-items: center;
  width: 38px;
  box-sizing: border-box;
  justify-content: center;

  ${(props) =>
    props.active &&
    `
    color: #030419;
    border-color: #F2D093;
    background-color: #F2D093;
  `}
`;

const ProduceButton = styled.div.attrs((props: { disabled: boolean }) => props)`
  width: 240px;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: #030419;
    opacity: 0.6;
  `}
`;

const PresetsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  .options-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    gap: 8px;
  }

  .input {
    display: flex;
    width: 100%;
  }
  gap: 16px;
`;

const PresetOption = styled.div.attrs((props: { active: boolean }) => props)`
  padding: 8px;
  position: relative;
  border-radius: 8px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  border: 1px solid #484848;
  cursor: pointer;
  color: #484848;
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;

  ${(props) =>
    props.active &&
    `
    color: #F2D093;
    border: 1px solid #F2D093;
  `}

  &:hover {
    color: #f2d093;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #484848;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
    props.active &&
    `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
    `}
  }
`;
