import React, { useState } from 'react'
import FileUpload from '../common/FileUpload';
import PhotoIcon from '../../svgs/PhotoIcon';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import FileListItem from '../common/FileListItem';
import { runInAction } from 'mobx';
import SpinningLoading from '../SpinningLoading';
import { SidebarOption } from '../../types.ts/general';

const VideoArtifacts = () => {
    const [uploadButtonActive, setUploadButtonActive] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const video_artifacts_data = videoCreator?.story?.storyArtifactsVideo?.
        map((a) => ({
            id: a.id,
            title: a.title,
            src: a.url,
            duration: a.video?.duration,
            thumbnailUrl: a.video?.thumbnailUrl
        }));
    const [videos, setVideos] = useState(video_artifacts_data)

    async function handleRemoveVideo(mediaId: string) {
        let artifactVideos = videoCreator.story?.
            storyArtifactsVideo?.
            filter((artifact) => artifact.id !== mediaId) || [];

        videoCreator.story!.storyArtifactsVideo = artifactVideos
        setVideos(artifactVideos.map(v => (
            {
                id: v.id,
                title: v.title,
                src: v.url,
                duration: v.video?.duration,
                thumbnailUrl: v.video?.thumbnailUrl
            }))
        )
        await videoCreator.storyRepository?.update(videoCreator.story!);
    }

    return (
        <div>
            {isFileUploading && <SpinningLoading
                positionTop="20px"
                text="Saving video"
            />}
            <FileUpload
                width="100%"
                Button={
                    <AddFileButton isActivated={uploadButtonActive}>
                        <PhotoIcon strokeColor="#03041A" /> Upload
                    </AddFileButton>
                }
                type="storyArtifactsVideo"
                showToggle={false}
                onClick={() => {
                    setUploadButtonActive(true);
                    setTimeout(() => setUploadButtonActive(false), 3000);
                }}
                callback={(artifactVideos) => {
                    setVideos(artifactVideos.map(v => (
                        {
                            id: v.id,
                            title: v.title,
                            src: v.url,
                            duration: v.video?.duration,
                            thumbnailUrl: v.video?.thumbnailUrl
                        }))
                    )
                }}
                setIsLoading={setIsFileUploading}
            />
            <VideoContent>
                {videos?.map((r) => (
                    <FileListItem
                        key={r.id}
                        type="video"
                        item={{
                            id: r.id,
                            description: r.title,
                            src: r.src!,
                            thumbnailUrl: r.thumbnailUrl
                        }}

                        handleClick={async () => {
                            await videoCreator.createElement({
                                type: 'video',
                                source: r.src,
                                duration: r.duration || '8 s',
                                autoplay: true,
                            });
                            videoCreator.sidebarOptions = SidebarOption.editing;
                        }}
                        isSelected={false}
                        handleRemove={() => handleRemoveVideo(r.id)}
                    />
                ))}
            </VideoContent>
        </div>
    )
}

export default VideoArtifacts

const AddFileButton = styled.button<{ isActivated?: boolean }>`
  outline: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  background-color: #17c964;
  justify-content: center;
  font-weight: 700;
  flex: 1;
  cursor: pointer;
  opacity: ${(props) => (props.isActivated ? '1' : '0.9')};
`;

const VideoContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;