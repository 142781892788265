import { autorun } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import WaveformData from 'waveform-data';

type Props = {
  url: string;
  customTimelineScale?: number;
  isInMusicProducer: boolean;
};

export const useWaveformResample = (props: Props) => {
  const { customTimelineScale, isInMusicProducer, url } = props;
  const isOriginalVideo = url === videoCreator.originalVideoAudioUrl;
  const waveform: WaveformData | undefined | null = isOriginalVideo
    ? videoCreator.originalWaveForm
    : //@ts-ignore
      videoCreator.audioTracksData[url]?.waveform;

  useEffect(() => {
    autorun(() => {
      debounceGenerateWaveform(
        customTimelineScale || videoCreator.timelineScale,
      );
    });
  }, [waveform, videoCreator.timelineScale, customTimelineScale]);

  const generateWaveform = useCallback(
    (timelineScale: number) => {
      if (!waveform) {
        console.log('No waveform', url);
        return;
      }

      const currentResampledWaveform: WaveformData | undefined | null =
        isOriginalVideo
          ? videoCreator.resampledOriginalWaveForm
          : //@ts-ignore
            videoCreator.audioTracksData[url]?.resampledWaveform;

      const maxTimelineScale = isInMusicProducer
        ? 180
        : videoCreator.maxTimelineScale;

      const newMaxTimelineScale = Math.min(
        maxTimelineScale,
        Math.floor(waveform.sample_rate / waveform.scale),
      ); // MAX_WAVEFORM_SCALE = 256 is max scale for waveform

      if (timelineScale > newMaxTimelineScale) {
        videoCreator.timelineScale = newMaxTimelineScale;
        videoCreator.maxTimelineScale = newMaxTimelineScale;
        timelineScale = newMaxTimelineScale;
      }

      let scale = Math.floor(waveform.sample_rate / timelineScale);

      if (currentResampledWaveform?.scale !== scale) {
        console.log('resampling waveform for', url, scale);
        const resampledWaveform = waveform.resample({ scale });
        if (isOriginalVideo) {
          videoCreator.resampledOriginalWaveForm = resampledWaveform;
        } else {
          //@ts-ignore
          videoCreator.audioTracksData[url].resampledWaveform =
            resampledWaveform;
        }
      }
    },
    [waveform],
  );

  const debounceGenerateWaveform = useCallback(
    debounce((scale) => generateWaveform(scale), 2050),
    [waveform],
  );
};
