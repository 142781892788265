import React from 'react';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createApi } from 'unsplash-js';
import {
  ImageKey,
  ImageWithType,
  PunchListItemReplacement,
} from '../../types.ts/general';
import { runInAction } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import PhotoModal from '../common/PhotosModal';
import { FileData, PunchListItem } from '../../types.ts/story';
import RotatingSquare from '../../svgs/RotatingSquare';
import styled, { css } from 'styled-components';
import { getRandomFileName } from '../../utility/general';

type Props = {
  modalItem: PunchListItem;
  setReplacement: Dispatch<SetStateAction<PunchListItemReplacement | null>>;
  onClose: () => void;
  openPrevModal: () => void;
};

type Img = {
  url: string;
  alt: string;
  width: string;
  height: string;
};

const unsplashApi = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY as string,
});
const GeneratedPunchListModalItems = (props: Props) => {
  const { modalItem, setReplacement, onClose } = props;
  const [stock, setStock] = useState<Img[]>();
  const [ai, setAi] = useState<(Img & { id: string })[]>();
  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const aiRef = useRef(false);
  const stockRef = useRef(false);
  const [keywords, setKeywords] = useState<string[]>(
    modalItem.stockKeywords
      ?.trim()
      ?.split(',')
      .map((k) => k.trim()),
  );

  const [dallePrompt, setDallePrompt] = useState<string>(modalItem.dallePrompt);

  const generateAIImages = useCallback(() => {
    (async () => {
      const aiResponse =
        modalItem.dalleImages?.map((data) => ({
          url: data.uploadUrl,
          id: data.uploadId,
          alt: '',
          width: '',
          height: '',
        })) || [];

      setAi(aiResponse);
    })();
  }, [ai]);

  const generateStockImages = useCallback(() => {
    (async () => {
      const { response } = await unsplashApi.search.getPhotos({
        query: modalItem.stockKeywords,
        orientation: 'landscape',
      });
      const stockData = (response?.results?.map((res) => ({
        url: res.urls.regular,
        alt: res.alt_description,
        width: res.width,
        height: res.height,
      })) || []) as unknown as Img[];
      setStock(stockData);
    })();
  }, [stock]);

  useEffect(() => {
    if (aiRef.current) return;
    try {
      generateAIImages();
    } catch (error) {
      console.log(error);
    }
    return () => {
      aiRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (stockRef.current) return;
    try {
      generateStockImages();
    } catch (error) {
      console.log(error);
    }
    return () => {
      stockRef.current = true;
    };
  }, []);

  const replaceAction = async () => {
    if (setReplacement && selectedImage) {
      try {
        if (selectedImage.type === 'ai') {
          setIsLoading(true);

          let uploadId = selectedImage.id;
          let url = selectedImage.url;

          if (!uploadId && url.startsWith('data:image')) {
            const fileName = getRandomFileName(dallePrompt);
            const newPhotoData: FileData & { fileName: string } = {
              type: 'ai',
              url: selectedImage.url,
              fileName,
              alt: dallePrompt.slice(0, 150),
              title: dallePrompt.slice(0, 150),
            };
            const newUpload =
              await videoCreator.assetRepository?.uploadFile(newPhotoData);
            uploadId = newUpload!.id;
            url = newUpload!.url;
          }

          runInAction(() => {
            videoCreator.photoDataForDato = {
              ...videoCreator.photoDataForDato,
              [modalItem.id!]: {
                type: selectedImage.type,
                url, //todo deleted some stuff when merging
                uploadId,
              },
            };
          });
          setReplacement({
            ...selectedImage,
            url: url || '',
            id: modalItem.id!,
          });
        } else {
          runInAction(() => {
            videoCreator.photoDataForDato = {
              ...videoCreator.photoDataForDato,
              [modalItem.id!]: {
                type: selectedImage.type,
                url: selectedImage.url,
                fileName: '', //todo
              },
            };
          });
          setReplacement({
            ...selectedImage,
            id: modalItem.id!,
          });
        }
      } catch (error) {
        console.log('error:', error);
      } finally {
        setIsLoading(false);
        props.onClose();
      }
    }
  };

  const TopComponent = () => (
    <TopWrapper>
      <SubTitle>Swap or search for a new image.</SubTitle>

      <Content>
        <Image>
          <img src={modalItem.artifactSrc} />
        </Image>
        <div className="content">
          <div className="line">{modalItem.line}</div>
          <div className="description">{modalItem.description}</div>
        </div>
      </Content>
    </TopWrapper>
  );

  const BottomComponent = () => {
    return (
      <>
        <ReplaceButton isActive={!!selectedImage} onClick={replaceAction}>
          <RotatingSquare
            strokeColor={!selectedImage ? '#484848' : undefined}
          />{' '}
          Replace Image
        </ReplaceButton>
      </>
    );
  };

  return (
    <PhotoModal
      TopComponent={<TopComponent />}
      externalPhotos={{ ai, stock }}
      onCloseSelf={props.onClose}
      openPrevModal={props.openPrevModal}
      setSelectedImage={setSelectedImage}
      selectedImage={selectedImage}
      isLoading={isLoading}
      keywords={keywords}
      setKeywords={setKeywords}
      dallePrompt={dallePrompt}
      setDallePrompt={setDallePrompt}
      BottomComponent={<BottomComponent />}
      searchBarRadius="10px"
    />
  );
};

export default GeneratedPunchListModalItems;

const SubTitle = styled.h5`
  color: #f3e9d7;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;
`;
const Image = styled.div`
  width: min(215px, 45%);
  height: 116px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: top;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .content {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .line,
    .description {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 40px;
      overflow-y: hidden;
    }
    .line {
      color: #45d483;
      font-size: 12px;
    }
    .description {
      color: #bdbdbd;
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
    }
  }
`;

const ReplaceButton = styled.button<{ isActive: boolean }>`
  display: flex;
  padding: 11px 20px 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  background: #45d483;
  color: #03041a;
  border: 0;
  outline: 0;
  margin: 20px auto;
  margin-bottom: 0;
  width: 50%;
  font-weight: 700;

  ${(props) =>
    !props.isActive &&
    css`
      background: #03041a;
      border: 1px solid #484848;
      color: #484848;
    `}
`;

const TopWrapper = styled.div`
  padding: 20px;
  margin-bottom: 10px;
`;
