import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { videoCreator } from "../../stores/VideoCreatorStore";
import { TranscriptChange, TranscriptTextElement, getClosestTextIndexToLeft, getClosestTextIndexToRight, getRoundedTo } from "../../videoTranscriptionProcessor/utils";
var parseString = require('xml2js').parseString;
import { v4 as uuid } from 'uuid';
import DocumentIcon from "../../svgs/DocumentIcon";
import Modal from "../common/Modal";
import SpinningLoading from "../SpinningLoading";
import { ProduceIcon } from "../common/icons";

interface ClipItem {
  start: number;
  end: number;
  in: number;
  out: number;
  duration: number;
}

type Props = {
  onUpload: () => void;
}

export const XmlUpload = observer(({ onUpload }: Props) => {

  const originalElements = videoCreator.originalTranscription?.elements;
  const [uploadButtonActive, setUploadButtonActive] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<{ type: 'error' | 'loading', message?: string } | null>(null);

  function parseXML(xmlString: string): Promise<{ name: string, clipItems: ClipItem[] }> {
    return new Promise((resolve, reject) => {
      parseString(xmlString, (err: any, result: any) => {
        if (err) {
          reject(err);
        } else {
          const name = result.xmeml.sequence[0].name[0];
          const clipItems: ClipItem[] = result.xmeml.sequence[0].media[0].video[0].track[0].clipitem.map((clip: any) => ({
            start: parseInt(clip.start[0], 10),
            end: parseInt(clip.end[0], 10),
            in: parseInt(clip.in[0], 10),
            out: parseInt(clip.out[0], 10),
            duration: parseInt(clip.duration[0], 10)
          }));
          resolve({ name, clipItems });
        }
      });
    });
  }

  const getEmptyVideoSource = () => {
    return {
      output_format: 'mp4',
      width: 1280,
      height: 720,
      frame_rate: '24 fps',
      // duration: this.originalVideoDuration, - let duration autoadjust
      elements: [] as any[],
    };
  }

  const generateSourceAndTranscriptionChanges = (clipItems: ClipItem[]) => {
    if (!originalElements) throw new Error('No transcription elements loaded');
    // debugger;
    const videoSource = getEmptyVideoSource();
    const restoreChanges: TranscriptChange[] = [];
    const firstTextElement = getClosestTextIndexToRight(0, originalElements);
    const lastTextElement = getClosestTextIndexToLeft(originalElements.length - 1, originalElements);
    const firstElement = originalElements[firstTextElement] as TranscriptTextElement;
    const lastElement = originalElements[lastTextElement] as TranscriptTextElement;
    const timeBufferBefore = firstElement.ts;
    const timeBufferAfter = videoCreator.story!.originalVideo.video.duration - lastElement.end_ts;
    const removeAllChange: TranscriptChange = {
      type: 'remove',
      index: 0,
      count: originalElements.length,
      timeBufferBefore,
      timeBufferAfter,
      oldValue: 'all',
      newValue: null,
      datetime: new Date().toISOString(),
      version: 2,
    };

    let maxRestoredIndex = -1;
    let addedCounter = 0;

    for (let i = 0; i < clipItems.length; i++) {
      const clipItem = clipItems[i];
      const newTs = getRoundedTo(clipItem.start / 24, 0.01);
      const endTs = getRoundedTo(clipItem.end / 24, 0.01);
      const duration = endTs - newTs;

      const fromTs = getRoundedTo(clipItem.in / 24, 0.01); // todo round to 4 decimal places
      const fromElementIndex = originalElements.findIndex(el => el.end_ts && el.end_ts >= fromTs && fromTs - el.ts < el.end_ts - fromTs);

      const toTs = fromTs + duration;
      let toElementIndex = originalElements.findIndex(el => el.end_ts && el.end_ts >= toTs);

      if (fromElementIndex >= 0) {
        if (toElementIndex === -1) {
          toElementIndex = lastTextElement;
        }
        if (originalElements[toElementIndex].ts && originalElements[toElementIndex].end_ts && (toTs - originalElements[toElementIndex].ts! < originalElements[toElementIndex].end_ts! - toTs)) {
          toElementIndex = getClosestTextIndexToLeft(toElementIndex - 1, originalElements);
        }

        if (toElementIndex >= fromElementIndex) {
          const timeBufferBefore = originalElements[fromElementIndex].ts! - fromTs;
          const timeBufferAfter = toTs - originalElements[toElementIndex].end_ts!;
          if (fromElementIndex > maxRestoredIndex) {
            restoreChanges.push({
              type: 'restore',
              index: fromElementIndex + addedCounter,
              count: toElementIndex - fromElementIndex + 1 + 1, // +1 to add space or punctuation
              newTs,
              timeBufferBefore,
              timeBufferAfter,
              datetime: new Date().toISOString(),
            })
            maxRestoredIndex = toElementIndex + addedCounter + 1 + 1;
          } else {
            restoreChanges.push({
              type: 'insert_text',
              index: fromElementIndex,
              count: toElementIndex - fromElementIndex + 1 + 1, // +1 to add space or punctuation
              newTs,
              newIndex: maxRestoredIndex + addedCounter,
              timeBufferBefore,
              timeBufferAfter,
              datetime: new Date().toISOString(),
            })
            addedCounter += toElementIndex - fromElementIndex + 1 + 1;
          }
        }
      }

      let sourceUrl = videoCreator.story!.originalVideo.url;
      // debugger;
      videoSource.elements.push({
        id: uuid(),
        track: 1,
        time: newTs,
        trim_start: fromTs,
        duration,
        type: 'video',
        source: sourceUrl,
      })
    }

    return { videoSource, transcriptionChanges: [removeAllChange, ...restoreChanges] }
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files || [])];
    if (!files.length) return;
    const file = files[0];
    try {
      setModalMessage({ type: 'loading' });
      const xmlContent = await file.text();
      const { name, clipItems } = await parseXML(xmlContent);
      console.log('clipItems', clipItems)
      const { videoSource, transcriptionChanges } = generateSourceAndTranscriptionChanges(clipItems);
      videoCreator.createNewVideoFromSource(name || `${videoCreator.storyName} - imported`, videoSource, transcriptionChanges, {}, 'creator-studio');
      setModalMessage(null);
      onUpload();
    } catch (error) {
      console.error('Error parsing XML', error);
      setModalMessage({ type: 'error', message: '' });
    }
  }

  return (
    <div style={{ width: '100%', height: '100%' }}
      onClick={() => {
        setUploadButtonActive(true);
        setTimeout(() => setUploadButtonActive(false), 3000);
      }}>
      <AddFileButton isActivated={uploadButtonActive}>
        <DocumentIcon width="15" height="16" /> Import XML
      </AddFileButton>
      <File
        type="file"
        accept={'text/xml'}
        onChange={handleFileChange}
      />

      {modalMessage && (
        <Modal
          isOpen={modalMessage !== null}
          closeModal={modalMessage.type === 'error' ? () => {
            setModalMessage(null);
          } : undefined}
        >
          <Wrapper>
            <Heading>
              {modalMessage.type === 'loading' && (
                <SpinningLoading
                  Ico={<DocumentIcon width={'24'} height={'26'} strokeColor="#17c964" />}
                  text={'Parsing XML...'}
                />
              )}
            </Heading>
            {modalMessage.type === 'error' && (
              <ErrorMessage>
                {`Error parsing XML\n ${modalMessage.message}`}
              </ErrorMessage>
            )}
          </Wrapper>
        </Modal>
      )}

    </div>
  )
})

const File = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const AddFileButton = styled.button<{ isActivated?: boolean }>`
  background-color: transparent;
  padding: 3px 0;
  font-size: 12px;
  font-family: Inter;
  color: #f3e9d7;

  &:hover {
    color: rgb(242, 208, 147);
  }

  outline: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  flex: 1;
  cursor: pointer;
  opacity: ${(props) => (props.isActivated ? '1' : '0.9')};
`;


const Wrapper = styled.div`
  width: 380px;
  height: 210px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;


const Heading = styled.div`
  color: #45d483;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
`;

const ErrorMessage = styled.div`
  color: #f05757;
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
`