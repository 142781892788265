import React, { useState, FormEvent } from 'react';
import Modal from '../Modal';
import styled from 'styled-components';
import RotatingSquare from '../../svgs/RotatingSquare';

type Props = {
  onClose: () => void;
  title: string;
  subTitle?: string;
  placeholder?: string;
  cta?: string;
  handleGenerate: (value: string) => void;
};
const BrandVoiceRevisionModal = (props: Props) => {
  const {
    placeholder = 'Describe your voice',
    subTitle = 'Make a revision',
    cta = 'Tell us what you want to be different',
  } = props;
  const [value, setValue] = useState('');

  const handleAdjustInputHeight = (e: FormEvent<HTMLTextAreaElement>) => {
    const textarea = e.target as HTMLTextAreaElement;
    textarea.style.height = textarea.scrollHeight + 'px';
  };
  return (
    <Modal isOpen={true} onClose={props.onClose}>
      <Title>{props.title}</Title>
      <SubTitle>{subTitle}</SubTitle>

      <Content>
        <span>{cta}</span>
        <Description
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder={placeholder}
          onInput={(e) => handleAdjustInputHeight(e)}
        />
      </Content>
      <Action>
        <Button onClick={() => props.handleGenerate(value)}>
          <RotatingSquare />
          Regenerate
        </Button>
      </Action>
    </Modal>
  );
};

export default BrandVoiceRevisionModal;

const Title = styled.h3`
  color: #f2d093;
  font-size: 12px;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
`;
const SubTitle = styled.h4`
  color: #f3e9d7;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
`;

const Content = styled.div`
  text-align: left;
  span {
    color: #f3e9d7;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;
const Description = styled.textarea`
  box-sizing: border-box;
  display: flex;
  padding: 15px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  width: 100%;
  resize: none;
  height: 50px;
`;

const Button = styled.button`
  display: flex;
  padding: 11px 16px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  color: #03041a;
  background-color: #17c964;
  width: fit-content;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  outline: 0;
  border: 0;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
`;
