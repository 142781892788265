import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled, { css } from 'styled-components';

interface PropertyNumericalInputProps {
  activeElement?: ElementState;
  propertyName: string;
  defaultValue: any;
  unit: string;
  getInputValue?: any;
  manualKaraoke?: boolean;
  padding?: string;
  customStyles?: string;
}

function byString(o: any, s: string) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const PropertyNumericalInput: React.FC<PropertyNumericalInputProps> =
  observer((props) => {
    const elementByString = () => {
      if (!props.activeElement) return null;
      return byString(props.activeElement?.source, props.propertyName);
    };
    const [value, setValue] = React.useState(
      () => elementByString() ?? props.defaultValue,
    );
    const [changed, setChanged] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    React.useEffect(() => {
      let value = elementByString() ?? props.defaultValue;
      setValue(value);
    }, [
      props.activeElement?.source,
      elementByString(),
      props.propertyName,
      props.defaultValue,
    ]);

    let displayValue = value;

    if (!isNaN(value) && !editing) {
      displayValue += ` ${props.unit}`;
    }

    return (
      <TextInput
        value={displayValue}
        padding={props.padding}
        manualKaraoke={props.manualKaraoke}
        customStyles={props.customStyles}
        onFocus={(e) => {
          setEditing(true);
          e.target.select();
          setChanged(false);
        }}
        onChange={async (e) => {
          setValue(e.target.value);
          setChanged(true);
        }}
        onBlur={async (e) => {
          if (changed && props.getInputValue) {
            setEditing(false);
            return await props.getInputValue(
              props.propertyName,
              e.target.value,
            );
          }
          if (
            changed &&
            props.activeElement &&
            e.target.value !== elementByString()
          ) {
            await videoCreator.renderer?.applyModifications({
              [`${props.activeElement?.source.id}.${props.propertyName}`]:
                e.target.value,
            });
          }
          setEditing(false);
        }}
      />
    );
  });

const TextInput = styled.input<{ manualKaraoke?: boolean; padding?: string; customStyles?: string }>`
  display: flex;
  resize: none;
  padding: 10px 15px;
  margin: 10px 0;
  color: #fff;
  background: #2b3035;
  border: none;
  border-radius: 5px;

  &:focus {
    outline: 1px solid #2a85ff;
  }

  ${(props) =>
    props.manualKaraoke &&
    css`
      outline: none;
      border: 1px solid #484848;
      background: transparent;
      color: #f3e9d7;
      margin: 0;
      padding: ${props.padding ?? '14px 15px'};
      border-radius: 8px;
      width: inherit;

      &:focus {
        outline: 0;
      }
    `}

    ${(props) =>
    props.customStyles &&
    css`
          ${props.customStyles}
        `}
    

`;
