import React from 'react';

type Props = {
  width?: string;
  height?: string;
  strokeWidth?: number;
  strokeColor?: string;
  fillColor?: string;
};
const ExpandIcon = (props: Props) => {
  const {
    width = '40',
    height = '40',
    strokeColor = 'black',
    fillColor = '#17C964',
    strokeWidth = 2,
  } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none">
      <rect width={width} height={height} rx="20" fill={fillColor} />
      <path
        d="M27.5005 27.5L21.3467 21.3461"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7313 21.3461H21.3467V26.7307"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12.5L18.6538 18.6538"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2695 18.6539H18.6541V13.2693"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandIcon;
