import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import _uniqBy from 'lodash/uniqBy';

import AIGenerateCard from '../common/AIGenerateCard';
import { videoCreator } from '../../stores/VideoCreatorStore';

import { PunchListItem, Story } from '../../types.ts/story';
import {
  AIProducerCard,
  PunchListImageGeneration,
  PunchListItemReplacement,
  SidebarOption,
} from '../../types.ts/general';
import { runInAction } from 'mobx';
import Modal from '../Modal';

import FeelingLuckyIcon from '../../svgs/FeelingLuckyIcon';
import SpinningLoading from '../SpinningLoading';
import TriggerPunchListGenerateModal from './TriggerPunchListGenerateModal';
import GeneratedPunchListModalItems from './GeneratedPunchListModalItems';
import { TranscriptElement } from '../../videoTranscriptionProcessor/utils';
import ChatGPTService from '../../services/ChatGPTService';
import PunchListContentItem from './PunchListContentItem';
import { delay } from '../../utility/general';

type Props = {
  story?: Story;
  selectedCard?: AIProducerCard;
};

const AIPhotoPunchListProducer: FC<Props> = observer((props) => {
  const [generatingMissingAiImage, setGeneratingMissingAiImage] =
    useState<boolean>(false);
  const [openGeneratedImages, toggleGeneratedImages] =
    useState<PunchListImageGeneration | null>(null);
  const [replacement, setReplacement] =
    useState<PunchListItemReplacement | null>(null);
  const [selectedPunchListId, setSelectedPunchListId] = useState<string>();

  const [selectedAction, setSelectedAction] = useState<string>('photoHunt');
  const [modalItem, toggleModal] = useState<{
    item: PunchListItem | null;
    archive: PunchListItem | null;
  }>({ item: null, archive: null });
  const { story } = props;
  const { punchListData: punchList, punchListGenerateCount } = videoCreator;

  const [loading, setLoading] = useState(false);

  const storyTranscription = videoCreator.finalTranscriptionElements
    ?.map((e) => e.value || '')
    .join('');

  function getPunchListItemPosition(
    punchListItem: PunchListItem,
    transcriptElements?: TranscriptElement[],
  ): {
    transcriptPosition: {
      startIndex: number;
      endIndex: number;
    };
    duration: number;
    startTime: number;
    endTime: number;
  } | null {
    let targetSentence = punchListItem.line;
    // loop through all transcript elements and track the index of the target sentence across multiple elements
    if (!transcriptElements || !targetSentence) return null;

    let targetSentenceCurrIndex = 0;
    let currElementIndex = 0;
    let targetSentenceElementStartIndex = 0;
    let targetSentenceElementEndIndex = 0;
    let found = false;
    while (currElementIndex < (transcriptElements?.length - 1 || 0)) {
      let currElementValue = transcriptElements[currElementIndex].value;

      // if element contains the start of the target sentence save it as the start of the index
      if (
        currElementValue &&
        transcriptElements[currElementIndex]?.state !== 'removed' &&
        transcriptElements[currElementIndex]?.state !== 'cut'
      ) {
        // console.log('comparing X', targetSentence.substring(targetSentenceCurrIndex, targetSentenceCurrIndex + currElementValue.length).length, 'X to X', currElementValue.length, 'X')
        if (
          targetSentence.substring(
            targetSentenceCurrIndex,
            targetSentenceCurrIndex + currElementValue.length,
          ) === currElementValue
        ) {
          if (targetSentenceCurrIndex === 0) {
            targetSentenceElementStartIndex = currElementIndex;
          }
          targetSentenceCurrIndex += currElementValue.length;
        } else {
          targetSentenceCurrIndex = 0;
        }
      }
      if (targetSentenceCurrIndex === targetSentence.length) {
        targetSentenceElementEndIndex = currElementIndex;
        found = true;
        // punchListItem.found = true;
        break;
      }
      currElementIndex++;
    }

    // RETURN if not found
    if (!found) return null;

    let ts = null;
    let end_ts = null;
    // loop through all transcript elements and track the index of the target sentence across multiple elements
    currElementIndex = targetSentenceElementStartIndex;
    while (currElementIndex <= targetSentenceElementEndIndex) {
      if (videoCreator.finalTranscriptionElements) {
        const currElementFinal =
          videoCreator.finalTranscriptionElements[currElementIndex];

        if (currElementFinal) {
          if (!ts && currElementFinal.ts) {
            ts = currElementFinal.ts;
          }
          if (currElementFinal.end_ts) {
            end_ts = currElementFinal.end_ts;
          }
        }
      }
      currElementIndex++;
    }

    // todo set into current video punchList
    // add changes to transcript
    videoCreator.videoTranscriptionProcessor.addPhotoHighlight(
      targetSentenceElementStartIndex,
      targetSentenceElementEndIndex,
      punchListItem.id!,
    );
    console.log('ts, end_ts', ts, end_ts);
    if (!ts || !end_ts) return null;

    return {
      transcriptPosition: {
        startIndex: targetSentenceElementStartIndex,
        endIndex: targetSentenceElementEndIndex,
      },
      duration: Math.min(8, end_ts - ts),
      startTime: ts,
      endTime: end_ts,
    };
  }

  // todo move to separate class (AIProducer?)
  const handleGeneratePhotoPunchList = async () => {
    try {
      setLoading(true);
      // SAVE CURRENT VIDEO BEFORE GENERATING PUNCHLIST

      // delete elments that are related to the current photo punchlist
      let punch_list_element_ids = punchList?.map((pld) => pld.id) || [];
      let to_delete = videoCreator.state?.elements.filter(
        (e) => e.source.id && punch_list_element_ids.includes(e.source.id),
      );
      let to_delete_ids = to_delete?.map((e) => e.source.id);
      console.log('to_delete_ids', to_delete_ids);
      if (to_delete_ids) {
        for (let i = 0; i < to_delete_ids.length; i++) {
          const id = to_delete_ids[i];
          await videoCreator.deleteElementWithTranscription(id);
        }
      }

      // delete previous transcript photo highlight changes
      await videoCreator.removeAllPhotoHighlightTranscriptChanges();
      let punchListResponse: PunchListItem[] | undefined = undefined;
      if (punchListGenerateCount === 0 && story?.punchList) {
        await delay(4500);
        punchListResponse = story?.punchList;
      } else if (punchListGenerateCount === 1 && story?.followUpPunchList) {
        await delay(4500);
        punchListResponse = story?.followUpPunchList;
      } else {
        const gptService = new ChatGPTService();
        videoCreator.punchListLoading = true;
        punchListResponse = await gptService.regenerateResponse(
          'Photo Punchlist',
          videoCreator.punchListData!,
        );
        videoCreator.punchListLoading = false;
      }

      console.log('punchListResponse', punchListResponse);

      if (!punchListResponse) return;

      const punchListData = punchListResponse?.map((obj: any) => {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            `${key.slice(0, 1).toLowerCase()}${key.slice(1)}`,
            value,
          ]),
        );
      }) as PunchListItem[];
      const formattedResponse = { punchList: punchListData };

      if (!formattedResponse) throw new Error('No response from GPT');

      // find the transcript elements that match the target sentence and set a flag to highlight in the
      // transcript
      const transcriptElements = videoCreator.finalTranscriptionElements;
      const maxTrack = videoCreator.getMaxTrack();
      const currTrack = maxTrack + 1;

      for (let i = 0; i < formattedResponse.punchList.length; i++) {
        let punchListItem = formattedResponse.punchList[i];

        let targetPosition = getPunchListItemPosition(
          punchListItem,
          transcriptElements,
        );

        console.log('target position', targetPosition);

        if (targetPosition) {
          await videoCreator.addPunchListItemToTimeline(
            punchListItem,
            targetPosition.duration,
            targetPosition.startTime,
            targetPosition.endTime,
            currTrack,
          );
          punchListItem.transcriptPosition = targetPosition.transcriptPosition;
        } else {
          console.log('could not add', punchListItem.line, 'in transcript');
          // punchListItem.found = false;
        }
      }

      formattedResponse.punchList = formattedResponse.punchList.filter(
        (punchListItem) => punchListItem.transcriptPosition,
      );

      videoCreator.punchListData = formattedResponse.punchList;
      setLoading(false);

      let punchListItems = formattedResponse.punchList;

      // todo set into current video
      // todo type safety
      videoCreator.currentVideo!.punchList = punchListItems;

      videoCreator.punchListGenerateCount = punchListGenerateCount + 1;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const generateMissingPunchList = async () => {
    try {
      setGeneratingMissingAiImage(true);
      await videoCreator.generateMissingPunchListPhotos();
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratingMissingAiImage(false);
    }
  };

  useEffect(() => {
    if (!replacement?.id || !punchList) return;
    const { id, url } = replacement;
    const idx = punchList.findIndex((p: any) => p.id === id);
    punchList[idx] = { ...punchList[idx], artifactSrc: url };
    let time = punchList[idx].startTime || null;

    runInAction(async () => {
      videoCreator.punchListData = punchList;
      await videoCreator.findOrReplaceInTimeline(id, url, null, time);
      await videoCreator.setActiveElements(id);
      videoCreator.sidebarOptions = SidebarOption.editing;
    });
  }, [replacement]);

  return (
    <Main>
      {(generatingMissingAiImage || videoCreator.punchListLoading) && (
        <SpinningLoading
          positionTop="20px"
          text={
            videoCreator.punchListLoading
              ? 'Generating punchlist data'
              : undefined
          }
        />
      )}
      {videoCreator.savingStockPhoto && (
        <SpinningLoading
          positionTop="20px"
          text="Saving photo"
        />
      )}

      <ButtonGroup>
        <AIGenerateCard
          handleClick={async () => {
            setSelectedAction('photoHunt');
            await handleGeneratePhotoPunchList();
          }}
          unsetWidth={true}
          strokeColor={selectedAction !== 'photoHunt' ? '#484848' : ''}
          text={loading ? 'Generating...' : 'Photo Hunt'}
        />
        <AIGenerateCard
          handleClick={async () => {
            setSelectedAction('feelingLucky');
            await generateMissingPunchList();
          }}
          unsetWidth={true}
          text="Feeling Lucky"
          disabled={!punchList?.length}
          Icon={
            <FeelingLuckyIcon
              strokeColor={punchList?.length ? '#17C964' : '#484848'}
            />
          }
        />
      </ButtonGroup>

      {/* {modalItem?.item && (
        <Modal
          isOpen={!!modalItem}
          onClose={() => toggleModal({ ...modalItem, item: null })}
          paddingHorizontal="0"
        >
          <TriggerPunchListGenerateModal
            modalItem={modalItem.item}
            onClose={() => toggleModal({ ...modalItem, item: null })}
            toggleGeneratedImages={toggleGeneratedImages}
            openGeneratedImages={openGeneratedImages}
          />
        </Modal>
      )} */}
      {modalItem?.item && (
        <Modal
          isOpen={!!modalItem}
          onClose={() => toggleModal({ ...modalItem, item: null })}
          paddingHorizontal="0"
          customWidth="560px"
        >
          <GeneratedPunchListModalItems
            // {...openGeneratedImages}
            modalItem={modalItem.item}
            setReplacement={setReplacement}
            onClose={() => toggleModal({ ...modalItem, item: null })}
            openPrevModal={() =>
              toggleModal({
                ...(modalItem || {}),
                item: (modalItem || {}).archive,
              })
            }
          />
        </Modal>
      )}
      <Content>
        {punchList
          ?.map((punchListItem) => (
            <PunchListContentItem
              punchListItem={punchListItem}
              selectedPunchListId={selectedPunchListId}
              setSelectedPunchListId={setSelectedPunchListId}
              toggleModal={toggleModal}
            />
          ))
          .filter(Boolean)}
      </Content>
    </Main>
  );
});

export default AIPhotoPunchListProducer;

const Main = styled.div``;
const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;
