import './styles/globals.css';
import {
  connect,
  IntentCtx,
  RenderFieldExtensionCtx,
  RenderModalCtx,
} from 'datocms-plugin-sdk';
import { render } from './utils/render';
import ConfigScreen from './entrypoints/ConfigScreen';
import FinalVideo from './entrypoints/FinalVideo';
import EditorEntry from './entrypoints/EditorEntry';
import 'datocms-react-ui/styles.css';

const isInIframe = window.location !== window.parent.location;

if (isInIframe) {
  connect({
    renderConfigScreen(ctx) {
      return render(<ConfigScreen ctx={ctx} />);
    },
    manualFieldExtensions(ctx: IntentCtx) {
      return [
        {
          id: 'videoEditor',
          name: 'Video Editor',
          type: 'addon',
          fieldTypes: ['link'],
        },
      ];
    },
    renderFieldExtension(
      fieldExtensionId: string,
      ctx: RenderFieldExtensionCtx,
    ) {
      switch (fieldExtensionId) {
        case 'videoEditor':
          return render(<FinalVideo ctx={ctx} />);
      }
    },
  });
} else {
  render(<EditorEntry />);
}
