import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { keyframes } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { TranscriptContextMenu } from './TranscriptContextMenu';
import { WordReplacementModal } from './WordReplacementModal';
import { TranscriptHistoryIcon } from '../common/icons';
import {
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedElementIndexToLeft,
  getClosestRemovedIndexToRight,
  getClosestRemovedIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
} from '../../videoTranscriptionProcessor/utils';
import { useOutsideAlerter } from './useClickOutside';
import { ImageKey, ImageWithType, SidebarOption, TranscriptContextMenuActions } from '../../types.ts/general';
import { TranscriptionDiff } from './TranscriptionDiff';
import { TranscriptionText } from './TranscriptionText';
import ReplaceIcon from '../../svgs/ReplaceIcon';
import { RegenerateTranscriptionModal } from './RegenerateTranscriptionModal';
import { SubtitlesContextMenuActions, TranscriptionSubtitles } from './TranscriptionSubtitles';
import Modal from '../Modal';
import PhotoModal from '../common/PhotosModal';
import PhotoModalTop from '../common/PhotoModalTop';
import { saveAssetToDato } from '../../utility/general';

const NAV_HEIGHT = 68;

export const Transcript: React.FC = observer(() => {
  const [cursor, setCursor] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  });
  const [lastTimeUpdated, setLastTimeUpdated] = useState<number>(0);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [contextMenuActions, setContextMenuActions] = useState<TranscriptContextMenuActions[] | null>(null)
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(-1);
  const [showReplaceModal, setShowReplaceModal] = useState<Record<
    'startIndex' | 'endIndex',
    number
  > | null>(null);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showReplaceWarning, setShowReplaceWarning] = useState<boolean>(false);
  const [showSubtitles, setShowSubtitles] = useState<boolean>(false);
  const [photoSelection, togglePhotoSelectionModal] = useState<{
    loc: Record<'startIndex' | 'endIndex', number>,
    text: string
  } | null>(null);
  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  // autoscroll transcript when playing
  useEffect(() => {
    setAutoScroll(videoCreator.isPlaying);
  }, [videoCreator.isPlaying]);

  useEffect(() => {
    if (!autoScroll) return;
    scrollRef?.current
      ?.querySelector(
        `[data-index='${Math.min(
          cursor.to + (cursor.to < 10 ? 1 : 10),
          (videoCreator.finalTranscriptionElements?.length || 0) - 1,
        )}']`,
      )
      ?.scrollIntoView({
        // behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }, [cursor, autoScroll]);

  // highlight words as video playing
  useEffect(() => {
    if (
      !videoCreator.finalTranscriptionElements ||
      (videoCreator.isPlaying && (videoCreator.time < lastTimeUpdated) && (lastTimeUpdated < videoCreator.time + 5)) ||
      Math.abs(lastTimeUpdated - videoCreator.time) < 0.1
    )
      return;
    setLastTimeUpdated(videoCreator.time);

    const cursor = getCursorPosition();
    setCursor(cursor);
  }, [videoCreator.finalTranscriptionElements, videoCreator.time]);

  function compensateForRemovedElementsPosition(index: number) {
    if (showHistory) return index;
    if (!videoCreator.finalTranscriptionElements) return 0;
    let i = 0;
    while (index > 0 && i < videoCreator.finalTranscriptionElements.length) {
      if (videoCreator.finalTranscriptionElements[i].state !== 'removed' &&
        videoCreator.finalTranscriptionElements[i].state !== 'cut'
      ) {
        index--;
      }
      i++;
    }
    return i;
  }

  // todo rework without depending on transcription elements
  function getBoundaryIndexesFromSelection(selection: Selection) {
    const anchorIndex: number =
      selection?.anchorNode?.parentElement === scrollRef.current
        ? compensateForRemovedElementsPosition(selection.anchorOffset) - 2 // -2 to compensate cursor position
        : Number(
          selection.anchorNode?.parentElement?.dataset?.index ||
          (selection.anchorNode as HTMLElement)?.dataset?.index,
        ); // cursor has index
    const focusIndex: number =
      selection?.focusNode?.parentElement === scrollRef.current
        ? compensateForRemovedElementsPosition(selection?.focusOffset) - 2 // -2 to compensate cursor position
        : Number(
          selection?.focusNode?.parentElement?.dataset?.index ||
          (selection.focusNode as HTMLElement)?.dataset?.index,
        ); // cursor has index

    if (isNaN(anchorIndex) || isNaN(focusIndex)) return null;

    let startIndex, endIndex, startOffset, endOffset;
    // selecting from left to right or from right to left
    if (anchorIndex >= focusIndex) {
      startIndex = focusIndex;
      endIndex = anchorIndex;
      startOffset = selection.focusOffset;
      endOffset = selection.anchorOffset;
    } else {
      startIndex = anchorIndex;
      endIndex = focusIndex;
      startOffset = selection.anchorOffset;
      endOffset = selection.focusOffset;
    }

    //todo: selection of transcript?
    // start and end of selection may be a little outside of text selected
    if (selection?.toString()?.length) {
      if (
        videoCreator.finalTranscriptionElements![startIndex]?.type ===
        'punct' &&
        startOffset
      ) {
        startIndex += 1;
      }

      // if (endOffset === 0) {
      //   endIndex -= 1;
      // }
    }
    return { startIndex, endIndex };
  }

  function getStartAndEndOfSelection() {
    const selection = window.getSelection();
    if (!(selection && selection.toString().length)) return null;

    const boundaries = getBoundaryIndexesFromSelection(selection);
    if (!boundaries) {
      selection.empty();
    }
    return boundaries;
  }

  const handleCursorMove = useCallback(
    (direction: 1 | -1) => {
      if (!videoCreator.finalTranscriptionElements) return;
      let newPosition;
      if (direction > 0) {
        newPosition = getClosestNotRemovedElementIndexToRight(
          cursor.from + 1,
          videoCreator.finalTranscriptionElements,
        )
        if (newPosition === -1) {
          newPosition = cursor.from + 1 > videoCreator.finalTranscriptionElements.length ? 0 : videoCreator.finalTranscriptionElements.length;
        }
      } else {
        newPosition = getClosestNotRemovedElementIndexToLeft(
          cursor.from - 1,
          videoCreator.finalTranscriptionElements,
        );
        newPosition =
          newPosition === -1
            ? videoCreator.finalTranscriptionElements.length
            : newPosition;
      }
      setAutoScroll(true);
      setCursor({
        from: newPosition || 0,
        to: (newPosition || 0) + 1,
      });

      // move playhead on timeline 
      if (!isNaN(newPosition) && videoCreator.finalTranscriptionElements[newPosition] && videoCreator.finalTranscriptionElements[newPosition].ts !== undefined) {
        videoCreator.setTime(videoCreator.finalTranscriptionElements[newPosition].ts || 0, true);
      }
    },
    [videoCreator.finalTranscriptionElements, cursor],
  );

  const handleAddPunctuation = (code: 'Comma' | 'Period' | 'Space') => {
    videoCreator.addPunctuation(code, cursor.from);
  }

  const handleTranscriptRestore = () => {
    if (videoCreator.isPlaying) return;
    if (!videoCreator.finalTranscriptionElements) return;

    let startIndex, endIndex;
    const boundaries = getStartAndEndOfSelection();
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
      startIndex = getClosestRemovedIndexToRight(
        startIndex,
        videoCreator.finalTranscriptionElements,
      );
      endIndex = getClosestRemovedIndexToLeft(
        endIndex,
        videoCreator.finalTranscriptionElements,
      );
      if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) return;
    } else if (selectedWordIndex > -1) {
      endIndex = startIndex = selectedWordIndex;
    } else {
      return;
    }

    videoCreator.restoreTranscriptAndVideo(startIndex, endIndex! + 1);
    setCursor({
      from: endIndex,
      to: endIndex + 1,
    });
  };

  const getSelectedStartEndIndex = () => {
    let startIndex, endIndex;
    const boundaries = getStartAndEndOfSelection();
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
    } else if (selectedWordIndex > -1) {
      endIndex = startIndex = selectedWordIndex;
    } else {
      return null;
    }
    return { startIndex, endIndex };
  };

  const handleHideKaraoke = () => {
    if (!videoCreator.finalTranscriptionElements) return;
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    videoCreator.hideKaraoke({ startIndex, endIndex });
  };

  const handleReplace = () => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    setShowReplaceModal({ startIndex, endIndex });
  };

  const handleTranscriptDelete = useCallback(() => {
    if (videoCreator.isPlaying) return;
    if (!videoCreator.finalTranscriptionElements) return;
    let startIndex, endIndex;
    const boundaries = getStartAndEndOfSelection();
    //debugger;
    if (boundaries) {
      ({ startIndex, endIndex } = boundaries);
    } else if (selectedWordIndex > 0) {
      endIndex = startIndex = getClosestNotRemovedElementIndexToLeft(selectedWordIndex, videoCreator.finalTranscriptionElements);
    } else if (cursor.to > 0) {
      endIndex = startIndex = getClosestNotRemovedElementIndexToLeft(cursor.from - 1, videoCreator.finalTranscriptionElements);
    } else {
      return;
    }

    let newPosition = Math.min(getClosestNotRemovedElementIndexToRight(
      endIndex + 1,
      videoCreator.finalTranscriptionElements,
    ), videoCreator.finalTranscriptionElements!.length)

    if (newPosition < 0) {
      newPosition = videoCreator.finalTranscriptionElements.length; //getClosestNotRemovedElementIndexToRight(endIndex, videoCreator.finalTranscriptionElements);
    }

    videoCreator.cutTranscriptAndVideo(startIndex, endIndex! + 1).then(() => {
      if (newPosition > 0) {
        const newTextPosition = getClosestNotRemovedTextIndexToRight(newPosition, videoCreator.finalTranscriptionElements!);
        if (newTextPosition > 0) {
          const newTime = videoCreator.finalTranscriptionElements?.at(newTextPosition)?.ts || 0;
          videoCreator.setTime(newTime, true);
          setCursor({
            from: newPosition,
            to: newPosition + 1,
          });
          setLastTimeUpdated(newTime)
        }
      }
    });
    setCursor({
      from: newPosition,
      to: newPosition + 1,
    });
  }, [videoCreator.finalTranscriptionElements, cursor, selectedWordIndex]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      console.log('code', e.code)
      switch (e.code) {
        case 'Backspace':
          handleTranscriptDelete();
          break;
        case 'ArrowRight':
        case 'ArrowLeft':
          handleCursorMove(e.code === 'ArrowRight' ? 1 : -1);
          break;
        case 'Space':
        case 'Comma':
        case 'Period':
          handleAddPunctuation(e.code);
          break;
      }
    },
    [handleTranscriptDelete],
  );

  // todo make sure keyboard events from other places are not intercepted
  useEffect(() => {
    if (showReplaceModal) return
    const container = document.getElementById("transcript-container");
    if (container) {
      container.addEventListener('keydown', handleKeyDown);
      return function cleanup() {
        container.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [handleKeyDown, showReplaceModal]);

  function getCursorPosition() {
    const currentWordIndex = videoCreator.finalTranscriptionElements!.findIndex(
      (el) =>
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.value &&
        el.end_ts &&
        el.end_ts > videoCreator.time,
    );

    return {
      from: currentWordIndex,
      to: currentWordIndex + 1,
    };
  }

  function skipToWordWithIndex(index: number) {
    if (!videoCreator.finalTranscriptionElements?.at(index)) return;
    if (videoCreator.finalTranscriptionElements.at(index)?.state === 'removed'
      || videoCreator.finalTranscriptionElements.at(index)?.state === 'cut') {
      return
    }
    const wasPlaying = videoCreator.isPlaying;
    let element = videoCreator.finalTranscriptionElements[index];
    if (element.type !== 'text') {
      element =
        videoCreator.finalTranscriptionElements[
        getClosestNotRemovedTextIndexToRight(
          index,
          videoCreator.finalTranscriptionElements,
        )
        ] ??
        videoCreator.finalTranscriptionElements[
        getClosestNotRemovedTextIndexToLeft(
          index,
          videoCreator.finalTranscriptionElements,
        )
        ];
    }
    videoCreator.setTime(element.ts!, true).then(() => {
      if (wasPlaying) {
        videoCreator.renderer?.play();
      }
      setCursor({
        from: index,
        to: index + 1,
      });
      setAutoScroll(true);
    });
  }

  function showOptionsForWordWithIndexAtPosition(
    index: number,
    positionXY: { x: number; y: number },
  ) {
    const actions = getActions(index);
    if (!actions.length) return;
    setSelectedWordIndex(index);
    setCursor({ from: index, to: index + 1 })
    setContextMenuPosition(positionXY);
    setContextMenuActions(actions);
  }

  const mouseEnter = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    scrollRef.current?.focus();
  }

  const mouseLeave = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    scrollRef.current?.blur();
  }

  async function handleMouseUp(e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) {
    if (e.button !== 0) return;
    await new Promise((resolve) => setTimeout(resolve, 200)); // hack to wait for selection to be reset properly, otherwise onmouseup old selection is captured
    const selection = window.getSelection();
    if (!(selection && selection.toString().length)) return null;
    const { startIndex, endIndex } =
      getBoundaryIndexesFromSelection(selection) || {};
    if (endIndex !== undefined && endIndex > -1) {
      showOptionsForWordWithIndexAtPosition(endIndex, {
        x: e.pageX,
        y: e.pageY - NAV_HEIGHT + 2,
      });
    } else if (startIndex !== undefined && startIndex > -1) {
      showOptionsForWordWithIndexAtPosition(startIndex, {
        x: e.pageX,
        y: e.pageY - NAV_HEIGHT + 2,
      });
    }
    if (
      endIndex &&
      endIndex > -1 &&
      videoCreator.finalTranscriptionElements![endIndex].state !== 'removed' &&
      videoCreator.finalTranscriptionElements![endIndex].state !== 'cut'
    ) {
      // do not set cursor on removed
      // videoCreator.setTime(videoCreator.finalTranscriptionElements![endIndex]?.ts || 0);
      setCursor({ from: endIndex, to: endIndex + 1 });
    }
  }

  const getActions = (
    selectedWordIndex: number,
  ): TranscriptContextMenuActions[] => {
    if (selectedWordIndex >= videoCreator.finalTranscriptionElements!.length) {
      if (videoCreator.videoTranscriptionProcessor.transcriptClipboard) {
        return ['pasteTextBefore'];
      }
      return [];
    }
    const selectedItem =
      videoCreator.finalTranscriptionElements![selectedWordIndex];

    const selection = window.getSelection();
    if (selection && selection.toString().length) {
      const { startIndex, endIndex } =
        getBoundaryIndexesFromSelection(selection) || {};
      if (showHistory && startIndex !== undefined && endIndex !== undefined
        && videoCreator.finalTranscriptionElements!.slice(startIndex, endIndex).some(
          (el) => (el.state === 'removed' || el.state === 'cut'))
      ) {
        return ['restore'];
      }
    }

    if (selectedItem.state === 'removed' || selectedItem.state === 'cut') return ['restore'];
    const menuActions = [] as TranscriptContextMenuActions[];
    if ((window.getSelection()?.toString()?.split(' ') || []).length <= 5) {
      // max 5 words to replace
      // replacing makes text set into a single transcription element so that all words will have single timestamp
      menuActions.push('replace');
      if (videoCreator.videoTranscriptionProcessor.transcriptClipboard) {
        menuActions.push('pasteTextBefore');
        // menuActions.push('pasteTextAfter');
      }
    }

    menuActions.push(
      selectedItem.state === 'muted' ? 'showKaraoke' : 'hideKaraoke',
    );

    if (selectedItem.photo_highlight_id) {
      return videoCreator.addedPunchListItemId
        ? [...menuActions, 'removeTextLoading', 'removePhotoLoading']
        : [...menuActions, 'removeText', 'removePhoto'];
    }

    return [
      ...menuActions,
      'removeText',
      'cutText',
      videoCreator.addedPunchListItemId ? 'addPhotoLoading' : 'addPhoto',
    ];
  };

  const handleRemovePhoto = async () => {
    if (videoCreator.addedPunchListItemId) return;
    const punchListId =
      videoCreator.finalTranscriptionElements![selectedWordIndex!]
        .photo_highlight_id;
    if (!punchListId) return;
    await videoCreator.removePunchListItem(punchListId);
    setSelectedWordIndex(-1)
  };

  const handleAddPhoto = async () => {
    if (!photoSelection || !selectedImage?.url) {
      togglePhotoSelectionModal(null)
      return
    };
    togglePhotoSelectionModal(null)
    if (!photoSelection.loc) return;

    let startIndex = getClosestNotRemovedTextIndexToRight(
      photoSelection.loc.startIndex,
      videoCreator.finalTranscriptionElements!,
    );

    let endIndex = getClosestNotRemovedTextIndexToLeft(
      photoSelection.loc.endIndex,
      videoCreator.finalTranscriptionElements!,
    );
    if (startIndex === -1 || endIndex === -1 || endIndex < startIndex) return;

    const startTime = videoCreator.finalTranscriptionElements![startIndex!].ts;
    const endTime = videoCreator.finalTranscriptionElements![endIndex!].end_ts;
    videoCreator.sidebarOptions = SidebarOption.aiProducer;

    const url = await saveAssetToDato(selectedImage)

    await videoCreator.addPhotoToPunchList(
      photoSelection.text,
      url,
      selectedImage.description || '',
      startIndex!,
      endIndex!,
      startTime!,
      endTime!,
    );
    setSelectedWordIndex(-1)
  };

  const handleCutText = () => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    videoCreator.cutSentence(startIndex, endIndex);
    setSelectedWordIndex(-1);
  }

  const handlePasteText = (after: boolean) => {
    const { startIndex, endIndex } = getSelectedStartEndIndex() || {};
    if (startIndex === undefined || endIndex === undefined) return;
    videoCreator.pasteSentence(after ? startIndex + 1 : startIndex);
    setSelectedWordIndex(-1);
  }

  const handleRemoveText = async () => {
    await handleRemovePhoto();
    handleTranscriptDelete();
    setSelectedWordIndex(-1);
  };

  const handleOpenPhotoModal = () => {
    const selection = window.getSelection();
    if (selection) {
      const boundaries = getBoundaryIndexesFromSelection(selection)
      const text = selection.toString()
      togglePhotoSelectionModal({ loc: boundaries!, text })
    }
  }

  const contextMenuCallback = async (
    action: TranscriptContextMenuActions | SubtitlesContextMenuActions | 'close',
  ) => {
    if (action === 'replace') {
      handleReplace();
    } else if (action === 'hideKaraoke') {
      handleHideKaraoke();
    } else if (action === 'removeText') {
      await handleRemoveText();
    } else if (action === 'removePhoto') {
      await handleRemovePhoto();
    } else if (action === 'addPhoto') {
      // handleAddPhoto();
      handleOpenPhotoModal()
    } else if (action === 'restore' || action === 'showKaraoke') {
      handleTranscriptRestore();
      setSelectedWordIndex(-1);
    } else if (action === 'cutText') {
      handleCutText();
    } else if (action === 'pasteTextAfter' || action === 'pasteTextBefore') {
      handlePasteText(action === 'pasteTextAfter');
    } else {
      setSelectedWordIndex(-1);
    }
    setContextMenuPosition(null);
  };
  const renderTranscription = () => {
    if (
      videoCreator.storyId &&
      !videoCreator.transcriptionId &&
      videoCreator.transcriptionLoadingStatus !== 'failed' &&
      videoCreator.transcriptionLoadingStatus !== 'generation_failed'
    ) {
      return (
        <div>
          <div style={{ width: '100%' }}>Transcript is regenerating...</div>
          {videoCreator.transcriptionLoadingStatus === 'none' && (
            <div>
              Click{' '}
              <PanelButton onClick={() => setShowReplaceWarning(true)}>
                <ReplaceIcon />
              </PanelButton>{' '}
              to Re-generate again
            </div>
          )}
        </div>
      );
    }
    if (
      !videoCreator.transcriptionId &&
      (videoCreator.transcriptionLoadingStatus === 'failed' ||
        videoCreator.transcriptionLoadingStatus === 'generation_failed')
    ) {
      return (
        <div>
          <div style={{ width: '100%' }}>
            Failed to generate the transcript.
          </div>
          <div>
            Click{' '}
            <PanelButton onClick={() => setShowReplaceWarning(true)}>
              <ReplaceIcon />
            </PanelButton>{' '}
            to try again
          </div>
          <div>Or contact developers about the issue</div>
        </div>
      );
    }
    if (
      videoCreator.transcriptionId &&
      videoCreator.transcriptionLoadingStatus === 'loading'
    ) {
      return <div>Transcription is loading...</div>;
    }
    if (
      videoCreator.transcriptionId &&
      videoCreator.transcriptionLoadingStatus === 'failed'
    ) {
      return <div>Transcription loading failed. Try refreshing the page.</div>;
    }
    if (
      videoCreator.transcriptionLoadingStatus === 'loaded' &&
      !videoCreator.finalTranscriptionElements?.length
    ) {
      return <div>Transcription loading failed. Try refreshing the page.</div>;
    }
    if (showSubtitles) {
      return <TranscriptionSubtitles />;
    } else if (showHistory) {
      return <TranscriptionDiff cursor={cursor} />;
    } else {
      return <TranscriptionText cursor={cursor} />;
    }
  };
  const originalTranscription = videoCreator.originalTranscription;

  return (
    <Main
      id={'transcript-container'}
      ref={scrollRef}
      tabIndex={-1}
      onWheel={() => {
        setAutoScroll(false);
      }}
      onTouchMove={() => {
        setAutoScroll(false);
      }}
      onClick={(e) => {
        const index = Number((e.target as HTMLElement).dataset.index);
        if (!isNaN(index)) {
          skipToWordWithIndex(index);
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        const index = Number((e.target as HTMLElement).dataset.index);

        if (!isNaN(index)) {
          showOptionsForWordWithIndexAtPosition(index, {
            x: e.pageX,
            y: e.pageY - NAV_HEIGHT + 2,
          });
        }
        return false;
      }}
      onMouseUp={handleMouseUp}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}

    >
      {contextMenuPosition && contextMenuActions && selectedWordIndex > -1 && (
        <TranscriptContextMenu
          positionXY={contextMenuPosition}
          actions={contextMenuActions}
          actionCallback={contextMenuCallback}
          clientHeight={scrollRef.current?.clientHeight!}
        />
      )}
      {showReplaceModal && (
        <WordReplacementModal
          type={'input'}
          currentText={videoCreator
            .finalTranscriptionElements!.slice(
              showReplaceModal.startIndex!,
              showReplaceModal.endIndex! + 1,
            )
            .map((el) => el.value || '')
            .join('')}
          submitText={(text) => {
            videoCreator.replaceTranscriptionElement(
              showReplaceModal?.startIndex!,
              showReplaceModal.endIndex!,
              text,
            );

            setShowReplaceModal(null);
            setSelectedWordIndex(-1);
          }}
          discard={() => {
            setShowReplaceModal(null);
            setSelectedWordIndex(-1);
          }}
        />
      )}
      {showReplaceWarning && !showSubtitles && (
        <RegenerateTranscriptionModal
          type={'transcription'}
          confirm={async () => {
            videoCreator.regenerateTranscription();
            setShowReplaceWarning(false);
          }}
          discard={() => {
            setShowReplaceWarning(false);
          }}
        />
      )}
      {showReplaceWarning && showSubtitles && (
        <RegenerateTranscriptionModal
          type={'subtitles'}
          confirm={async () => {
            videoCreator.refetchSubtitlesForCurrentVideo();
            setShowReplaceWarning(false);
          }}
          discard={() => {
            setShowReplaceWarning(false);
          }}
        />
      )}
      <Header>
        <Heading>Transcript</Heading>
        <Buttons>
          {!showSubtitles ? (
            <PanelButton onClick={() => setShowReplaceWarning(true)}>
              <ReplaceIcon strokeColor="#484848" />
            </PanelButton>
          ) : <span></span>}
          <RightSideButtons>
            {originalTranscription && !originalTranscription.language.includes('en') && (
              <>
                {showSubtitles && (
                  <PanelButton onClick={() => setShowReplaceWarning(true)}>
                    <ReplaceIcon strokeColor="#484848" />
                  </PanelButton>
                )}
                <div style={{ height: '20px' }}>
                  <PanelButton
                    outlined={true}
                    isPressed={showSubtitles}
                    onClick={() => {
                      setShowSubtitles(!showSubtitles)
                    }}>
                    <SubtitlesButton>ENG</SubtitlesButton>
                  </PanelButton>
                </div>
              </>
            )}
            <PanelButton
              isPressed={showHistory}
              onClick={() => setShowHistory(!showHistory)}
            >
              <TranscriptHistoryIcon />
            </PanelButton>
          </RightSideButtons>
        </Buttons>
      </Header>
      <ScrollableText>{renderTranscription()}</ScrollableText>

      {photoSelection && (
        <Modal
          isOpen={true}
          onClose={() => togglePhotoSelectionModal(null)}
          paddingHorizontal="0"
        >
          <PhotoModal
            TopComponent={
              <PhotoModalTop
                replaceAction={handleAddPhoto}
                title="Swap or search for a new image."
                isSelected={!!selectedImage}
                origin="transcript"
              />
            }
            showDescription
            otherFields={['stock', 'ai']}
            onCloseSelf={() => togglePhotoSelectionModal(null)}
            openPrevModal={() => { }}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            from="transcript"
            searchBarRadius="10px"
          />
        </Modal>
      )}
    </Main>
  );
});

const Main = styled.div`
  height: calc(100% - 16px);
  box-sizing: border-box;
  max-width: 380px;
  min-width: 200px;
  font-size: 14px;
  margin-left: 32px;
  overflow: hidden;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const ScrollableText = styled.div`
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding-right: 14px;
  padding-bottom: 42px;
  box-sizing: border-box;
  line-height: 175%;
  margin-top: 12px;
  float: left;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.span`
  color: #f2d093;
  font-weight: 700;
  line-height: normal;
  margin: 4px 0;
`;

const Buttons = styled.div`
  height: 26px;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
`;

const PanelButton = styled.button.attrs(
  (props: { isPressed: boolean, outlined: boolean }) => props,
)`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? '#F2D093' : 'transparent')};
  color: ${(props) => (props.isPressed ? '#030419' : '#484848')};
  border: ${(props) => (props.outlined ? `1px solid ${props.isPressed ? '#F2D093' : '#484848'}` : 'none')};

  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const SubtitlesButton = styled.span`
font-size: 10px;
`

const RightSideButtons = styled.div`
display: flex;
align-items: center;
gap: 16px;
height: 100%;
`