import { marked } from 'marked';
import { videoCreator } from '../../../stores/VideoCreatorStore';
import ReactDOMServer from 'react-dom/server';
import ImageComponent from './ImageComponent';
import { MouseEvent } from 'react';
import {
  BlogOrEmailContent,
  ImageKey,
  ImageWithType,
} from '../../../types.ts/general';
import {
  getRandomFileName,
  handleCopyToClipboard,
} from '../../../utility/general';
import CryptoJS from 'crypto-js';
import { Artifact, FileData } from '../../../types.ts/story';

export class BlogProducer {
  public formattedSavedBlog: string | undefined = undefined;
  private parser = new DOMParser();
  private MAX_POSITION = 100;
  private AVERAGE_POSITION = 50;
  private MIN_POSITION = 0;
  private DELTA_POSITION = 1;

  private removeConsecutiveImages = (markdownText: string) => {
    const consecutiveImagesRegex = /(!\[.*\]\(.*\))\s*(!\[.*\]\(.*\))/;

    // Check if the Markdown text contains two consecutive images
    const match = markdownText.match(consecutiveImagesRegex);

    if (match) {
      // Remove the second image
      return markdownText.replace(consecutiveImagesRegex, (_, g1) => g1);
    }
    return markdownText;
  };

  private sanitizeGeneratedContent = (generatedContent: string) => {
    // const content = generatedContent.replace(
    //   /^##\s*([^\n]+)\s*(?:\n{1,5}|\r\n{1,5})##[^\n]+/,
    //   '## $1',
    // );
    return this.removeConsecutiveImages(generatedContent);
  };

  public getEntries = (): BlogOrEmailContent[] => {
    return Object.entries(videoCreator.story?.savedBlog || {}).map(
      ([id, data]) => {
        return { id: Number(id), ...data };
      },
    );
  };

  public getCurrentEntry = (
    entries: BlogOrEmailContent[],
  ): BlogOrEmailContent => {
    const content = videoCreator.selectedBlogContent;
    if (content?.type !== 'saved' || !content.id) {
      return (entries?.[0] || {}) as BlogOrEmailContent;
    }

    const id = Number(content.id);
    return (entries.find((entry) => entry.id === id) ||
      {}) as BlogOrEmailContent;
  };

  public produceHtmlFromGeneratedContent = (
    generatedContent: string,
    clickedImageId: string | null,
    imageSize: Record<string, Record<'width' | 'height', number>> | null,
    openDropdown: number | null,
    isResizing: string | null,
    dragPositions: Record<string, Record<'xAxis' | 'yAxis', string>> | null,
  ) => {
    let content = this.sanitizeGeneratedContent(generatedContent as string);
    const artifacts = videoCreator.story?.storyArtifacts;
    // Replace bolded sections with subheadings
    // content = (content as string)?.replace(/\*\*(.*?)\*\*\n\n/g, '## $1\n\n');
    let markedHtml = marked.parse(content);

    if (artifacts?.length) {
      let counter = 0;

      // Replace image placeholder with existing artifact images
      markedHtml = markedHtml?.replace(
        /<img[^>]*>|image\s*placeholder|\[insert picture here\]/gi,
        (match) => {
          const replacement = artifacts[counter % artifacts.length];
          counter++;
          if (counter > artifacts.length) {
            return '';
          }
          // const uuid = window.crypto.randomUUID();

          let customReplacement;
          if (videoCreator.replacementImages.blog) {
            customReplacement =
              videoCreator.replacementImages.blog[counter.toString()];
          }
          if (customReplacement?.isRemoved) return '';

          const src = customReplacement?.value?.url || replacement.url;
          const alt = customReplacement?.value?.alt || replacement.title;

          const width = customReplacement?.value?.width || replacement.width;
          const height = customReplacement?.value?.height || replacement.height;

          const uuid = null;

          return ReactDOMServer.renderToString(
            <ImageComponent
              src={src!}
              alt={alt!}
              counter={counter}
              uuid={uuid}
              clickedImageId={clickedImageId}
              imageSize={imageSize}
              openDropdown={openDropdown}
              isResizing={isResizing}
              defaultWidth={width}
              defaultHeight={height}
              dragPositions={dragPositions}
            />,
          );
        },
      );
      // Regular expression to check for two images next to each other or within 10 characters
    } else {
      markedHtml = markedHtml?.replace(/<img[^>]*>|image\s*placeholder/gi, '');
    }
    return markedHtml;
  };

  public initializeSavedBlogContent = (
    contentId: number,
    saved_blogs: Record<number, Record<'title' | 'content', string>>,
    dragPosition: Record<string, Record<'xAxis' | 'yAxis', string>> | null,
  ) => {
    const data = saved_blogs[contentId];
    const currSavedKey = contentId;
    const imgData = videoCreator.savedItemReplacementImages.blogs[currSavedKey];

    const doc = this.parser.parseFromString(data.content, 'text/html');
    const imageWrappers = doc.querySelectorAll('.image-wrapper');
    const dragPos = { ...(dragPosition || {}) };

    imageWrappers?.forEach((imageWrapper, index) => {
      const imageSrc = imageWrapper.querySelector('img');
      const uuid = imageWrapper?.getAttribute('data-uuid');

      if (!uuid || imgData?.[uuid]?.value?.url || imgData?.[uuid]?.isRemoved) {
        return;
      }

      const position = imageWrapper?.getAttribute('data-position');

      if (position) {
        const [xAxis, yAxis] = position.split(' ');
        const x = parseInt(xAxis);
        const y = parseInt(yAxis);

        if (x || y) {
          dragPos[uuid] = { xAxis, yAxis } as Record<'xAxis' | 'yAxis', string>;
        }
      }

      const src = imageSrc?.getAttribute('src');
      const alt = imageSrc?.getAttribute('alt');

      const dataToSave = {
        url: src!,
        alt: alt!,
        id: window.crypto.randomUUID(),
        type: 'artifact',
      } as ImageWithType[ImageKey] | null;

      const allBlogs = videoCreator.savedItemReplacementImages.blogs;
      const currBlog = allBlogs?.[currSavedKey!] || {};
      const currImage = currBlog?.[uuid!] || {};

      videoCreator.savedItemReplacementImages.blogs = {
        ...allBlogs,
        [currSavedKey!]: {
          ...currBlog,
          [uuid!]: {
            ...currImage,
            value: dataToSave,
            isRemoved: false,
          },
        },
      };
    });
    return dragPos;
  };

  public produceSavedBlogContent = (
    currentContentId: number | undefined,
    clickedImageId: string | null,
    imageSize: Record<string, Record<'width' | 'height', number>> | null,
    openDropdown: number | null,
    isResizing: string | null,
    saved_blogs:
      | Record<number, Record<'title' | 'content', string>>
      | undefined,
    dragPositions: Record<string, Record<'xAxis' | 'yAxis', string>> | null,
  ) => {
    if (!saved_blogs || !currentContentId) return;
    const saved_blog = saved_blogs[currentContentId];
    const doc = this.parser.parseFromString(
      saved_blog?.content || '',
      'text/html',
    );
    const imageWrappers = doc.querySelectorAll('.image-wrapper');

    const imgData =
      videoCreator.savedItemReplacementImages.blogs[currentContentId];

    imageWrappers.forEach((imageWrapper, index) => {
      const imageSrc = imageWrapper.querySelector('img');
      const uuid = imageWrapper.getAttribute('data-uuid');
      if (!uuid) return;

      const currValue = imgData?.[uuid!]?.value;

      const isRemoved = imgData?.[uuid!]?.isRemoved === true;
      let width = imageWrapper.getAttribute('width');
      let height = imageWrapper.getAttribute('height');

      const defaultWidth =
        currValue?.width || imageWrapper.getAttribute('data-default-width');
      const defaultHeight =
        currValue?.height || imageWrapper.getAttribute('data-default-height');

      const src = currValue?.url ?? imageSrc?.getAttribute('src');
      const alt = currValue?.alt ?? imageSrc?.getAttribute('alt');

      const replacement = ReactDOMServer.renderToString(
        <ImageComponent
          src={src!}
          alt={alt!}
          counter={index}
          uuid={uuid}
          clickedImageId={clickedImageId}
          imageSize={imageSize}
          openDropdown={openDropdown}
          isResizing={isResizing}
          width={width ? Number(width) : null}
          height={height ? Number(height) : null}
          defaultWidth={defaultWidth}
          defaultHeight={defaultHeight}
          dragPositions={dragPositions}
        />,
      );

      imageWrapper!.outerHTML = isRemoved ? '' : replacement;
    });
    this.formattedSavedBlog = doc.body.innerHTML;
  };

  resizeImage = (
    e: MouseEvent<HTMLDivElement>,
    imageDragged: {
      uuid: string;
      loc: 'down' | 'up';
      initialPos: {
        x: number;
        y: number;
      };
    } | null,
    imageDimension: Record<
      string,
      Record<'width' | 'height' | 'x' | 'y' | 'pageX' | 'pageY', number>
    > | null,
    originalImageSize: Record<
      string,
      {
        width: number;
        height: number;
      }
    >,
    dragPosition: Record<string, Record<'xAxis' | 'yAxis', string>> | null,
  ) => {
    if (!imageDragged || !imageDimension) return;

    const uuid = imageDragged.uuid;
    const parent = document.getElementById(uuid);
    const currPos = dragPosition?.[uuid];

    let defaultHeight = parent?.getAttribute('data-default-height');
    let defaultWidth = parent?.getAttribute('data-default-width');
    let originalHeight;

    if (defaultHeight && defaultWidth) {
      const ratio = parseInt(defaultHeight) / parseInt(defaultWidth);
      originalHeight = 800 * ratio;
    }

    originalHeight = originalHeight || originalImageSize?.[uuid]?.height || 800;
    const currDim = imageDimension[uuid];

    const initialWidth = currDim?.width || 800;
    const initialHeight = currDim?.height || 800;

    const draggingUp = currDim.pageY > e.pageY;
    const draggingRight = currDim.pageX > e.pageX;

    const deltaX =
      imageDragged.loc === 'up'
        ? currDim?.x - e.clientX
        : e.clientX - currDim?.x;

    const deltaY =
      imageDragged.loc === 'up'
        ? currDim?.y - e.clientY
        : e.clientY - currDim?.y;

    const newWidth = Math.min(Math.max(initialWidth + deltaX, 50), 800);
    const newHeight = Math.min(
      Math.max(initialHeight + deltaY, 50),
      Number(originalHeight),
    );

    const xAxisPos = this.computeObjectPositionAxisX(
      imageDragged.loc,
      currPos?.xAxis,
      draggingRight,
    );
    const yAxisPos = this.computeObjectPositionAxisY(
      imageDragged.loc,
      currPos?.yAxis,
      draggingUp,
    );

    return {
      width: newWidth,
      height: newHeight,
      uuid,
      xAxisPos,
      yAxisPos,
    };
  };

  private computeObjectPositionAxisX = (
    loc: 'up' | 'down',
    currentPos: string | undefined,
    draggingRight: boolean,
  ): string => {
    let currPos = parseFloat(currentPos || '50%');
    let newPosition = currPos;

    if (loc === 'up') {
      const sign = draggingRight ? -1 : 1;
      // newPosition = Math.max(
      //   Math.min(currentPercentage + sign * 1, maxPercentage),
      //   0,
      // );
      newPosition = Math.min(
        currPos + sign * this.DELTA_POSITION,
        this.MAX_POSITION,
      );
      if (
        draggingRight &&
        newPosition < this.AVERAGE_POSITION &&
        currPos === this.AVERAGE_POSITION
      ) {
        newPosition = currPos;
      }
    } else {
      const sign = !draggingRight ? -1 : 1;
      // newPosition = Math.min(
      //   Math.max(currentPercentage - sign * 1, 0),
      //   maxPercentage,
      // );
      newPosition = Math.max(
        currPos - sign * this.DELTA_POSITION,
        this.MIN_POSITION,
      );

      if (
        !draggingRight &&
        newPosition > this.AVERAGE_POSITION &&
        currPos === this.AVERAGE_POSITION
      ) {
        newPosition = currPos;
      }
    }

    newPosition = Math.max(
      Math.min(newPosition, this.MAX_POSITION),
      this.MIN_POSITION,
    );
    return `${newPosition}%`;
  };

  private computeObjectPositionAxisY = (
    loc: 'up' | 'down',
    currentPos: string | undefined,
    draggingUp: boolean,
  ): string => {
    let currPos = parseFloat(currentPos || '50%');
    let newPosition = currPos;

    if (loc === 'up') {
      const sign = draggingUp ? -1 : 1;
      newPosition = Math.min(
        currPos + sign * this.DELTA_POSITION,
        this.MAX_POSITION,
      );

      if (
        draggingUp &&
        newPosition < this.AVERAGE_POSITION &&
        currPos === this.AVERAGE_POSITION
      ) {
        newPosition = currPos;
      }

      // newPosition = Math.max(
      //   Math.min(currPos + sign * 0.5, maxPercentage),
      //   0,
      // );
    } else {
      const sign = !draggingUp ? -1 : 1;
      newPosition = Math.max(
        currPos - sign * this.DELTA_POSITION,
        this.MIN_POSITION,
      );

      if (
        !draggingUp &&
        newPosition > this.AVERAGE_POSITION &&
        currPos === this.AVERAGE_POSITION
      ) {
        newPosition = currPos;
      }

      // newPosition = Math.min(
      //   Math.max(currPos - sign * 0.5, 0),
      //   maxPercentage,
      // );
    }

    newPosition = Math.max(
      Math.min(newPosition, this.MAX_POSITION),
      this.MIN_POSITION,
    );
    return `${newPosition}%`;
  };

  handleMouseDown = (
    e: MouseEvent<HTMLDivElement>,
    setClickedImageId: (value: string | null) => void,
    setImageDimension: (
      value: Record<
        string,
        Record<'width' | 'height' | 'x' | 'y' | 'pageX' | 'pageY', number>
      > | null,
    ) => void,
    imageDimension: Record<
      string,
      Record<'width' | 'height' | 'x' | 'y' | 'pageX' | 'pageY', number>
    > | null,
    setImageDragged: (
      value: {
        uuid: string;
        loc: 'down' | 'up';
        initialPos: {
          x: number;
          y: number;
        };
      } | null,
    ) => void,
    cancelResizing: () => void,
    setUuid: (value: string | null) => void,
    setCurrentImageCounter: (value: string | null) => void,
    toggleDropdown: (value: number | null) => void,
  ) => {
    const targetElement = e.target as Element;
    const resizeHandle = targetElement.closest('.resize-handle');

    const imageWrapper = targetElement.closest('.image-wrapper');

    if (imageWrapper) {
      const uuid = imageWrapper.getAttribute('data-uuid');

      if (!uuid) return;

      setClickedImageId(uuid);

      setImageDimension({
        ...(imageDimension || {}),
        [uuid]: {
          width: imageWrapper?.clientWidth!,
          height: imageWrapper?.clientHeight!,
          x: e.clientX,
          y: e.clientY,
          pageX: e.pageX,
          pageY: e.pageY,
        },
      });
    }

    if (resizeHandle) {
      // Only set the initial position if the resize handle is clicked
      const uuid = resizeHandle.parentElement?.getAttribute('data-uuid');
      const classes = resizeHandle.classList;
      const down = classes.contains('left');
      if (uuid) {
        setImageDragged({
          uuid,
          loc: down ? 'up' : 'down',
          initialPos: { x: e.clientX, y: e.clientY },
        });
      }
    } else {
      cancelResizing();
    }

    const handleDocumentMouseUp = () => {
      cancelResizing();

      // Remove the event listener after mouseup
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };

    // Add event listener for mouseup on the document
    document.addEventListener('mouseup', handleDocumentMouseUp);
  };

  handleContentClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    setClickedImageId: (value: string | null) => void,
    setUuid: (value: string | null) => void,
    setCurrentImageCounter: (value: string | null) => void,
    toggleDropdown: (value: number | null) => void,
    toggleMedia: (value: boolean) => void,
    hasBeenGenerated: boolean | undefined,
    currentContentId: number | undefined,
  ) => {
    const targetElement = e.target as Element;
    const circleEllipsis = targetElement.closest('.circle-ellipsis');
    const imageWrapper = targetElement.closest('.image-wrapper');
    const resizeHandle = targetElement.closest('.resize-handle');

    if (circleEllipsis) {
      const parent = circleEllipsis?.parentElement;
      this.kebabClick(
        e,
        parent,
        setUuid,
        setCurrentImageCounter,
        toggleDropdown,
      );
    } else {
      this.triggerImageClick(
        e,
        setClickedImageId,
        toggleDropdown,
        imageWrapper,
        resizeHandle,
      );
    }

    const addPhotoAction = targetElement.closest('.add-photo-action');
    if (addPhotoAction) {
      toggleMedia(true);
      toggleDropdown(null);
    }
    this.handleDeletePhoto(
      e,
      setCurrentImageCounter,
      toggleDropdown,
      hasBeenGenerated,
      currentContentId,
    );
  };

  triggerImageClick(
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    setClickedImageId: (value: string | null) => void,
    toggleDropdown: (value: number | null) => void,
    imageWrapper: Element | null,
    resizeHandle: Element | null,
  ) {
    toggleDropdown(null);
    if (imageWrapper) {
      const uuid = imageWrapper.getAttribute('data-uuid');
      if (!uuid) return;

      setClickedImageId(uuid);
    } else if (!resizeHandle) {
      setClickedImageId(null);
    }
  }

  kebabClick(
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    parentElement: HTMLElement | null | undefined,
    setUuid: (value: string | null) => void,
    setCurrentImageCounter: (value: string | null) => void,
    toggleDropdown: (value: number | null) => void,
  ) {
    e.stopPropagation();
    const counter = parentElement
      ?.querySelector('img')
      ?.getAttribute('data-counter');
    const uuid = parentElement?.getAttribute('data-uuid');

    if (uuid) {
      setUuid(uuid);
    }

    if (counter) {
      setCurrentImageCounter(counter);
    }
    toggleDropdown(Number(counter));
  }

  handleDeletePhoto(
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    setCurrentImageCounter: (value: string | null) => void,
    toggleDropdown: (value: number | null) => void,
    hasBeenGenerated: boolean | undefined,
    currentContentId: number | undefined,
  ) {
    const targetElement = e.target as Element;

    const deleteAction = targetElement.closest('.delete-action');
    if (deleteAction) {
      const kabebAction = targetElement.closest('.kabeb-action');
      const counter = (kabebAction?.nextSibling as Element)?.getAttribute(
        'data-counter',
      );
      const uuid = (kabebAction?.parentElement as Element)?.getAttribute(
        'data-uuid',
      );

      const dataToRemove = {
        isRemoved: true,
        value: null,
      };

      if (counter) {
        setCurrentImageCounter(null);

        if (hasBeenGenerated) {
          const updatedReplacementImages = {
            ...videoCreator.replacementImages,
          };
          updatedReplacementImages.blog[counter] = dataToRemove;
          videoCreator.replacementImages = updatedReplacementImages;
        }
      }

      if (uuid && currentContentId) {
        videoCreator.savedItemReplacementImages.blogs[currentContentId][uuid] =
          dataToRemove;
      }

      toggleDropdown(null);
    }
  }

  private async saveStockImage(
    selectedImage: ImageWithType[ImageKey],
    setLoadingStock: (e: boolean) => void,
  ) {
    const isInDato = selectedImage.url.startsWith(
      'https://www.datocms-assets.com',
    );

    if (!isInDato) {
      setLoadingStock(true);
      const text = selectedImage.description || selectedImage.alt;
      const fileName = getRandomFileName(text);

      const newPhotoData: FileData & { fileName: string } = {
        type: 'stock',
        url: selectedImage.url,
        fileName,
        alt: selectedImage.alt,
        title: text,
      };

      const newUpload =
        await videoCreator.assetRepository?.uploadFile(newPhotoData);
      if (newUpload) {
        const { id, url } = newUpload;

        const assets = [
          ...(videoCreator.story?.storyAssets || []),
          {
            id,
            title: text,
            customData: {},
            format: newUpload.format,
            mimeType: newUpload.mime_type,
            url,
            responsiveImage: {
              srcSet: url,
              alt: selectedImage.alt,
              title: text,
            },
            video: null,
            _createdAt: new Date(),
          },
        ] as Artifact[];
        videoCreator.story!.storyAssets! = assets;

        await videoCreator.storyRepository?.update(videoCreator.story!);
        selectedImage.url = url;
      }
      setLoadingStock(false);
    }
    return selectedImage;
  }

  public async replaceImage(
    selectedImage: ImageWithType[ImageKey] | null,
    setStockLoading: (e: boolean) => void,
    hasBeenGenerated: boolean | undefined,
    currentImageCounter: string | null,
    currentContentId: number | undefined,
    uuid: string | null,
  ) {
    if (!selectedImage) return;
    const selectedType = videoCreator.selectedBlogContent?.type;
    const imageData = await this.saveStockImage(selectedImage, setStockLoading);

    const dataToUpdate = {
      value: imageData,
      isRemoved: false,
    };

    if (selectedType !== 'saved' && hasBeenGenerated && currentImageCounter) {
      const replacements = { ...(videoCreator.replacementImages.blog || {}) };

      replacements[currentImageCounter] = dataToUpdate;
      videoCreator.replacementImages.blog[currentImageCounter] = dataToUpdate;
    }

    if (selectedType === 'saved' && currentContentId && uuid) {
      videoCreator.savedItemReplacementImages.blogs[currentContentId!][uuid] =
        dataToUpdate;
    }
  }

  copyBlogContent = (
    markedHtml: string,
    hasBeenGenerated: boolean,
    formattedSavedBlog: string,
    clonedContentElement: HTMLElement,
    mainContentElement: HTMLElement,
  ) => {
    if ((!markedHtml || !hasBeenGenerated) && !formattedSavedBlog) return;

    const kebabActions =
      clonedContentElement?.querySelectorAll('.kabeb-action');

    kebabActions?.forEach((kebabAction) => {
      kebabAction.remove();
    });

    const circleEllipsis =
      clonedContentElement?.querySelectorAll('.circle-ellipsis');
    circleEllipsis?.forEach((ellipsis) => {
      ellipsis.remove();
    });

    const resizeHandles =
      clonedContentElement?.querySelectorAll('.resize-handle');
    resizeHandles?.forEach((resizeHandle) => {
      resizeHandle.remove();
    });

    const blockquotes = clonedContentElement?.querySelectorAll('blockquote');
    blockquotes?.forEach((blockquote) => {
      blockquote.style.fontStyle = 'italic';
    });

    const mainWrapper = mainContentElement.querySelectorAll('.image-wrapper');
    const clonedWrapper =
      clonedContentElement.querySelectorAll('.image-wrapper');

    clonedWrapper?.forEach((wrapper, index) => {
      const originalWrapperStyle = window.getComputedStyle(mainWrapper[index]);
      wrapper.setAttribute('style', originalWrapperStyle.cssText);

      const currImage = wrapper.querySelector('img');
      const imageUrl = currImage?.getAttribute('src');

      const originalImg = mainWrapper[index].querySelector('img');
      const originalImgStyle = window.getComputedStyle(originalImg!);

      const width = parseInt(originalImgStyle.width);
      const height = parseInt(originalImgStyle.height);
      const [posX, posY] = originalImgStyle.objectPosition.split(' ');
      const px = parseInt(posX) / 100;
      const py = parseInt(posY) / 100;

      const newImageUrl = `${imageUrl}?crop=focalpoint&fit=crop&fp-x=${px}&fp-y=${py}&w=${width}&h=${height}`;
      currImage?.setAttribute('src', newImageUrl);
    });

    const updatedContent = clonedContentElement?.innerHTML || '';
    handleCopyToClipboard(updatedContent, 'html');
  };

  saveBlogContent = async (
    name: string | null | undefined,
    clonedContentElement: HTMLElement,
    contentElement: HTMLDivElement | null,
  ) => {
    const story = videoCreator.story;

    const title =
      clonedContentElement.querySelector('h1')?.textContent ||
      clonedContentElement.querySelector('h2')?.textContent ||
      '';

    const doc = this.parser.parseFromString(
      clonedContentElement.innerHTML,
      'text/html',
    );

    const imageWrappers = doc.querySelectorAll('.image-wrapper');
    const originalWrapper = contentElement?.querySelectorAll('.image-wrapper');

    imageWrappers.forEach((imageWrapper, index) => {
      const origWrapper = originalWrapper![index];
      const originalImage = origWrapper.querySelector('img');
      const computedStyle = window.getComputedStyle(originalImage!);

      const defaultWidth = origWrapper.getAttribute('data-default-width');
      const defaultHeight = origWrapper.getAttribute('data-default-height');

      if (defaultWidth) {
        imageWrapper.setAttribute('data-default-width', defaultWidth);
      }

      if (defaultHeight) {
        imageWrapper.setAttribute('data-default-height', defaultHeight);
      }

      imageWrapper.setAttribute('data-position', computedStyle.objectPosition);

      const uuid = window.crypto.randomUUID();
      imageWrapper.setAttribute('data-uuid', uuid);
    });

    const updatedContent = await videoCreator.storyRepository?.saveBlogOrEmail(
      story!,
      doc.body.innerHTML,
      title,
      name,
      'saved_blog',
    );

    if (updatedContent) {
      videoCreator.story!.savedBlog = updatedContent;
      const ids = Object.keys(updatedContent) as unknown as number[];
      const id = Math.max(...ids);
      const data = updatedContent[id];

      return {
        id,
        title: data.title,
        content: data.content,
        username: data.username,
      };
    }
  };

  serveContent(markedHtml: string | undefined, savedBlog: string | undefined) {
    const selectedType = videoCreator.selectedBlogContent?.type;
    if (
      (selectedType === 'generated' || selectedType === 'generating') &&
      markedHtml
    ) {
      return markedHtml;
    }

    if (selectedType === 'saved' && savedBlog) {
      return savedBlog;
    }
    videoCreator.selectedBlogContent = null
    return ''
  }

  generateStableHash(content: string) {
    const contentString = JSON.stringify(content);
    const hash = CryptoJS.SHA256(contentString);
    return hash.toString(CryptoJS.enc.Hex);
  }

  currentContentHash(
    generatedContent: string | undefined,
    savedBlog: string | undefined,
  ) {
    const selectedType = videoCreator.selectedBlogContent?.type;
    if (!selectedType) return null;

    if (selectedType === 'generated' && generatedContent) {
      return this.generateStableHash(generatedContent);
    }

    if (selectedType === 'saved' && savedBlog) {
      return this.generateStableHash(savedBlog);
    }
    return null;
  }

  extractOriginalDimension(imageWrappers: NodeListOf<Element> | undefined) {
    const newOriginalImageSize: Record<
      string,
      { width: number; height: number }
    > = {};

    imageWrappers?.forEach((imageWrapper) => {
      const uuid = imageWrapper.getAttribute('data-uuid');
      const image = imageWrapper.querySelector('img');

      const parent = imageWrapper as HTMLElement;

      if (uuid && image) {
        newOriginalImageSize[uuid] = {
          width: parent.offsetWidth || image.width,
          height: parent.offsetHeight || image.height,
        };
      }
    });
    return newOriginalImageSize;
  }

  public static getModifiedAt = (timestamp: number) => {
    if (
      !Number.isInteger(timestamp) ||
      timestamp < 0 ||
      timestamp > Date.now()
    ) {
      return;
    }

    const prevDate = new Date(timestamp);

    const currDate = new Date();
    const timeDiff = currDate.getTime() - prevDate.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return 'just now';
    }
  };
}
