import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { TranscriptChange } from '../videoTranscriptionProcessor/utils';
import { KaraokeConfig } from '../videoTranscriptionProcessor/KaraokeProducer';
import { TalkingPointContent } from './general';

export type ContentViewData = { storyId: string } & Record<
  Extract<
    SupportedGeneratedContentTypes,
    'Blog' | 'Quotes' | 'Takeaways' | 'Email'
  >,
  {
    content: AiGeneratedContent<
      Extract<
        SupportedGeneratedContentTypes,
        'Blog' | 'Quotes' | 'Takeaways' | 'Email'
      >
    > | null;
    storyId: string;
    hasBeenGenerated: boolean;
  }
>;
export type SupportedGeneratedContentTypes =
  | 'Photo Punchlist'
  | 'Description'
  | 'Blog'
  | 'Quotes'
  | 'Takeaways'
  | 'Email'
  | 'Music Selection';

export type AiGeneratedContent<
  T extends SupportedGeneratedContentTypes = SupportedGeneratedContentTypes,
> = {
  id?: string;
  title: T;
  response: T extends 'Photo Punchlist'
    ? PunchListContent[]
    : T extends 'Blog' | 'Email' | 'Music Selection' | 'Description'
      ? string
      : string[];
};

export type AiGeneratedPunchListContent = {
  id?: string;
  prompt: 'Photo Punchlist';
  generatedContent: {
    punchList: PunchListItem[];
  };
};

export type Person = {
  id?: string;
  name: string;
  email: string;
  social_profile_key: string;
  link_account_page: string;
};

export type Caption = {
  id?: string;
  platform:
    | 'twitter'
    | 'facebook'
    | 'linkedin'
    | 'tiktok'
    | 'instagram'
    | 'youtube';
  caption?: string;
  story: {
    id: string;
  };
  shareable: {
    __typename: string;
  };
};

export type Subtitles = {
  lines: {
    text: string;
    start: number;
    end: number;
    translated: string;
  }[];
};

export type ShareableImageCaption = Caption & {
  shareableContent?: {
    id: string;
  };
};

export type VideoClipCaption = Caption & {
  video?: {
    id: string;
  };
};

export type ShareableImageType = {
  id: string;
  imagefile?: Artifact;
  quote: string;
  _allReferencingSharedContents?: SharedContent[];
  _allReferencingCaptions?: Caption[];
  // _allReferencingShareableImageCaptions?: ShareableImageCaption[];
};

export type ShareableImagePreviewType = Record<
  'id' | 'url' | 'quote',
  string
> & {
  // _allReferencingShareableImageCaptions?: ShareableImageCaption[];
  imageFile: Artifact | undefined;
  _allReferencingCaptions: Caption[];
  _allReferencingSharedContents?: SharedContent[];
};

export type Story = {
  id: string;
  slug: string;
  hash?: string;
  title: string;
  description: string;
  thumbnail: {
    url: string;
  };
  _allReferencingShowcases: Showcase[];
  _allReferencingSharedContents?: SharedContent[];
  // _allReferencingShareableImageCaptions?: ShareableImageCaption[];
  // _allReferencingVideoClipCaptions?: VideoFilesQueryResult[];
  _allReferencingCaptions: Caption[];
  punchList: PunchListItem[];
  followUpPunchList: PunchListItem[];
  aiGeneratedContent: AiGeneratedContent<SupportedGeneratedContentTypes>[];
  originalVideo: StoryVideo;
  originalVideoStatus: { status: 'IN_PROGRESS' | 'COMPLETE' | 'FAILED' };
  finalVideo?: Pick<Video, 'id' | 'videoFilePrimary' | 'videoStatus'>;
  otherVideos: Array<VideoClip & Pick<Video, 'aiGeneratedContent'>>;
  storyTeller: {
    name: string;
  };
  storyArtifacts?: Artifact[];
  storyAssets?: Asset[];
  aiPhotos?: AIPhotos[];
  storyArtifactsVideo?: Artifact[];
  transcription?: Transcription;
  // transcriptionChanges: TranscriptChangelog;
  alreadyGeneratedContent: string[];
  aiResponse?: {
    responses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
    followUpResponses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
  };
  savedBlog?: Record<number, Record<'title' | 'content' | 'username', string>>;
  savedEmail?: Record<number, Record<'title' | 'content' | 'username', string>>;
  savedTalkingPointContent?: Record<
    number,
    {
      title: string;
      content: Record<
        keyof TalkingPointContent,
        Record<'title' | 'content' | 'prompt', string>
      >;
    }
  >;
  // socialCaptions: Caption | null;
  shareableImages: ShareableImageType[];
};

export type Transcription = {
  jobStatus: string;
  audio: {
    url: string;
    format: string;
    mimeType: string;
    customData: Record<string, string>;
  };
  elementsJson: {
    id: string;
    url: string;
  };
  waveformData: {
    id: string;
    url: string;
  };
};

export type Showcase = {
  id: string;
  title: string;
  organization: string;
  stories: Pick<Story, 'id' | 'originalVideo'>[];
  slug: string;
  logo: {
    url: string;
  };
  mainImage: {
    url: string;
  };
  profiles?: Array<{
    id: string;
    name: string;
    platforms?: {
      displayName: string;
      id: string;
      platform: string;
      profileUrl: string;
      type: string;
      usedQuota: number;
      userImage: string;
      username: string;
    }[];
  }>;
};

export type PunchListContent = {
  Line: string;
  Description: string;
  Evocative: string;
  Metadata: string;
  dallePrompt: string;
  dalleImages: string[];
  stockKeywords: string;
};

export type FileData = {
  id?: string;
  uploadId?: string;
  type: 'stock' | 'ai' | 'artifact' | 'quotes';
  url?: string;
  file?: File;
  fileName?: string;
  title: string;
  alt: string;
  cat?: string;
  customData?: Record<string, any>;
};

export type StoryDTO = Omit<
  Story,
  'aiPhotos' | 'storyAssets' | 'storyArtifacts'
> & {
  aiPhotos?: (AIPhotos | { id: string })[];
  storyAssets?: (Asset | { id: string })[];
  storyArtifacts?: (Artifact | { id: string })[];
};

export type VolumeKeyPoint = {
  value: string;
  time: string;
};

export type VideoVersion = Video &
  Pick<SimpleSchemaTypes.ItemVersion, 'meta' | 'type' | 'editor'> & {
    versionId: string;
  } & {
    editor: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
  };

export type SharedContent = {
  id: string;
  title: string;
  socialProfileGroup: {
    id: string;
    name: string;
    platform: string;
    profiles: {
      id: string;
      name: string;
      platform: string;
      profileUrl: string;
      userImage: string;
      username: string;
    }[];
  };
  external: boolean;
  video: {
    id: string;
    title: string;
    thumbnail: {
      url: string;
    };
  };
  story: {
    id: string;
  };
  organization: {
    id: string;
  };
  postId: string;
  postUrl: string;
  lastFetched: string;
  postType: string;
  platform: string;
  post: string;
  likes: number;
  comments: number;
  shares: number;
  clicks: number;
  views: number;
};

export type ExtraElementData = {
  volumeKeyPoints?: VolumeKeyPoint[];
  punchListData?: PunchListItem;
};

export type AssociatedVideo = Pick<
  Video,
  | 'id'
  | 'title'
  | 'aspectRatio'
  | 'parentVideo'
  | 'associatedVideos'
  | 'videoFilePrimary'
  | 'thumbnail'
  | 'shareableImageId'
  | 'videoStatus'
  | '_allReferencingSharedContents'
  | '_allReferencingCaptions'
  | 'sourcePlatform'
>;

export type VideoClip = Pick<
  Video,
  | 'id'
  | 'title'
  | 'videoFilePrimary'
  | 'thumbnail'
  | 'shareableImageId'
  | 'slug'
  | 'hash'
  | 'aspectRatio'
  | 'associatedVideos'
  | 'videoStatus'
  | '_allReferencingSharedContents'
  | '_allReferencingCaptions'
  | 'sourcePlatform'
  | 'videoSource'
>;

export type Video = {
  id?: string;
  title: string;
  videoFilePrimary?: StoryVideo;
  parentVideo?: AssociatedVideo;
  associatedVideos: AssociatedVideo[];
  aspectRatio: '16:9' | '1:1' | '9:16';
  thumbnail?: {
    id?: string;
    url: string;
    responsiveImage?: {
      src: string;
      srcSet: string;
    };
  };
  shareableImageId?: string;
  extraElementData: Record<
    string,
    ExtraElementData | KaraokeConfig | null | undefined
  >;
  renderId?: string;
  videoStatus: 'none' | 'rendering' | 'rendered' | 'editing' | 'error';
  videoSource: Record<string, any>; // RendererState;
  transcriptionChanges?: TranscriptChange[];
  subtitles?: Subtitles;
  aiGeneratedContent?: Array<{
    id?: string;
    prompt: string;
    generatedContent: string;
  }>;
  slug?: string;
  hash?: string;
  punchList?: PunchListItem[];
  _allReferencingSharedContents?: SharedContent[];
  // _allReferencingVideoClipCaptions?: VideoClipCaption[];
  _allReferencingCaptions?: Caption[];
  sourcePlatform: 'creator-studio' | 'content-studio';
};

export type StoryQueryResult = {
  story?: Omit<Story, 'punchList'>;
};

export type AlbumQueryResult = {
  id: string;
  title: string;
  stories: {
    id: string;
    title: string;
    _allReferencingSharedContents?: SharedContent[];
    aiResponse?: {
      responses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
      followUpResponses: AiGeneratedContent<SupportedGeneratedContentTypes>[];
    };
    storyTeller: {
      name: string;
    };
    _publishedAt: string;
    thumbnail: {
      url: string;
    };
    originalVideo: {
      video: {
        duration: string;
        thumbnailUrl: string;
        muxPlaybackId: string;
      };
    };
    finalVideo: Pick<Video, 'thumbnail' | 'videoFilePrimary'>;
  }[];
};

export type VideoFilesQueryResult = {
  video?: {
    id: string;
    thumbnail: {
      id: string;
      url: string;
      responsiveImage: {
        src: string;
        srcSet: string;
      };
    };
    videoFilePrimary?: StoryVideo;
    associatedVideos: AssociatedVideo[];
    _allReferencingVideos: AssociatedVideo[];
    aspectRatio: Video['aspectRatio'];
    hash: string;
    slug: string;
  };
};

export type VideoItem = {
  id: string;
  title: string;
  video_files?: {
    upload_id: string;
  };
  thumbnail: {
    upload_id: string;
  };
  subtitles_json: string;
  video_json: string;
  video_status: 'none' | 'rendering' | 'rendered' | 'editing' | 'error';
  aspect_ratio: Video['aspectRatio'];
  transcription_json: string;
  ai_generated_content: Array<{
    id: string;
    prompt: string;
    generated_content: string;
  }>;
  extra_elements_json: string;
  slug: string;
  hash: string;
  associated_videos: Array<{
    id: string;
  }>;
  source_platform: 'creator-studio' | 'content-studio';
  // punch_list_json: string; //Record<string, any>;
  shared_content: string;
};

export type PunchListItem = {
  id?: string;
  type: 'punch_list';
  elementId: string;
  artifactSrc?: string;
  matchReason?: string;
  artifactDescription?: string;
  line: string;
  description: string;
  evocative: string;
  metadata: Record<string, string>;
  genAiPrompt?: string;
  dallePrompt: string;
  dalleImages: Record<'uploadId' | 'uploadUrl', string>[];
  stockKeywords: string;
  transcriptPosition: { startIndex: number; endIndex: number };
  duration?: number;
  startTime?: number;
  endTime?: number;
};

export type StoryVideo = {
  id?: string;
  url: string;
  height: number;
  width: number;
  video: {
    duration: number;
    muxPlaybackId: string;
    thumbnailUrl: string;
    mp4Url: string;
    mp4UrlHigh: string;
    mp4UrlMedium: string;
    mp4UrlLow: string;
  };
  customData: any;
};

export type Artifact = {
  id: string;
  author?: string;
  title: string;
  width: string;
  height: string;
  video: {
    duration: number;
    playbackId: string;
    muxPlaybackId: string;
    thumbnailUrl: string;
    mp4Url?: string;
  } | null;
  responsiveImage: {
    alt: string;
    base64: string;
    sizes: string;
    srcSet: string;
    title: string;
  } | null;
  format: string;
  mimeType: string;
  url: string;
  customData: {
    people?: string;
    place?: string;
    description?: string;
  };
  _createdAt: string;
};

export type Music = {
  id: string;
  genre: string;
  collection: {
    id: string;
    url: string;
    tags: string[];
    title: string;
    customData: {
      artist?: string;
      artists?: string;
      duration: string;
      songName: string;
    };
  }[];
};

export type Asset = Artifact;
export type AIPhotos = Artifact;

export type PhotoAsset = {
  id: string;
  title: string;
  description?: string;
  src: string;
  fullSrc?: string;
  people?: string;
  places?: string;
  type: 'stock' | 'ai' | 'artifact';
  alt?: string;
  slug?: string;
};

export type PhotoAssetData = {
  tab: PhotoTab;
  resource: PhotoAsset[] | undefined;
  lastSelectedStock?: PhotoAsset[] | undefined;
  lastSelectedAi?: PhotoAsset[] | undefined;
  selectedId?: string;
};

export enum PhotoTab {
  artifact = 'Artifacts',
  stock = 'Stock',
  ai = 'Ai',
  quote = 'Quotes',
}

export type SocialPost = {
  id: string;
  external: boolean;
  title?: string;
  post?: string;
  platform: string;
  permalink: string;
  thumbnailUrl?: string;
  media?: ExternalMedia[];
  postType?: string; // carousel, video, image, text
  analytics?: {
    likes?: number;
    comments?: number;
    shares?: number;
    clicks?: number;
    views?: number;
  };
  raw_data?: any;
  created?: Date;
};

export type ExternalMedia = {
  width?: number;
  height?: number;
  bitRate?: number;
  contentType: string; // image or video
  mediaUrl: string;
};

export enum SocialPlatform {
  instagram = 'Instagram',
  facebook = 'Facebook',
  linkedin = 'LinkedIn',
  tiktok = 'TikTok',
  x = 'X',
  youtube = 'YouTube',
}
