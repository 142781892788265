import { Renderer } from '../renderer/Renderer';
import { deepClone } from '../utility/deepClone';
import {
  TranscriptChange,
  TranscriptClipboard,
  TranscriptElement,
  TranscriptTextElement,
  applyDeletionToTransctipt,
  applyShiftingToTranscript,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedNotWhiteSpaceElementIndexToLeft,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestRemovedIndexToRight,
  getClosestTextIndexToLeft,
  getClosestTextIndexToRight,
} from './utils';
import { v4 as uuid } from 'uuid';

const PRECISION_EPS = 0.01;
const DEFAULT_FPS = 24;
export default class VideoTranscriptionProcessor {
  private transcriptionElements?: TranscriptElement[];
  private transcriptionChanges: TranscriptChange[] = [];
  private renderer?: Renderer;
  private originalSource: Record<string, any> = {};

  transcriptClipboard?: TranscriptClipboard;

  finalTranscriptionElements?: TranscriptElement[];
  onFinalTranscriptionElementsChange: (elements: TranscriptElement[]) => void =
    () => {};

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  setOriginalSource(source: Record<string, any>) {
    this.originalSource = source;
  }

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    this.transcriptionElements = transcriptionElements;
    this.finalTranscriptionElements = transcriptionElements.map(
      (el, index) => ({ ...el, initial_index: index }),
    );
    if (!this.checkTranscriptionTimeConsistency(transcriptionElements)) {
      console.warn('Transcription time inconsistency');
    }
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements);
  }

  checkTranscriptionTimeConsistency(
    transcriptionElements: TranscriptElement[],
  ) {
    for (let i = 0; i < transcriptionElements.length - 1; i++) {
      if (transcriptionElements[i].type === 'text') {
        if (transcriptionElements[i].end_ts! < transcriptionElements[i].ts!) {
          // // debugger;
          return false;
        }
        const nextElementIndex = getClosestNotRemovedElementIndexToRight(
          i + 1,
          transcriptionElements,
        );
        if (nextElementIndex === -1) {
          return true;
        }
        if (
          transcriptionElements[nextElementIndex].ts! <
          transcriptionElements[i].end_ts!
        ) {
          // // debugger;
          return false;
        }
      }
    }
    return true;
  }

  getTranscriptionChanges() {
    return structuredClone(this.transcriptionChanges);
  }

  getFinalTranscriptionText() {
    return (
      this.finalTranscriptionElements
        ?.filter((el) => el.state !== 'removed' && el.state !== 'cut')
        .map((el) => el.value || '')
        .join('') || ''
    );
  }

  /*
    Operations on video tracks reflected in transcription
  */
  async trimTrackStart(
    elementId: number,
    newStartTime: number,
    trimStart: number,
    newDuration: number,
  ) {
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToTrim = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToTrim) {
      return;
    }

    const currentTrimStart = elementToTrim.source.trim_start || 0;

    // TODO: THIS IS NOT RIGHT, it doesn't account for trimStart
    if (elementToTrim.source.type === 'video') {
      if (currentTrimStart < trimStart) {
        const newChange = applyDeletionToTransctipt(
          elementToTrim.globalTime,
          elementToTrim.globalTime + trimStart - currentTrimStart,
          true,
          this.finalTranscriptionElements!,
        ) as TranscriptChange;
        this.applyChange(newChange);
      } else {
        this.untrimTextElements(
          trimStart,
          currentTrimStart,
          newStartTime,
          'start',
        );
      }
    }

    // Trim the element
    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
      [`${elementId}.trim_start`]: trimStart,
      [`${elementId}.duration`]: newDuration,
    });
  }

  async moveTrack(elementId: number, newStartTime: number) {
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToMove = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToMove) {
      return;
    }

    //// debugger;
    if (elementToMove.source.type === 'video') {
      const newChange = applyShiftingToTranscript(
        elementToMove.globalTime,
        elementToMove.globalTime + elementToMove.duration,
        newStartTime,
        this.finalTranscriptionElements!,
      ) as TranscriptChange;

      this.applyChange(newChange);
    }

    // Trim the element
    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
    });
  }

  async trimTrackDuration(elementId: string, duration: number) {
    let newChange: TranscriptChange;
    // Logic to trim a track from the end
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToTrim = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToTrim || elementToTrim.source.type !== 'video') {
      return;
    }
    const trimStart = elementToTrim.source.trim_start || 0;
    // if (elementToTrim.source.type === 'video') {
    if (elementToTrim.duration > duration) {
      newChange = applyDeletionToTransctipt(
        elementToTrim.globalTime + duration,
        elementToTrim.globalTime + elementToTrim.duration,
        true,
        this.finalTranscriptionElements!,
      ) as TranscriptChange;

      this.applyChange(newChange);
    } else {
      this.untrimTextElements(
        trimStart + elementToTrim.duration,
        trimStart + duration,
        elementToTrim.globalTime + elementToTrim.duration,
        'end',
      );
    }
    // }

    // Trim the element
    // elementToTrim.duration = duration;
    await this.renderer!.applyModifications({
      [`${elementId}.duration`]: duration,
    });
    this.undoStack.push({
      undoCommand: () => {
        const lastChange = this.transcriptionChanges.pop();
        if (lastChange) {
          this.renderer?.undo();
        }
      },
      redoCommand: () => {
        this.applyChange(newChange);
        this.renderer?.redo();
      },
    });
    // Set source by the mutated state
    // await this.renderer!.setSource(this.renderer!.getSource(state), true);
  }

  /**
   * Removes a track from the video and transcription
   * @param elementId
   */
  async deleteOriginalVideoTrack(elementId: string) {
    let changes: TranscriptChange[] = [];
    // Logic to remove a track from both video and transcription
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToDelete = state.elements.find(
      (el) => el.source.id === elementId,
    );
    if (
      elementToDelete?.source.type === 'video' &&
      this.finalTranscriptionElements
    ) {
      const deletionChange = applyDeletionToTransctipt(
        elementToDelete.globalTime,
        elementToDelete.globalTime + elementToDelete.duration,
        false,
        this.finalTranscriptionElements,
      );

      // shift tracks to the left after this one
      state.elements.forEach((element) => {
        if (
          element.globalTime >=
          elementToDelete.globalTime + elementToDelete.duration
        ) {
          element.source.time = element.globalTime - elementToDelete.duration;
        }
      });

      if (deletionChange) {
        this.applyChange(deletionChange);
        changes = [deletionChange];
      } else {
        const shiftChange = applyShiftingToTranscript(
          elementToDelete.globalTime + elementToDelete.duration,
          state.duration,
          elementToDelete.globalTime,
          this.finalTranscriptionElements,
        ) as TranscriptChange;
        if (shiftChange) {
          this.applyChange(shiftChange);
          changes = [shiftChange];
        }
      }
    }

    // Remove the element
    state.elements = state.elements.filter(
      (element) => element.source.id !== elementId,
    );

    // Set source by the mutated state
    await this.renderer!.setSource(this.renderer!.getSource(state), true);

    this.undoStack.push({
      undoCommand: () => {
        if (changes.length > 0) {
          this.transcriptionChanges.pop();
        }
        this.renderer?.undo();
      },
      redoCommand: () => {
        if (changes.length > 0) {
          this.applyChange(changes[0]);
        }
        this.renderer?.redo();
      },
    });
  }

  // if intoPosition is -1, then insert position is not known at cut time (todo?)
  // if toElement is text element and the next element is whitespace, then toElement should become the next element;
  // if toElement is a text and the next element is punctuation, then fromElement should be the next element after closest text element to left of fromElement, but if there is no elements before fromElement, then toElement should be the one before next text element after toElement;
  // if toElement is a punctuation or the last element, fromElement should be the next element after closest text element to left;
  // if toElement is a whitespace, then fromElement shouldn't be a whitespace or punctuation, it should be the next text element to the right;
  // if intoPosition >= 0, then insert position is known and cut range may be changed (todo?)
  getValidCutPositions(fromElement: number, toElement: number) {
    const elements = this.finalTranscriptionElements!;
    let nextElementIndex = getClosestNotRemovedElementIndexToRight(
      toElement + 1,
      elements,
    );
    let prevElementIndex = getClosestNotRemovedElementIndexToLeft(
      fromElement - 1,
      elements,
    );
    // let prevToInsertIndex = getClosestNotRemovedElementIndexToLeft(
    //   intoPosition - 1,
    //   elements,
    // );
    let nextElement = nextElementIndex >= 0 ? elements[nextElementIndex] : null;
    let prevElement = prevElementIndex >= 0 ? elements[prevElementIndex] : null;
    // let prevToInsertElement =
    //   prevToInsertIndex >= 0 ? elements[prevToInsertIndex] : null;

    if (elements[toElement].type === 'text' && nextElement?.value === ' ') {
      toElement = nextElementIndex;
    } else if (elements[toElement].value === ' ') {
      fromElement = Math.min(
        getClosestTextIndexToRight(fromElement, elements),
        toElement,
      );
    } else if (
      (elements[toElement].type === 'text' && nextElement?.type === 'punct') ||
      elements[toElement].type === 'punct' ||
      getClosestNotRemovedElementIndexToRight(toElement + 1, elements) === -1
    ) {
      let prevIndex;
      if (
        elements[toElement].type === 'text' &&
        nextElement?.type === 'punct'
      ) {
        prevIndex = getClosestTextIndexToLeft(fromElement - 1, elements);
      } else {
        prevIndex = getClosestNotRemovedNotWhiteSpaceElementIndexToLeft(
          fromElement - 1,
          elements,
        );
      }
      if (prevIndex >= 0) {
        fromElement = getClosestNotRemovedElementIndexToRight(
          prevIndex + 1,
          elements,
        );
      } else {
        fromElement = getClosestNotRemovedElementIndexToRight(0, elements);
        const nextTextIndex = getClosestNotRemovedTextIndexToRight(
          toElement + 1,
          elements,
        );
        if (nextTextIndex >= 0) {
          toElement = getClosestNotRemovedElementIndexToLeft(
            nextTextIndex - 1,
            elements,
          );
        } else {
          // nothing to cut
        }
      }
    }

    if (fromElement > toElement) {
      console.error('Invalid cut positions', fromElement, toElement, elements);
      throw Error('Invalid cut positions');
    }

    return { fromElement, toElement };
  }

  generateClipboardRanges(fromElement: number, toElement: number) {
    let fromIndex = fromElement;
    const ranges = [];
    while (fromIndex >= 0 && fromIndex <= toElement) {
      if (fromIndex === toElement) {
        ranges.push({ start: fromIndex, end: fromIndex });
        break;
      }

      const nextRemovedIndex = getClosestRemovedIndexToRight(
        fromIndex,
        this.finalTranscriptionElements!,
      );
      const lastNotRemovedElement =
        nextRemovedIndex > -1
          ? getClosestNotRemovedElementIndexToLeft(
              nextRemovedIndex,
              this.finalTranscriptionElements!,
            )
          : toElement;
      if (lastNotRemovedElement === -1) break;

      ranges.push({
        start: fromIndex,
        end: Math.min(lastNotRemovedElement, toElement),
      });

      fromIndex = getClosestNotRemovedElementIndexToRight(
        lastNotRemovedElement + 1,
        this.finalTranscriptionElements!,
      );
    }
    // // debugger;
    return ranges;
  }

  /** Operations on transcription reflected in video tracks */
  async pasteFromClipboard(intoPosition: number) {
    const clipboard = this.transcriptClipboard;
    if (!clipboard) return;
    let addedPositions = 0;
    for (const range of clipboard.ranges) {
      const insertingBefore = intoPosition < range.start;
      // // debugger;
      await this.insertTextElements(
        range.start + (insertingBefore ? addedPositions : 0),
        range.end + 1 + (insertingBefore ? addedPositions : 0),
        intoPosition + addedPositions,
      );
      addedPositions += range.end - range.start + 1;
    }
    this.transcriptClipboard = undefined;
  }

  async removeTextElements(
    fromElement: number,
    toElement: number,
    toClipboard: boolean = false,
  ) {
    // debugger;
    // const { fromElement: newFromElement, toElement: newToElement } =
    //   this.getValidCutPositions(fromElement, toElement - 1);
    const type = toClipboard ? 'cut' : 'remove';

    const newFromElement = fromElement;
    const newToElement = toElement - 1;

    if (toClipboard) {
      const ranges = this.generateClipboardRanges(newFromElement, newToElement);
      this.transcriptClipboard = {
        text: this.finalTranscriptionElements!.slice(
          newFromElement,
          newToElement + 1,
        )
          .map((el) => el.value || '')
          .join(''),
        ranges: ranges,
      };
    }

    fromElement = newFromElement;
    toElement = newToElement + 1;
    // debugger;
    // Logic to remove a segment from both video and transcription
    const cutPoints = this.getCutPointsForTranscriptionElements(
      fromElement,
      toElement,
    );
    if (!cutPoints) return;
    const { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter } = cutPoints;

    if (cutFromTs === cutToTs) {
      // no text elements between fromElement and toElement, or elements are removed
      this.applyChange(
        this.getRemoveChange(fromElement, toElement, 0, 0, type),
      );
      return;
    }

    // console.log('timings 2', timeBufferBefore, timeBufferAfter, cutFromTs, cutToTs)
    await this.cutSpecifiedTypeTracksSegment(cutFromTs, cutToTs, [
      'composition',
    ]);
    this.applyChange(
      this.getRemoveChange(
        fromElement,
        toElement,
        timeBufferBefore,
        timeBufferAfter,
        type,
      ),
    );
  }

  async cropVideoToKeepTextElements(fromElement: number, toElement: number) {
    const state = this.renderer!.state!;
    // // debugger;

    for (const part of ['tail', 'head']) {
      //order matters
      const startIndex = part === 'tail' ? toElement + 1 : 0;
      const endIndex =
        part === 'tail' ? this.finalTranscriptionElements!.length : fromElement;

      // CUT AFTER toElement
      const cutPoints = this.getCutPointsForTranscriptionElements(
        startIndex,
        endIndex,
      );
      if (!cutPoints) continue;
      const { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter } =
        cutPoints;
      // // debugger;
      let extraDeletedTime = 0;
      if (part === 'tail') {
        await this.cutSpecifiedTypeTracksSegment(cutFromTs, state.duration, [
          'composition',
        ]);
        extraDeletedTime = state.duration - cutToTs;
      } else {
        await this.cutSpecifiedTypeTracksSegment(0, cutToTs, ['composition']);
        extraDeletedTime = cutFromTs;
      }
      // // debugger;
      this.applyChange(
        this.getRemoveChange(
          startIndex,
          endIndex,
          timeBufferBefore + extraDeletedTime,
          timeBufferAfter,
        ),
      );
    }
  }

  async cutTranscriptElementsForClip(fromElement: number, toElement: number) {
    for (const part of ['tail', 'head']) {
      //order matters
      const startIndex = part === 'tail' ? toElement + 1 : 0;
      const endIndex =
        part === 'tail' ? this.finalTranscriptionElements!.length : fromElement;

      // CUT AFTER toElement
      const cutPoints = this.getCutPointsForTranscriptionElements(
        startIndex,
        endIndex,
      );
      if (!cutPoints) continue;
      const { timeBufferBefore, timeBufferAfter } = cutPoints;
      // // debugger;
      this.applyChange(
        this.getRemoveChange(
          startIndex,
          endIndex,
          timeBufferBefore,
          timeBufferAfter,
        ),
      );
    }
  }

  async insertTextElements(
    fromElement: number,
    toElement: number,
    intoPosition: number,
  ) {
    // debugger;
    if (toElement <= fromElement || fromElement < 0) return;
    const originalElements = this.transcriptionElements!;
    const finalElements = this.finalTranscriptionElements!;

    // find start time and end time of moved text segment in original transcription
    const fromTextElement = getClosestTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextElement = getClosestTextIndexToLeft(
      toElement - 1,
      finalElements,
    );

    const originalFromIndex = finalElements[fromTextElement].initial_index;
    const originalToIndex = finalElements[toTextElement].initial_index;

    const timeBufferBefore =
      (finalElements[fromTextElement] as TranscriptTextElement)
        .buffer_before_ts || 0;
    const timeBufferAfter =
      (finalElements[toTextElement] as TranscriptTextElement).buffer_after_ts ||
      0;

    let fromTime = originalElements[originalFromIndex].ts! - timeBufferBefore;
    let toTime = originalElements[originalToIndex].end_ts! + timeBufferAfter;

    // fromTime = this.getClosestFrameTime(fromTime);
    // toTime = this.getClosestFrameTime(toTime);

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      intoPosition - 1,
      this.finalTranscriptionElements!,
    );

    let afterTime = 0;
    if (afterTextElement >= 0) {
      const afterEl = this.finalTranscriptionElements![
        afterTextElement
      ] as TranscriptTextElement;
      afterTime = afterEl.end_ts + (afterEl.buffer_after_ts || 0);
      // getTimeBufferAfter(afterTextElement, originalElements);
    } else {
      // insert at the beginning
      const firstNonRemovedElement = this.finalTranscriptionElements![
        getClosestNotRemovedTextIndexToRight(
          0,
          this.finalTranscriptionElements!,
        )
      ] as TranscriptTextElement;

      afterTime = firstNonRemovedElement
        ? firstNonRemovedElement.ts -
          (firstNonRemovedElement.buffer_before_ts || 0)
        : 0;
    }
    // debugger;
    // insert video segment corresponding to moved text segment after afterElement
    await this.insertVideoSegment(fromTime, toTime, afterTime);

    const cutBufferBefore =
      (
        this.finalTranscriptionElements![
          fromTextElement
        ] as TranscriptTextElement
      ).buffer_before_ts || 0;

    const cutFromTs =
      this.finalTranscriptionElements![fromTextElement].ts! - cutBufferBefore;

    // debugger;

    this.applyChange({
      type: 'shift',
      index: intoPosition,
      count: this.finalTranscriptionElements!.length - intoPosition,
      newIndex: intoPosition,
      timeShift: toTime - fromTime,
      datetime: new Date().toISOString(),
    });

    this.applyChange({
      type: 'shift',
      index: fromElement,
      count: toElement - fromElement,
      newIndex: intoPosition,
      timeShift:
        afterTime - cutFromTs - (cutFromTs > afterTime ? toTime - fromTime : 0),
      datetime: new Date().toISOString(),
    });
  }

  /** remove transcription elements between fromElement and toElement positions and place them next to afterElement */
  async moveTextElements(
    fromElement: number,
    toElement: number,
    afterElement: number,
  ) {
    // debugger;
    if (toElement <= fromElement || fromElement < 0) return;
    const originalElements = this.transcriptionElements!;
    // find start time and end time of moved text segment in original transcription
    const fromTextElement = getClosestNotRemovedTextIndexToRight(
      fromElement,
      this.finalTranscriptionElements!,
    );
    const toTextElement = getClosestNotRemovedTextIndexToLeft(
      toElement - 1,
      this.finalTranscriptionElements!,
    );

    const originalFromIndex =
      this.finalTranscriptionElements![fromTextElement].initial_index;
    const originalToIndex =
      this.finalTranscriptionElements![toTextElement].initial_index;

    const timeBufferBefore =
      (originalElements[originalFromIndex] as TranscriptTextElement)
        .buffer_before_ts || 0;
    const timeBufferAfter =
      (originalElements[originalFromIndex] as TranscriptTextElement)
        .buffer_after_ts || 0;

    const fromTime = originalElements[originalFromIndex].ts! - timeBufferBefore;
    const toTime = originalElements[originalToIndex].end_ts! + timeBufferAfter;

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      afterElement - 1,
      this.finalTranscriptionElements!,
    );

    let afterTime = 0;
    if (afterTextElement >= 0) {
      const afterEl = this.finalTranscriptionElements![
        afterTextElement
      ] as TranscriptTextElement;
      afterTime = afterEl.end_ts + (afterEl.buffer_after_ts || 0);
      // getTimeBufferAfter(afterTextElement, originalElements);
    } else {
      afterTime = originalElements[0].ts!; // todo check
      // getTimeBufferBefore(
      //   getClosestNotRemovedTextIndexToLeft(0, originalElements),
      //   originalElements,
      // );
    }
    // debugger;
    // insert video segment corresponding to moved text segment after afterElement
    await this.insertVideoSegment(fromTime, toTime, afterTime);

    // debugger;
    // cut video segment from fromElement to toElement related to final transcription

    const cutBufferBefore =
      (
        this.finalTranscriptionElements![
          fromTextElement
        ] as TranscriptTextElement
      ).buffer_before_ts || 0;
    const cutBufferAfter =
      (
        this.finalTranscriptionElements![
          toTextElement - 1
        ] as TranscriptTextElement
      ).buffer_after_ts || 0;

    const cutFromTs =
      this.finalTranscriptionElements![fromTextElement].ts! - cutBufferBefore;
    const cutToTs =
      this.finalTranscriptionElements![toTextElement].end_ts! + cutBufferAfter;

    const shiftTs = afterTime - cutFromTs > 0 ? 0 : cutToTs - cutFromTs;
    await this.cutSpecifiedTypeTracksSegment(
      cutFromTs + shiftTs,
      cutToTs + shiftTs,
      ['audio', 'composition'],
    );
    // debugger;
    // apply three shifts to final transcription using applyChange:
    // 1. shift transcription elements after afterElement to the right by duration of moved segment
    // 2. shift transcription elements from fromElement to toElement to the afterElement position
    // 3. shift transcription elements after toElement to the left by duration of moved segment

    // shift 1:
    this.applyChange({
      type: 'shift',
      index: afterElement,
      count: this.finalTranscriptionElements!.length - afterElement,
      newIndex: afterElement,
      timeShift: toTime - fromTime,
      datetime: new Date().toISOString(),
    });

    //shift 2:
    this.applyChange({
      type: 'shift',
      index: fromElement,
      count: toElement - fromElement,
      newIndex: afterElement,
      timeShift: afterTime - cutFromTs - shiftTs,
      datetime: new Date().toISOString(),
    });

    //shift 3:
    this.applyChange({
      type: 'shift',
      index: toElement,
      count: this.finalTranscriptionElements!.length - toElement,
      newIndex: toElement,
      timeShift: -(cutToTs - cutFromTs),
      datetime: new Date().toISOString(),
    });
  }

  replaceTextElement(startIndex: number, endIndex: number, newValue: string) {
    this.applyChange({
      type: 'replace',
      index: startIndex,
      endIndex,
      count: Math.max(endIndex - startIndex + 1, 1),
      oldValue: this.finalTranscriptionElements!.slice(startIndex, endIndex + 1)
        .map((el) => el.value || '')
        .join(''),
      newValue: newValue,
      datetime: new Date().toISOString(),
    });
  }

  hideKaraoke(boundaries: { startIndex: number; endIndex: number }) {
    this.applyChange({
      type: 'mute',
      index: boundaries.startIndex,
      count: boundaries.endIndex - boundaries.startIndex + 1,
      datetime: new Date().toISOString(),
    });
  }

  async restoreMutedTextElement(fromElement: number, toElement: number) {
    const originalElements = this.transcriptionElements!;
    if (
      fromElement < 0 ||
      toElement <= fromElement ||
      toElement > originalElements.length
    ) {
      throw Error('Invalid elements range');
    }

    this.applyChange({
      type: 'restore_mute',
      index: fromElement,
      count: toElement - fromElement,
      datetime: new Date().toISOString(),
    });
  }

  async untrimTextElements(
    fromTs: number,
    toTs: number,
    newTs: number,
    type: 'start' | 'end',
  ) {
    this.applyChange({
      index: -1,
      count: 0,
      type: type === 'start' ? 'untrim_start' : 'untrim_end',
      fromTs,
      toTs,
      newTs,
      datetime: new Date().toISOString(),
    });
  }

  async applyUntrimStartChange(
    change: TranscriptChange & { type: 'untrim_start' },
  ) {
    const { fromTs, toTs, newTs: intoTs } = change;
    // debugger;
    const originalElements = this.transcriptionElements!;
    let finalElements = this.finalTranscriptionElements!;
    let addedDuration = toTs - fromTs;

    let firstTextElementIndex = this.finalTranscriptionElements!.findIndex(
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.end_ts > intoTs + addedDuration,
    );
    // debugger;
    const firstIndexToUntrim = originalElements.findIndex(
      (el) =>
        el.type === 'text' &&
        el.end_ts! > fromTs &&
        el.end_ts! - fromTs > fromTs - el.ts!,
    );

    let lastIndexToUntrim = originalElements.findIndex(
      (el) => el.type === 'text' && el.end_ts! > toTs,
    );

    const lastElementToUntrim = originalElements[
      lastIndexToUntrim
    ] as TranscriptTextElement;

    // debugger;
    if (firstTextElementIndex > 0) {
      const firstTextElement = finalElements[
        firstTextElementIndex
      ] as TranscriptTextElement;
      if (
        lastIndexToUntrim > 0 &&
        lastIndexToUntrim === firstTextElement.initial_index
      ) {
        const newTs =
          firstTextElement.end_ts! -
          (lastElementToUntrim.end_ts - lastElementToUntrim.ts);
        const newBufferBefore = lastElementToUntrim.buffer_before_ts || 0;
        addedDuration -=
          firstTextElement.ts -
          newTs +
          newBufferBefore -
          (firstTextElement.buffer_before_ts || 0);
        firstTextElement.ts = newTs;
        firstTextElement.buffer_before_ts =
          lastElementToUntrim.buffer_before_ts || 0;

        firstTextElementIndex--;
        lastIndexToUntrim--;
        // last element overlaps
        // expand first element in existing track
      } else if (lastIndexToUntrim > 0) {
        addedDuration +=
          lastElementToUntrim.end_ts -
          toTs +
          (lastElementToUntrim.buffer_after_ts || 0);
        firstTextElementIndex = getClosestTextIndexToLeft(
          firstTextElementIndex - 1,
          finalElements,
        );
        // expand last element of added part
      } else {
        // edge case, last word in the track
      }
    }
    // debugger;
    const elementsCount = lastIndexToUntrim - firstIndexToUntrim;

    for (let i = elementsCount; i >= 0; i--) {
      const originalElement = originalElements[firstIndexToUntrim + i];
      const finalElement =
        finalElements[firstTextElementIndex - elementsCount + i];
      if (finalElement.initial_index === firstIndexToUntrim + i) {
        delete finalElement.state;
        if (finalElement.type === 'text' && originalElement.type === 'text') {
          // todo handle partial restore of the first word (addedDuration becomes < 0)
          finalElement.buffer_after_ts = originalElement.buffer_after_ts || 0;
          finalElement.end_ts =
            intoTs + addedDuration - finalElement.buffer_after_ts;
          finalElement.ts =
            finalElement.end_ts - (originalElement.end_ts - originalElement.ts);
          finalElement.buffer_before_ts = originalElement.buffer_before_ts || 0;
          if (i === 0) {
            // first element
            if (finalElement.ts - finalElement.buffer_before_ts < intoTs) {
              const newTs = Math.max(finalElement.ts, intoTs);
              finalElement.buffer_before_ts = newTs - intoTs;
              finalElement.trim_start = newTs - finalElement.ts;
              finalElement.ts = newTs;
            }
          }
          addedDuration -=
            finalElement.end_ts -
            finalElement.ts +
            finalElement.buffer_after_ts +
            finalElement.buffer_before_ts;
        }
      } else {
        // debugger;
        const addedSegment = originalElements
          .slice(firstIndexToUntrim, firstIndexToUntrim + i)
          .map((el) => {
            if (el.type === 'punct') {
              return { ...el, state: 'added' } as TranscriptElement;
            } else {
              const end_ts = intoTs + el.end_ts! - fromTs; // does el.end_ts - fromTs === addedDuration - buffer_after?
              const ts = end_ts - (el.end_ts - el.ts);
              return { ...el, state: 'added', end_ts, ts } as TranscriptElement;
            }
          });
        // debugger;
        finalElements.splice(
          firstTextElementIndex - elementsCount,
          0,
          ...addedSegment,
        );
        break;
      }
    }
    this.onFinalTranscriptionElementsChange(finalElements);
  }

  async applyUntrimEndChange(
    change: TranscriptChange & { type: 'untrim_end' },
  ) {
    const { fromTs, toTs, newTs: intoTs, type } = change;
    // debugger;
    const originalElements = this.transcriptionElements!;
    let finalElements = this.finalTranscriptionElements!;
    let addedDuration = 0; //toTs - fromTs;

    //@ts-ignore
    let lastTextElementIndex = this.finalTranscriptionElements!.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.ts < intoTs,
    );
    // debugger;
    //@ts-ignore
    const lastIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.ts! < toTs &&
        el.end_ts! - toTs < toTs - el.ts!,
    );

    //@ts-ignore
    let firstIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) => el.type === 'text' && el.ts < fromTs,
    );

    const firstElementToUntrim = originalElements[
      firstIndexToUntrim
    ] as TranscriptTextElement;

    // debugger;
    if (lastTextElementIndex > 0) {
      const lastTextElement = finalElements[
        lastTextElementIndex
      ] as TranscriptTextElement;
      if (
        firstIndexToUntrim > 0 &&
        firstIndexToUntrim === lastTextElement.initial_index
      ) {
        const newTs =
          lastTextElement.ts! +
          (firstElementToUntrim.end_ts - firstElementToUntrim.ts);
        const newBufferAfter = firstElementToUntrim.buffer_after_ts || 0;
        addedDuration +=
          newTs -
          lastTextElement.end_ts +
          newBufferAfter -
          (lastTextElement.buffer_after_ts || 0);
        lastTextElement.end_ts = newTs;
        lastTextElement.buffer_after_ts =
          firstElementToUntrim.buffer_after_ts || 0;

        lastTextElementIndex++;
        firstIndexToUntrim++;
        // last element overlaps
        // expand first element in existing track
      } else if (firstIndexToUntrim > 0) {
        addedDuration -=
          fromTs -
          firstElementToUntrim.ts +
          (firstElementToUntrim.buffer_before_ts || 0);
        lastTextElementIndex = getClosestTextIndexToRight(
          lastTextElementIndex + 1,
          finalElements,
        );
        // expand last element of added part
      } else {
        // edge case, last word in the track
      }
    }
    // debugger;
    const elementsCount = lastIndexToUntrim - firstIndexToUntrim;

    for (let i = 0; i <= elementsCount; i++) {
      const originalElement = originalElements[firstIndexToUntrim + i];
      const finalElement = finalElements[lastTextElementIndex + i];
      if (finalElement.initial_index === firstIndexToUntrim + i) {
        delete finalElement.state;
        if (finalElement.type === 'text' && originalElement.type === 'text') {
          // todo handle partial restore of the first word (addedDuration becomes < 0)
          finalElement.buffer_before_ts = originalElement.buffer_before_ts || 0;
          finalElement.ts =
            intoTs + addedDuration + finalElement.buffer_before_ts;
          finalElement.end_ts =
            finalElement.ts + (originalElement.end_ts - originalElement.ts);
          finalElement.buffer_after_ts = originalElement.buffer_after_ts || 0;
          if (i === elementsCount) {
            // last element
            if (
              finalElement.end_ts + finalElement.buffer_after_ts >
              intoTs + (toTs - fromTs)
            ) {
              const newTs = Math.min(
                finalElement.end_ts,
                intoTs + (toTs - fromTs),
              );
              finalElement.buffer_after_ts = intoTs + (toTs - fromTs) - newTs;
              finalElement.trim_end = finalElement.end_ts - newTs;
              finalElement.end_ts = newTs;
            }
          }
          addedDuration +=
            finalElement.end_ts -
            finalElement.ts +
            finalElement.buffer_after_ts +
            finalElement.buffer_before_ts;
        }
      } else {
        // debugger;
        const addedSegment = originalElements
          .slice(firstIndexToUntrim + i, firstIndexToUntrim + elementsCount)
          .map((el) => {
            if (el.type === 'punct') {
              return { ...el, state: 'added' } as TranscriptElement;
            } else {
              const ts = intoTs + toTs - el.ts!; // does el.end_ts - fromTs === addedDuration - buffer_after?
              const end_ts = ts + (el.end_ts - el.ts);
              return { ...el, state: 'added', end_ts, ts } as TranscriptElement;
            }
          });
        // debugger;
        finalElements.splice(
          lastTextElementIndex + elementsCount,
          0,
          ...addedSegment,
        );
        break;
      }
    }
    this.onFinalTranscriptionElementsChange(finalElements);
  }

  async restoreTextElementsFromOriginal(
    fromElement: number,
    toElement: number,
  ) {
    // debugger;
    const originalElements = this.transcriptionElements!;
    const finalElements = this.finalTranscriptionElements!;
    if (
      fromElement < 0 ||
      toElement <= fromElement ||
      toElement > finalElements.length
    ) {
      throw Error('Invalid elements range');
    }

    const fromTextIndex = getClosestTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextIndex = getClosestTextIndexToLeft(toElement - 1, finalElements);

    if (fromTextIndex === -1 || toTextIndex === -1) return;

    const fromTextElement = finalElements[fromTextIndex].initial_index;
    const toTextElement = finalElements[toTextIndex].initial_index;

    if (fromTextElement > toTextElement || fromTextElement === -1) {
      // that means selection doesn't contain any text element
      this.applyChange({
        type: 'restore',
        index: fromElement,
        count: toElement - fromElement,
        newTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
        datetime: new Date().toISOString(),
      });
      return;
    }

    if (fromTextElement === -1 || toTextElement === -1) return;

    //TODO use initial_index

    // find text elements closest to outside of boundaries (to cut video between words, not on start)
    const finalFromElement = finalElements[
      fromTextIndex
    ] as TranscriptTextElement;
    const finalToElement = finalElements[toTextIndex] as TranscriptTextElement;
    // debugger;
    let timeBufferBefore =
      finalFromElement.buffer_before_ts ||
      0 - (finalFromElement.trim_start || 0);
    let timeBufferAfter =
      finalToElement.buffer_after_ts || 0 - (finalToElement.trim_end || 0);

    let restoreFromTs =
      originalElements[fromTextElement].ts! - timeBufferBefore;
    let restoreToTs = originalElements[toTextElement].end_ts! + timeBufferAfter;

    if (restoreToTs - restoreFromTs <= 0) {
      // negative or zero duration segment, just restore the text
      this.applyChange({
        type: 'restore',
        index: fromElement,
        count: toElement - fromElement,
        newTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
        datetime: new Date().toISOString(),
      });
      return;
    }

    // restoreFromTs = this.getClosestFrameTime(restoreFromTs);
    // restoreToTs = this.getClosestFrameTime(restoreToTs);
    // todo handle restoreToTs === restoreFromTs
    // timeBufferBefore = originalElements[fromTextElement].ts! - restoreFromTs;
    // timeBufferAfter = restoreToTs - originalElements[toTextElement].end_ts!;

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      fromElement,
      finalElements,
    );

    let intoTs = 0;
    // debugger;
    if (afterTextElement >= 0) {
      // insert somewhere in the middle
      const afterTextTimeBuffer =
        (finalElements[afterTextElement] as TranscriptTextElement)
          .buffer_after_ts || 0;
      intoTs = finalElements[afterTextElement].end_ts! + afterTextTimeBuffer;
      // intoTs = this.getClosestFrameTime(intoTs);
    } else {
      // insert at the beginning
      const firstNonRemovedElement = finalElements[
        getClosestNotRemovedTextIndexToRight(0, finalElements)
      ] as TranscriptTextElement;

      intoTs = firstNonRemovedElement
        ? firstNonRemovedElement.ts -
          (firstNonRemovedElement.buffer_before_ts || 0)
        : 0;
      // intoTs = this.getClosestFrameTime(intoTs);
    }
    // debugger;
    const newTs = await this.insertVideoSegment(
      restoreFromTs,
      restoreToTs,
      intoTs,
    );

    this.applyChange({
      type: 'restore',
      index: fromElement,
      count: toElement - fromElement,
      newTs,
      timeBufferBefore,
      timeBufferAfter,
      datetime: new Date().toISOString(),
    });
  }

  /*
    Other operations on transcription
  */

  findClosestTimestamp(ts: number, time: 'ts' | 'end_ts' = 'ts') {
    let closest: number = 0;
    let minDiff = Infinity;

    this.finalTranscriptionElements?.forEach((element, index) => {
      const currTs = element[time];
      if (currTs) {
        const diff = Math.abs(currTs - ts);

        if (diff < minDiff) {
          minDiff = diff;
          closest = index;
        }
      }
    });
    return closest;
  }

  async addPunctuation(code: 'Comma' | 'Period' | 'Space', position: number) {
    const punctMap = {
      Comma: ',',
      Period: '.',
      Space: ' ',
    } as Record<string, ' ' | ',' | '.'>;
    this.applyChange({
      type: 'insert_punct',
      index: position,
      count: 1,
      value: punctMap[code],
      datetime: new Date().toISOString(),
    });
  }

  addPhotoHighlight(
    fromElement: number,
    toElement: number,
    photoHighlightId: string,
  ) {
    this.applyChange({
      index: fromElement,
      count: toElement - fromElement + 1,
      type: 'photo_highlight',
      newPhotoHighlightId: photoHighlightId,
      datetime: new Date().toISOString(),
    });
  }

  getElementByPlaybackTime(timeSeconds: number) {
    const currentWordIndex = this.finalTranscriptionElements!.findIndex(
      (el) =>
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.end_ts &&
        el.end_ts > timeSeconds,
    );
    return currentWordIndex;
  }

  applyChanges(transcriptChanges: TranscriptChange[]) {
    if (!this.transcriptionElements)
      throw new Error('No transcription elements');
    this.finalTranscriptionElements = this.transcriptionElements.map(
      (el, index) => ({ ...el, initial_index: index }),
    );
    for (const change of transcriptChanges) {
      this.applyChange(change);
    }

    this.transcriptionChanges = transcriptChanges;
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  private getCutPointsForTranscriptionElements(
    fromElement: number,
    toElement: number,
  ) {
    if (fromElement >= toElement || fromElement < 0) return null;
    // fromElement including, toElement excluding
    // find text elements (handling case when selection starts or ends on punctuation elements)
    const fromTextElement = getClosestNotRemovedTextIndexToRight(
      fromElement,
      this.finalTranscriptionElements!,
    );
    const toTextElement = getClosestNotRemovedTextIndexToLeft(
      toElement - 1,
      this.finalTranscriptionElements!,
    );

    if (fromTextElement > toTextElement || fromTextElement === -1) {
      // that means selection doesn't contain any text element
      return {
        cutFromTs: 0,
        cutToTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
      };
    }

    if (fromTextElement === -1 || toTextElement === -1) return null;

    // // find text elements closest to outside of boundaries (to cut video between words, not on start)
    // const originalFromIndex =
    //   this.finalTranscriptionElements![fromTextElement].initial_index;
    // const originalToIndex =
    //   this.finalTranscriptionElements![toTextElement].initial_index;

    // const timeBufferBefore =
    //   (this.transcriptionElements![originalFromIndex] as TranscriptTextElement)
    //     .buffer_before_ts || 0;
    // const timeBufferAfter =
    //   (this.transcriptionElements![originalToIndex] as TranscriptTextElement)
    //     .buffer_after_ts || 0;

    let timeBufferBefore =
      (
        this.finalTranscriptionElements![
          fromTextElement
        ] as TranscriptTextElement
      ).buffer_before_ts || 0;
    let timeBufferAfter =
      (this.finalTranscriptionElements![toTextElement] as TranscriptTextElement)
        .buffer_after_ts || 0;

    // TODO CHECK buffers between words are calculated differently from right side vs from left side

    let cutFromTs = Math.max(
      0,
      this.finalTranscriptionElements![fromTextElement].ts! - timeBufferBefore,
    );
    let cutToTs =
      this.finalTranscriptionElements![toTextElement].end_ts! + timeBufferAfter;
    cutFromTs = this.getClosestFrameTime(cutFromTs);
    cutToTs = this.getClosestFrameTime(cutToTs);

    // if (cutToTs === cutFromTs) {
    //   cutToTs = cutFromTs + 1 / DEFAULT_FPS;
    // }

    timeBufferBefore =
      this.finalTranscriptionElements![fromTextElement].ts! - cutFromTs;
    timeBufferAfter =
      cutToTs - this.finalTranscriptionElements![toTextElement].end_ts!;

    return { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter };
  }

  private getClosestFrameTime(time: number) {
    // const frameRate = this.originalSource.frame_rate || DEFAULT_FPS;
    // const frameDuration = 1 / frameRate;
    // debugger;
    const precision = 0.001;
    const frameTime = Math.round(time / precision) / (1 / precision);
    return frameTime;
  }

  private getRemoveChange(
    fromElement: number,
    toElement: number,
    timeBufferBefore: number,
    timeBufferAfter: number,
    type: 'remove' | 'cut' = 'remove',
  ): TranscriptChange {
    const newChange: TranscriptChange = {
      type,
      index: fromElement,
      count: toElement - fromElement,
      timeBufferBefore,
      timeBufferAfter,
      oldValue: this.finalTranscriptionElements!.slice(fromElement, toElement)
        .map((el) => el.value || '')
        .join(''),
      newValue: null,
      datetime: new Date().toISOString(),
      version: 2,
    };
    return newChange;
  }

  private async cutSpecifiedTypeTracksSegment(
    fromTs: number,
    toTs: number,
    skipTypes: string[],
  ) {
    // if (fromTs - this.getClosestFrameTime(fromTs) > 0.00001) {
    //   console.warn('Cutting not on frame boundary', fromTs);
    // }
    // if (toTs - this.getClosestFrameTime(toTs) > 0.00001) {
    //   console.warn('Cutting not on frame boundary', toTs);
    // }
    const source = this.renderer!.getSource();
    const elements = this.renderer!.getElements();
    // debugger;
    // let videoElement = source.elements.find((el: any) => el.type === 'video');
    // let trackNumber = videoElement?.track || 1;
    const newTracks = [];
    const elementsInComposition = {} as any;
    // find composition elements
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].source.type === 'composition') {
        elements[i].elements?.forEach((el) => {
          elementsInComposition[el.source.id] = el;
        });
      }
    }

    for (let i = 0; i < elements.length; i++) {
      const elementTime = elements[i].globalTime;
      const elementDuration = elements[i].duration;
      if (
        elements[i].source.type === 'composition' ||
        elementsInComposition[elements[i].source.id]
      ) {
        // skip elements from compositions
        continue;
      }
      // if (skipTypes.includes(elements[i].source.type)) {
      //   // skip audio tracks from cutting it
      //   newTracks.push(source.elements[i]);
      //   continue;
      // }
      // console.log('all numbers', elements[i].globalTime, elements[i].duration, fromTs, toTs, elements[i].source.trim_start)
      if (
        // source.elements[i].track === trackNumber &&
        elementTime < fromTs &&
        elementTime + elementDuration > fromTs
      ) {
        // console.log('Head', elements[i],'duration', fromTs - (elements[i].globalTime || 0) );
        newTracks.push({
          ...elements[i].source,
          id: uuid(),
          duration: fromTs - elementTime,
        });
      }

      if (
        // source.elements[i].track === trackNumber &&
        elementTime < toTs &&
        elementTime + elementDuration > toTs
      ) {
        // console.log('Tail', elements[i], 'duration', elements[i].duration - toTs + (elements[i].globalTime || 0), 'trim_start',(elements[i].source.trim_start || '0') + toTs - (elements[i].globalTime || 0));
        newTracks.push({
          ...elements[i].source,
          id: uuid(),
          time: fromTs,
          duration: elementDuration - toTs + (elementTime || 0),
          trim_start:
            parseFloat(elements[i].source.trim_start || '0') +
            toTs -
            elementTime,
        });
      }

      if (
        // source.elements[i].track === trackNumber &&
        elementTime + elementDuration <=
        fromTs
      ) {
        // console.log('Before', elements[i]);
        newTracks.push(elements[i].source);
      }

      //elements[i].track === trackNumber &&
      if (elementTime >= toTs) {
        // console.log('After', elements[i], 'time', elements[i].globalTime - (toTs - fromTs));
        newTracks.push({
          ...elements[i].source,
          id: uuid(),
          time: elementTime - (toTs - fromTs),
        });
      }
    }

    // debugger;
    source.elements = newTracks;
    await this.renderer!.setSource(source, true);
  }

  private applyChange(change: TranscriptChange) {
    switch (change?.type) {
      case 'remove':
      case 'cut':
        this.applyRemoveChange(change);
        break;
      case 'replace':
        this.applyReplaceChange(change);
        break;
      case 'shift':
        this.applyShiftChange(change);
        break;
      case 'restore':
        this.applyRestoreChange(change);
        break;
      case 'photo_highlight':
        this.applyPhotoHighlightChange(change);
        break;
      case 'restore_mute':
        this.applyRemoveMuteChanges(change);
        break;
      case 'mute':
        this.applyMuteChange(change);
        break;
      case 'insert_punct':
        this.applyInsertChange(change);
        break;
      case 'untrim_start':
        this.applyUntrimStartChange(change);
        break;
      case 'untrim_end':
        this.applyUntrimEndChange(change);
        break;
    }
    this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
    this.transcriptionChanges.push(change);
  }

  private increaseBufferAfter(index: number, time: number) {
    if (time <= 0) {
      throw Error('Buffer time to increase should be positive');
    }
    const elements = this.finalTranscriptionElements!;
    const nextWord = elements[
      getClosestTextIndexToRight(index + 1, elements)
    ] as TranscriptTextElement | undefined;
    const currentWord = elements[index] as TranscriptTextElement;

    currentWord.buffer_after_ts = (currentWord.buffer_after_ts || 0) + time;
    if (nextWord) {
      nextWord.buffer_before_ts = (nextWord.buffer_before_ts || 0) - time;
      if (nextWord.buffer_before_ts < 0) {
        // todo make sure ts < end_ts still
        nextWord.ts -= nextWord.buffer_before_ts;
        nextWord.trim_start =
          (nextWord.trim_start || 0) - nextWord.buffer_before_ts;
        nextWord.buffer_before_ts = 0;
      }
    }
    console.debug(currentWord.value + ' buffer after increased by ' + time);
    console.debug('Current and next word:', currentWord, nextWord);
  }

  private increaseBufferBefore(index: number, time: number) {
    if (time <= 0) {
      throw Error('Buffer time to increase should be positive');
    }
    const elements = this.finalTranscriptionElements!;
    const prevWord = elements[
      getClosestTextIndexToLeft(index - 1, elements)
    ] as TranscriptTextElement | undefined;
    const currentWord = elements[index] as TranscriptTextElement;

    currentWord.buffer_before_ts = (currentWord.buffer_before_ts || 0) + time;
    if (prevWord) {
      prevWord.buffer_after_ts = (prevWord.buffer_after_ts || 0) - time;
      if (prevWord.buffer_after_ts < 0) {
        // todo make sure ts < end_ts still
        prevWord.end_ts += prevWord.buffer_after_ts;
        prevWord.trim_end = (prevWord.trim_end || 0) - prevWord.buffer_after_ts;
        prevWord.buffer_after_ts = 0;
      }
    }
    console.debug(currentWord.value + ' buffer before increased by ' + time);
    console.debug('Current and prev word:', currentWord, prevWord);
  }

  private applyRemoveChange(
    change: TranscriptChange & { type: 'remove' | 'cut' },
  ) {
    // debugger;
    const elements = this.finalTranscriptionElements!;
    const firstWordIndex = getClosestNotRemovedTextIndexToRight(
      change.index,
      elements,
    );
    const firstWord = elements[firstWordIndex] as TranscriptTextElement;
    const lastWordIndex = getClosestNotRemovedTextIndexToLeft(
      change.index + change.count - 1,
      elements,
    );
    const lastWord = elements[lastWordIndex] as TranscriptTextElement;
    console.log('last word', lastWord, lastWordIndex, firstWord, firstWordIndex);
    const startTs = firstWord?.ts ?? 0;
    const endTs = lastWord?.end_ts ?? startTs;

    if (change.version === 2 && firstWord && lastWord) {
      const prevWordIndex = getClosestTextIndexToLeft(
        change.index - 1,
        elements,
      );
      const nextWordIndex = getClosestTextIndexToRight(
        change.index + change.count,
        elements,
      );

      const startBufferDiff =
        firstWord.buffer_before_ts! - change.timeBufferBefore;
      // debugger;
      if (startBufferDiff > 0.00001) {
        if (prevWordIndex > -1) {
          this.increaseBufferAfter(prevWordIndex, startBufferDiff);
        } else {
          firstWord.buffer_before_ts = change.timeBufferBefore;
        }
      } else if (startBufferDiff < -0.00001) {
        this.increaseBufferBefore(firstWordIndex, -startBufferDiff);
      }

      const endBufferDiff = lastWord.buffer_after_ts! - change.timeBufferAfter;
      if (endBufferDiff > 0.00001) {
        if (nextWordIndex > -1) {
          this.increaseBufferBefore(nextWordIndex, endBufferDiff);
        } else {
          lastWord.buffer_after_ts = change.timeBufferAfter;
        }
      } else if (endBufferDiff < -0.00001) {
        this.increaseBufferAfter(lastWordIndex, -endBufferDiff);
      }
    }

    for (let i = change.index; i < change.index + change.count; i++) {
      if (elements[i].initial_index >= 0) {
        elements[i].state = change.type === 'remove' ? 'removed' : change.type;
      } else {
        elements.splice(i, 1);
        i--;
        change.count--;
      }
    }

    if (startTs - change.timeBufferBefore >= endTs + change.timeBufferAfter) {
      console.warn('Not deleted any text: Incorrect time range', change);
      return;
    }

    if (change.inPlace) {
      // do not shift ebverything after the cut
      return;
    }

    const timeChange =
      endTs - startTs + change.timeBufferAfter + change.timeBufferBefore;

    for (let j = change.index + change.count; j < elements.length; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts -= timeChange;
        element.end_ts -= timeChange;
      }
    }
  }

  private applyRemoveMuteChanges(
    change: TranscriptChange & { type: 'restore_mute' },
  ) {
    const elements = this.finalTranscriptionElements!;
    const elementsCount = change.count || 1;

    if (change.type !== 'restore_mute') return;

    for (let i = 0; i < elementsCount + 1; i++) {
      const index = change.index + i;
      if (elements[index].state === 'muted') {
        delete elements[index].state;
      }
    }
  }

  private applyMuteChange(change: TranscriptChange & { type: 'mute' }) {
    const elements = this.finalTranscriptionElements!;
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      elements[change.index + i].state = 'muted';
    }
  }

  private applyReplaceChange(change: TranscriptChange & { type: 'replace' }) {
    const elements = this.finalTranscriptionElements!;

    const elementsCount = change.count || 1;
    let startTs = null;
    let endTs = null;
    let i = 0;
    while (i < elementsCount) {
      elements[change.index + i].old_value = elements[change.index + i].value;
      elements[change.index + i].state = 'replaced';
      elements[change.index + i].value = '';
      if (startTs === null && elements[change.index + i].ts != null) {
        startTs = elements[change.index + i].ts;
      }
      if (elements[change.index + i].end_ts != null) {
        endTs = elements[change.index + i].end_ts;
      }
      i++;
    }

    let extraSpaceAfter = '';
    let nextElementIndex = getClosestNotRemovedElementIndexToRight(
      change.index + elementsCount,
      elements,
    );
    if (
      !change.newValue?.endsWith(' ') &&
      nextElementIndex > -1 &&
      elements[nextElementIndex].type === 'text'
    ) {
      extraSpaceAfter = ' ';
    }

    let extraSpaceBefore = '';
    let prevElementIndex = getClosestNotRemovedElementIndexToLeft(
      change.index - 1,
      elements,
    );
    if (
      !change.newValue?.startsWith(' ') &&
      prevElementIndex > -1 &&
      elements[prevElementIndex].value !== ' '
    ) {
      extraSpaceBefore = ' ';
    }

    if (startTs && endTs) {
      elements[change.index + elementsCount - 1].ts = startTs;
      elements[change.index + elementsCount - 1].end_ts = endTs;
      elements[change.index + elementsCount - 1].type = 'text';
    }
    elements[change.index + elementsCount - 1].value =
      extraSpaceBefore + change.newValue + extraSpaceAfter;
  }

  private applyShiftChange(change: TranscriptChange & { type: 'shift' }) {
    let elements = this.finalTranscriptionElements!;
    const elementsCount = change.count || 1;

    if (change.newIndex !== change.index) {
      const addedSegment = elements
        .slice(change.index, change.index + change.count)
        .map((el) => ({ ...el, state: 'added' }) as TranscriptElement);

      for (let i = 0; i < change.count; i++) {
        if (elements[change.index + i].state === 'added') {
          delete elements[change.index + i]; // holes in array
        } else {
          elements[change.index + i].state = 'cut';
        }
      }
      elements.splice(change.newIndex, 0, ...addedSegment);
    }

    const timeChange = change.timeShift;
    for (let j = change.newIndex; j < change.newIndex + change.count; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts += timeChange;
        element.end_ts += timeChange;
      }
    }
    this.finalTranscriptionElements = elements.filter(Boolean);
  }

  private applyInsertChange(
    change: TranscriptChange & { type: 'insert_punct' },
  ) {
    let elements = this.finalTranscriptionElements!;
    elements.splice(change.index, 0, {
      type: 'punct',
      value: change.value,
      old_value: null,
      initial_index: -1,
      ts: null,
      end_ts: null,
      state: 'added',
    });
  }

  private applyRestoreChange(change: TranscriptChange & { type: 'restore' }) {
    // debugger;
    const elements = this.finalTranscriptionElements!;
    const originalElements = this.transcriptionElements!;
    // debugger;
    const originalIndex = elements[change.index].initial_index;

    if (originalIndex === -1) {
      console.warn(
        'No original element found for index',
        change.index,
        this.finalTranscriptionElements,
      );
    }

    const firstWordIndex = getClosestNotRemovedTextIndexToRight(
      originalIndex,
      originalElements,
    );
    const lastWordIndex = getClosestNotRemovedTextIndexToLeft(
      originalIndex + change.count - 1,
      originalElements,
    );

    const firstWord = originalElements[firstWordIndex];
    const lastWord = originalElements[lastWordIndex];

    const endTs = lastWord?.end_ts ?? 0;
    const startTs = firstWord?.ts ?? 0;
    const intoTs = change.newTs;
    const diffTs =
      lastWordIndex >= firstWordIndex
        ? startTs - (intoTs + change.timeBufferBefore)
        : 0;

    for (let i = 0; i < change.count; i++) {
      const index = change.index + i;
      const element = elements[index];
      if (element.type === 'text') {
        element.ts =
          originalElements[originalIndex + i].ts! -
          diffTs +
          (element.trim_start || 0);
        element.end_ts =
          originalElements[originalIndex + i].end_ts! -
          diffTs -
          (element.trim_end || 0);
      }
      delete elements[index].state; // now unremoved
    }

    if (lastWordIndex < firstWordIndex) {
      return;
    }

    const shiftTs =
      endTs - startTs + change.timeBufferAfter + change.timeBufferBefore;

    for (let j = change.index + change.count; j < elements.length; j++) {
      const element = elements[j];
      if (element.type === 'text') {
        element.ts += shiftTs;
        element.end_ts += shiftTs;
      }
    }
  }

  private applyPhotoHighlightChange(
    change: TranscriptChange & { type: 'photo_highlight' },
  ) {
    const elements = this.finalTranscriptionElements!;
    const elementsCount = change.count || 1;
    for (let i = 0; i < elementsCount; i++) {
      elements[change.index + i].photo_highlight_id =
        change.newPhotoHighlightId;
    }
    elements[change.index + elementsCount - 1].last_photo_highlight =
      change.newPhotoHighlightId ? true : undefined;
  }

  private async insertVideoSegment(
    fromTs: number,
    toTs: number,
    intoTs: number,
  ): Promise<number> {
    // debugger;
    const source = this.renderer!.getSource();
    // const elements = this.renderer!.getElements();
    const trackNumber = source.elements.find(
      (el: any) => el.type === 'video',
    )!.track;

    let insertTrackIndex = -1;
    let nextTrackIndex = -1;
    let skippedTracks = 0;
    let newTs = -1;

    const newTracks: Record<string, any>[] = [];
    // debugger;
    for (let i = 0; i < source.elements.length; i++) {
      const elementTrack = parseInt(source.elements[i].track);
      const elementTime = parseFloat(source.elements[i].time);
      const elementDuration = parseFloat(source.elements[i].duration);
      const elementTrimStart = parseFloat(source.elements[i].trim_start || '0');
      if (
        elementTrack === trackNumber &&
        elementTime < intoTs - PRECISION_EPS &&
        elementTime + elementDuration >= intoTs - PRECISION_EPS
      ) {
        // insertion place
        insertTrackIndex = i;
        newTs =
          parseFloat(source.elements[insertTrackIndex].time) +
          parseFloat(source.elements[insertTrackIndex].duration);

        if (Math.abs(newTs - intoTs) < PRECISION_EPS) {
          // no extra cut
          newTracks.push(source.elements[i]);
        } else {
          // extra cut
          newTs = intoTs;
          newTracks.push({
            ...source.elements[i],
            duration: intoTs - elementTime,
          });
          newTracks.push({
            ...source.elements[i],
            id: uuid(),
            time: intoTs + toTs - fromTs,
            duration: elementTime + elementDuration - intoTs,
            trim_start: elementTrimStart + intoTs - elementTime,
          });
          nextTrackIndex = i + 1;
        }

        continue;
      }

      if (
        elementTrack === trackNumber &&
        elementTime >= intoTs - PRECISION_EPS
      ) {
        // after insertion place
        // if (
        //   nextTrackIndex === -1 &&
        //   toTs - (elementTrimStart + source.elements[i].duration) >
        //     PRECISION_EPS
        // ) {
        //   skippedTracks++;
        //   continue;
        // }
        newTracks.push({
          ...source.elements[i],
          time: elementTime + toTs - fromTs,
        });
        if (nextTrackIndex === -1) {
          nextTrackIndex = i;
        }
        continue;
      }

      if (
        elementTrack === trackNumber &&
        elementTime + elementDuration < intoTs
      ) {
        // before insertion place
        newTracks.push(source.elements[i]);
        continue;
      }
    }
    // // debugger;

    //TODO REFACTOR
    if (insertTrackIndex >= 0) {
      // INSERT SOMEWHERE IN THE MIDDLE
      const insertTrackTrimStart = parseFloat(
        newTracks[insertTrackIndex].trim_start || '0',
      );
      let tracksToRemove = 0;
      let trackToInsert;

      trackToInsert = {
        ...newTracks[insertTrackIndex],
        id: uuid(),
        time: newTs,
        duration: toTs - fromTs,
        trim_start: fromTs,
      };

      if (
        Math.abs(
          fromTs -
            insertTrackTrimStart -
            parseFloat(newTracks[insertTrackIndex].duration),
        ) < PRECISION_EPS
      ) {
        // join on start
        tracksToRemove = 1;
        trackToInsert = {
          ...newTracks[insertTrackIndex],
          id: uuid(),
          duration: toTs - insertTrackTrimStart, //elements[insertTrackIndex].globalTime,
        };
      }

      if (
        nextTrackIndex >= 0 &&
        Math.abs(
          parseFloat(newTracks[nextTrackIndex].trim_start || '0') - toTs,
        ) < PRECISION_EPS
      ) {
        // join on end
        trackToInsert = {
          ...trackToInsert,
          duration:
            parseFloat(newTracks[nextTrackIndex].duration) +
            trackToInsert.duration,
        };
        newTracks.splice(nextTrackIndex - skippedTracks, 1);
      }

      newTracks.splice(insertTrackIndex, tracksToRemove, trackToInsert);
    } else {
      // INSERT AT THE BEGINNING
      newTs = 0;

      let trackToInsert;
      // // debugger;
      trackToInsert = {
        ...this.originalSource.elements.find((el: any) => el.type === 'video'), // in case all elements are deleted take original elements
        id: uuid(),
        time: newTs,
        duration: toTs - fromTs,
        trim_start: fromTs,
      };

      if (
        nextTrackIndex >= 0 &&
        Math.abs(
          parseFloat(newTracks[nextTrackIndex].trim_start || '0') - toTs,
        ) < PRECISION_EPS
      ) {
        // join on end
        trackToInsert = {
          ...trackToInsert,
          duration:
            parseFloat(newTracks[nextTrackIndex].duration) +
            trackToInsert.duration,
        };
        newTracks.splice(nextTrackIndex - skippedTracks, 1);
      }
      newTracks.splice(0, 0, trackToInsert);
    }

    //@ts-ignore
    newTracks.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));

    // // debugger;
    source.elements = source.elements
      .filter((el: any) => el.track !== trackNumber)
      .concat(newTracks);

    // console.log('new source', source);
    // return;
    await this.renderer!.setSource(source, true);
    return newTs;
  }

  undoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
  }[] = [];
  redoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
  }[] = [];

  resetUndoRedo() {
    this.undoStack = [];
    this.redoStack = [];
  }

  undo() {
    // // debugger;
    if (this.undoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.undoStack.pop()!;
    undoCommand();
    this.applyChanges(this.transcriptionChanges);
    this.redoStack.push({ undoCommand, redoCommand });
  }

  redo() {
    // // debugger;
    if (this.redoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.redoStack.pop()!;
    redoCommand();
    this.applyChanges(this.transcriptionChanges);
    this.undoStack.push({ undoCommand, redoCommand });
  }

  // // Remove last TranscriptionChange into redo and call undo in Renderer
  // undo() {
  //   const lastChange = this.transcriptionChanges.pop();
  //   if (lastChange) {
  //     this.redoChanges.push(lastChange);
  //     this.renderer?.undo();
  //   }
  // }

  // // Remove last redo change and call redo in Renderer
  // redo() {
  //   const lastChange = this.redoChanges.pop();
  //   if (lastChange) {
  //     this.transcriptionChanges.push(lastChange);
  //     this.renderer?.redo();
  //   }
  // }

  reset() {
    //todo doesn't work
    this.transcriptionChanges = [];
    // this.redoChanges = [];
    this.renderer?.setSource(this.originalSource);
  }
}
