import React, { useState } from 'react';
import { get } from 'mobx';
import styled, { css } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';
import AiProducerIcon from '../../svgs/AiProducerIcon';

type Props = {
  isInClipsModal?: boolean
}

export const CreateButton: React.FC<Props> = observer((props) => {
  const { isInClipsModal = false } = props
  const [isQueueing, setIsQueueing] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const renderButtonText = (text?: string) => {
    if (isInClipsModal) {
      return <><AiProducerIcon strokeColor="currentColor" /> {text || 'Render'}</>
    }
    return <div>{text || 'Publish'}</div>
  }

  const renderDropdownArrow = () => {
    return <DropdownIcon isOpen={dropdownOpen} onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setDropdownOpen(!dropdownOpen)
    }}>
      <ArrowIcon />
    </DropdownIcon>
  }

  const statusStyle = {
    background: '#e67e22',
    border: '1px solid #f2d093',
    color: '#f2d093',
  }

  if (get(videoCreator, 'renderingStatus') === 'rendering') {
    return <Main
      isInClipsModal={isInClipsModal}
      style={{ ...statusStyle }}
    >
      {renderButtonText('Publishing...')}
    </Main>;
  }
  if (isQueueing) {
    return <Main
      isInClipsModal={isInClipsModal}
      style={{ ...statusStyle }}>
      {renderButtonText('Queueing...')}
    </Main>;
  }

  const onRenderClick = async (event: any, res: 'high' | 'medium' | 'low' | 'original') => {
    event.stopPropagation();
    event.preventDefault();
    setIsQueueing(true);
    setDropdownOpen(false);
    videoCreator.isSavingOrPublishing = true
    try {
      await videoCreator.finishVideo(res);
    } catch (error) {
      console.error('rendering error', error)
      videoCreator.renderingStatus = 'error';
    } finally {
      setIsQueueing(false);
      videoCreator.isSavingOrPublishing = false
    }
  }

  return (
    <Main
      isInClipsModal={isInClipsModal}
      className={videoCreator.renderingStatus === 'error' ? 'error' : ''}
      onClick={(e) => {
        onRenderClick(e, 'high')
      }}
    >
      {renderButtonText()}
      {!isInClipsModal && renderDropdownArrow()}
      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropdownOpen(false)
            }}
          ></DropdownBackground>
          <Dropdown>
            <DropdownItem onClick={(e) => onRenderClick(e, 'low')}>Low (270p)</DropdownItem>
            <DropdownItem onClick={(e) => onRenderClick(e, 'medium')}>Medium (480p)</DropdownItem>
            <DropdownItem onClick={(e) => onRenderClick(e, 'high')}>High (720p) - default</DropdownItem>
            {videoCreator.story?.originalVideo && (
              <DropdownItem onClick={(e) => onRenderClick(e, 'original')}>
                Original ({Math.min(videoCreator.story.originalVideo.width, videoCreator.story.originalVideo.height)}p)
              </DropdownItem>
            )}
          </Dropdown>
        </>)}
    </Main>
  );
});

const Main = styled.div<{ isInClipsModal: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  
  ${props => !props.isInClipsModal && css`
    border-radius: 4px;
    border: 1px solid #f2d093;
    color: #f2d093;
    font-size: 12px;
    padding: 8px 6px;
    margin: 10px 0 10px 10px;
  `}

  &.error {
    border: 1px solid #e74c3c;
    color: #e74c3c;
  }

  ${props => props.isInClipsModal && css`
  margin: 10px auto;
  width: 50%;
  padding: 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #484848;
  font-weight: bold;
  background-color: #F2D093;
  color: #03041A;
  flex: 1;
  position: relative;
  &:disabled {
    border: 1px solid #484848;
    background-color: #030419;
    color: #484848;
  }
  `}

`;

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 16px;
  height: 18px;
  justify-content: end;
  align-items: center;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) => !props.isOpen && `rotate: 180deg; justify-content: start;`}
`;


const Dropdown = styled.div`
  position: absolute;
  z-index: 1000;
  top: 60px;
  width: 200px;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #030419;
  padding: 8px 0;
  box-sizing: border-box;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const DropdownItem = styled.div`
  color: rgb(243, 233, 215);
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #484848;
    color: #f2d093;
    p {
      color: #f2d093;
      font-weight: 700;
    }
  }

  &:not(:hover):not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    background-color: #484848;
    bottom: -1px;
  }
`;