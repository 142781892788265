import styled from 'styled-components';
import AIClip from './AIClip';
import { useState } from 'react';
import { ClipFragment, saveProducedClips } from '../../services/AIClipProducer';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SpinningLoading from '../SpinningLoading';
import { ProduceIcon } from '../common/icons';
import { Video } from '../../types.ts/story';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import ChatGPTService from '../../services/ChatGPTService';

type Props = {
  hidePlatformName?: boolean;
  sourcePlatform?: 'content-studio' | 'creator-studio';
  fragments: ClipFragment[];
  isProducing: boolean;
  onClose: () => void;
};

const AIClipProducerResults = (props: Props) => {
  const {
    fragments,
    isProducing,
    onClose,
    hidePlatformName,
    sourcePlatform = 'creator-studio',
  } = props;
  const platforms = fragments
    .map((fragment) => fragment.platform)
    .filter((value, index, self) => self.indexOf(value) === index);

  const [clipsSelected, setClipsSelected] = useState<number[]>([]);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [sourceVideo, setSourceVideo] = useState<Video>(
    videoCreator.currentVideo!,
  );

  const saveSelectedClips = async () => {
    if (clipsSelected.length === 0) return;
    setSaving(true);
    try {
      const videoIds = await saveProducedClips(
        fragments.filter((fragment) => clipsSelected.includes(fragment.index)),
        sourceVideo,
        sourcePlatform,
      );
      setClipsSelected([]);
      onClose();
    } catch (e) {
      console.log('Error saving clips', e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Wrapper>
      {isSaving && (
        <SpinningLoading
          Ico={<ProduceIcon width={'24'} height={'24'} />}
          text={'Saving clips'}
        />
      )}
      <Heading>{fragments.length} stories generated</Heading>
      <Title>Select your social clips to save</Title>
      <TopBar hidePlatformName={hidePlatformName}>
        {!hidePlatformName && (
          <Filters>
            <div style={{ opacity: 0.6 }}>Filter by platform</div>
            {platforms.map((platform) => (
              <FilterButton>{platform}</FilterButton>
            ))}
          </Filters>
        )}
        <SaveClipsButton
          hidePlatformName={hidePlatformName}
          disabled={isProducing || clipsSelected.length === 0}
          onClick={saveSelectedClips}
        >
          <span>Save Clips</span>
          <CircleCheckIcon
            strokeColor="currentColor"
            width="18px"
            height="18px"
          />
        </SaveClipsButton>
      </TopBar>
      <Clips>
        {fragments.map((fragment) => (
          <AIClip
            fragment={fragment}
            isSelected={clipsSelected.includes(fragment.index)}
            onSelect={() => {
              if (clipsSelected.includes(fragment.index)) {
                setClipsSelected(
                  clipsSelected.filter(
                    (clipIndex) => clipIndex !== fragment.index,
                  ),
                );
              } else {
                setClipsSelected([...clipsSelected, fragment.index]);
              }
            }}
          />
        ))}
        {isProducing && (
          <LoadingClipCard>
            <SpinningLoading
              Ico={<ProduceIcon width={'24'} height={'24'} />}
              text={''}
            />
          </LoadingClipCard>
        )}
      </Clips>
    </Wrapper>
  );
};

export default AIClipProducerResults;

const Wrapper = styled.div`
  width: 85vw;
  max-width: 942px;
  height: 80vh;
  max-height: 600px;
  background-color: #03041a;
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px;
  border-radius: 16px;
  border: 1px solid #484848;
  color: #45d483;
`;

const LoadingClipCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 286px;
  height: 362px;
  border-radius: 10px;
  border: 1px solid #484848;
  box-sizing: border-box;
`;

const Heading = styled.div`
  width: 100%;
  text-align: center;
  color: #45d483;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 15px */
  text-transform: uppercase;
`;

const Title = styled.div`
  width: 100%;
  color: #f3e9d7;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
`;

const TopBar = styled.div<{ hidePlatformName?: boolean }>`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.hidePlatformName ? 'center' : 'space-between'};
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #f3e9d7;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;

const FilterButton = styled.div`
  padding: 8px;
  color: #484848;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #484848;
  text-transform: capitalize;
  cursor: pointer;
`;

const SaveClipsButton = styled.div.attrs(
  (props: { disabled: boolean; hidePlatformName?: boolean }) => props,
)`
  cursor: pointer;
  display: flex;
  position: relative;
  padding: ${(props) =>
    props.hidePlatformName ? ' 11px 30px 12px 30px' : ' 11px 16px 12px 16px'};
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: ${(props) => props.hidePlatformName && 'row-reverse'};
  border-radius: ${(props) => (props.hidePlatformName ? '8px' : '100px')};
  border: 1px solid #45d483;
  background-color: #45d483;
  color: #03041a;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  ${(props) =>
    props.disabled &&
    `
    background-color: #03041a;
  color: #484848;
  border: 1px solid #484848;
`}
`;

const Clips = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  overflow-y: scroll;
  overscroll-behavior: contain;
  margin-top: 24px;
  padding: 5px 2px;
  scrollbar-width: thin;
`;
