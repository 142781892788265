import React, { useState } from 'react';
import styled from 'styled-components';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/KaraokeProducer';

type Props = {
  title: string;
  Icon: React.ReactNode;
  action: (propertyMap: Partial<KaraokeConfig>) => void;
  propertyName: keyof KaraokeConfig;
  defaultValue: any;
  unit?: string;
  reverse?: boolean;
  currValue?: number;
  min?: number;
  max?: number;
};
const SliderSelect = (props: Props) => {
  let min = props.min ?? 1;
  let max = props.max ?? (props.propertyName === 'font_size' ? 300 : 100);
  let scale = [0, 100];

  const getDefaultSliderValue = () => {
    const value = props.currValue || props.defaultValue;
    if (props.reverse) return scale[1] - Number(value);
    return value;
  };
  const [sliderValue, setSliderValue] = useState<string>(
    getDefaultSliderValue(),
  );

  const handleRange = (value: any) => {
    setSliderValue(value);
    value = Math.max(min, Math.min(max, value));
    if (props.reverse) value = scale[1] - Number(value);
    if (props.unit) value += props.unit;
    props.action({ [props.propertyName]: value });
  };

  return (
    <Main>
      {props.Icon}

      <Slider
        type="range"
        min={min}
        max={max}
        value={Number(sliderValue)}
        onChange={(e) => {
          handleRange(e.target.value);
        }}
      />
    </Main>
  );
};

export default SliderSelect;

const Main = styled.div`
  display: flex;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 12px;
  gap: 5px;
  align-items: center;
  height: 15px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  width: 90px;
  flex: 1;
  height: 1px;
  background: #484848;
  border-radius: 2px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #17c964;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
