type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const LikesIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    strokeColor = 'currentColor',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g clipPath="url(#clip0_5804_3216)">
        <path d="M1.99527 9.97363H6.12033V20.9564H1.99527C1.76446 20.9564 1.5431 20.8648 1.3799 20.7014C1.21669 20.5383 1.125 20.3169 1.125 20.0862V10.8439C1.125 10.6131 1.21669 10.3917 1.3799 10.2285C1.5431 10.0653 1.76446 9.97363 1.99527 9.97363Z" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.12061 9.97289L10.5415 2.90633C10.7129 2.62703 10.9536 2.39686 11.2404 2.23824C11.5271 2.07963 11.85 1.99799 12.1777 2.00126C12.4357 1.99194 12.693 2.03445 12.9343 2.12628C13.1757 2.2181 13.3962 2.35736 13.5828 2.53586C13.7694 2.71435 13.9183 2.92842 14.0207 3.16545C14.1232 3.40247 14.1771 3.65763 14.1793 3.91584V8.98078H21.8202C22.1025 8.98994 22.3797 9.05875 22.6336 9.18271C22.8874 9.30666 23.1122 9.48292 23.293 9.69994C23.4739 9.91696 23.6067 10.1698 23.6829 10.4418C23.759 10.7139 23.7767 10.9989 23.7348 11.2783L22.3423 20.2594C22.283 20.7394 22.0502 21.1812 21.6881 21.5017C21.326 21.8223 20.8592 21.9994 20.3755 22H8.95767C8.41414 22.0021 7.87766 21.877 7.39119 21.6344L6.13801 21.0079" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6.12061 9.97266V20.9555" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5804_3216">
          <rect width="24" height="24" fill="white" transform="translate(0.125)" />
        </clipPath>
      </defs>

    </svg>
  );
};

export default LikesIcon;
