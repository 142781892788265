import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { convertTimeToMMSS } from '../../utility/timeFormat';
import Modal from '../common/Modal';
import CopyContentModal from './CopyContentModal';
import CheckIcon from '../../svgs/CheckIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { Video } from '../../types.ts/story';
import SpinningLoading from '../SpinningLoading';
import { XmlUpload } from '../xmlparser/XmlUpload';

type DropdownOption = {
  id: string;
  title: string;
  duration?: number;
  isFeatured?: boolean;
};

export const VideoSelector: React.FC = observer(() => {
  const currentParentVideo =
    videoCreator.currentVideo?.parentVideo || videoCreator.currentVideo;
  const currentVideoOption = currentParentVideo
    ? ({
      id: currentParentVideo.id,
      title: currentParentVideo.title,
      duration: currentParentVideo.videoFilePrimary?.video?.duration,
      isFeatured: !!currentParentVideo.videoFilePrimary,
    } as DropdownOption)
    : null;

  const storyFinalVideo = videoCreator.story?.finalVideo;

  const originalStoryOption = {
    id: 'original_story',
    title: videoCreator.story?.title,
    duration: videoCreator.story?.originalVideo?.video.duration,
  } as DropdownOption;

  let allVideoOptions: DropdownOption[] = [];
  if (currentVideoOption) {
    allVideoOptions.push(currentVideoOption);
  }
  if (videoCreator.story?.otherVideos?.length) {
    allVideoOptions = allVideoOptions.concat(
      videoCreator.story?.otherVideos
        .filter((video) => video.id !== currentVideoOption?.id)
        .map((video) => ({
          id: video.id!,
          title: video.title,
          duration: video.videoFilePrimary?.video?.duration,
          isFeatured: !!video.videoFilePrimary,
        })),
    );
  }

  allVideoOptions.push(originalStoryOption);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newVideoModal, setNewVideoModal] = useState<{
    type: 'save_as' | 'rename';
    video: DropdownOption | null;
  } | null>(null);
  const [actionProgress, setActionProgress] = useState<string>('');

  const [optionsModal, setOptionsModal] = useState<{
    position: number;
    video: DropdownOption | null;
  } | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(buttonRef, () => {
    setOptionsModal(null);
  });

  async function copyVideo(videoId: string, newTitle: string) {
    if (videoId !== 'original_story') {
      await videoCreator.copyVideo(videoId, newTitle);
    } else {
      await videoCreator.createNewVideoFromSource(newTitle);
    }
  }

  async function deleteVideo(videoId: string) {
    // we shouldnt delete original video
    if (videoId === 'original_story') return;

    const idx = allVideoOptions.findIndex((data) => data.id === videoId);
    let nextVideoIdx = idx + 1;
    if (idx === allVideoOptions.length - 1) {
      nextVideoIdx = idx - 1;
    }
    const nextVideoId = allVideoOptions[nextVideoIdx].id;

    // Load the next video
    await videoCreator.loadVideo(nextVideoId);

    // remove video from list of other videos
    videoCreator.story!.otherVideos = videoCreator.story!.otherVideos.filter(
      (v) => v.id !== videoId,
    );

    await videoCreator.storyRepository?.update(videoCreator.story!);

    setOptionsModal(null);
  }

  async function makeFeatureVideo(videoId: string) {
    try {
      videoCreator.renderingStatus = 'rendering';
      setOptionsModal(null);

      if (videoId === 'original_story') {
        await videoCreator.createNewVideoFromSource();
      } else if (videoId && videoId !== videoCreator.currentVideo?.id) {
        await videoCreator.loadVideo(videoId);
      }

      await videoCreator.saveStoryAndVideo(true);
      await videoCreator.finishVideo('high');
    } catch (error) {
      console.error(error);
      videoCreator.renderingStatus = 'error';
    }
  }

  function selectVideo(videoId: string) {
    // todo confirm saving or discard changes.
    if (videoId === currentVideoOption?.id) {
      return;
    } else if (videoId === 'original_story') {
      videoCreator.createNewVideoFromSource();
    } else {
      videoCreator.loadVideo(videoId);
    }
  }

  function getColor(video: DropdownOption) {
    let videoId = video.id;
    if (!videoId) return '#484848';
    if (videoId === 'original_story' || video.isFeatured) {
      return '#17C964';
    }
    return '#484848';
  }

  function getClipType(video: DropdownOption) {
    let videoId = video.id;
    if (!videoId) return 'Social Clip';

    if (videoId === 'original_story') {
      return 'Original';
    }

    if (videoId === storyFinalVideo?.id) {
      return 'Feature';
    }
    return 'Social Clip';
  }

  const renderDropdownItem = (video: DropdownOption) => (
    <DropdownItem
      key={video.id}
      onClick={() => {
        setDropdownOpen(false);
        selectVideo(video.id);
      }}
    >
      <Title isSelected={currentVideoOption?.id === video.id}>
        <ClipType>
          <Check color={getColor(video)}>
            <CheckIcon fillColor="#03041A" />
          </Check>

          {getClipType(video)}
        </ClipType>
        <p>{video.title}</p>
      </Title>
      <TimeAndEllipsis>
        <Duration>
          {video.duration ? convertTimeToMMSS(video.duration) : '--:--'}
        </Duration>

        <EllipsisButton
          onClick={(e) => {
            const rect = e.currentTarget.getBoundingClientRect();
            // console.log(rect);
            setOptionsModal({
              position: rect.top,
              video,
            });
            e.stopPropagation();
          }}
        >
          <EllipsisIcon fillColor="currentColor" width="10" />
        </EllipsisButton>
      </TimeAndEllipsis>
    </DropdownItem>
  );

  return (
    <Wrapper>
      <Item onClick={() => setDropdownOpen((open) => !open)}>
        <WrapperItemContent>
          <MainTitle>{currentVideoOption?.title}</MainTitle>
          <StorytellerName>
            {videoCreator.story?.storyTeller.name}
          </StorytellerName>
        </WrapperItemContent>
        <DropdownIcon isOpen={dropdownOpen}>
          <ArrowIcon />
        </DropdownIcon>
      </Item>
      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={() => setDropdownOpen(false)}
          ></DropdownBackground>
          <Dropdown>
            {!!actionProgress && <SpinningLoading text={actionProgress} />}
            {allVideoOptions.map((video) => renderDropdownItem(video))}
            <DropdownItem onClick={() => {
              // setDropdownOpen(false);
            }}>
              <XmlUpload onUpload={() => setDropdownOpen(false)} />
            </DropdownItem>
          </Dropdown>
          {optionsModal?.video && (
            <Actions
              ref={buttonRef}
              isVisible={!!optionsModal?.video}
              top={optionsModal?.position}
            >
              <RenameButton
                onClick={(e) => {
                  setNewVideoModal({
                    type: 'rename',
                    video: optionsModal.video,
                  });
                  e.stopPropagation();
                }}
              >
                <span>Rename</span>
              </RenameButton>
              <SaveAsButton
                onClick={(e) => {
                  setNewVideoModal({
                    type: 'save_as',
                    video: optionsModal.video,
                  });
                  e.stopPropagation();
                }}
              >
                <span>Save As</span>
              </SaveAsButton>
              <FeatureStoryButton
                onClick={() => makeFeatureVideo(optionsModal.video?.id!)}
              >
                <span>Make Feature Story</span>
              </FeatureStoryButton>
              <DeleteButton
                onClick={async () => {
                  setActionProgress('Deleting video...');
                  await deleteVideo(optionsModal.video?.id!);
                  setActionProgress('');
                }}
              >
                <span>Delete</span>
              </DeleteButton>
            </Actions>
          )}
        </>
      )}

      {newVideoModal && (
        <Modal
          isOpen={newVideoModal !== null}
          closeModal={() => setNewVideoModal(null)}
        >
          <CopyContentModal
            name={
              (newVideoModal.video?.id === 'original_video' ? 'Copy of ' : '') +
              newVideoModal.video?.title
            }
            tag="Story"
            type={newVideoModal.type}
            onCancel={() => setNewVideoModal(null)}
            onSave={async (storyName) => {
              setNewVideoModal(null);
              if (newVideoModal?.type === 'rename') {
                setActionProgress('Renaming video...');
                await videoCreator.renameVideo(
                  newVideoModal.video?.id!,
                  storyName,
                );
              } else {
                setActionProgress('Copying video data...');
                await copyVideo(newVideoModal.video?.id!, storyName);
              }
              setActionProgress('');

              setDropdownOpen(false);
            }}
          />
        </Modal>
      )}
    </Wrapper>
  );
});

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const WrapperItemContent = styled.div`
  flex: 1;
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 10px;
  height: 6px;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) => !props.isOpen && `rotate: 180deg;`}
`;

const Wrapper = styled.div`
  position: relative;
  min-width: 500px;
  height: fit-content;
`;

const CopyButton = styled.div`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 6px;
  margin-right: -6px;

  &:hover {
    color: #f2d093;
    scale: 1.1;
  }
`;

const EllipsisButton = styled.div`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 6px;
  margin-right: -6px;

  &:hover {
    color: #f2d093;
    scale: 1.1;
  }
`;

const MainTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const StorytellerName = styled.div`
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 400;
`;

const Title = styled.div<{ isSelected: boolean }>`
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
  }
`;

const Duration = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TimeAndEllipsis = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Item = styled.div`
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1000;
  top: 60px;
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #030419;
  padding: 8px 0;
  box-sizing: border-box;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media only screen and (max-height: 950px) {
    max-height: 450px;
  }
  @media only screen and (max-height: 810px) {
    max-height: 400px;
  }
  @media only screen and (max-height: 700px) {
    max-height: 350px;
  }
  @media only screen and (max-height: 600px) {
    max-height: 300px;
  }
  @media only screen and (max-height: 500px) {
    max-height: 250px;
  }
  @media only screen and (max-height: 430px) {
    max-height: 200px;
  }
  @media only screen and (max-height: 344px) {
    max-height: 150px;
  }
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const DropdownItem = styled.div`
  color: #484848;
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #484848;
    color: #f2d093;
    p {
      color: #f2d093;
      font-weight: 700;
    }
  }

  &:not(:hover):not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    background-color: #484848;
    bottom: -1px;
  }
`;

const Check = styled.div<{ color: string }>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: ${(props) => props.color};
`;

const ClipType = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
`;

const Actions = styled.div<{ isVisible: boolean; top: number | undefined }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  top: ${(props) => (props.top ? props.top + 10 : '20')}px;
  right: -80px;
  z-index: 2000;
  flex-direction: column;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
`;

const Action = styled.div`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }
`;

const RenameButton = styled(Action)``;

const SaveAsButton = styled(Action)``;
const FeatureStoryButton = styled(Action)``;

const DeleteButton = styled(Action)`
  &:hover {
    background-color: #ef5d6f;
  }
`;

const ImportSection = styled.div`padding:
`