import React, { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react'
import styled from 'styled-components';

type Props = {
    placeholder?: string;
    hasOutline?: boolean;
    width?: string;
    getValue: (text: string) => void
    handleOnEnter?: () => void;
}
const AutoResizeableMultilineInput = (props: Props) => {
    const [value, setValue] = useState('');
    const { hasOutline = true, width = '500px' } = props

    const handleAdjustInputHeight = (e: FormEvent<HTMLTextAreaElement>) => {
        const textarea = e.target as HTMLTextAreaElement;
        if (textarea.scrollHeight < 50) return
        textarea.style.height = textarea.scrollHeight + 'px';
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && props.handleOnEnter) {
            e.preventDefault();
            props.handleOnEnter()
        }
    };

    return (
        <Description
            hasOutline={hasOutline}
            width={width}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setValue(e.target.value)
                props.getValue(e.target.value)
            }}
            value={value}
            placeholder={props.placeholder}
            onInput={(e: FormEvent<HTMLTextAreaElement>) => handleAdjustInputHeight(e)}
            onKeyDown={handleKeyDown}
        />
    )
}

export default AutoResizeableMultilineInput

const Description = styled.textarea<{ hasOutline: boolean, width: string; }>`
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #484848;
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  resize: none;
  height: 50px;
  @media only screen and (max-width: 1400px) {
    height: 60px;
  }

  @media only screen and (max-width: 900px) {
    height: 70px;
  }
  width: ${props => props.width};
  outline: ${props => !props.hasOutline && 0};
`;