import { Canvas, Button } from 'datocms-react-ui';
import { RenderFieldExtensionCtx, RenderModalCtx } from 'datocms-plugin-sdk';
import { buildClient, LogLevel } from '@datocms/cma-client-browser';
import { Studio } from '../types.ts/general';

type PropTypes = {
  ctx: RenderFieldExtensionCtx;
};

interface CustomWindow extends Window {
  id?: string;
  ctx?: RenderFieldExtensionCtx;
}
export default function FinalVideo({ ctx }: PropTypes) {
  const dClient = buildClient({
    apiToken: ctx.currentUserAccessToken || null,
    environment: ctx.environment,
    logLevel: LogLevel.NONE,
  });

  const handleOpenModal = async () => {
    const upload = await dClient.uploads.find(
      (ctx.item?.attributes?.original_video as Record<any, any>).upload_id,
    );
    try {
      if (ctx.item?.id) {
        // this line will throw error if can't save
        if (ctx.item.attributes.final_video_status !== 'rendering') {
          await dClient.items.update(ctx.item?.id, {
            final_video_status: 'editing',
          });
        }

        (window as unknown as CustomWindow).ctx = ctx;

        const newWindow = window.open(
          `${window.location.href.replace(/\/$/, '')}/${
            Studio.creator
          }?storyId=${ctx.item?.id}&env=${ctx.environment}`,
        ) as CustomWindow | null;

        if (newWindow) {
          //todo remove, doesn't work
          newWindow.ctx = ctx;
        }
        const newest: Record<any, any> = await dClient.items.find(ctx.item?.id);

        // todo update before creating new window (to not conflict with StoryRepository)
        if (newest.final_video_status === 'editing') {
          if (newest.final_video?.upload_id) {
            await dClient.items.update(ctx.item?.id, {
              final_video_status: 'rendered',
            });
          } else {
            await dClient.items.update(ctx.item?.id, {
              final_video_status: 'none',
            });
          }
        }
      }
    } catch (e) {
      // could check e[0].attribues.code === "ITEM_LOCKED" but that isn't exact syntax in typescript
      ctx.alert('Save current story before editing the final video');
    }
  };

  return (
    <Canvas ctx={ctx}>
      <Button type="button" onClick={handleOpenModal}>
        Open Editor
      </Button>
    </Canvas>
  );
}
