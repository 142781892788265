import { Client } from '@datocms/cma-client-browser';
import { SHOWCASE_QUERY } from '../utility/gql';
import { GraphQLClient } from 'graphql-request';
import { Showcase } from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';

export class AlbumRepository {
  private gqlClient: GraphQLClient;
  // private dClient: Client;

  constructor(gqlClient: GraphQLClient) {
    this.gqlClient = gqlClient;
  }
  async findOneBySlug(slug: string): Promise<Showcase | null> {
    const response = (await this.gqlClient.request(SHOWCASE_QUERY, {
      slug,
    })) as { showcase?: Showcase };
    if (!response.showcase) return null;

    return response.showcase;
  }
}
