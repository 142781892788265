import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { createApi } from 'unsplash-js';
import styled, { css } from 'styled-components';
import { PhotoAsset, FileData, PhotoTab, Story } from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { SidebarOption } from '../../types.ts/general';
import CheckIcon from '../../svgs/CheckIcon';
import SearchInput from './SearchInput';
import BackIcon from '../../svgs/BackIcon';
import { Circle } from '../../styles/mainStyle';
import { runInAction } from 'mobx';
import ChatGPTService from '../../services/ChatGPTService';
import { v4 as uuid } from 'uuid';
import { generateImagesWithGPT } from '../../utility/processGPTData';
import SpinningLoading from '../SpinningLoading';
import { getRandomFileName, handleRemoveMedia } from '../../utility/general';
import FileUpload from '../common/FileUpload';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import FileListItem from '../common/FileListItem';
import PhotoIcon from '../../svgs/PhotoIcon';

const unsplashApi = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY as string,
});

type Props = {
  selectedAssetFunction?: (asset: any) => void;
};

export const ArtifactsAndAssets: React.FC<Props> = observer((props) => {
  const artifact = videoCreator?.story?.storyArtifacts
    ?.filter((a) => a.responsiveImage)
    .map((a) => ({
      id: a.id,
      title: a.title,
      src: a.responsiveImage?.srcSet,
      type: 'artifact',
      ...a.customData,
    })) as PhotoAsset[];

  const { tab, resource, lastSelectedStock, lastSelectedAi, selectedId } =
    videoCreator.selectedPhotoAssets || {};
  const [loadingAi, setLoadingAi] = useState(false);
  const [savingAi, setSavingAi] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadButtonActive, setUploadButtonActive] = useState(false);

  useEffect(() => {
    if (resource?.length) return;

    videoCreator.selectedPhotoAssets = {
      tab: PhotoTab.artifact,
      resource: artifact,
      lastSelectedStock,
      lastSelectedAi,
      selectedId,
    };
  }, [videoCreator.story?.storyArtifacts]);

  const [showBack, setShowBack] = useState(false);

  const uploadAiPhoto = async (url: string) => {
    console.log('searchValue', searchValue);
    if (!searchValue) return;
    try {
      setSavingAi(true);
      const fileName = getRandomFileName(searchValue);

      const newPhotoData: FileData & { fileName: string } = {
        type: 'ai',
        url,
        fileName,
        alt: searchValue,
        title: searchValue,
      };

      const newUpload =
        await videoCreator.assetRepository?.uploadFile(newPhotoData);
      return { url: newUpload?.url, id: newUpload?.id, fileName };
    } catch (error) {
    } finally {
      setSavingAi(false);
    }
  };
  const handleSelectResource = async (resource: PhotoAsset) => {
    if (props.selectedAssetFunction) {
      props.selectedAssetFunction(resource);
    } else {
      // add to timeline if no custom function provided
      let source = resource?.src;
      let fileName = resource?.slug;
      let title = resource?.title;
      let alt = resource?.alt;
      let id = resource?.id;

      runInAction(() => {
        videoCreator.selectedPhotoAssets = {
          ...videoCreator.selectedPhotoAssets,
          selectedId: resource?.id,
        };
      });

      if (resource.type === 'ai') {
        const data = await uploadAiPhoto(source);
        if (!data?.fileName) return;
        source = data.url || '';
        fileName = data.fileName;
        title = searchValue;
        alt = searchValue;
        id = data.id || resource.id;
      }
      await videoCreator.createElement({
        type: 'image',
        source,
        duration: '8 s',
        autoplay: true,
      });

      runInAction(() => {
        if (resource.type === 'stock' || resource.type === 'ai') {
          videoCreator.photoDataForDato = {
            ...videoCreator.photoDataForDato,
            [id]: {
              type: resource.type,
              url: source,
              fileName: fileName!,
              title,
              alt,
            },
          };
        }
      });
      videoCreator.sidebarOptions = SidebarOption.editing;
    }
  };

  const handleSelectTab = async (tab: PhotoTab) => {
    let resource: PhotoAsset[] | undefined = undefined;
    switch (tab) {
      case PhotoTab.artifact:
        resource = artifact;
        break;
      case PhotoTab.stock:
        resource = lastSelectedStock?.length ? lastSelectedStock : undefined;
        break;
      case PhotoTab.ai:
        resource = lastSelectedAi?.length ? lastSelectedAi : undefined;
        break;
    }
    setShowBack(false);
    videoCreator.selectedPhotoAssets = {
      tab,
      resource,
      lastSelectedStock,
      lastSelectedAi,
      selectedId,
    };
  };

  const handleSearchAssets = async (textValue: string) => {
    const { response } = await unsplashApi.search.getPhotos({
      query: textValue,
      orientation: 'landscape',
    });
    let resource = (response?.results.map((a) => ({
      id: a.id,
      title: a.description,
      description: a.description,
      src: a.urls?.regular,
      people: a.user.name,
      type: 'stock',
      alt: a.alt_description,
      slug:
        (a.alt_description || a.description)
          ?.toLowerCase()
          ?.split(' ')
          .join('_') + `_${a.id}`,
    })) || []) as PhotoAsset[];
    videoCreator.selectedPhotoAssets = {
      tab: PhotoTab.stock,
      resource,
      lastSelectedStock: resource,
      lastSelectedAi,
      selectedId,
    };
    setShowBack(true);
  };

  const handleSearchAi = async (textValue: string) => {
    if (!textValue.trim()) return;
    try {
      setLoadingAi(true);
      setSearchValue(textValue);
      const gpt_service = new ChatGPTService();
      const arborStylePrompt =
        await gpt_service.fetchAiPrompts('Arbor Photo Style');

      let prompt = `${textValue} ${arborStylePrompt?.description || ''}`;

      const result = await generateImagesWithGPT(prompt, 2);
      let resource = result?.map((a) => ({
        id: uuid(),
        title: '',
        description: a.alt,
        src: a.url,
        type: 'ai',
      })) as PhotoAsset[];

      videoCreator.selectedPhotoAssets = {
        tab: PhotoTab.ai,
        resource,
        lastSelectedStock,
        lastSelectedAi: resource,
        selectedId,
      };
      setShowBack(true);
    } catch (error) {
    } finally {
      setLoadingAi(false);
    }
  };

  let stockMessageContent = null;

  if (tab === PhotoTab.stock) {
    if (!resource) {
      stockMessageContent = (
        <NoResource>
          Search the stock library to add photos to the story
        </NoResource>
      );
    } else if (!resource.length) {
      stockMessageContent = (
        <NoResource>You have no matching assets</NoResource>
      );
    }
  }

  return (
    <Main>
      <Tabs>
        {Object.entries(PhotoTab).map(([k, v]) => (
          <Tab
            key={k}
            isSelected={tab === v}
            onClick={() => handleSelectTab(v)}
          >
            {v}
          </Tab>
        ))}
      </Tabs>
      {showBack && (
        <Back
          onClick={() => {
            setShowBack(false);
            videoCreator.selectedPhotoAssets = {
              tab: PhotoTab.artifact,
              resource: artifact,
              lastSelectedStock,
            };
          }}
        >
          <BackIcon />
        </Back>
      )}
      {tab === PhotoTab.stock ? (
        <SearchInput
          placeholder="Search photo"
          handleAction={handleSearchAssets}
        />
      ) : tab === PhotoTab.ai ? (
        <SearchInput
          placeholder="Describe the photo you want to see and hit enter"
          handleAction={handleSearchAi}
          expandHeight
          hideIcons
          radius="15px"
        />
      ) : tab === PhotoTab.artifact ? (
        <FileUpload
          width="100%"
          Button={
            <AddFileButton isActivated={uploadButtonActive}>
              <PhotoIcon strokeColor="#03041A" /> Upload Photo
            </AddFileButton>
          }
          showToggle={false}
          onClick={() => {
            setUploadButtonActive(true);
            setTimeout(() => setUploadButtonActive(false), 3000);
          }}
          setIsLoading={setIsFileUploading}
        />
      ) : null}

      {(loadingAi || savingAi || isFileUploading) && (
        <SpinningLoading
          positionTop="20px"
          text={
            loadingAi
              ? 'Generating Ai photos'
              : savingAi
                ? 'Saving AI photo'
                : isFileUploading
                  ? 'Saving new artifacts'
                  : null
          }
        />
      )}

      {tab === PhotoTab.artifact ? (
        <PhotoContent>
          {resource?.map((r) => (
            <FileListItem
              key={r.id}
              item={{
                id: r.id,
                description: r.description || r.title,
                src: r.src!,
              }}
              handleClick={() => handleSelectResource(r)}
              isSelected={selectedId === r.id}
              handleRemove={() => handleRemoveMedia(r.id, tab)}
            />
          ))}
        </PhotoContent>
      ) : (
        <Container>
          <>
            {stockMessageContent}
            {resource?.map((r) => (
              <ResourceElement
                key={r.id}
                onClick={() => handleSelectResource(r)}
              >
                <Circle isSelected={selectedId === r.id}>
                  {selectedId === r.id && <CheckIcon />}
                </Circle>
                <ResourceImage loading="lazy" srcSet={r.src} />
              </ResourceElement>
            ))}
          </>
        </Container>
      )}
    </Main>
  );
});
export default ArtifactsAndAssets;
const Main = styled.div`
  margin-top: 5px;
`;
const Back = styled.button`
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: #030419;
  width: 0;
  margin-bottom: 10px;
`;

const AddFileButton = styled.button<{ isActivated?: boolean }>`
  outline: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  background-color: #17c964;
  justify-content: center;
  font-weight: 700;
  flex: 1;
  cursor: pointer;
  opacity: ${(props) => (props.isActivated ? '1' : '0.9')};
`;

const PhotoContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  column-gap: 20px;
`;
const ResourceElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100px;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  border: 1px solid #484848;
`;
const ResourceImage = styled.img`
  margin: auto;
  min-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  object-position: top;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.1);
`;
const NoResource = styled.div`
  margin-top: 10px;
  grid-column: 1/3;
  text-align: center;
`;
const Tab = styled.div<{ isSelected?: boolean }>`
  color: ${(props) => (props.isSelected ? '#F2D093' : '')};
  text-decoration: ${(props) => (props.isSelected ? 'underline' : '')};
  cursor: pointer;
  font-size: 14px;
  padding: 10px 10px 10px 0;
`;
const Tabs = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 20px;
  margin-bottom: 5px;
`;
