import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/KaraokeProducer';

type Props = {
  defaultValue?: any;
  reverse?: boolean;
  currValue?: number;
  min?: number;
  max?: number;
  ballColor?: string;
  getValue: (value: number) => void;
};
const SliderSelect = (props: Props) => {
  const {min = 1, max = 100, defaultValue = 1, ballColor = '#F2D093'} = props

  const getDefaultSliderValue = () => {
    const value = props.currValue || defaultValue;
    if (props.reverse) return max - Number(value);
    return value;
  };
  const [sliderValue, setSliderValue] = useState<number>(
    getDefaultSliderValue(),
  );

  useEffect(() => {
    if(props.currValue) setSliderValue(getDefaultSliderValue())
  }, [props.currValue])

  const handleRange = (value: any) => {
    setSliderValue(value);
    value = Math.max(min, Math.min(max, value));
    if (props.reverse) value = max - Number(value);
    props.getValue(value)
  };

  return (
    <Main>
      <Slider
        type="range"
        min={min}
        max={max}
        value={Number(sliderValue)}
        ballColor={ballColor}
        onChange={(e) => {
          handleRange(e.target.value);
        }}
      />
    </Main>
  );
};

export default SliderSelect;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = styled.input<{ballColor: string}>`
  -webkit-appearance: none;
  width: 60px;
  flex: 1;
  height: 1px;
  background: #484848;
  border-radius: 2px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${props => props.ballColor};
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: ${props => props.ballColor};
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
