import { SupportedGeneratedContentTypes } from './story';

export enum Studio {
  dashboard = 'story-dashboard',
  creator = 'creator',
  content = 'content',
}

export enum ContentTab {
  video_clip = 'Video Clips',
  pull_quotes = 'Shareable Images',
  blog = 'Blog',
  email = 'Email',
  talking_point_studio = 'Talking Points',
  media = 'Media',
}

export enum SidebarOption {
  aiProducer = 'ai_producer',
  photo = 'photo',
  music = 'music',
  text = 'text',
  video = 'video',
  editing = 'editing',
  history = 'history',
}

export enum AIProducerCard {
  story = 'story',
  clip = 'clip',
  music = 'music',
  photo = 'photo',
  sound_effect = 'sound_effect',
  karoke_text = 'karoke_text',
  retention_edit = 'retention_edit',
}

export type PunchListImageGeneration = {
  id: string;
  prompt: string;
  title: string;
  stock_keywords: string;
  dalleImages: Record<'uploadId' | 'uploadUrl', string>[];
};

export type PunchListItemReplacement = {
  url: string;
  alt: string;
  id: string;
};

export type CustomGeneratedTypes = Extract<
  SupportedGeneratedContentTypes,
  'Blog' | 'Quotes' | 'Email'
>;

export type TranscriptContextMenuActions =
  | 'replace'
  | 'hideKaraoke'
  | 'showKaraoke'
  | 'removeText'
  | 'removePhoto'
  | 'addPhoto'
  | 'removeTextLoading'
  | 'removePhotoLoading'
  | 'addPhotoLoading'
  | 'restore'
  | 'cutText'
  | 'pasteTextBefore'
  | 'pasteTextAfter';

export type Img = {
  url: string;
  alt: string;
  id: string;
};
export type ImageKey = 'ai' | 'stock' | 'artifact' | 'quotes';

export type ImageWithType = {
  [key in ImageKey]: Img & {
    type: key;
    width: string;
    height: string;
    fileName?: string;
    alt?: string;
    description?: string;
  } & (key extends 'ai' ? { id: string } : {});
};

export enum MediaSubType {
  all = 'All Media',
  artifact = 'Artifacts',
  video = 'Videos',
  stock = 'Stock',
  ai = 'AI Generated',
}

export type TalkingPointContent = Record<
  'summary' | 'narrative' | 'impact' | 'quote' | 'takeaway' | 'donation',
  Record<'prompt' | 'content', string>
>;

export type BlogOrEmailContent = {
  id: number;
  title: string;
  content: string;
  username: string;
};
