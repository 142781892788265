/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { TextInput } from './TextInput';
import styled, { css } from 'styled-components';
import BoldIcon from '../../svgs/BoldIcon';
import ItalicIcon from '../../svgs/ItalicIcon';
import CapitalizeIcon from '../../svgs/CapitalizationIcon';
import LeftAlignIcon from '../../svgs/LeftAlignIcon';
import MiddleAlignIcon from '../../svgs/MiddleAlignIcon';
import RightAlignIcon from '../../svgs/RightAlignIcon';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SliderSelect from './SliderSelect';
import UpDownIcon from '../../svgs/UpDownIcon';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import {
  AnimationSettingsUI,
  InstagramAnimationLines,
} from './AnimationSettingsUI';
import lodash from 'lodash';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import {
  DEFAULT_KARAOKE_CONFIG,
  KaraokeConfig,
  MAX_CHARS_INSTAGRAM,
} from '../../videoTranscriptionProcessor/KaraokeProducer';
import StrokeSelect from '../common/StrokeSelect';
import KaraokeBackground from '../common/KaraokeBackground';
import { CommaIcon, PeriodIcon } from '../common/icons';


interface TextSettingsProps {
  activeElement?: ElementState;
  skipFields: string[];
  selectedBold: Bold | null;
  selectedActions: (Action | Readability)[];
  selectedFontFamily: string;
  selectedFontSize: string | number;
  selectedFillColor: string;
  selectedBackgroundColor: string | null;
  modifyProperty: (propertyMap: Partial<KaraokeConfig>) => void;
  handleToggleBold: (bold: Bold) => void;
  handleSanitizePunctuation: (action: Readability) => void;
  handleToggleAction: (
    action: Action,
    propertyName: keyof KaraokeConfig,
    normalState: string,
    requiredState: string,
  ) => void;
  alignType: Align;
  toggleAlign: (align: Align) => void;
  setAnimation: (
    time: string | number | null,
    type: any,
    subType?: any,
    speed?: any,
    duration?: number | null,
    background_effect?: string | null,
  ) => void;
  animations: {
    enter?: string;
    exit?: string;
    element?: string;
  };
  karaokeElement: KaraokeConfig;
}

const FONT_SIZE_VALUES = [
  16, 18, 20, 24, 30, 36, 48, 60, 72, 96, 120, 144, 192, 240, 300,
];

const FONTS = [
  'Aileron',
  'Open Sans',
  'Dosis',
  'Inter',
  'Roboto',
  'Montserrat',
  'Lato',
  'Poppins',
  'Roboto Condensed',
  'Raleway',
  'Nunito',
  'Ubuntu',
  'Blinker',
  'Urbanist',
  'Work Sans',
];

export enum Action {
  bold = 'Bold',
  italic = 'Italic',
  capitalize = 'Capitalize',
  align = 'Align',
  bgColor = 'Background Color',
}

export enum Readability {
  comma = "Comma",
  period = "Period",
  filler = "Filler"
}

export enum Align {
  leftAlign = 'Left Align',
  midAlign = 'Middle Align',
  rightAlign = 'Right Align',
}

export enum Bold {
  normal = 'Normal',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
}

export const BoldValues = {
  [Bold.normal]: 400,
  [Bold.semiBold]: 500,
  [Bold.bold]: 600,
  [Bold.extraBold]: 700,
};

export const AlignValues = {
  [Align.leftAlign]: '0%',
  [Align.midAlign]: '50%',
  [Align.rightAlign]: '100%',
};

enum Colors {
  white = 'rgba(255, 255, 255, 1)',
  black = 'rgba(0, 0, 0, 1)',
  red = 'rgba(255, 0, 0, 1)',
  green = 'rgba(0, 255, 0, 1)',
  orange = 'rgba(255, 165, 0, 1)',
  blue = 'rgba(0, 0, 255, 1)',
  yellow = 'rgba(255, 255, 0, 1)',
}

export const TextSettingsUI: React.FC<TextSettingsProps> = (props) => {
  const {
    skipFields,
    selectedBold,
    selectedActions,
    selectedFontFamily,
    selectedFontSize,
    selectedFillColor,
    selectedBackgroundColor,
    modifyProperty,
    handleToggleBold,
    handleToggleAction,
    handleSanitizePunctuation,
    alignType,
    toggleAlign,
    setAnimation,
    animations,
    karaokeElement,
  } = props;

  const modifyPropertyDebounced = lodash.debounce(modifyProperty, 500);

  const [openBold, toggleBold] = useState<boolean>(false);
  const [lines, setLines] = useState<keyof typeof InstagramAnimationLines>(
    karaokeElement.instagramLines || 5,
  );

  const boldRef = useRef<HTMLButtonElement>(null);
  useOutsideAlerter(boldRef, () => {
    toggleBold(false);
  });

  const modifyLines = (lines: keyof typeof InstagramAnimationLines) => {
    setLines(lines);
    modifyProperty({ instagramLines: lines });
  };

  const getActionColor = (action: Action) => {
    if (selectedActions.includes(action)) return '#03041A';
    return '#484848';
  };

  const getAlignColor = (align: Align) => {
    if (alignType === align) return '#03041A';
    return '#484848';
  };

  const transcriptionLanguage = videoCreator.originalTranscription?.language;
  const selectedLanguage = karaokeElement.language || DEFAULT_KARAOKE_CONFIG.language!;

  const selectedBackgroundOpacity = parseFloat(
    !selectedBackgroundColor || selectedBackgroundColor === 'transparent'
      ? '0'
      : selectedBackgroundColor.split(',')[3]?.split(')')[0]?.trim() ?? '1',
  );

  const strokeWidth = parseFloat(
    karaokeElement.stroke_width ?? DEFAULT_KARAOKE_CONFIG.stroke_width,
  );
  const strokeColor =
    karaokeElement.stroke_color || DEFAULT_KARAOKE_CONFIG.stroke_color;

  const minMaxXPosition = (() => {
    let min = 0;
    let max = 100;
    let width = videoCreator.renderer?.getSource()?.width || 1280;
    const pxPerChar = 15 / 20; // 14px per char for 20px font size
    if (karaokeElement.instagramEffect) {
      const largeFontSize = 1.25 * Number(selectedFontSize);
      const dx =
        100 - ((MAX_CHARS_INSTAGRAM * largeFontSize * pxPerChar) / width) * 100;
      if (alignType === Align.midAlign) {
        min = 47 - 0.5 * dx;
        max = 51 + 0.5 * dx;
      } else if (alignType === Align.leftAlign) {
        min = 45;
        max = 50 + dx;
      } else if (alignType === Align.rightAlign) {
        min = 50 - dx;
        max = 56;
      }
    } else {
      min = 40;
      max = 60;
    }
    return [min, max];
  })();

  const minMaxYPosition = (() => {
    let min = 0;
    let max = 100;
    let height = videoCreator.renderer?.getSource()?.height || 720;
    if (karaokeElement.instagramEffect) {
      const smallFontSize = 1.1 * 0.8 * Number(selectedFontSize); // 1.25 - line height multiplier, 0.8 - multiplier for small line
      const largeFontSize = 1.1 * 1.25 * Number(selectedFontSize); // 1.25 - line height multiplier, 1.25 - multiplier for large line
      min = (largeFontSize / 2 / height) * 100;
      const largeLines = Math.floor((2 * lines) / 5);
      const smallLines = lines - largeLines;
      max =
        102 -
        ((largeLines * largeFontSize + smallLines * smallFontSize) / height) *
        100;
    } else {
      min = (Number(selectedFontSize) / height) * 100;
      max = 100 - ((2 * Number(selectedFontSize)) / height) * 100;
    }
    return [min, max];
  })();

  const renderAction = (action: Action | Align) => {
    switch (action) {
      case Action.bold:
        const isSelected = selectedBold !== Bold.normal && !!selectedBold;
        return (
          <BoldAction
            ref={boldRef}
            isSelected={isSelected || !!openBold}
            onClick={() => {
              toggleBold(!openBold);
            }}
          >
            <BoldIcon fillColor={getActionColor(Action.bold)} />
            {openBold && (
              <Dropdown>
                {Object.values(Bold).map((bold) => (
                  <DropdownItem
                    isSelected={selectedBold === bold}
                    boldValue={BoldValues[bold]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleBold(bold);
                      toggleBold(false);
                    }}
                  >
                    {bold}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </BoldAction>
        );
      case Action.italic:
        return (
          <ActionButton
            isSelected={selectedActions.includes(Action.italic)}
            onClick={() =>
              handleToggleAction(
                Action.italic,
                'font_style',
                'normal',
                'italic',
              )
            }
          >
            <ItalicIcon fillColor={getActionColor(Action.italic)} />
          </ActionButton>
        );

      case Action.capitalize:
        return (
          <ActionButton
            isSelected={selectedActions.includes(Action.capitalize)}
            onClick={() =>
              handleToggleAction(
                Action.capitalize,
                'text_transform',
                'none',
                'uppercase',
              )
            }
          >
            <CapitalizeIcon fillColor={getActionColor(Action.capitalize)} />
          </ActionButton>
        );

      case Action.align:
        return renderAlignOptions();


      default:
        return null;
    }
  };

  const renderAlignOptions = () => {
    return (
      <>
        {(Object.keys(Align) as Array<keyof typeof Align>).map((alignKey) => (
          <ActionButton
            isSelected={alignType === Align[alignKey]}
            onClick={() => {
              toggleAlign(Align[alignKey]);
            }}
          >
            {
              {
                [Align.leftAlign]: (
                  <LeftAlignIcon fillColor={getAlignColor(Align[alignKey])} />
                ),
                [Align.midAlign]: (
                  <MiddleAlignIcon
                    strokeColor={getAlignColor(Align[alignKey])}
                  />
                ),
                [Align.rightAlign]: (
                  <RightAlignIcon fillColor={getAlignColor(Align[alignKey])} />
                ),
              }[Align[alignKey]]
            }
          </ActionButton>
        ))}
      </>
    );
  };
  return (
    <Main>
      {!skipFields.includes('text') && props.activeElement && (
        <TextInput activeElement={props.activeElement} />
      )}

      <SliderGroup>
        <Section>
          <SectionCaption>Animation</SectionCaption>
          <AnimationSettingsUI
            types={['enter']}
            elementType="text"
            defaultAnimations={{}}
            setAnimation={setAnimation}
            animations={animations}
            setLines={modifyLines}
            lines={lines}
          />
        </Section>
        <Section>
          <SectionCaption>Position</SectionCaption>
          <Position>
            <SliderSelect
              title="Position"
              Icon={<UpDownIcon />}
              action={modifyPropertyDebounced}
              defaultValue="80"
              unit="%"
              currValue={parseInt(karaokeElement.y)}
              propertyName="y"
              reverse={true}
              min={100 - minMaxYPosition[1]}
              max={100 - minMaxYPosition[0]}
            />
            <SliderSelect
              title="Position"
              Icon={
                <div style={{ rotate: '90deg', paddingTop: '5px' }}>
                  <UpDownIcon />
                </div>
              }
              action={modifyPropertyDebounced}
              defaultValue="50"
              unit="%"
              currValue={parseInt(karaokeElement.x || '50')}
              propertyName="x"
              min={minMaxXPosition[0]}
              max={minMaxXPosition[1]}
            />
          </Position>
        </Section>
      </SliderGroup>

      {!transcriptionLanguage?.includes('en') &&
        <Section>
          <SectionCaption>Subtitles</SectionCaption>
          <div style={{ width: '177px', marginBottom: '8px' }}>
            <ItemDropdownSelect
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="language"
              defaultValue={selectedLanguage.includes('en') ? 'english' : 'original'}
              values={['Original', 'English'].map((value) => ({ caption: value, value: value.toLowerCase() }))}
            />
          </div>
        </Section>
      }

      <Section>
        <SectionCaption>Font</SectionCaption>
        <FontColorGroup>
          <FontContainer>
            <ItemDropdownSelect
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="font_family"
              defaultValue={selectedFontFamily}
              values={FONTS.map((value) => ({ caption: value, value }))}
              styleFont
            />
          </FontContainer>

          <FontSize>
            <ItemDropdownSelect
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="font_size"
              defaultValue={selectedFontSize.toString()}
              values={FONT_SIZE_VALUES.map((value) => ({
                caption: `${value}`,
                value: `${value}`,
              }))}
            />
          </FontSize>

          <ItemDropdownSelect
            action={(propertyName, value) =>
              modifyProperty({ [propertyName]: value })
            }
            propertyName="fill_color"
            defaultValue={selectedFillColor}
            width="50px"
            values={[]}
            showColor
          />
        </FontColorGroup>
      </Section>

      <StrokeSegment>
        <Section>
          <SectionCaption>Highlight</SectionCaption>
          <Hightlight>
            <KaraokeBackground
              action={modifyProperty}
              defaultValue={selectedBackgroundColor}
              opacity={selectedBackgroundOpacity}
            />
          </Hightlight>
        </Section>

        <Section>
          <SectionCaption>Stroke</SectionCaption>
          <Stroke>
            <ItemDropdownSelect
              action={(propertyName, value) => {
                const propChange = {
                  [propertyName]: value,
                };
                if (value === 'transparent') {
                  propChange.stroke_width = '0';
                } else if (karaokeElement.stroke_color === 'transparent') {
                  propChange.stroke_width = DEFAULT_KARAOKE_CONFIG.stroke_width;
                }
                modifyProperty(propChange);
              }}
              propertyName="stroke_color"
              defaultValue={strokeColor}
              width="50px"
              values={[
                { caption: 'transparent', value: 'transparent' },
                { caption: Colors.black, value: Colors.black },
                { caption: Colors.white, value: Colors.white },
                { caption: Colors.red, value: Colors.red },
                { caption: Colors.green, value: Colors.green },
                { caption: Colors.orange, value: Colors.orange },
                { caption: Colors.blue, value: Colors.blue },
                { caption: Colors.yellow, value: Colors.yellow },
              ]}
              showBorderColor
            />
            <StrokeSelect
              action={(propertyName, value) =>
                modifyProperty({ [propertyName]: value })
              }
              propertyName="stroke_width"
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
            />
          </Stroke>
        </Section>
      </StrokeSegment>

      <Section>
        <SectionCaption>Text Formatting</SectionCaption>
        <ActionGroup>
          {Object.values(Action).map((action) => (
            <>{renderAction(action)}</>
          ))}
        </ActionGroup>
      </Section>

      <Section>
        <SectionCaption>Readability</SectionCaption>
        <ActionGroup>
          {Object.values(Readability).map((action) => (
            <ActionButton
              key={action}
              isSelected={selectedActions.includes(action)}
              onClick={() =>
                handleSanitizePunctuation(action)
              }
            >
              {(() => {
                switch (action) {
                  case Readability.comma:
                    return <CommaIcon fillColor={"currentColor"} />
                  case Readability.period:
                    return <PeriodIcon fillColor={"currentColor"} />
                  case Readability.filler:
                    return <span className='filler'>Filler</span>
                }
              })()}
            </ActionButton>
          ))}
        </ActionGroup>
      </Section>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Position = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const FontSize = styled.div`
  // flex: 1;
`;

const ActionButton = styled.button<{ isSelected: boolean }>`
  outline: 0;
  border: 1px solid ${(props) => (props.isSelected ? '#F2D093' : '#484848')};
  // width: 32px;
  width: 12%;
  aspect-ratio: 1/1;
  // height: 32px;
  background-color: ${(props) => (props.isSelected ? '#F2D093' : '#03041A')};
  border-radius: 4px;
  color: ${(props) => (props.isSelected ? '#03041A' : '#484848')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span.filler{
    font-weight: 400;
    font-size: 11px;
    line-height: 9.68px;
    color: ${(props) => (props.isSelected ? '#03041A' : 'white')};
  }
`;

const BoldAction = styled(ActionButton)`
  position: relative;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 5px;
  // justify-content: space-between;
  align-items: center;
`;

const SliderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding-bottom: 8px;
`;

const FontColorGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const FontContainer = styled.div`
  flex: 1;
`;

const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  // padding: 0 10px;
  text-align: left;
  width: 100px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0;
  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{ isSelected: boolean; boldValue: number }>`
  padding: 12px;
  background-color: ${(props) => props.isSelected && '#f2d093'};
  &:not(:last-child) {
    border-bottom: 1px solid #484848;
  }
  font-weight: ${(props) => props.isSelected && props.boldValue};
  color: ${(props) => (props.isSelected ? '#030419' : '#f3e9d7')};
  &:hover {
    color: ${(props) => (props.isSelected ? '#030419' : '#f2d093')};
    font-weight: ${(props) => props.boldValue};
  }
`;

const StrokeSegment = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 25px;
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const HighlightButton = styled(ActionButton)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 40px;
  height: 40px;
`;

const HighlightStroke = styled.span`
  background-color: white;
  width: 18px;
  height: 5px;
`;

const Hightlight = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const Stroke = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StrokeDeg = styled.div`
  padding: 0px;
  width: 30px;
`;

const SectionCaption = styled.span`
  color: #484848;
  font-size: 10px;
  font-weight: 500;
`;
