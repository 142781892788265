import React from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { TimelineElement } from './TimelineElement';
import { Story } from '../../types.ts/story';

interface TimelineTrackProps {
  elements: ElementState[];
  story?: Story;
  type?: string;
  isActive: boolean;
  isOriginalVideo?: ConstrainBoolean
}

export const TimelineTrack: React.FC<TimelineTrackProps> = (props) => {

  return (
    <Main
      linkedVideo={
        props.elements[0].source.type === 'video' &&
        props.type !== 'linked-audio'
      }
      isOriginalVideo={!!props.isOriginalVideo}
      linkedAudio={
        props.elements[0].source.type === 'video' &&
        props.type === 'linked-audio'
      }
      isText={
        props.elements[0].source.type === 'text' ||
        props.elements[0].source.type === 'composition'
      }
      isActive={props.isActive}
    >
      {props.elements.map((element, index) => (
        <TimelineElement
          key={`element-${index}-${element.source.id}`}
          type={props.type}
          element={element}
          allElements={props.elements}
          story={props.story}
        />
      ))}
    </Main>
  );
};

const Main = styled.div<{
  linkedVideo: boolean;
  linkedAudio: boolean;
  isText: boolean;
  isActive: boolean;
  isOriginalVideo: boolean
}>`
  position: relative;
  height: ${(props) => {
    if (props.isOriginalVideo) return '59px';
    if (props.linkedAudio) return '40px';
    if (props.isText) return '38px';
    return '78px';
  }};
  ${(props) => (props.isActive ? 'background-color: #484848B8;' : 'filter: brightness(70%);')}
  padding-left: 14px;
  padding-top: ${(props) => props.linkedAudio ? '0px' : '4px'};
  box-sizing: border-box;
`;
