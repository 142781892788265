/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useRef, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { TextInput } from './TextInput';
import { AnimationSettings } from './AnimationSettings';
import styled, { css } from 'styled-components';
import BoldIcon from '../../svgs/BoldIcon';
import ItalicIcon from '../../svgs/ItalicIcon';
import CapitalizeIcon from '../../svgs/CapitalizationIcon';
import LeftAlignIcon from '../../svgs/LeftAlignIcon';
import MiddleAlignIcon from '../../svgs/MiddleAlignIcon';
import RightAlignIcon from '../../svgs/RightAlignIcon';
import { videoCreator } from '../../stores/VideoCreatorStore';
import SliderSelect from './SliderSelect';
import UpDownIcon from '../../svgs/UpDownIcon';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import BackgroundColorDropdownSelect from '../common/BackgroundColorDropdownSelect';
import lodash from 'lodash';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/KaraokeProducer';
import KaraokeBackground from '../common/KaraokeBackground';

interface TextSettingsProps {
  activeElement: ElementState;
}

enum Action {
  bold = 'Bold',
  italic = 'Italic',
  capitalize = 'Capitalize',
  align = 'Align',
  // bgColor = 'Background Color',
}

enum Align {
  leftAlign = 'Left Align',
  midAlign = 'Middle Align',
  rightAlign = 'Right Align',
}

enum Bold {
  normal = 'Normal',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
}

const BoldValues = {
  [Bold.normal]: 400,
  [Bold.semiBold]: 500,
  [Bold.bold]: 600,
  [Bold.extraBold]: 700,
};

enum Colors {
  black = 'black',
  white = 'white',
  red = 'red',
  green = 'green',
  orange = 'orange',
  blue = 'blue',
  yellow = 'yellow',
}

export const TextSettings: React.FC<TextSettingsProps> = (props) => {
  const [selectedActions, setSelectedActions] = useState<Action[]>([
    Action.align,
  ]);
  const [alignType, setAlignType] = useState<Align>(Align.leftAlign);
  const [selectedBold, setSelectedBold] = useState<Bold | null>(null);
  const [openBold, toggleBold] = useState<boolean>(false);
  const selectFillColor = props.activeElement?.source?.fill_color
  const selectedBackgroundColor = props.activeElement?.source?.background_color
  const selectedBackgroundOpacity = parseFloat(
    !selectedBackgroundColor || selectedBackgroundColor === 'transparent'
      ? '0'
      : selectedBackgroundColor.split(',')[3]?.split(')')[0]?.trim() ?? '1',
  );


  const modifyProperty = async (propertyMap: Partial<KaraokeConfig>) => {
    // supports multiple properties at a time
    await videoCreator.renderer?.applyModifications(
      (Object.keys(propertyMap) as Array<keyof KaraokeConfig>).reduce(
        (acc, key) => {
          acc[`${props.activeElement.source.id}.${key}`] = propertyMap[key];
          return acc;
        },
        {} as any,
      ),
    );
  };

  const modifyPropertyDebounced = lodash.debounce(modifyProperty, 250);

  const boldRef = useRef<HTMLButtonElement>(null);
  useOutsideAlerter(boldRef, () => {
    toggleBold(false);
  });

  const toggleAction = (action: Action) => {
    const hasAction = selectedActions.find((a) => a === action);

    let updatedActions = [...selectedActions];
    if (hasAction) {
      updatedActions = updatedActions.filter((a) => a !== action);
    } else {
      updatedActions = [...updatedActions, action];
    }
    setSelectedActions(updatedActions);
  };

  const handleToggleAction = (
    action: Action,
    propertyName: keyof KaraokeConfig,
    normalState: string,
    requiredState: string,
  ) => {
    toggleAction(action);
    const isSelected = selectedActions.includes(action);

    const state = isSelected ? normalState : requiredState;
    modifyProperty({ [propertyName]: state });
  };

  const toggleAlign = (align: Align) => {
    setAlignType(align);
  };

  const getActionColor = (action: Action) => {
    if (selectedActions.includes(action)) return '#03041A';

    return '#484848';
  };

  const getAlignColor = (align: Align) => {
    if (alignType === align) return '#03041A';

    return '#484848';
  };

  const handleToggleBold = (bold: Bold, propertyName: string) => {
    const state = bold === selectedBold ? null : bold;
    setSelectedBold(state);

    let value = BoldValues[Bold.normal];
    switch (state) {
      case Bold.normal:
        value = BoldValues[Bold.normal];
        break;
      case Bold.bold:
        value = BoldValues[Bold.semiBold];
        break;
      case Bold.semiBold:
        value = BoldValues[Bold.bold];
        break;
      case Bold.extraBold:
        value = BoldValues[Bold.extraBold];
        break;
      default:
        value = BoldValues[Bold.normal];
    }

    modifyProperty({ [propertyName]: value.toString() });
    toggleBold(false);
  };



  const renderAction = (action: Action | Align) => {
    switch (action) {
      case Action.bold:
        const isSelected = selectedBold !== Bold.normal && !!selectedBold;
        return (
          <BoldAction
            ref={boldRef}
            isSelected={isSelected || !!openBold}
            onClick={() => {
              toggleBold(!openBold);
            }}
          >
            <BoldIcon fillColor={getActionColor(Action.bold)} />
            {openBold && (
              <Dropdown>
                {Object.values(Bold).map((action) => (
                  <DropdownItem
                    isSelected={selectedBold === action}
                    boldValue={BoldValues[action]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleBold(action, 'font_weight');
                    }}
                  >
                    {action}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </BoldAction>
        );
      case Action.italic:
        return (
          <ActionButton
            isSelected={selectedActions.includes(Action.italic)}
            onClick={() =>
              handleToggleAction(
                Action.italic,
                'font_style',
                'normal',
                'italic',
              )
            }
          >
            <ItalicIcon fillColor={getActionColor(Action.italic)} />
          </ActionButton>
        );

      case Action.capitalize:
        return (
          <ActionButton
            isSelected={selectedActions.includes(Action.capitalize)}
            onClick={() =>
              handleToggleAction(
                Action.capitalize,
                'text_transform',
                'none',
                'capitalize',
              )
            }
          >
            <CapitalizeIcon fillColor={getActionColor(Action.capitalize)} />
          </ActionButton>
        );

      case Action.align:
        return renderAlignOptions();
      // case Action.bgColor:
      //   return <BackgroundColorDropdownSelect action={modifyProperty} />;

      default:
        return null;
    }
  };

  const renderAlignOptions = () => {
    return (
      <>
        <ActionButton
          isSelected={alignType === Align.leftAlign}
          onClick={() => {
            toggleAlign(Align.leftAlign);
            modifyProperty({ x_alignment: '0%' });
          }}
        >
          <LeftAlignIcon fillColor={getAlignColor(Align.leftAlign)} />
        </ActionButton>
        <ActionButton
          isSelected={alignType === Align.midAlign}
          onClick={() => {
            toggleAlign(Align.midAlign);
            modifyProperty({ x_alignment: '50%' });
          }}
        >
          <MiddleAlignIcon strokeColor={getAlignColor(Align.midAlign)} />
        </ActionButton>
        <ActionButton
          isSelected={alignType === Align.rightAlign}
          onClick={() => {
            toggleAlign(Align.rightAlign);
            modifyProperty({ x_alignment: '100%' });
          }}
        >
          <RightAlignIcon fillColor={getAlignColor(Align.rightAlign)} />
        </ActionButton>
      </>
    );
  };
  return (
    <Main>
      <TextInput activeElement={props.activeElement} />
      <FontColorGroup>
        <FontContainer>
          <ItemDropdownSelect
            action={(propertyName, value) =>
              modifyProperty({ [propertyName]: value })
            }
            propertyName="font_family"
            defaultValue="Aileron"
            values={[
              { caption: 'Aileron', value: 'Aileron' },
              { caption: 'Open Sans', value: 'Open Sans' },
              { caption: 'Dosis', value: 'Dosis' },
              { caption: 'Inter', value: 'Inter' },
              { caption: 'Roboto', value: 'Roboto' },
              { caption: 'Montserrat', value: 'Montserrat' },
              { caption: 'Lato', value: 'Lato' },
              { caption: 'Poppins', value: 'Poppins' },
              { caption: 'Roboto Condensed', value: 'Roboto Condensed' },
              { caption: 'Raleway', value: 'Raleway' },
              { caption: 'Nunito', value: 'Nunito' },
              { caption: 'Ubuntu', value: 'Ubuntu' },
              { caption: 'Blinker', value: 'Blinker' },
              { caption: 'Urbanist', value: 'Urbanist' },
              { caption: 'Work Sans', value: 'Work Sans' },
            ]}
            styleFont
          />
        </FontContainer>

        <ItemDropdownSelect
          action={(propertyName, value) =>
            modifyProperty({ [propertyName]: value })
          }
          propertyName="fill_color"
          defaultValue={selectFillColor || Colors.black}
          width="50px"
          values={[
            { caption: Colors.black, value: Colors.black },
            { caption: Colors.white, value: Colors.white },
            { caption: Colors.red, value: Colors.red },
            { caption: Colors.green, value: Colors.green },
            { caption: Colors.orange, value: Colors.orange },
            { caption: Colors.blue, value: Colors.blue },
            { caption: Colors.yellow, value: Colors.yellow },
          ]}
          showColor
        />
      </FontColorGroup>

      <SliderGroup>
        <Position>
          <SliderSelect
            title="Position"
            Icon={<UpDownIcon />}
            action={modifyPropertyDebounced}
            defaultValue="50"
            unit="%"
            propertyName="y"
            reverse={true}
          />
        </Position>
        <FontSize>
          <ItemDropdownSelect
            action={(propertyName, value) =>
              modifyProperty({ [propertyName]: value })
            }
            propertyName="font_size"
            defaultValue="72"
            values={[
              { caption: '16', value: '16' },
              { caption: '18', value: '18' },
              { caption: '20', value: '20' },
              { caption: '24', value: '24' },
              { caption: '30', value: '30' },
              { caption: '36', value: '36' },
              { caption: '48', value: '48' },
              { caption: '60', value: '60' },
              { caption: '72', value: '72' },
              { caption: '96', value: '96' },
              { caption: '120', value: '120' },
              { caption: '144', value: '144' },
              { caption: '192', value: '192' },
              { caption: '240', value: '240' },
              { caption: '300', value: '300' },
            ]}
          />
        </FontSize>
      </SliderGroup>
      <ActionGroup>
        {Object.values(Action).map((action) => (
          renderAction(action)
        ))}
      </ActionGroup>
      <KaraokeBackground
        action={modifyProperty}
        defaultValue={selectedBackgroundColor}
        opacity={selectedBackgroundOpacity}
      />
      <AnimationSettings
        manualKaraoke={true}
        activeElement={props.activeElement}
      />
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Position = styled.div`
  flex: 1;
`;
const FontSize = styled.div`
  flex: 1;
`;

const ActionButton = styled.button<{ isSelected: boolean }>`
  outline: 0;
  border: 1px solid ${(props) => (props.isSelected ? '#F2D093' : '#484848')};
  width: 12%;
  aspect-ratio: 1/1;
  background-color: ${(props) => (props.isSelected ? '#F2D093' : '#03041A')};
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const BoldAction = styled(ActionButton)`
  position: relative;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const SliderGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const FontColorGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const FontContainer = styled.div`
  flex: 1;
`;

const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  // padding: 0 10px;
  text-align: left;
  width: 100px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0;
  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{ isSelected: boolean; boldValue: number }>`
  padding: 12px;
  background-color: ${(props) => props.isSelected && '#f2d093'};
  &:not(:last-child) {
    border-bottom: 1px solid #484848;
  }
  font-weight: ${(props) => props.isSelected && props.boldValue};
  color: ${(props) => (props.isSelected ? '#030419' : '#f3e9d7')};
  &:hover {
    color: ${(props) => (props.isSelected ? '#030419' : '#f2d093')};
    font-weight: ${(props) => props.boldValue};
  }
`;
