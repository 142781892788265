import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';
import { PADDING_LEFT_COMPENSATION } from './TimelineElement';

interface ResizeHandleProps {
  element: ElementState;
  side: 'start' | 'end';
  time: number;
  //todo boundaries
  onChange: (time: number, trim_start: number, duration: number) => void;
  onComplete: () => void;
}

export const ResizeHandle: React.FC<ResizeHandleProps> = observer((props) => {
  const timelineScale = videoCreator.timelineScale;

  return (
    <Draggable
      cancel={'.volume-key-point'}

      onStart={(e, data) => {
        return { startX: data.x };
      }}
      onDrag={(e, data, context) => {
        let timeOffset = videoCreator.snapTime((data.x - context.startX) / timelineScale);

        if (props.side === 'start') {
          let time = props.element.localTime + timeOffset;
          let trimStart = (props.element.source.trim_start || 0) + timeOffset;
          let duration = props.element.duration - timeOffset;
          if (time < 0) {
            duration += time;
            time = 0;
          }

          if (trimStart < 0) {
            trimStart = 0;
          }

          props.onChange(videoCreator.snapTime(time), videoCreator.snapTime(trimStart), videoCreator.snapTime(duration));
        } else {
          const duration = Math.max(videoCreator.snapTime(props.element.duration + timeOffset), 0.5);

          props.onChange(videoCreator.snapTime(props.element.localTime), props.element.trimStart, videoCreator.snapTime(duration));
        }
      }}
      onStop={() => {
        props.onComplete();
      }}
    >
      {(ref) => (
        <Main
          ref={ref}
          style={{
            left: PADDING_LEFT_COMPENSATION + props.time * timelineScale - (props.side === 'end' ? 7 : 0),
          }}
        ></Main>
      )}
    </Draggable>
  );
});

const Main = styled.div`
  position: absolute;
  cursor: ew-resize;
  z-index: 1;
  left: 0px;
  width: 7px;
  height: 100%;
  cursor: ew-resize;
`;
