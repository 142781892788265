import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  closeModal?: () => void;
  children: React.ReactNode;
};

export default function Modal(props: Props) {
  const { isOpen, closeModal, children } = props;
  return createPortal(
    <>
      {isOpen ? (
        <>
          <Wrapper
          >
            <div
              style={{ position: 'relative' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {closeModal && (
                <CloseButton
                  onClick={() => closeModal()}
                  aria-label="Close modal"
                >
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="M11 1 1 11M1 1l10 10"
                      stroke="white"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </CloseButton>
              )}

              {children}
            </div>
          </Wrapper>
          <Overlay></Overlay>
        </>
      ) : null}
    </>,
    document.body,
  );
}

const Wrapper = styled.div`
  background-color: #0002;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  inset: 0;
  z-index: 1000;
  outline: none;
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 10;
  padding: 12px;
  top: 6px;
  right: 6px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Overlay = styled.div`
  opacity: 0.25;
  position: fixed;
  inset: 0;
  z-index: 40;
  background-color: black;
`;
