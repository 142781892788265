import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css, keyframes } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import ReplaceIcon from '../../svgs/ReplaceIcon';
import { Subtitles } from '../../types.ts/story';
import { TranscriptContextMenu } from './TranscriptContextMenu';
import { TranscriptContextMenuActions } from '../../types.ts/general';
import { WordReplacementModal } from './WordReplacementModal';

export type SubtitlesContextMenuActions = 'replaceSubtitle' | 'splitSubtitle' | 'removeSubtitle' | 'joinSubtitle';

const NAV_HEIGHT = 68;

export const TranscriptionSubtitles: React.FC = observer(() => {

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const subtitles = videoCreator.currentVideo?.subtitles;
  const [lastTimeUpdated, setLastTimeUpdated] = useState<number>(0);
  const [lineHighlighted, setLineHighlighted] = useState<number>(-1);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [contextMenuActions, setContextMenuActions] = useState<SubtitlesContextMenuActions[] | null>(null)
  const [selectedLineIndex, setSelectedLineIndex] = useState<number>(-1);
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(-1);
  const [showReplaceModalFor, setShowReplaceModalFor] = useState<number | null>(null);

  const loadingStatus = videoCreator.subtitleLoadingStatus;

  const fetchData = async () => {
    const requestedSubtitles = await videoCreator.requestSubtitlesForCurrentVideo();
  }

  const getCurrentLineIndex = () => {
    return subtitles?.lines.findIndex((line) => {
      return videoCreator.time >= line.start && videoCreator.time < line.end
    })
  }

  useEffect(() => {
    if (!subtitles) {
      fetchData();
    }
  }, []);

  // highlight lines as video playing
  useEffect(() => {
    if (
      !videoCreator.finalTranscriptionElements ||
      (videoCreator.isPlaying && (videoCreator.time < lastTimeUpdated) && (lastTimeUpdated < videoCreator.time + 5)) ||
      Math.abs(lastTimeUpdated - videoCreator.time) < 0.1
    )
      return;
    setLastTimeUpdated(videoCreator.time);

    const currentLine = getCurrentLineIndex();
    setLineHighlighted(currentLine ?? -1);
  }, [videoCreator.finalTranscriptionElements, videoCreator.time]);

  const handleReplace = () => {
    console.log('handle replace', selectedLineIndex)
    const lineIndex = selectedLineIndex;
    setShowReplaceModalFor(lineIndex);
  };

  const onReplace = (text: string, range?: { start: number, end: number }) => {
    videoCreator.currentVideo!.subtitles!.lines[selectedLineIndex].translated = text;
    if (range) {
      range.start = Math.round(range.start * 100) / 100;
      range.end = Math.round(range.end * 100) / 100;
      videoCreator.currentVideo!.subtitles!.lines[selectedLineIndex].start = range.start;
      videoCreator.currentVideo!.subtitles!.lines[selectedLineIndex].end = range.end;
      videoCreator.currentVideo!.subtitles!.lines.forEach((line, index) => {
        if (index < selectedLineIndex) {
          if (line.end > range.start) {
            line.end = range.start;
          }
        } else if (index > selectedLineIndex) {
          if (line.start < range.end) {
            line.start = range.end;
          }
        }
      })
    }
    setShowReplaceModalFor(null);
    setSelectedLineIndex(-1);
    if (videoCreator.karaokeProducer.hasElements() && videoCreator.karaokeProducer.getKaraokeConfig()?.language === 'english') {
      videoCreator.karaokeProducer.produceKaraoke();
    }
  }

  const handleRemove = () => {
    const lineIndex = selectedLineIndex;
    videoCreator.currentVideo?.subtitles?.lines.splice(lineIndex, 1);
    setSelectedLineIndex(-1);
    if (videoCreator.karaokeProducer.hasElements() && videoCreator.karaokeProducer.getKaraokeConfig()?.language === 'english') {
      videoCreator.karaokeProducer.produceKaraoke();
    }
  }

  function showOptionsForWordWithIndexAtPosition(
    lineIndex: number,
    wordIndex: number,
    positionXY: { x: number; y: number },
  ) {
    console.log('line index');
    const actions = getActions(lineIndex, wordIndex);
    if (!actions.length) return;
    setSelectedLineIndex(lineIndex);
    setSelectedWordIndex(wordIndex);
    setContextMenuPosition(positionXY);
    setContextMenuActions(actions);
  }

  const contextMenuCallback = async (
    action: SubtitlesContextMenuActions | TranscriptContextMenuActions | 'close',
  ) => {
    if (action === 'replaceSubtitle') {
      handleReplace();
    } else if (action === 'joinSubtitle') {
      // handleJoin();
    } else if (action === 'splitSubtitle') {
      // handleSplit();
    } else if (action === 'removeSubtitle') {
      handleRemove();
    } else {
      setSelectedLineIndex(-1);
    }
    setContextMenuPosition(null);
  };


  const getActions = (
    lineIndex: number,
    wordIndex: number,
  ): SubtitlesContextMenuActions[] => {
    // const selection = window.getSelection();
    // if (selection && selection.toString().length) {
    //   const { startIndex, endIndex } =
    //     getBoundaryIndexesFromSelection(selection) || {};
    //   if (showHistory && startIndex !== undefined && endIndex !== undefined
    //     && videoCreator.finalTranscriptionElements!.some(
    //       (el, i) => el.state === 'removed' && i >= startIndex && i <= endIndex)
    //   ) {
    //     return ['restore'];
    //   }
    // }
    return [
      'replaceSubtitle',
      'joinSubtitle',
      'splitSubtitle',
      'removeSubtitle',
    ];
  };

  const renderSubtitle = (line: Subtitles["lines"][0], index: number, lines: Subtitles["lines"]) => {
    return (
      <Line key={`line-${index}`} >
        <Timerange><div>{line.start.toFixed(2)}</div>{'-->'}<div>{line.end.toFixed(2)}</div></Timerange>
        <LineText data-subtitle-index={index}><Text isHighlighted={index === lineHighlighted} onClick={() => {
          const wasPlaying = videoCreator.isPlaying;
          videoCreator.setTime(line.start, true).then(() => {
            if (wasPlaying) {
              videoCreator.renderer?.play();
            }
            // setAutoScroll(true);
          });
        }}>{line.translated}</Text></LineText>
      </Line>
    );
  }

  // console.log('showReplaceModalFor', showReplaceModalFor, selectedLineIndex, selectedWordIndex)
  // console.log('contextMenuPosition', contextMenuPosition, contextMenuActions, selectedWordIndex)
  // console.log('scrollRef.current?.clientHeight!', scrollRef.current?.clientHeight)
  // todo: quick fix for height +42px
  return (
    <div style={{ height: 'calc(100% + 42px)' }}>
      {loadingStatus === 'loading' && <div>Loading...</div>}
      {loadingStatus === 'failed' && (<div>
        <div style={{ width: '100%' }}>
          Failed to generate subtitles
        </div>
        <div>
          Click{' '}
          <PanelButton onClick={() => fetchData()}>
            <ReplaceIcon />
          </PanelButton>{' '}
          to try again
        </div>
      </div>)}

      <Main
        id={'subtitles-container'}
        ref={scrollRef}
        tabIndex={-1}
        onWheel={() => {
          // setAutoScroll(false);
        }}
        onTouchMove={() => {
          // setAutoScroll(false);
        }}
        onContextMenu={(e) => {
          console.log('context menu', e.target, e.pageY)
          e.preventDefault();
          const target = e.target as HTMLElement;
          const lineIndex = Number(target.dataset.subtitleIndex ?? target.parentElement?.dataset.subtitleIndex);

          if (!isNaN(lineIndex)) {
            showOptionsForWordWithIndexAtPosition(lineIndex, 0, {
              x: e.pageX,
              y: e.pageY - NAV_HEIGHT + 2,
            });
          }
          return false;
        }}
      >
        {contextMenuPosition && contextMenuActions && selectedWordIndex > -1 && (
          <TranscriptContextMenu
            positionXY={contextMenuPosition}
            actions={contextMenuActions}
            actionCallback={contextMenuCallback}
            clientHeight={scrollRef.current?.clientHeight!}
          />
        )}
        {showReplaceModalFor != null && (
          <WordReplacementModal
            type={'textarea'}
            currentText={subtitles!.lines[selectedLineIndex].translated}
            submitText={(text) => {
              onReplace(text);
            }}
            currentRange={{
              start: subtitles!.lines[selectedLineIndex].start,
              end: subtitles!.lines[selectedLineIndex].end,
            }}
            changeRange={(range) => {
              onReplace(subtitles!.lines[selectedLineIndex].translated, range)
            }}
            discard={() => {
              setShowReplaceModalFor(null);
              setSelectedLineIndex(-1);
            }}
          />
        )}
        {
          videoCreator.currentVideo?.subtitles?.lines.map(renderSubtitle)
        }
      </Main>
    </div>
  );
},
);

const Main = styled.div`
  width: 100%;
  height: 100%;
`;

const PanelButton = styled.button`
  border: none;
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #F2D093;

  &:hover {
    transform: scale(1.1);
  }
`;

const Line = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  margin-bottom: 5px;
`

const LineText = styled.div.attrs((props: { isHighlighted: boolean }) => props)`
  flex: 1;
  border-radius: 4px;
  line-height: 1.35;
  overflow: hidden;
`

const Text = styled.span.attrs((props: { isHighlighted: boolean }) => props)`
  color: rgb(189, 189, 189);
  border-radius: 4px;
  padding: 0 1px 4px;
  cursor: pointer;

  &:hover {
    color: lightgreen;
  }

  ${(props) => props.isHighlighted && css`
    color: black;
    background-color: #dfb615;
  `}
`

const Timerange = styled.div`
  padding: 0 1px;
  color: #484848;
  line-height: 1;
  font-size: 10px;
  display: flex;
  gap: 6px;
  align-items: end;
  justify-content: center;
`