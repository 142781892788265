import {
  Client,
  SimpleSchemaTypes,
  buildBlockRecord,
} from '@datocms/cma-client-browser';
import { STORY_QUERY } from '../utility/gql';
import { GraphQLClient } from 'graphql-request';
import { FileData, Story, StoryDTO } from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';

export class AssetRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async uploadFile(
    fileData: FileData & { fileName: string },
  ): Promise<SimpleSchemaTypes.Upload> {
    if (!fileData.url && !fileData.file) {
      throw new Error('Either a url or a file must be provided');
    }

    let file = fileData.file!;

    if (fileData.url) {
      const blobObject = await AssetRepository.base64ToBlob(fileData.url);
      const blobData = {
        ...fileData,
        blob: blobObject,
      };

      file = AssetRepository.convertBlobToFile(
        blobData.blob,
        fileData.fileName,
      );
    }

    const result = await this.dClient.uploads.createFromFileOrBlob({
      fileOrBlob: file,
      filename: fileData.fileName,
      default_field_metadata: {
        en: {
          alt: fileData.alt,
          title: fileData.title,
          custom_data: fileData.customData || {},
        },
      },
    });

    return result;
  }

  static convertBlobToFile(blobObj: Blob, fileName: string) {
    const newBlob: any = blobObj;
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return newBlob as File;
  }

  static async base64ToBlob(base64Url: string) {
    return fetch(base64Url).then((res) => res.blob());
  }
}
