import { ElementState } from '../renderer/ElementState';
import { videoCreator } from '../stores/VideoCreatorStore';

const FIELDS_TO_NOT_COMPARE = ['time', 'track', 'duration', 'trim_start'];

export const getOriginalVideoTrackSourceDiff = (
  prevTracksState: ElementState[],
  newTracksState: ElementState[],
) => {
  const diff: Record<string, { track: number; changes: Record<string, any> }> =
    {};
  //debugger;
  newTracksState.forEach((trackState, index) => {
    //debugger;
    if (!videoCreator.isOriginalVideoElement(trackState.source)) return;
    const prevSource = prevTracksState.find(
      (prevTrackState) => prevTrackState.source.id === trackState.source.id,
    )?.source;
    if (!prevSource) return;
    //debugger;
    for (const key in { ...trackState.source, ...prevSource }) {
      if (FIELDS_TO_NOT_COMPARE.includes(key)) {
        continue;
      }
      if (prevSource && prevSource[key] !== trackState.source[key]) {
        diff[trackState.source.id] = diff[trackState.source.id] || {
          track: trackState.track,
          changes: {},
        };
        diff[trackState.source.id].changes[key] = trackState.source[key];
      }
    }
  });
  return diff;
};
