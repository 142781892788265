import { observer } from 'mobx-react-lite';
import { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';
import PhotoIcon from '../../svgs/PhotoIcon';
import MusicIcon from '../../svgs/MusicIcon';
import AiProducerIcon from '../../svgs/AiProducerIcon';
import { AIProducerCard, SidebarOption } from '../../types.ts/general';
import { HistoryIcon, TextIcon } from '../common/icons';
import VideoIcon from '../../svgs/VideoIcon';

type Props = {
  selectedCard?: AIProducerCard;
  setSelectedCard?: Dispatch<SetStateAction<AIProducerCard | undefined>>;
};
const SidePanelMenu: FC<Props> = observer((props) => {
  const { sidebarOptions } = videoCreator;
  const isPhoto = sidebarOptions === SidebarOption.photo;
  const isProducer = sidebarOptions === SidebarOption.aiProducer;
  const isAudio = sidebarOptions === SidebarOption.music;
  const isHistory = sidebarOptions === SidebarOption.history;
  const isVideo = sidebarOptions === SidebarOption.video;

  const handleSetOption = (state: SidebarOption) => {
    videoCreator.sidebarOptions = state;
    videoCreator.setActiveElements();
  };

  const handleSetCard = (state: AIProducerCard) => {
    if (!props.setSelectedCard) return;
    props.setSelectedCard(state);
    videoCreator.setActiveElements();
  };

  return (
    <Main>
      <TopMenu>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.aiProducer);
            handleSetCard(AIProducerCard.story);
          }}
        >
          <AiProducerIcon strokeColor={isProducer ? '#F2D093' : '#484848'} />
          <Text isSelected={isProducer}>AI Producer</Text>
        </Icon>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.photo);
          }}
        >
          <PhotoIcon
            height="20"
            strokeColor={isPhoto ? '#F2D093' : '#484848'}
          />
          <Text isSelected={isPhoto}>Photos</Text>
        </Icon>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.music);
          }}
        >
          <MusicIcon
            height="20"
            viewBox="0 0 22 20"
            strokeColor={isAudio ? '#f2d093' : '#484848'}
          />

          <Text isSelected={isAudio}>Sound</Text>
        </Icon>

        <Icon
          onClick={async () => {
            const source = videoCreator.renderer?.getSource();
            const textElement = source?.elements.find(
              (el: any) =>
                el.type === 'text' &&
                parseInt(el.track) !== KARAOKE_TRACK_NUMBER,
            );

            await videoCreator.createElement({
              type: 'text',
              text: 'New text element',
              duration: '8 s',
              animations: [{ type: 'text-appear', time: 'start' }],
              // ...(textElement?.track && { track: textElement.track }),
            });
            videoCreator.sidebarOptions = SidebarOption.text;
          }}
        >
          <TextIcon
            height="20"
            width="20"
            fillColor={
              videoCreator.sidebarOptions === SidebarOption.text
                ? '#f2d093'
                : '#484848'
            }
          />
          <Text isSelected={videoCreator.sidebarOptions === SidebarOption.text}>
            Text
          </Text>
        </Icon>

        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.video);
          }}
        >
          <VideoIcon
            height="20"
            width="20"
            strokeColor={isVideo ? '#F2D093' : '#484848'}
          />

          <Text isSelected={isVideo}>Video</Text>
        </Icon>

        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.history);
          }}
        >
          <HistoryIcon
            height="20"
            width="20"
            strokeColor={isHistory ? '#F2D093' : '#484848'}
          />

          <Text isSelected={isHistory}>History</Text>
        </Icon>
      </TopMenu>
      {isProducer && (
        <Cards>
          <Card
            onClick={() => handleSetCard(AIProducerCard.story)}
            isSelected={props.selectedCard === AIProducerCard.story}
          >
            Story
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.clip)}
            isSelected={props.selectedCard === AIProducerCard.clip}
          >
            Clip
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.music)}
            isSelected={props.selectedCard === AIProducerCard.music}
          >
            Music
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.photo)}
            isSelected={props.selectedCard === AIProducerCard.photo}
          >
            Photo
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.sound_effect)}
            isSelected={props.selectedCard === AIProducerCard.sound_effect}
          >
            Sound Effects
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.karoke_text)}
            isSelected={props.selectedCard === AIProducerCard.karoke_text}
          >
            Karaoke Text
          </Card>
          <Card
            onClick={() => handleSetCard(AIProducerCard.retention_edit)}
            isSelected={props.selectedCard === AIProducerCard.retention_edit}
          >
            Retention Edit
          </Card>
        </Cards>
      )}
      <HorizontalLine height="1" fill="none">
        <path d="M0 1L312 1.00002" stroke="#484848" />
      </HorizontalLine>
    </Main>
  );
});

export default SidePanelMenu;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  gap: 20px;
`;

const TopMenu = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-end;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  border-radius: 8px;
  span {
    font-size: 11px;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Card = styled.div<{ isSelected?: boolean }>`
  border: 1px solid ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
  border-radius: 4px;
  font-size: 12px;
  padding: 8px 10px;
  text-align: center;
  color: #484848;
  background-color: ${(props) => (props.isSelected ? '#f2d093' : '')};
  cursor: pointer;
`;

const Text = styled.span<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
`;

const HorizontalLine = styled.svg`
  width: 100%;
`;
