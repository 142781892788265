import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import CheckIcon from '../../svgs/CheckIcon';

type Props = {
  values: { caption: string; value: string | null }[];
  onChange: (value: string | null) => void;
  defaultValue: string | undefined | null;
  value: string;
};


const PropertyDropdown = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState<string | undefined | null>(
    props.defaultValue,
  );
  const [showDropdown, toggleDropdown] = useState<boolean>(false);

  useEffect(() => {
    setSelectedItem(props.defaultValue);
  }, [props.defaultValue]);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    toggleDropdown(false);
  });

  const ArrowDownIcon = (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path d="M2 4.33008L6 8.33008L10 4.33008" stroke="#F2D093" />
    </svg>
  );

  const selectedValue = props.values.find((v) => v.value === selectedItem);

  const renderDropdownItem = (item: {
    caption: string;
    value: string | null;
  }) => (
    <DropdownItem
      key={item.value}
      onClick={() => {
        setSelectedItem(item.value);
        toggleDropdown(false);
        props.onChange(item.value);
      }}
    >
      <span>{item.caption}</span>
      {selectedItem === item.value && <CircleCheck><CheckIcon /></CircleCheck>}
    </DropdownItem>
  );

  return (
    <Main ref={contentRef}>
      <Selected onClick={(e) => toggleDropdown(!showDropdown)}>
        <SelectedText
        >
          {(selectedValue?.caption) ||
            ''}
        </SelectedText>{' '}
        <ToggleIcon>{ArrowDownIcon}</ToggleIcon>
      </Selected>
      {showDropdown && (
        <Dropdown>
          {props.values.map((item) => renderDropdownItem(item))}
        </Dropdown>
      )}
    </Main>
  );
};

export default PropertyDropdown;

const Main = styled.div`
  position: relative;
`;
const Selected = styled.div`
  border: 1px solid #484848;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  cursor: pointer;

  padding: 4px 8px;
  border-radius: 8px;
`;

const SelectedText = styled.span`
  font-size: 14px;
`;
const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 5px 0;
  max-height: 230px;
  width: 100%;
  overflow: auto;
  position: absolute;
  margin-top: 1px;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.div`
  padding: 5px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  &:hover {
    color: #f2d093;
  }
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;

const CircleCheck = styled.span`
  background-color: #4AD067;
  width: 14px;
  height: 14px;
  border-radius: 50%;

  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`