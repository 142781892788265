import { RequestDocument } from 'graphql-request';

const VIDEO_FILE_GQL_FRAGMENT = `
{
  width
  height
  url
  video {
    duration
    thumbnailUrl
    muxPlaybackId
  }
  customData
}
`;

const REFERENCING_SHARED_CONTENT = `
 {
  id
  socialProfileGroup {
    id
  }
  video {
    id
    title
  }
  shareableContent {
    id
    title
  }
  story {
    id
  }
  organization {
    id
  }
  external
  postId
  postUrl
  lastFetched
  postType
  platform
  post
  title
  likes
  comments
  shares
  clicks
  views
}
`;

const REFERENCING_CAPTION = `
{
  id
  platform
  caption
  story{
    id
  }
  shareable{
    __typename 
  }
}`;

export const SHOWCASE_QUERY: RequestDocument = `query getShowcase($slug: String!) {
  showcase(filter: {slug: {eq: $slug}}) {
    id
    title
    organization
    slug
    logo {
      url
    }
    mainImage {
      url
    }
    stories {
      id
      originalVideo {
        url
      }
    }
  }
}`;

export const ALBUM_QUERY: RequestDocument = `query getAlbum($id: ItemId) {
  showcase(filter: {id: {eq: $id}}) {
        id
        title
        stories {
          id
          title
          aiResponse
          storyTeller {
            name
          }
          _allReferencingSharedContents
          ${REFERENCING_SHARED_CONTENT}
          _publishedAt
          thumbnail {
            url
          }
          originalVideo {
            video {
              duration
              thumbnailUrl
              muxPlaybackId
            }
          }
          finalVideo {
            thumbnail {
              url
            }
            videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          }
        }
    }
  }`;

export const STORY_QUERY: RequestDocument = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
        id
        slug
        hash
        title(locale: en)
        description(locale: en)
        _allReferencingShowcases{
          id
          title
          organization
          slug
          logo {
            url
          }
          mainImage {
            url
          }
          profiles {
            id
            name
            platforms
          }
        }
        thumbnail {
          url
        }
        aiGeneratedContent{
          id
          prompt
          generatedContent
        }
        _allReferencingSharedContents 
        ${REFERENCING_SHARED_CONTENT}

        _allReferencingCaptions ${REFERENCING_CAPTION}

        alreadyGeneratedContent
        aiResponse
        savedBlog
        savedTalkingPointContent
        savedEmail
        shareableImages {
          id
          imagefile {
            id
            title
            url
            width
            height
            responsiveImage {
              srcSet
              alt
              title
            }
            _createdAt
          }
          quote
          _allReferencingSharedContents 
          ${REFERENCING_SHARED_CONTENT}

          _allReferencingCaptions ${REFERENCING_CAPTION}
        }
        transcription {
          jobStatus
          audio {
            url
            format
            mimeType
            customData
          }
          elementsJson {
            id
            url
          }
          waveformData {
            id
            url
          }
        }
        originalVideo {
          url
          height
          width
          video {
            duration
            thumbnailUrl
            muxPlaybackId
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          customData
        }
        finalVideo {
          id
          title
          thumbnail {
            url
          }
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          videoStatus
          sourcePlatform
        }
        otherVideos {
          id
          title
          videoStatus
          sourcePlatform
          videoJson
          thumbnail {
            url
          }
          shareableImageId
          videoFilePrimary
            ${VIDEO_FILE_GQL_FRAGMENT}
          slug
          hash
          aspectRatio
          aiGeneratedContent {
            id
            prompt
            generatedContent
          }
          associatedVideos {
            id
            aspectRatio
            videoStatus
            sourcePlatform
            thumbnail {
              url
            }
            shareableImageId
            videoFilePrimary
              ${VIDEO_FILE_GQL_FRAGMENT}
          }
          _allReferencingSharedContents 
          ${REFERENCING_SHARED_CONTENT}
          _allReferencingCaptions ${REFERENCING_CAPTION}
          
        }
        tags {
          name
        }
        storyTeller {
          name
        }
        storyArtifacts {
          id
          title
          customData
          format
          mimeType
          url
          width
          height
          responsiveImage {
            base64
            sizes
            srcSet
            alt
            title
          }
          video {
            duration
            muxPlaybackId
            thumbnailUrl
            mp4Url
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
          }
          _createdAt
        }
        storyAssets {
          id
          title
          customData
          format
          mimeType
          url
          width
          height
          responsiveImage {
            base64
            sizes
            src
            srcSet
            alt
            title
          }
          video {
            duration
            muxPlaybackId
            thumbnailUrl
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          _createdAt
        }
        aiPhotos {
          id
          title
          customData
          format
          mimeType
          url
          responsiveImage {
            base64
            sizes
            src
            srcSet
            alt
            title
          }
          video {
            duration
            muxPlaybackId
            thumbnailUrl
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          _createdAt
        }

        storyArtifactsVideo {
          id
          title
          customData
          format
          mimeType
          url
          video {
            duration
            muxPlaybackId
            thumbnailUrl
            mp4UrlHigh: mp4Url(exactRes: high, res: high)
            mp4UrlMedium: mp4Url(exactRes: medium, res: medium)
            mp4UrlLow: mp4Url(exactRes: low, res: low)
            mp4Url
          }
          _createdAt
        }
  	}
  }`;

export const SHARABLE_SHARED_CONTENT_QUERY: RequestDocument = `query getSharedContents($id: String) {
  allSharedContents(filter: {sharableContent: {eq: $id}}) _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
}`;

export const AIPROMPT_QUERY: RequestDocument = `query {
  allAiPrompts{
    id
    promptFields{
      id
      name
      description
    }
    functionDescription,
    description
    title
    followUp
  }
}`;

export const AIPROMPT_SINGLE_BY_TITLE_QUERY: RequestDocument = `query getAipromptSingle($title: String!){
  aiPromptSingle(filter: {title: {matches: {pattern: $title, caseSensitive:false} }}){
    id
    promptFields{
      id
      name
      description
    }
    description
    title
    followUp
  }
} `;

export const MUSIC_QUERY: RequestDocument = `query {
  allMusics{
    id
    genre
    collection {
      id
      url
      title
      tags
      customData
    }
  }
}`;

export const VIDEO_RENDERING_STATUS_QUERY: RequestDocument = `query getVideo($id: ItemId) {
    video(filter: {id: {eq: $id}}) {
      id
      renderId
      videoStatus
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
      videoJson
      sourcePlatform
  	}
  }`;

export const ORIGINAL_VIDEO_TRANSCRIPTION_QUERY: RequestDocument = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
      id
      transcription {
        jobStatus
        elementsJson {
          id
          url
        }
      } 
  	}
  }`;

export const VIDEO_FILES_QUERY: RequestDocument = `query getVideoFilesFormatted($id: ItemId) {
  video(filter: {id: {eq: $id}}) {
    id
    title
    videoJson
    associatedVideos {
      id
      aspectRatio
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
    }
    _allReferencingVideos(through: {fields: {anyIn: video_associatedVideos}}) {
      id
      title
      aspectRatio
      associatedVideos {
        id
        aspectRatio
      }
      videoFilePrimary
        ${VIDEO_FILE_GQL_FRAGMENT}
    }
    _allReferencingSharedContents ${REFERENCING_SHARED_CONTENT}
    _allReferencingCaptions ${REFERENCING_CAPTION}
    thumbnail {
      id
      url
      responsiveImage {
        src
        srcSet
      }
    }
    videoFilePrimary
      ${VIDEO_FILE_GQL_FRAGMENT}
  }
}`;

export const AI_FLOW_QUERY: RequestDocument = `query getAiFlow($title: String!) {
  aiFlow(filter: {title: {eq: $title}}) {
    id
    title
    steps {
      label
      systemMessageTemplate
      userPromptTemplate
      overrideMessagesFromStep
      maxAttempts
      iterateOver
      modelName
      temperature
      temperatureFunction
      retryConditionFunction
      filterFunction
      reducerFunction
    }
    resultMapperFunction
    logEnabled
  }
}`;
