import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import VerticalLine from '../../svgs/VerticalLine';
import UndoIcon from '../../svgs/UndoIcon';
import RedoIcon from '../../svgs/RedoIcon';
import AspectRatiosDropdown from './AspectRatiosDropdown';

interface Props {
  storyTellerName: null | string;
}
export const Stage: React.FC<Props> = observer((props) => {
  return (
    <Main>
      <MainArea>
        <MediaContainer>
          <PlayerContainer>
          <TopItems 
            aspectRatio={videoCreator.currentVideo?.aspectRatio}
            timelineHeight={parseFloat(videoCreator.timelineHeight)}>
            <TopContent>
              <LoadingIndicator>
                {videoCreator.isLoading && (
                  <LoadingText>Loading...</LoadingText>
                )}
                {videoCreator.isSaving && <LoadingText>Saving...</LoadingText>}
              </LoadingIndicator>
              <TopActions>
                <TopLeftAction>
                  <AspectRatiosDropdown />
                </TopLeftAction>
              </TopActions>
            </TopContent>
          </TopItems>
            <VideoPlayer
              aspectRatio={videoCreator.currentVideo?.aspectRatio}
              ref={(element) => {
                if (element && element !== videoCreator.renderer?.element) {
                  videoCreator.initializeVideoPlayer(element);
                }
              }}
            />
            <BottomItems 
              aspectRatio={videoCreator.currentVideo?.aspectRatio}
              timelineHeight={parseFloat(videoCreator.timelineHeight)}
            >
            <BottomItemsLeft>
              
              <UndoRedo>
                <IconButton
                  unsetHeight={true}
                  background={false}
                  disabled={!videoCreator.canUndo}
                  onClick={() => {
                    videoCreator.undo();
                  }}
                >
                  <UndoIcon />
                </IconButton>

                <VerticalLine width="2" height="16" viewBox="0 0 2 16" />

                <IconButton
                  unsetHeight={true}
                  background={false}
                  disabled={!videoCreator.canRedo}
                  onClick={() => {
                    videoCreator.redo();
                  }}
                >
                  <RedoIcon />
                </IconButton>
              </UndoRedo>
            </BottomItemsLeft>
            <BottomItemsMid>
              <PlayButton
                background={false}
                onClick={() => {
                  if (!videoCreator.isPlaying) {
                    videoCreator.renderer?.play();
                  } else {
                    videoCreator.renderer?.pause();
                  }
                }}
              >
                {!videoCreator.isPlaying ? (
                  <svg
                    viewBox="0 0 24 24"
                    fill="#f2d093"
                    width="40"
                    height="40"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    width="40"
                    height="40"
                    fill="#f2d093"
                  >
                    <path d="M14,19H18V5H14M6,19H10V5H6V19Z" />
                  </svg>
                )}
              </PlayButton>
            </BottomItemsMid>
            <BottomItemsRight>
              <Time>
               
              </Time>
            </BottomItemsRight>
          </BottomItems>
          </PlayerContainer>

          
        </MediaContainer>
      </MainArea>
    </Main>
  );
});

const Main = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainArea = styled.div`
  position: relative;
  flex: 1;
`;

const TopItems = styled.div<{aspectRatio?: '16:9' | '1:1' | '9:16'; timelineHeight: number}>`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  
  ${({ aspectRatio, timelineHeight }) => (aspectRatio === '1:1' || aspectRatio === '9:16') && css`
      margin-top: ${timelineHeight > 40 ? '-20px' : 0 };
  `}

  ${({ aspectRatio, timelineHeight }) => (!aspectRatio || aspectRatio === '16:9') && css`
      margin-top: ${timelineHeight > 40 ? '-20px' : timelineHeight < 20 ?  '20px' : 0 };
  `}
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingIndicator = styled.div`
  margin-top: 10px;
  margin-right: 0;
  display: flex;
  align-items: center;
  .line {
    opacity: 0.3;
  }
  position: relative;
`;

const TopActions = styled.div`
  display: flex;
  flex-directon: row;
  justify-content: space-between;
  margin-top: 30px;
`;

const TopLeftAction = styled.div`
  margin-right: 0;
  display: flex;
  align-items: center;
`;

const PlayButton = styled.div<{
  disabled?: boolean;
  background?: boolean;
  margin?: number;
}>`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Time = styled.div`
  display: flex;
  gap: 3px;
  font-size: 12px;
  margin-top: -3px;
  align-items: center;
  .current {
    opacity: 0.8;
    color: #f2d093;
    width: 60px;
    display: flex;
    justify-content: center;
  }
  .end {
    opacity: 0.4;
    width: 60px;
    display: flex;
    justify-content: center;
  }
`;

const IconButton = styled.div<{
  disabled?: boolean;
  background?: boolean;
  margin?: number;
  unsetHeight?: boolean;
}>`
  display: flex;
  margin-right: ${(props) => (props.unsetHeight ? '' : '5px')};
  margin-left: ${(props) => (props.unsetHeight ? '5px' : '')};
  width: 24px;
  height: ${(props) => (props.unsetHeight ? '' : '24px')};
  padding: ${(props) => (props.unsetHeight ? '0' : '2px')};
  background: ${(props) => (props.background === false ? '' : '#333')};
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    opacity: ${(props) => (props.disabled ? '40%' : 'auto')};
  }
`;

const ActionButton = styled.div<{
  disabled?: boolean;
  background?: boolean;
  margin?: number;
}>`
  .track {
    font-size: 10px;
    color: #f2d093;
  }
  display: flex;
  margin: 0 5px;
  padding: 2px;
  height: 24px;
  width: 24px;
  background: ${(props) => (props.background === false ? '' : '#333')};
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    opacity: ${(props) => (props.disabled ? '40%' : 'auto')};
  }
`;

const LoadingText = styled.div`
  margin-right: 20px;
  color: #a3a5a5;
  position: absolute;
  top: 10px;
  right: 0;
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const PlayerContainer = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-rows: 10px 80% 40px 10% 10px;
  flex-direction: column;
  margin-top: 0;
`;

const VideoPlayer = styled.div<{ aspectRatio?: '16:9' | '1:1' | '9:16' }>`
  width: 100%;
  height: 100%;
  padding-right: 0;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex: 1;
  grid-row: 2 / 4;
`;

const BottomItems = styled.div<{ aspectRatio?: '16:9' | '1:1' | '9:16'; timelineHeight: number }>`
  // position: absolute;
  // margin: 0 auto 100px auto;

  width: 80%;
  display: flex;
  align-items: center;
  z-index: 1;

  ${({ aspectRatio, timelineHeight }) =>
    (!aspectRatio || aspectRatio === '16:9') &&
    css`
      margin: 0 auto ${timelineHeight > 60 ? '50px' : '80px'} auto;
    `}
  ${({ aspectRatio, timelineHeight }) =>
    (aspectRatio === '1:1' || aspectRatio === '9:16') &&
    css`
      margin: 0 auto 30px auto;
    `}
`;

const BottomItemsLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 2px;
`;
const BottomItemsMid = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
`;
const BottomItemsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -4px;
  flex: 1;
`;

const UndoRedo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  * {
    margin: 0;
    padding: 0;
  }
`;
