import React, { Dispatch, SetStateAction } from 'react';
import { Music } from '../../types.ts/story';
import styled from 'styled-components';

type Props = {
  music: Music[] | undefined;
  setSelectedGenre: Dispatch<SetStateAction<string>>;
};
const MusicTheme = (props: Props) => {
  return (
    <Theme>
      <>
        {props.music?.map((m) => (
          <ThemeCards
            key={m.id}
            genre={m.genre.toLowerCase()}
            onClick={() => props.setSelectedGenre(m.genre)}
          >
            {m.genre}
          </ThemeCards>
        ))}
      </>
    </Theme>
  );
};

export default MusicTheme;

const Theme = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  & > span {
    font-size: 14px;
  }
  margin-bottom: 20px;
`;

const ThemeCards = styled.div<{ genre?: string }>`
  display: flex;
  width: 88px;
  height: 88px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  border-radius: 4px;
  background-image: ${(props) => `url("/mood_thumbnails/${props.genre}.png")`};
  border: 1px solid #484848;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
`;
