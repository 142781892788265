// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';
import { ResizeHandle } from './ResizeHandle';
import { TimelineAudioElement } from './TimelineAudioElement';
import { TimelineTextElement } from './TimelineTextElement';
import { Story } from '../../types.ts/story';
import { SidebarOption } from '../../types.ts/general';
import { delay } from '../../utility/general';
import { VolumeKeyPoints } from './VolumeKeyPoints';
import TimelineBrollElement from './TimelineBrollElement';
import { runInAction } from 'mobx';

interface TimelineElementProps {
  element: ElementState;
  allElements: ElementState[];
  story?: Story;
  type?: string;
}

export const PADDING_LEFT_COMPENSATION = 14;

export const TimelineElement: React.FC<TimelineElementProps> = observer(
  (props) => {
    const { element, story, type, allElements } = props;
    const active =
      videoCreator.activeElementIds.includes(element.source.id) ||
      element.track === videoCreator.selectedTrack;
    const timelineScale = videoCreator.timelineScale;
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    const nextElement = React.useMemo(() =>
      allElements.find((el) => el.globalTime > element.globalTime)
      , [allElements])

    //@ts-ignore
    const prevElement = React.useMemo(() => allElements.findLast((el) => el.globalTime < element.globalTime)
      , [allElements])

    const volumeKeyPoints =
      videoCreator.currentVideo?.extraElementData[element.source?.id]
        ?.volumeKeyPoints;

    useEffect(() => {
      if (element.source.type !== 'video') return;
      if (videoCreator.isOriginalVideoElement(element.source)) {
        setThumbnailUrl(story?.originalVideo?.video?.thumbnailUrl);
      } else {
        const storyVideo = story?.storyArtifactsVideo?.find(s => s.url === element.source.source)
        setThumbnailUrl(storyVideo?.video?.thumbnailUrl || '');
      }
    }, []);

    const [placement, setPlacement] = useState({
      time: element.localTime,
      trim_start: element.source.trim_start,
      duration: element.duration,
    });
    const [volumeKeyPointsLocal, setVolumeKeyPointsLocal] =
      useState(volumeKeyPoints);

    useEffect(() => {
      setVolumeKeyPointsLocal(volumeKeyPoints);
    }, [volumeKeyPoints]);

    useEffect(() => {
      setPlacement({
        time: element.localTime,
        trim_start: element.source.trim_start,
        duration: element.duration,
      });
    }, [element.localTime, element.source.trim_start, element.duration]);

    const applyPlacementAndKeyPoints = async () => {
      if (
        (prevElement?.globalTime || 0) + (prevElement?.duration || 0) >
        placement.time
      ) {
        placement.time =
          videoCreator.snapTime((prevElement?.globalTime || 0) + (prevElement?.duration || 0));

        setPlacement({ ...placement });

      } else if (nextElement && nextElement.globalTime < (placement.time + placement.duration)) {
        placement.time = videoCreator.snapTime(nextElement.globalTime - placement.duration);
        setPlacement({ ...placement });
      }

      videoCreator.applyPlacement(element, placement);

      if (volumeKeyPointsLocal && volumeKeyPointsLocal.length > 0) {
        runInAction(() => {
          videoCreator.applyVolumeKeyPoints(
            element.source.id,
            volumeKeyPointsLocal,
          );
        });
      }

    };

    const onTimeDurationChange = (time, trim_start, duration) => {
      // if time is shorter, get rid of any keypoints, and make sure last one is at the end
      // if time is longer, set last keypoint to be at the end
      const originalDuration = element.duration;
      const originalVolumeKeyPoints = volumeKeyPoints;
      if ((prevElement?.trimStart || 0) + (prevElement?.duration || 0) > trim_start) {
        const minTrimStart = (prevElement?.trimStart || 0) + (prevElement?.duration || 0);
        time = time + (minTrimStart - trim_start);
        duration = duration - (minTrimStart - trim_start);
        trim_start = minTrimStart;
        // todo videoCreator.snapTime may create a gap?
      } else if (nextElement && nextElement.trimStart < (trim_start + duration)) {
        const maxDuration = nextElement.trimStart - trim_start;
        duration = maxDuration;
      } else if (
        element.mediaDuration &&
        element.mediaDuration + element.trimStart < duration + trim_start
      ) {
        duration = element.mediaDuration;
      }

      if (prevElement && prevElement.globalTime + prevElement.duration > time) {
        // todo videoCreator.snapTime
        trim_start += prevElement.globalTime + prevElement.duration - time;
        duration -= prevElement.globalTime + prevElement.duration - time;
        time = prevElement.globalTime + prevElement.duration;
      }

      if (nextElement && nextElement.globalTime < time + duration) {
        duration = nextElement.globalTime - time;
      }

      setPlacement({
        time,
        trim_start,
        duration,
      });

      if (element.source.type === 'image') {
        videoCreator.handleResetPhotoHighlight(element, time, duration)
      }

      if ((element.source.type === 'audio' || element.source.type === 'video') && originalVolumeKeyPoints) {
        let newVolumeKeyPoints = JSON.parse(
          JSON.stringify(originalVolumeKeyPoints),
        );
        let trimStartDiff = 0;
        // shift all key points to the right or left if trim_start changed
        if (trim_start !== element.source.trim_start) {
          trimStartDiff = trim_start - (parseFloat(element.source.trim_start) || 0);

          newVolumeKeyPoints = newVolumeKeyPoints.map((keyPoint: any) => {
            return {
              time: `${parseFloat(keyPoint.time) - trimStartDiff} s`,
              value: keyPoint.value,
            };
          });

          // filter out all but one key point that has a negative time and make that the first key point
          // unless there is already a keypoint at exactly 0
          newVolumeKeyPoints = newVolumeKeyPoints.filter(
            (keyPoint: any) => parseFloat(keyPoint.time) >= 0,
          );
          const zeroKeyPoint = newVolumeKeyPoints.find(
            (keyPoint: any) => parseFloat(keyPoint.time) === 0,
          );
          if (!zeroKeyPoint) {
            newVolumeKeyPoints.unshift({
              time: '0 s',
              value: originalVolumeKeyPoints[0].value,
            });
          }
        }

        // if duration is shorter, get rid of any keypoints, and make sure last one is at the end
        if (duration < originalDuration && Math.abs((originalDuration - duration) - trimStartDiff) > 0.0001) {
          newVolumeKeyPoints = newVolumeKeyPoints.filter(
            (keyPoint: any) => {
              return parseFloat(keyPoint.time) <= parseFloat(duration);
            },
          );

          newVolumeKeyPoints.push({
            time: duration,
            value:
              originalVolumeKeyPoints[originalVolumeKeyPoints.length - 1].value,
          });
        } else {

          newVolumeKeyPoints[newVolumeKeyPoints.length - 1] = {
            time: duration,
            value:
              originalVolumeKeyPoints[originalVolumeKeyPoints.length - 1].value,
          };
        }

        setVolumeKeyPointsLocal(newVolumeKeyPoints);
      }
    };

    let color = '#3f4043';
    switch (element.source.type) {
      case 'audio':
        color = `#7828C8`;
        break;
      case 'video':
        color = `#006FEE`;
        break;
      case 'image':
        color = `#17C964`;
        break;
      case 'text':
      case 'composition':
        color = '#C8C8C8';
      default:
        break;
    }

    const timelineElementWidth =
      (placement.duration - element.exitDuration) * timelineScale;

    const isBroll = !videoCreator.isOriginalVideoElement(element.source)

    return (
      <Fragment>
        <Draggable
          cancel={'.volume-key-point'}
          onStart={(e, data) => {
            return { startX: data.x };
          }}
          onDrag={(e, data, context) => {
            const timeOffset = (data.x - context.startX) / timelineScale;

            setPlacement({
              time: videoCreator.snapTime(Math.max(element.localTime + timeOffset, 0)),
              duration: videoCreator.snapTime(element.duration),
              trim_start: element.source.trim_start,
            });
          }}
          onStop={(e, data, context) => {
            const timeOffset = (data.x - context.startX) / timelineScale;
            const time = Math.max(element.localTime + timeOffset, 0)
            if (element.source.type === 'image') {
              videoCreator.handleResetPhotoHighlight(element, time)
            }
            applyPlacementAndKeyPoints();
          }}
        >
          {(ref) => (
            <Bar
              ref={ref}
              active={active}
              color={color}
              audio={element.source.type === 'audio'}
              linkedAudio={type === 'linked-audio'}
              linkedVideo={
                element.source.type === 'video' &&
                type !== 'linked-audio' &&
                videoCreator.isOriginalVideoElement(element.source)
              }
              isText={
                element.source.type === 'text' ||
                element.source.type === 'composition'
              }
              style={{
                left: placement.time * timelineScale + PADDING_LEFT_COMPENSATION,
                width: timelineElementWidth,
              }}
              onClick={() => {
                videoCreator.sidebarOptions = SidebarOption.editing;
                videoCreator.setActiveElements(element.source.id);
              }}
            >
              {/* <Name>
                {element.source.name ??
                  element.source.type[0].toUpperCase() +
                    element.source.type.slice(1)}
              </Name> */}
              <ElementContainer
                imageUrl={
                  type !== 'linked-audio'
                    ? thumbnailUrl || element.source.source
                    : ''
                }
                audio={element.source.type === 'audio'}
                linkedAudio={type === 'linked-audio'}
                linkedVideo={
                  element.source.type === 'video' &&
                  type !== 'linked-audio'
                }
              >
                {element.source.type === 'audio' ? (
                  <TimelineAudioElement element={element} />
                ) : element.source.type === 'video' ? (
                  isBroll
                    ?
                    <TimelineBrollElement element={element} thumbnail={thumbnailUrl} />
                    :
                    <>
                      {type === 'linked-audio' ? (
                        <TimelineAudioElement element={element} />
                      ) : null}
                      {!thumbnailUrl ? (
                        <VideoPlayer src={element.source.source} />
                      ) : null}

                    </>
                ) : element.source.type === 'text' ||
                  element.source.type === 'composition' ? (
                  <TimelineTextElement element={element} />
                ) : null}
              </ElementContainer>
            </Bar>
          )}
        </Draggable>
        {
          type !== 'linked-audio' ? (
            <>
              <ResizeHandle
                element={element}
                side="start"
                time={element.localTime}
                onChange={onTimeDurationChange}
                onComplete={applyPlacementAndKeyPoints}
              />
              <ResizeHandle
                element={element}
                side="end"
                time={element.localTime + element.duration}
                onChange={onTimeDurationChange}
                onComplete={applyPlacementAndKeyPoints}
              />
            </>
          ) : null
        }
      </Fragment >
    );
  },
);

const Bar = styled.div<{
  color: string;
  audio: boolean;
  linkedAudio: boolean;
  linkedVideo: boolean;
  isText: boolean;
}>`
  position: absolute;
  z-index: unset;
  height: ${(props) => {
    if (props.linkedAudio) return '36px';
    if (props.isText) return '30px';
    if (props.linkedVideo) return '55px';
    return '70px';
  }};
  // padding: ${(props) => (props.audio ? '0px' : '0 15px')};
  -webkit-box-align: center;
  align-items: center;
  background: ${(props) => (props.linkedVideo) ? 'transparent' : props.color};
  // ${(props) => (props.active ? '' : 'filter: brightness(70%);')}
  border-top-left-radius: ${(props) => (!props.linkedAudio ? '6px' : '0px')};
  border-top-right-radius: ${(props) => (!props.linkedAudio ? '6px' : '0px')};
  border-bottom-left-radius: ${(props) => (!props.linkedVideo ? '5px' : '0px')};
  border-bottom-right-radius: ${(props) =>
    !props.linkedVideo ? '5px' : '0px'};
  border: ${(props) => ((props.linkedVideo || props.audio || props.linkedAudio) ? 'none' : '0.5px solid transparent')};
  border-left: 1px solid transparent;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
`;

const Name = styled.div`
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
`;

const ElementContainer = styled.div<{
  audio: boolean;
  imageUrl?: string;
  linkedAudio: boolean;
  linkedVideo: boolean;
}>`
  pointer-events: none;
  // ${(props) => (props.audio ? '-2px' : '0px')};
  // border: 1px solid red;
  height: 100%;
  width: ${(props) => (props.audio ? 'calc(100% + 4px)' : '100%')};
  background-image: ${(props) => `url("${props.imageUrl}")`};
  background-repeat: repeat-x;
  background-size: auto 100%;
  border-top-left-radius: ${(props) => (!props.linkedAudio ? '4px' : '0px')};
  border-top-right-radius: ${(props) => (!props.linkedAudio ? '4px' : '0px')};
  border-bottom-left-radius: ${(props) => (!props.linkedVideo ? '5px' : '0px')};
  border-bottom-right-radius: ${(props) =>
    !props.linkedVideo ? '5px' : '0px'};
  position: relative;
  // overflow: hidden;
`;

const VideoPlayer = styled.video`
  height: 100%;
  weight: 100%;
  object-fit: cover;
`;

const Image = styled.img`
  margin-top: 7px;
  // left: 50px;
  height: 80%;
  weight: 100%;
  object-fit: cover;
`;
