import React, { useState } from 'react';
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { observer } from 'mobx-react-lite';

export const SaveButton: React.FC = observer(() => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingFinal, setIsSavingFinal] = useState(false);

  const handleSaveData = async (asFinal: boolean) => {
    if (isSaving || isSavingFinal) return;
    setIsSaving(true);
    videoCreator.isSavingOrPublishing = true
    videoCreator.currentVideo!.punchList = videoCreator.punchListData!;
    const resetTimeline = false
    const withRenderer = true
    await videoCreator.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);
    setIsSaving(false);
    videoCreator.isSavingOrPublishing = false
  };

  return (
    <>
      <Main
        width={32}
        disabled={isSaving || isSavingFinal}
        onClick={async () => {
          await handleSaveData(false);
        }}
      >
        {isSaving ? 'Saving' : 'Save'}
      </Main>
      {/* <Main
        width={80}
        disabled={isSaving || isSavingFinal}
        onClick={async () => {
          await handleSaveData(true);
        }}
      >
        {isSavingFinal ? 'Saving' : 'Save As Final'}
      </Main> */}
    </>
  );
});

const Main = styled.div.attrs(
  (props: { disabled: boolean; width: number }) => props,
)`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2d093;
  color: #f2d093;
  font-size: 12px;
  cursor: pointer;
  margin: 10px 0 10px 5px;

  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.disabled ? 'background: #e67e22; cursor: wait;' : '')}
`;
