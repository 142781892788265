import React, { useRef, useState } from 'react';
import { VideoClip } from '../../types.ts/story';
import { getFormattedDurationForClip } from '../../utility/timeFormat';
import { videoCreator } from '../../stores/VideoCreatorStore';
import styled from 'styled-components';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Studio } from '../../types.ts/general';
import { EditIcon } from '../common/icons';
import ShareIcon from '../../svgs/ShareIcon';
import PencilIcon from '../../svgs/PencilIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import Modal from '../common/Modal';
import CopyContentModal from '../stage/CopyContentModal';
import SpinningLoading from '../SpinningLoading';
import CirclePlayIcon from '../../svgs/CirclePlayIcon';
import SocialShareStatus from './SocialShareStatus';
import SocialStats from './SocialStats';
import { getAvailableClipFormats } from '../../utility/general';

type VideoContent = VideoClip & { autoPlay?: boolean };
type Props = {
  video: VideoContent;
  toggleShare: React.Dispatch<
    React.SetStateAction<VideoContent | null | undefined>
  >;
};

const waitForRendererReady = (): Promise<void> => {
  console.log('Waiting for renderer to become ready...');
  return new Promise<void>((resolve) => {
    const checkRendererReady = () => {
      if (videoCreator.renderer?.ready) {
        console.log('Renderer is ready!');
        resolve();
      } else {
        console.log('Renderer not ready, checking again in 100ms...');
        setTimeout(checkRendererReady, 100);
      }
    };

    checkRendererReady();
  });
};

const VideoClipCard = (props: Props) => {
  const [openRenameDropdown, toggleRenameDropdown] = useState<boolean>(false);
  const [contentToEdit, toggleContentToEditModal] = useState<{
    type: 'rename';
  } | null>(null);
  const [renaming, setRenaming] = useState<boolean>(false);

  const renameRef = useRef<HTMLDivElement>(null);
  const { enableVideoClipEdit, enableSharing } = useFlagsCombination();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useOutsideAlerter(renameRef, () => {
    toggleRenameDropdown(false);
  });

  const { video, toggleShare } = props;
  const duration = getFormattedDurationForClip(video);
  const currentStory = videoCreator.story;
  const clips = [video, ...(video.associatedVideos || [])]
  const shareableClip = clips.find(clip => clip.shareableImageId)

  const getThumbnailUrl = () => {
    if (!shareableClip) {
      return video.thumbnail?.url ||
        currentStory?.thumbnail?.url ||
        video.videoFilePrimary?.video?.thumbnailUrl ||
        '';
    }
    if (video.shareableImageId) return video.thumbnail?.url
    return shareableClip.thumbnail?.url
  }

  let thumbnailUrl = getThumbnailUrl()
  const description = videoCreator.story?.description || '';

  const shouldFillImage = () => {
    if (!shareableClip) return true
    return !!video.shareableImageId
  }
  const fillImage = shouldFillImage()

  async function loadVideoModal(autoPlay = false) {
    video.autoPlay = autoPlay;
    toggleShare(video);

    if (video.id) {
      const formats = getAvailableClipFormats(video)
      videoCreator.videoLoaded = false;

      // if (formats.length && formats[0] !== "16:9") {
      //   const aspectRatio = formats[0] as "16:9" | "1:1" | "9:16"
      //   await videoCreator.loadVideoWithoutRendering(video.id);
      //   await waitForRendererReady();
      //   await videoCreator.loadVideoWithAspectRatio(aspectRatio);
      //   videoCreator.currentVideo!.id = video.id;
      // } else {
      await videoCreator.loadVideo(video.id);
      // }
      videoCreator.videoLoaded = true;
    }
  }

  return (
    <>
      <Card>
        <Icons>
          <DurationBadge>{duration.formattedDuration}</DurationBadge>
          {enableSharing && (<SocialShareStatus sharedContents={video._allReferencingSharedContents}></SocialShareStatus>)}
          <Kebab
            ref={renameRef}
            onClick={() => toggleRenameDropdown(!openRenameDropdown)}
          >
            <EllipsisIcon />
            <RenameDropdown isVisible={openRenameDropdown}>
              <RenameRow
                role="button"
                onClick={() => toggleContentToEditModal({ type: 'rename' })}
              >
                <span>Rename</span> <PencilIcon strokeColor="currentColor" />
              </RenameRow>
            </RenameDropdown>
          </Kebab>
        </Icons>
        <Title>{video.title}</Title>
        <ThumbnailWrapper>
          <Thumbnail src={thumbnailUrl} alt="thumbnail" fill={fillImage} />
          <div className="overlay"></div>
          <HoverPlayButton
            onClick={() => loadVideoModal(true)}
            className="play-icon"
          >
            <CirclePlayIcon />
          </HoverPlayButton>
        </ThumbnailWrapper>
        {enableSharing && (<SocialStats
          type="video"
          id={video.id}
          allReferencingSharedContents={video._allReferencingSharedContents}
          layout="wide"
        ></SocialStats>)}
        <Description>{description}</Description>
        <Buttons>
          {enableVideoClipEdit && (
            <EditButton
              onClick={() => {
                searchParams.set('videoId', video.id!);
                navigate({
                  pathname: `/${Studio.creator}`,
                  search: searchParams.toString(),
                });
              }}
            >
              <EditIcon />
              Edit
            </EditButton>
          )}

          <ShareButton onClick={() => loadVideoModal(false)}>
            <ShareIcon /> Preview
          </ShareButton>
        </Buttons>
      </Card>
      {contentToEdit && (
        <Modal
          isOpen={contentToEdit !== null}
          closeModal={() => toggleContentToEditModal(null)}
        >
          <CopyContentModal
            name={video.title! ?? ''}
            tag="Story"
            type={contentToEdit.type}
            onCancel={() => toggleContentToEditModal(null)}
            onSave={async (title) => {
              if (contentToEdit?.type === 'rename') {
                toggleContentToEditModal(null);
                setRenaming(true);
                await videoCreator.loadVideoWithoutRendering(video.id!);
                await videoCreator.renameVideo(video.id!, title, false);
                setRenaming(false);
              }
            }}
          />
        </Modal>
      )}
      {renaming && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text="Renaming video"
        />
      )}
    </>
  );
};

export default VideoClipCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  width: 392px;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #484848;
  position: relative;


  @media only screen and (max-width: 1000px){
    width: 380px;
  }

  @media only screen and (max-width: 900px){
    width: 360px;
  }

  @media only screen and (max-width: 450px){
    width: 350px;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Kebab = styled.div`
  cursor: pointer;
  margin-left: 3px;
  position: relative;
`;

const DurationBadge = styled.div`
  padding: 2px 5px 1px;
  background-color: #f178b6;
  color: black;
  width: fit-content;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0px;
  margin-right: auto;
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  gap: 5px;
`;

const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
`;

const ThumbnailWrapper = styled.div`
  margin: 5px 0;
  position: relative;
  height: 187px;
  display: flex;

  &:hover .overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover .play-icon {
    display: block;
    cursor: pointer;
  }
`;

const HoverPlayButton = styled.button`
  position: absolute;
  top: 40%;
  left: 40%;
  display: none;
  background-color: transparent;
  outline: none;
  border: none;
`;

const Thumbnail = styled.img<{ fill: boolean }>`
  width: ${props => props.fill ? '100%' : 'auto'};
  margin: 0 auto;
  height: 187px;
  object-fit: cover;
  border-radius: 10px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin: 10px 0;
`;

const EditButton = styled.button`
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #484848;
  background: none;
  cursor: pointer;

  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ShareButton = styled(EditButton)`
  background: #f2d093;
  color: black;
`;

const RenameDropdown = styled(ActionsWrapper)``;

const RenameRow = styled(ActionButton)`
  display: flex;
  gap: 25px;
`;
