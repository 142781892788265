import React, { useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import styled from 'styled-components';
import { numericalInputStyles } from '../../styles/mainStyle';
import PropertyDropdown from '../common/PropertyDropdown';

interface GenericAnimationSettingsProps {
  activeElement: ElementState;
  manualKaraoke?: boolean;
}

export const GenericAnimationSettings: React.FC<GenericAnimationSettingsProps> = (props) => {
  const { animations, color_overlay, time: elementTime } = props.activeElement.source;
  const originalVideo = videoCreator.renderer?.getElements().find(e => videoCreator.isOriginalVideoElement(e.source))

  const { color_overlay: videoOverlay } = originalVideo?.source || {}
  console.log("videoOverlay", videoOverlay)
  console.log('color_overlay', color_overlay)
  const enterAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'start' || keyframe.time === 0,
  );
  const exitAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'end',
  );

  const getDipBlack = (type: "enter" | 'exit') => {
    const overlays = (color_overlay || [])?.filter((c: any) => {
      if (type === 'enter') return c.easing === 'quadratic-in' || c.easing === 'ease-in'
      return c.easing.includes('quadratic-out') || c.easing.includes('ease-out')
    })


    if (overlays?.length) {
      const overlay = overlays.find((o: any) => o.time !== 0 && o.time !== 'end')
      if (!overlay) {
        return {
          exists: true,
          type: overlay.easing === 'quadratic-in' ? 'dip' : 'crossfade',
          duration: 1,
        }
      }

      if (overlay.easing === 'quadratic-in' || overlay.easing === 'ease-in') {
        return {
          exists: true,
          type: overlay.easing === 'quadratic-in' ? 'dip' : 'crossfade',
          duration: overlay.easing === 'ease-in' ? parseFloat(overlay.time) * 2 : overlay.time,
        }
      } else {

        // const elementDuration = props.activeElement.duration
        // const formatter = new Intl.NumberFormat('en-US', {
        //   style: 'decimal',
        //   maximumFractionDigits: 2,
        //   minimumFractionDigits: 0
        // })
        // let durationLength = overlay.easing === 'quadratic-out' ?
        //   elementDuration - parseFloat(overlay.time) :
        //   2 * (elementDuration - parseFloat(overlay.time))

        // let duration = formatter.format(durationLength)
        const duration = overlay.easing.replace('quadratic-out', '').replace('ease-out', '') || 1
        return {
          exists: true,
          duration,
          type: overlay.easing === 'quadratic-out' ? 'dip' : 'crossfade',
        }
      }
    }
    return null
  }

  const getEnterAnimation = () => {
    const enterDipToBlack = getDipBlack('enter')
    if (enterDipToBlack) return { animation: enterDipToBlack.type, duration: enterDipToBlack.duration }

    const enterAnimation = enterAnimationData?.type ?? 'none'
    const enterAnimationDuration = enterAnimationData?.duration;
    return { animation: enterAnimation, duration: enterAnimationDuration }
  }

  const getExitAnimation = () => {
    const exitDipToBlack = getDipBlack('exit')
    if (exitDipToBlack) return { animation: exitDipToBlack.type, duration: exitDipToBlack.duration }

    const exitAnimation = exitAnimationData?.type ?? 'none'
    const exitAnimationDuration = exitAnimationData?.duration;
    return { animation: exitAnimation, duration: exitAnimationDuration }
  }

  const enterAnimation = getEnterAnimation().animation
  const exitAnimation = getExitAnimation().animation

  const enterAnimationDuration = getEnterAnimation().duration;
  // const exitAnimationDuration = getExitAnimation().duration;

  const setAnimation = async (
    time: string | number | null,
    type: any,
    duration: number | null = null,
    background_effect: string | null = null,
  ) => {
    // Remove existing animation from list
    const newAnimations =
      animations?.filter(
        (keyframe: any) => {
          const frameTime = keyframe.time === 0 ? 'start' : keyframe.time
          return !(!frameTime && !time) && frameTime !== time
        },
      ) ?? [];


    let newOverlays = [...(color_overlay || [])]
    let newVideoOverlays = [...(videoOverlay || [])]

    if (type !== 'none' && type !== 'dip' && type !== 'crossfade') {
      const animation: any = {
        type: type === 'dip' ? 'fade' : type
      };

      if (!!time) {
        animation.time = time;
        animation.duration = duration || 1;
      }


      if (background_effect) {
        animation.background_effect = background_effect;
      }

      // Reverse animation when used as exit animation
      if (time === 'end') {
        animation.reversed = true;
      }

      if (time === 'start' && type === 'dip') {
        animation.scope = 'dip-entry'
        animation.reversed = true
      }

      if (time === 'end' && type === 'dip') {
        animation.scope = 'dip-exit'
        animation.reversed = false
      }

      newAnimations.push(animation);
    }

    if (type === 'dip') {
      if (time === 'start') {
        const existingOverlay = newOverlays.filter((c: any) => c.easing.includes('quadratic-out') || c.easing.includes('ease-out'))

        newVideoOverlays = newVideoOverlays.filter((v: any) => {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-start` && v.easing !== `${props.activeElement.source.id}-cross-fade-start-inc`
        })

        newOverlays = [
          {
            "time": 0,
            "easing": "quadratic-in",
            "value": "rgba(0,0,0,1)"
          },
          {
            "time": duration || 1,
            "easing": "quadratic-in",
            "value": "rgba(0,0,0,0)"
          },
          ...existingOverlay
        ]
      } else if (time === 'end') {

        const elementDuration = props.activeElement.duration
        const existingOverlay = newOverlays.filter((c: any) => c.easing === 'quadratic-in' || c.easing === 'ease-in')

        newVideoOverlays = newVideoOverlays.filter((v: any) => {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-end` && v.easing !== `${props.activeElement.source.id}-cross-fade-end-inc`
        })

        newOverlays = [
          ...existingOverlay,
          {
            "time": Math.min(Math.max(elementDuration - (duration || 1), 1), elementDuration),
            "easing": `quadratic-out${duration || 1}`,
            "value": "rgba(0,0,0,0)"
          },
          {
            "time": 'end',
            "easing": `quadratic-out${duration || 1}`,
            "value": "rgba(0,0,0,1)"
          }

        ]
      }
    } else if (type === 'crossfade' && originalVideo?.source.id !== props.activeElement.source.id) {
      if (time === 'start') {
        const existingOverlay = newOverlays.filter((c: any) => c.easing.includes('quadratic-out') || c.easing.includes('ease-out'))

        const existingVideoOverlays = newVideoOverlays.filter((v: any) => {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-start` && v.easing !== `${props.activeElement.source.id}-cross-fade-start-inc`
        })

        newVideoOverlays = [{
          "time": elementTime - (duration || 1) / 2,
          "easing": `${props.activeElement.source.id}-cross-fade-start`,
          "value": "rgba(0,0,0,0)"
        },
        {
          "time": elementTime,
          "easing": `${props.activeElement.source.id}-cross-fade-start`,
          "value": "rgba(0,0,0,1)"
        },
        {
          "time": elementTime + 0.1,
          "easing": `${props.activeElement.source.id}-cross-fade-start-inc`,
          "value": "rgba(0,0,0,0)"
        }, ...existingVideoOverlays]

        newOverlays = [
          {
            "time": 0,
            "easing": "ease-in",
            "value": "rgba(0,0,0,1)"
          },
          {
            "time": (duration || 1) / 2,
            "easing": "ease-in",
            "value": "rgba(0,0,0,0)"
          },
          ...existingOverlay
        ]
      } else if (time === 'end') {

        const elementDuration = props.activeElement.duration
        const existingOverlay = newOverlays.filter((c: any) => c.easing === 'quadratic-in' || c.easing === 'ease-in')
        const startTime = Math.min(Math.max(elementDuration - (duration || 1), 1), elementDuration)

        newOverlays = [
          ...existingOverlay,
          {
            "time": (startTime + elementDuration) / 2,
            "easing": `ease-out${duration || 1}`,
            "value": "rgba(0,0,0,0)"
          },
          {
            "time": 'end',
            "easing": `ease-out${duration || 1}`,
            "value": "rgba(0,0,0,1)"
          }
        ]

        const existingVideoOverlays = newVideoOverlays.filter((v: any) => {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-end` && v.easing !== `${props.activeElement.source.id}-cross-fade-end-inc`
        })

        newVideoOverlays = [
          {
            "time": elementTime + elementDuration - 0.1,
            "easing": `${props.activeElement.source.id}-cross-fade-end-inc`,
            "value": "rgba(0,0,0,0)"
          },
          {
            "time": elementTime + elementDuration,
            "easing": `${props.activeElement.source.id}-cross-fade-end`,
            "value": "rgba(0,0,0,1)"
          },
          {
            "time": elementTime + elementDuration + (duration || 1) / 2,
            "easing": `${props.activeElement.source.id}-cross-fade-end`,
            "value": "rgba(0,0,0,0)"
          }, ...existingVideoOverlays]
      }
    } else {

      if (newOverlays.length === 3) {
        const middleOverlay = newOverlays.find((o: any) => o.time !== 0 && o.time !== 'end')
        if (middleOverlay?.easing === 'quadratic-in') {
          newOverlays.splice(2, 0, { ...middleOverlay, easing: `quadratic-out${duration || 1}` })
        } else if (middleOverlay?.easing?.includes('quadratic-out')) {
          newOverlays.splice(1, 0, { ...middleOverlay, easing: 'quadratic-in' })
        }
      }

      newOverlays = newOverlays.filter((o: any) => {
        if (time === 'start') return o.easing.includes('quadratic-out') || o.easing === 'ease-in'
        return o.easing === 'quadratic-in' || o.easing === 'ease-in'
      })

      newVideoOverlays = newVideoOverlays.filter((v: any) => {
        if (time === 'start') {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-start` && v.easing !== `${props.activeElement.source.id}-cross-fade-start-inc`
        } else {
          return v.easing !== `${props.activeElement.source.id}-cross-fade-end` && v.easing !== `${props.activeElement.source.id}-cross-fade-end-inc`
        }
      })

      console.log("in else - newOverlays", newOverlays)
      console.log("in else - newVideoOverlays", newVideoOverlays)
    }

    await videoCreator.renderer?.applyModifications({
      // ...(newVideoOverlays.length && { [`${originalVideo!.source.id}.color_overlay`]: newVideoOverlays.sort((a: any, b: any) => a.time - b.time) }),
      // ...(newOverlays.length && { [`${props.activeElement.source.id}.color_overlay`]: newOverlays }),
      [`${originalVideo!.source.id}.color_overlay`]: newVideoOverlays.length ? newVideoOverlays.sort((a: any, b: any) => a.time - b.time) : '',
      [`${props.activeElement.source.id}.color_overlay`]: newOverlays.length ? newOverlays : '',
      [`${props.activeElement.source.id}.animations`]: newAnimations
    });
  };

  const animationTypes =
    props.activeElement.source.type === 'text'
      ? TextAnimationTypes
      : GenericAnimationTypes;

  const getInputValue = async (time: string, duration: string) => {
    let type = time === 'start' ? enterAnimation : exitAnimation;
    let itemDuration = Number(duration);

    if (isNaN(itemDuration)) itemDuration = 1;
    await setAnimation(time, type, itemDuration);
  };

  if (props.manualKaraoke) {
    return (
      <>
        <ManualKaraoke>
          <PropertyDropdown
            value={enterAnimation}
            onChange={async (value) => {
              await setAnimation(
                'start',
                value,
                enterAnimationDuration,
                'animated'
              );
            }}
            defaultValue="text-appear"
            values={[
              { caption: 'None', value: 'none' },
              ...Object.entries(animationTypes).map(([type, caption]) => ({
                caption: type === 'dip' ? 'Dip from black' : caption,
                value: type,
              }))
            ]}
          />

          {enterAnimationDuration ? (
            <div className="animation-length">
              <p>Animation Length (s)</p>
              <PropertyNumericalInput
                activeElement={props.activeElement}
                propertyName="start"
                manualKaraoke={true}
                defaultValue={
                  (enterAnimation === 'dip' || enterAnimation === 'crossfade') ? getEnterAnimation().duration ?? 1 :
                    props.activeElement?.source?.animations?.find(
                      ({ time }: any) => time === 'start' || time === 0,
                    )?.duration ?? '1'
                }
                unit="s"
                getInputValue={getInputValue}
              />
            </div>
          ) : null}
        </ManualKaraoke>
      </>
    );
  }

  return (
    <Content>
      <Item>
        <PropertyCaption>Enter Animation</PropertyCaption>
        <PropertyDropdown
          value={enterAnimation}
          onChange={async (value) => {
            await setAnimation(
              'start',
              value,
              enterAnimationDuration,
            );
          }}
          defaultValue={enterAnimation}
          values={[
            { caption: 'None', value: 'none' },
            ...Object.entries(animationTypes).map(([type, caption]) => ({
              caption: type === 'dip' ? 'Dip from Black' : caption,
              value: type,
            }))
          ]}
        />
      </Item>

      <Item>
        <PropertyCaption>Enter Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          activeElement={props.activeElement}
          propertyName="start"
          defaultValue={
            enterAnimation === 'dip' || enterAnimation === 'crossfade' ? getEnterAnimation().duration ?? 1 :
              props.activeElement?.source?.animations?.find(
                ({ time }: any) => time === 'start' || time === 0,
              )?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>

      <Item>
        <PropertyCaption>Exit Animation</PropertyCaption>

        <PropertyDropdown
          value={exitAnimation}
          onChange={async (value) => {
            await setAnimation(
              'end',
              value,
              enterAnimationDuration,
            );
          }}
          defaultValue={exitAnimation}
          values={[
            { caption: 'None', value: 'none' },
            ...Object.entries(animationTypes).map(([type, caption]) => ({
              caption: type === 'dip' ? 'Dip to Black' : caption,
              value: type,
            }))
          ]}
        />
      </Item>
      <Item>
        <PropertyCaption>Exit Animation Length (s)</PropertyCaption>
        {/* todo: rework setting current value */}
        <PropertyNumericalInput
          activeElement={props.activeElement}
          propertyName="end"
          defaultValue={
            exitAnimation === 'dip' || exitAnimation === 'crossfade' ? getExitAnimation().duration ?? 1 :
              props.activeElement?.source?.animations?.find(
                ({ time }: any) => time === 'end',
              )?.duration ?? '1'
          }
          unit="s"
          getInputValue={getInputValue}
          customStyles={numericalInputStyles}
        />
      </Item>

    </Content>
  );
};

// Each of these animations has its own options
// For reference: https://github.com/Creatomate/creatomate-node/tree/main/src/animations
const GenericAnimationTypes = {
  dip: 'dip',
  crossfade: 'Cross fade',
  fade: 'Fade',
  scale: 'Scale',
  slide: 'Slide',
  // 'rotate-slide': 'Rotate Slide',
  pan: 'Pan',
  // wipe: 'Wipe',
  // 'color-wipe': 'Color Wipe',
  // 'circular-wipe': 'Circular Wipe',
  'film-roll': 'Film Roll',
  // squash: 'Squash',
  // spin: 'Spin',
  // stripe: 'Stripe',
  flip: 'Flip',
  // shake: 'Shake',
  // bounce: 'Bounce',
  // wiggle: 'Wiggle',
  shift: 'Shift',
};

const TextAnimationTypes = {
  'text-appear': 'Text Appear',
  // 'text-scale': 'Text Scale',
  'text-slide': 'Text Slide',
  'text-reveal': 'Text Reveal',
  'text-fly': 'Text Fly',
  // 'text-spin': 'Text Spin',
  // 'text-wave': 'Text Wave',
  // 'text-counter': 'Text Counter',
  'text-typewriter': 'Text Typewriter',
  ...GenericAnimationTypes,
};

const ManualKaraoke = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;

  .animation-length {
    display: flex;
    flex-direction: column;
  }
`;

const Section = styled.div``
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`

const Item = styled.div`
`