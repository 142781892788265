import { observer } from 'mobx-react-lite';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Video } from '../../types.ts/story';
import styled from 'styled-components';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';

export const VideoVersionHistory: React.FC = observer(() => {
  function switchToVersion(versionId: string) {
    videoCreator.setCurrentVersionVideo(versionId);
  }

  let currentDateString: string;
  return (
    <Wrapper>
      <List>
        {!videoCreator.currentVideoVersions?.length && (
          <NoVersions>
            No saved versions for this clip yet. Save the first one.
          </NoVersions>
        )}
        {videoCreator.currentVideoVersions?.map((video) => {
          let showNewDay = false;
          const versionDate = new Date(video.meta.created_at);
          const versionDateString = versionDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          // check if video created_at date is today
          const isToday =
            new Date().toDateString() === versionDate.toDateString();
          if (!currentDateString || currentDateString !== versionDateString) {
            currentDateString = versionDateString;
            showNewDay = true;
          }

          return (
            <>
              {showNewDay ? (
                <Weekday>
                  {isToday
                    ? 'Today'
                    : versionDate.toLocaleDateString('en-Us', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'short',
                      })}
                </Weekday>
              ) : null}

              <ListElementContainer
                key={video.id}
                onClick={() => switchToVersion(video.versionId)}
              >
                <ListElement
                  isCurrent={
                    videoCreator.currentVideo?.versionId === video.versionId
                  }
                >
                  <VersionDate>
                    {new Date(video.meta.created_at).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      },
                    )}
                  </VersionDate>
                  {videoCreator.currentVideo?.versionId === video.versionId && (
                    <Tag>Current Version</Tag>
                  )}
                  <EditingInfo>
                    Modified by{' '}
                    {(video.editor as SimpleSchemaTypes.User).full_name ||
                      'Arbor Platform'}{' '}
                    as{' '}
                    {new Date(video.meta.created_at).toLocaleTimeString(
                      'en-US',
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      },
                    )}
                  </EditingInfo>
                </ListElement>
              </ListElementContainer>
            </>
          );
        })}
      </List>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
`;

const List = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const Weekday = styled.div`
  margin-top: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ListElementContainer = styled.div`
  padding: 8px 0;
  width: 100%;
  box-sizing: border-box;
  margin: 4px 0;
  cursor: pointer;
  border-bottom: 1px solid #404040;
`;

const ListElement = styled.div.attrs((props: { isCurrent: boolean }) => props)`
  padding: 12px 8px;
  position: relative;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  ${(props) =>
    props.isCurrent &&
    `
    background: #484848;
    padding: 8px;
  `}

  &:hover {
    border: 1px solid #404040;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #d7d7e1;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.isCurrent &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
    `}
  }
`;

const VersionDate = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
`;

const Tag = styled.div`
  color: #4ad067;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
`;

const EditingInfo = styled.div`
  color: #777;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
`;

const NoVersions = styled.div`
  padding: 0px 40px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
