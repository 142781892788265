import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { runInAction } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';

type Props = {
  item: { id: string; src: string; title?: string; description: string; thumbnailUrl?: string; };
  type?: "image" | 'video';
  isSelected: boolean;
  handleClick: () => void;
  handleRemove: () => void;
};
const FileListItem = (props: Props) => {
  const { item, isSelected = false, type = "image", handleClick = () => { } } = props;
  const [isActionClicked, setIsActionClicked] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOutsideAlerter(buttonRef, () => {
    setIsActionClicked(false);
  });

  return (
    <Entry key={item.id} onClick={handleClick} isSelected={isSelected}>
      <Wrapper
        hasImage={!!item.src}
        onClick={() => {
          runInAction(async () => {
            videoCreator.setActiveElements(item.id!.toString());
          });
        }}
      >
        {item.src ? <>
          {(type === "image" || item.thumbnailUrl) ?
            <Image src={item.thumbnailUrl || item.src} /> :
            <Video src={item.src} />}
        </> : (
          <TimelinePhotoIcon strokeColor="#828282" />
        )}
      </Wrapper>

      <Text>
        {item.title && <div className="quote">{item.title}</div>}
        <TextDescription>
          <div className="text">{item.description}</div>
          <div
            className="ellipsis"
            onClick={(e) => {
              e.stopPropagation();
              setIsActionClicked(true);
            }}
          >
            <EllipsisIcon width="12" fillColor="#484848" />
          </div>
        </TextDescription>
      </Text>
      <RemoveButton
        isVisible={isActionClicked}
        ref={buttonRef}
        onClick={async (e) => {
          e.stopPropagation();
          props.handleRemove();
          setIsActionClicked(false);
        }}
      >
        Remove <DeleteIcon />
      </RemoveButton>
    </Entry>
  );
};

export default FileListItem;

const Entry = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  height: 59px;
  position: relative;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: #4f4f4f;
      border-radius: 8px;
    `}
`;
const Text = styled.div`
  width: 65%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
  height: 59px;

  .quote {
    width: 100%;
    white-space: nowrap;
    color: #45d483;
    font-weight: 500;
    line-height: 19.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
`;

const TextDescription = styled.div`
  display: flex;
  gap: 3px;
  .text {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 38px;
    overflow-y: hidden;
  }
  .ellipsis {
    width: 10%;
    margin-left: auto;
    text-align: right;
  }
`;
const Wrapper = styled.div<{ hasImage: boolean }>`
  border: 1px solid #03041926;
  color: #4f4f4f;
  width: 100px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  // object-position: top;
`;

const RemoveButton = styled.button<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  right: 0;
  bottom: -28px;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 15px 12px;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  gap: 25px;
  z-index: 1;
  cursor: pointer;
`;
