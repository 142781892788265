import React, { Fragment } from 'react';
import { Select } from '../Select';
import styled from 'styled-components';

interface DefaultAnimationProps {
  time: 'start' | 'end';
  type: keyof typeof GenericAnimationTypes | keyof typeof TextAnimationTypes;
  duration: number;
  arbor_subType?: string;
  start_scale?: any;
  end_scale?: any;
}

interface AnimationSettingsProps {
  types: Array<'enter' | 'exit' | 'element'>;
  elementType: 'text' | 'image' | 'video';
  defaultAnimations: {
    enter?: DefaultAnimationProps;
    exit?: DefaultAnimationProps;
    element?: DefaultAnimationProps;
  };
  setAnimation: (
    time: string | number | null,
    type: any,
    subType?: any,
    speed?: any,
    duration?: number | null,
    background_effect?: string | null,
  ) => void;
  animations: {
    enter?: string;
    exit?: string;
    element?: string;
  };
  lines: keyof typeof InstagramAnimationLines;
  setLines: (lines: keyof typeof InstagramAnimationLines) => void;
}

export const AnimationSettingsUI: React.FC<AnimationSettingsProps> = (
  props,
) => {
  const {
    defaultAnimations,
    types,
    elementType,
    setAnimation,
    animations,
    setLines,
    lines,
  } = props;
  console.log('lines', lines, InstagramAnimationLines[lines]);
  const speedLookup = (keyframe: any) => {
    if (!keyframe || !keyframe.arbor_subType) return null;
    switch (keyframe.arbor_subType) {
      case 'zoomIn':
        return keyframe.start_scale;
      case 'zoomOut':
        return keyframe.end_scale;
      case 'panLeft':
      case 'panRight':
      case 'panUp':
      case 'panDown':
        return null;
      case 'panLeftWithZoom':
      case 'panRightWithZoom':
      case 'panUpWithZoom':
      case 'panDownWithZoom':
        return keyframe.end_scale;
      default:
        return null;
    }
  };

  const enterAnimationData = defaultAnimations.enter;
  const exitAnimationData = defaultAnimations.exit;

  const enterAnimation = enterAnimationData?.type ?? 'none';
  const exitAnimation = exitAnimationData?.type ?? 'none';

  const enterAnimationDuration = enterAnimationData?.duration;
  const exitAnimationDuration = exitAnimationData?.duration;

  // const elementAnimation = defaultAnimations.element?.arbor_subType || 'none';
  // const elementAnimationSpeed = speedLookup(defaultAnimations.element);

  const animationTypeLookup = (subType: string) => {
    switch (subType) {
      case 'zoomIn':
      case 'zoomOut':
        return 'scale';
      default:
        return 'pan';
    }
  };

  const TextAnimationTypesWithInstagramEffect = {
    instagram: 'Instagram',
    ...TextAnimationTypes,
  };

  const animationTypes =
    elementType === 'text'
      ? TextAnimationTypesWithInstagramEffect
      : GenericAnimationTypes;

  const renderAnimationSettings = (type: string) => {
    if (type === 'enter' || type === 'exit') {
      const animationDuration =
        type === 'enter' ? enterAnimationDuration : exitAnimationDuration;
      const currentAnimation =
        type === 'enter' ? enterAnimation : exitAnimation;
      const time = type === 'enter' ? 'start' : 'end';
      return (
        <Main>
          {/* <PropertyCaption>
            {type === 'enter' ? 'Animation' : 'Exit Animation'}
          </PropertyCaption> */}
          <SelectNoMargin
            defaultValue={currentAnimation}
            value={animations[type]}
            onChange={async (e) => {
              setAnimation(
                time,
                e.target.value,
                null,
                null,
                animationDuration,
                'animated',
              );
            }}
          >
            <option key="none" value="none">
              Basic Caption
            </option>
            {Object.entries(animationTypes).map(([type, caption]) => (
              <option key={type} value={type}>
                {caption}
              </option>
            ))}
          </SelectNoMargin>

          {animations[type] === 'instagram' ? (
            <SelectNoMargin
              defaultValue={lines}
              value={lines}
              onChange={(e) => {
                setLines(
                  parseInt(
                    e.target.value,
                  ) as keyof typeof InstagramAnimationLines,
                );
              }}
            >
              {Object.entries(InstagramAnimationLines).map(
                ([type, caption]) => (
                  <option key={type} value={type}>
                    {caption}
                  </option>
                ),
              )}
            </SelectNoMargin>
          ) : null}
          {/* implemented but disabled
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              defaultChecked={false}
              disabled={!animations?.[type]}
              onChange={(e) => {
                setAnimation(
                  time,
                  animations?.[type],
                  null,
                  null,
                  animationDuration,
                  e.target.checked ? 'animated' : null,
                );
              }}
            />
            <label>Animate background</label>
          </CheckboxLabel> */}
          {/* {animationDuration ? (
            <>
              <PropertyCaption>
                {type === 'enter' ? 'Enter' : 'Exit'} Animation Length (s)
              </PropertyCaption> */}
          {/* todo: rework setting current value */}

          {/* <NumericalInput
                defaultValue={defaultAnimations[type]?.duration ?? '1'}
                unit="s"
                onChange={(length) => {
                  setAnimation(time, currentAnimation, null, null, length);
                }}
              />
            </>
          ) : null} */}
        </Main>
      );
    }

    // not implemented yet

    // if (type === 'element') {
    //   return (
    //     <>
    //       <PropertyCaption>Element Animation</PropertyCaption>
    //       <SelectNoMargin
    //         value={elementAnimation}
    //         onChange={async (e) => {
    //           setAnimation(
    //             null,
    //             animationTypeLookup(e.target.value),
    //             e.target.value,
    //             elementAnimationSpeed,
    //           );
    //         }}
    //       >
    //         <option key="none" value="none">
    //           None
    //         </option>
    //         {Object.entries(ElementAnimationTypes).map(([type, caption]) => (
    //           <option key={type} value={type}>
    //             {caption}
    //           </option>
    //         ))}
    //       </SelectNoMargin>
    //       {elementAnimationSpeed ? (
    //         <>
    //           <PropertyCaption>Element Animation Speed</PropertyCaption>
    //           <SelectNoMargin
    //             value={elementAnimationSpeed}
    //             onChange={async (e) => {
    //               setAnimation(
    //                 null,
    //                 animationTypeLookup(elementAnimation),
    //                 elementAnimation,
    //                 e.target.value,
    //               );
    //             }}
    //           >
    //             {Object.entries(AnimationSpeed).map(([type, caption]) => (
    //               <option key={type} value={type}>
    //                 {caption}
    //               </option>
    //             ))}
    //           </SelectNoMargin>
    //         </>
    //       ) : null}
    //     </>
    //   );
    // }
  };

  return <>{types.map((type) => renderAnimationSettings(type))}</>;
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckboxLabel = styled.div``;

const Checkbox = styled.input``;

const PropertyCaption = styled.div`
  margin-top: 5px;
  color: #a3a5a5;
`;

const SelectNoMargin = styled(Select)`
  margin: 0;
  background-color: transparent;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: auto 10px;
  &:focus {
    border: 1px solid #484848;
    outline: 0;
  }
  width: 150%;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath d='M2 4.33008L6 8.33008L10 4.33008' fill='none' stroke='%23F2D093' stroke-width='1px'/%3E%3C/svg%3E");
`;

const ElementAnimationTypes = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  panLeft: 'Pan Left',
  panRight: 'Pan Right',
  panUp: 'Pan Up',
  panDown: 'Pan Down',
  panLeftWithZoom: 'Pan Left With Zoom',
  panRightWithZoom: 'Pan Right With Zoom',
  panUpWithZoom: 'Pan Up With Zoom',
  panDownWithZoom: 'Pan Down With Zoom',
};

interface AnimationSpeedProps {
  [index: string]: string;
}

const AnimationSpeed: AnimationSpeedProps = {
  '110%': 'Very Slow',
  '120%': 'Slow',
  '130%': 'Normal',
  '140%': 'Fast',
};

// Each of these animations has its own options
// For reference: https://github.com/Creatomate/creatomate-node/tree/main/src/animations
const GenericAnimationTypes = {
  fade: 'Fade',
  scale: 'Scale',
  slide: 'Slide',
  // 'rotate-slide': 'Rotate Slide',
  pan: 'Pan',
  // wipe: 'Wipe',
  // 'color-wipe': 'Color Wipe',
  // 'circular-wipe': 'Circular Wipe',
  'film-roll': 'Film Roll',
  // squash: 'Squash',
  // spin: 'Spin',
  // stripe: 'Stripe',
  flip: 'Flip',
  // shake: 'Shake',
  // bounce: 'Bounce',
  // wiggle: 'Wiggle',
  shift: 'Shift',
};

const TextAnimationTypes = {
  'text-appear': 'Appear',
  // 'text-scale': 'Text Scale',
  'text-slide': 'Slide',
  'text-reveal': 'Reveal',
  'text-fly': 'Fly',
  // 'text-spin': 'Text Spin',
  // 'text-wave': 'Text Wave',
  // 'text-counter': 'Text Counter',
  'text-typewriter': 'Typerwriter',
  ...GenericAnimationTypes,
};

export const InstagramAnimationLines = {
  1: '1 Line',
  2: '2 Lines',
  3: '3 Lines',
  4: '4 Lines',
  5: '5 Lines',
  6: '6 Lines',
  7: '7 Lines',
};
