import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { marked } from 'marked';
import { observer } from 'mobx-react-lite';
import { useUser } from '@clerk/clerk-react';
import lodash from 'lodash';

import { AiGeneratedContent } from '../../../types.ts/story';
import ContentShare from '../../common/ContentShare';
import RegenerateButton from '../../common/RegenerateButton';
import SpinningLoading from '../../SpinningLoading';
import { videoCreator } from '../../../stores/VideoCreatorStore';
import PhotoModal from '../../common/PhotosModal';
import PhotoModalTop from '../../common/PhotoModalTop';
import {
  BlogOrEmailContent,
  ImageKey,
  ImageWithType,
} from '../../../types.ts/general';
import Modal from '../../Modal';
import { useOutsideAlerter } from '../../transcript/useClickOutside';
import CreateBlogUI from '../../common/CreateBlogUI';
import ContentSelector from '../../common/ContentSelector';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { BlogProducer } from './BlogProducer';
import SavedBlogList from './SavedBlogList';
import { initializeContentStudioContent } from '../../../utility/general';
import ChatGPTService from '../../../services/ChatGPTService';

const gptService = new ChatGPTService()

const BlogView = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { story } = videoCreator;

  const [openMedia, toggleMedia] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<
    ImageWithType[ImageKey] | null
  >(null);
  const [currentImageCounter, setCurrentImageCounter] = useState<string | null>(
    null,
  );
  const [clickedImageId, setClickedImageId] = useState<string | null>(null);
  const [imageDragged, setImageDragged] = useState<{
    uuid: string;
    loc: 'down' | 'up';
    initialPos: { x: number; y: number };
  } | null>(null);
  const [imageDimension, setImageDimension] = useState<Record<
    string,
    Record<'width' | 'height' | 'x' | 'y' | 'pageX' | 'pageY', number>
  > | null>(null);
  const [dragPosition, setDragPosition] = useState<Record<
    string,
    Record<'xAxis' | 'yAxis', string>
  > | null>(null);
  const [imageSize, setImageSize] = useState<Record<
    string,
    Record<'width' | 'height', number>
  > | null>(null);
  const [isResizing, setIsResizing] = useState<string | null>(null);
  const [uuid, setUuid] = useState<string | null>(null);
  const [openDropdown, toggleDropdown] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [originalImageSize, setOriginalImageSize] = useState<
    Record<string, { width: number; height: number }>
  >({});
  const [pageHash, setPageHash] = useState<string | null>(null);
  const [stockLoading, setStockLoading] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const userInfo = useUser();
  const blogContent = videoCreator.selectedBlogContent;
  const {
    contentStudioEnableBlogSave,
    contentStudioEnableBlogShare,
    enableBlogRegeneration,
  } = useFlagsCombination();

  const saved_blogs = videoCreator.story?.savedBlog;
  const blogProducer = new BlogProducer();

  const entries = blogProducer.getEntries();
  const [currentContent, setCurrentContent] =
    useState<BlogOrEmailContent | null>(blogProducer.getCurrentEntry(entries));

  useEffect(() => {
    initializeContentStudioContent(story?.id, 'Blog');
  }, [story?.id]);

  useOutsideAlerter(contentRef, () => {
    toggleDropdown(null);
    // setClickedImageId(null);
  });

  const data = videoCreator.contentStudioGeneratedContent?.Blog;

  const followUpResponse = story?.aiResponse?.followUpResponses?.find(
    (response) => response.title?.toLowerCase() === 'blog',
  ) as AiGeneratedContent<'Blog'>;

  const hasBeenGenerated = data?.hasBeenGenerated;

  useEffect(() => {
    if (!currentContent?.id || !currentContent?.title || !saved_blogs) return;
    const defaultDragPos = blogProducer.initializeSavedBlogContent(
      currentContent.id,
      saved_blogs,
      dragPosition,
    );
    setDragPosition(defaultDragPos);
  }, [currentContent, saved_blogs]);

  const stock =
    videoCreator?.story?.storyAssets
      ?.filter((a) => a.responsiveImage)
      .map((a) => ({
        id: a.id,
        alt: a.title,
        url: a.url,
        width: a.width,
        height: a.height,
      })) || [];

  let generatedContent = data?.content?.response || '';
  let markedHtml: string | undefined = undefined;

  if (generatedContent && hasBeenGenerated) {
    const htmlData = blogProducer.produceHtmlFromGeneratedContent(
      generatedContent as string,
      clickedImageId,
      imageSize,
      openDropdown,
      isResizing,
      dragPosition,
    );
    markedHtml = htmlData;
  }

  if (currentContent?.id && saved_blogs) {
    blogProducer.produceSavedBlogContent(
      currentContent?.id,
      clickedImageId,
      imageSize,
      openDropdown,
      isResizing,
      saved_blogs,
      dragPosition,
    );
  }

  const handleResizeImage = useMemo(
    () =>
      lodash.debounce((e: MouseEvent<HTMLDivElement>) => {
        const data = blogProducer.resizeImage(
          e,
          imageDragged,
          imageDimension,
          originalImageSize,
          dragPosition,
        );
        if (data) {
          const { width, height, uuid, xAxisPos, yAxisPos } = data;
          setImageSize((prevSize) => ({
            ...prevSize,
            [uuid]: { width, height },
          }));

          const dragPos = { ...(dragPosition || {}) };

          dragPos[uuid] = { xAxis: xAxisPos, yAxis: yAxisPos };

          setDragPosition(dragPos);
          setIsResizing(uuid);
        }
      }, 15),
    [imageSize, imageDimension, imageDragged, setIsResizing, setImageSize],
  );

  const cancelResizing = () => {
    setImageDragged(null);
    setIsResizing(null);
  };

  const handleMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    cancelResizing();
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    blogProducer.handleMouseDown(
      e,
      setClickedImageId,
      setImageDimension,
      imageDimension,
      setImageDragged,
      cancelResizing,
      setUuid,
      setCurrentImageCounter,
      toggleDropdown,
    );
  };

  const handleContentClick = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    blogProducer.handleContentClick(
      e,
      setClickedImageId,
      setUuid,
      setCurrentImageCounter,
      toggleDropdown,
      toggleMedia,
      hasBeenGenerated,
      currentContent?.id,
    );
  };

  const handleReplaceAction = async () => {
    toggleMedia(false);
    await blogProducer.replaceImage(
      selectedImage,
      setStockLoading,
      hasBeenGenerated,
      currentImageCounter,
      currentContent?.id,
      uuid,
    );

    if (uuid && selectedImage?.width && selectedImage?.height) {
      const sizes = { ...imageSize };
      const w = parseInt(selectedImage.width);
      const h = parseInt(selectedImage.height);
      const width = Math.min(w, 800);
      const height = (h / w) * width;

      sizes[uuid] = {
        width,
        height,
      };
      setImageSize(sizes);
    }
  };

  const handleCopyRichText = () => {
    if ((!markedHtml || !hasBeenGenerated) && !blogProducer.formattedSavedBlog)
      return;
    const contentElement = contentRef?.current;
    if (!contentElement) return;

    const clonedContentElement = contentElement?.cloneNode(true) as HTMLElement;

    blogProducer.copyBlogContent(
      markedHtml!,
      hasBeenGenerated!,
      blogProducer.formattedSavedBlog!,
      clonedContentElement,
      contentElement,
    );
  };

  const handleSave = async () => {
    if (
      !story?.id ||
      ((!markedHtml || !hasBeenGenerated) && !blogProducer.formattedSavedBlog)
    ) {
      return;
    }

    setIsSaving(true);
    const contentElement = contentRef?.current;
    const clonedContentElement = contentElement?.cloneNode(true) as HTMLElement;
    const name = userInfo?.user?.fullName;

    const data = await blogProducer.saveBlogContent(
      name,
      clonedContentElement,
      contentElement,
    );

    if (data) {
      const { id, title, content } = data;
      setCurrentContent({
        id,
        title: data.title,
        content: data.content,
        username: data.username,
      });
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (!currentContent?.id || !saved_blogs || !generatedContent) return;
    const contentId = currentContent?.id;
    const data = saved_blogs?.[contentId]?.content;
    const generated_content = generatedContent as string;

    const hash = blogProducer.currentContentHash(generated_content, data);

    setPageHash(hash);
  }, [currentContent, saved_blogs, generatedContent]);

  useEffect(() => {
    const imageWrappers =
      contentRef.current?.querySelectorAll('.image-wrapper');

    const originalImageSize =
      blogProducer.extractOriginalDimension(imageWrappers);

    setOriginalImageSize(originalImageSize);
  }, [pageHash]);

  const spinnerText = () => {
    if (stockLoading) return 'Saving stock data...';
    if (isSaving) return 'Saving blog data...';
    if (hasBeenGenerated) return 'Regenerating blog...';
    return 'Generating blog...';
  };

  const htmlContent = blogProducer.serveContent(
    markedHtml,
    blogProducer.formattedSavedBlog,
  );

  return (
    <>
      {(isLoading || isSaving || stockLoading) && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={spinnerText()}
        />
      )}

      <Panel>
        <TopActions>
          {(Boolean(entries?.length) || blogContent) && (
            <ContentSelector
              entries={entries}
              currentContent={currentContent}
              setCurrentContent={setCurrentContent}
              type="savedBlog"
              enableCreateAndEditing={contentStudioEnableBlogSave}
            />
          )}
          {blogContent?.type && (
            <div style={{ marginLeft: 'auto' }} className="buttons">
              {enableBlogRegeneration && (
                <RegenerateButton
                  setIsLoading={setIsLoading}
                  storyId={story?.id}
                  transcriptionId={story?.transcription?.elementsJson?.id}
                  setImageSize={setImageSize}
                  promptTitle="Blog"
                  gptService={gptService}
                />
              )}
              {contentStudioEnableBlogShare && (
                <ContentShare type="Blog" onCopy={handleCopyRichText} />
              )}
              {contentStudioEnableBlogSave && (
                <Save onClick={handleSave}>Save</Save>
              )}
            </div>
          )}
        </TopActions>

        {!blogContent?.type && enableBlogRegeneration && (
          <Create>
            <CreateBlogUI
              setImageSize={setImageSize}
              supportedTitle="Blog"
              setIsLoading={setIsLoading}
              gptService={gptService}
            />
          </Create>
        )}

        {Boolean(entries?.length) && !blogContent && (
          <SavedBlogList
            entries={entries}
            currentContent={currentContent}
            setCurrentContent={setCurrentContent}
            type="savedBlog"
          />
        )}

        {openMedia && (
          <Modal
            isOpen={true}
            onClose={() => toggleMedia(false)}
            paddingHorizontal="0"
          >
            <PhotoModal
              TopComponent={
                <PhotoModalTop
                  title="blog"
                  isSelected={!!selectedImage}
                  replaceAction={handleReplaceAction}
                />
              }
              otherFields={['stock']}
              onCloseSelf={() => toggleMedia(false)}
              openPrevModal={() => { }}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              from="quotecard"
            />
          </Modal>
        )}
        {htmlContent ? (
          <Main>
            <Content
              ref={contentRef}
              onClick={handleContentClick}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleResizeImage}
              dangerouslySetInnerHTML={{
                __html: marked.parse(htmlContent),
              }}
            ></Content>
          </Main>
        ) : null}
      </Panel>
    </>
  );
});

export default BlogView;

const Panel = styled.div`
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const TopActions = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

const Create = styled.div`
  margin-top: 80px;
`;

const Main = styled.div`
  margin: 15px auto;
  position: relative;
  border-radius: 8px;
  border: 1px solid #484848;
  max-height: 800px;
  overflow: scroll;
`;
const Content = styled.div`
  color: #030419;
  margin: 40px auto;
  padding: 10px 50px;
  border-radius: 5px;
  user-select: none;

  max-width: 800px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  p strong {
    font-weight: 800;
  }
  & > p:has(img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  blockquote {
    font-style: italic;
  }

  a {
    color: #030419;
  }
`;

const Save = styled.button`
  border: 0;
  outline: 0;
  background-color: #17c964;
  gap: 8px;
  width: 148px;
  height: 40px;
  border-radius: 10px;
  padding: 10px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03041a;
  cursor: pointer;
`;
