import { useRef } from 'react';
import styled from 'styled-components';
import { useOutsideAlerter } from './useClickOutside';

export const WordReplacementModal: React.FC<{
  currentText: string;
  type: 'input' | 'textarea';
  submitText: (text: string) => void;
  discard: () => void;
  currentRange?: { start: number; end: number };
  changeRange?: (range: { start: number; end: number }) => void;
}> = ({ currentText, type, currentRange, changeRange, submitText, discard }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const rangeStartRef = useRef<HTMLInputElement>(null);
  const rangeEndRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => discard());

  return (
    <Wrapper ref={wrapperRef}>
      <Title>Edit Transcription</Title>
      {currentRange && (
        <Ranges>
          <RangeInput type="number" step={0.01} ref={rangeStartRef} defaultValue={currentRange.start} />
          <span>{'-->'}</span>
          <RangeInput type="number" step={0.01} ref={rangeEndRef} defaultValue={currentRange.end} />
        </Ranges>
      )}
      {type === 'textarea' ? (
        <TextArea
          defaultValue={currentText}
          ref={textAreaRef}
          onKeyDown={(e) => e.stopPropagation()}
        />
      ) : (
        <TextInput
          type="text"
          defaultValue={currentText}
          ref={inputRef}
          onKeyDown={(e) => e.stopPropagation()}
        />)}
      <Buttons>
        <CancelButton onClick={discard}>Cancel</CancelButton>
        <SubmitButton onClick={() => {
          submitText(
            (inputRef.current || textAreaRef.current)?.value!,
          )
          if (changeRange) {
            changeRange({
              start: Number(rangeStartRef.current?.value),
              end: Number(rangeEndRef.current?.value),
            });
          }
        }}>
          Save
        </SubmitButton>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 300px;
  min-height: 160px;
  height: fit-content;
  z-index: 1000;
  margin: auto;
  inset: 0;

  background: #292b2e;
  padding: 10px 25px 20px;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 16px;
`;

const Title = styled.p`
  margin: 0;
`;

const Ranges = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
  margin-top: 6px;
`;

const RangeInput = styled.input`
  width: 70px;
  border: medium;
  border-radius: 4px;
  font-size: 13px;
  height: 20px;
  outline: none;
`

const TextInput = styled.input`
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 6px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

const TextArea = styled.textarea`
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 8px 6px;
  border-radius: 4px;
  width: 100%;
  height: 75px;
  box-sizing: border-box;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled.button`
  width: 120px;
  font-size: 16px;
  padding: 10px 0;
  background-color: #2a85ff;
  color: white;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
`;

const CancelButton = styled.button`
  width: 120px;
  font-size: 16px;
  padding: 10px 0px;
  color: white;
  border-radius: 6px;
  background-color: transparent;
  border: 0.5px solid gray;
  cursor: pointer;
}
`;
