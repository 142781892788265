import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useOutsideAlerter } from './useClickOutside';
import { TranscriptContextMenuActions } from '../../types.ts/general';
import ReplaceIcon from '../../svgs/ReplaceIcon';
import CameraIcon from '../../svgs/CameraIcon';
import RemoveIcon from '../../svgs/RemoveIcon';
import MuteKaraokeIcon from '../../svgs/MuteKaraokeIcon';
import CutTextIcon from '../../svgs/CutTextIcon';
import PasteTextIcon from '../../svgs/PasteTextIcon';
import { SubtitlesContextMenuActions } from './TranscriptionSubtitles';

const CONTENT_PADDING = 40;

export const TranscriptContextMenu: React.FC<{
  positionXY: { x: number; y: number };
  actions: Array<TranscriptContextMenuActions | SubtitlesContextMenuActions>;
  actionCallback: (action: TranscriptContextMenuActions | SubtitlesContextMenuActions | 'close') => void;
  clientHeight: number;
}> = ({ positionXY, actions, actionCallback, clientHeight }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(wrapperRef, () => actionCallback('close'));

  function getMenuItem(action: TranscriptContextMenuActions
    | SubtitlesContextMenuActions) {
    switch (action) {
      case 'replace':
      case 'replaceSubtitle':
        return (
          <MenuItem
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
          >
            <span>Replace</span>
            <div className="icon">
              <ReplaceIcon width={'16'} height={'14'} strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'hideKaraoke':
        return (
          <MenuItem
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onMouseUp={(e) => {
              actionCallback('hideKaraoke');
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span>Hide Karaoke</span>
            <div className="icon">
              <MuteKaraokeIcon strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'showKaraoke':
        return (
          <MenuItem
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onMouseUp={(e) => {
              actionCallback('showKaraoke');
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span>Show Karaoke</span>
            <div className="icon">
              <MuteKaraokeIcon strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'removeText':
      case 'removeTextLoading':
      case 'removeSubtitle':
        return (
          <MenuItem
            disabled={action === 'removeTextLoading'}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
            className="remove-text"
          >
            <span>Remove Text</span>
            <div className="icon">
              <RemoveIcon width={'16'} height={'16'} strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'removePhoto':
      case 'removePhotoLoading':
        return (
          <MenuItem
            disabled={action === 'removePhotoLoading'}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
            className="remove-photo"
          >
            <span>Remove Photo</span>
            <div className="icon">
              <RemoveIcon strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'addPhoto':
      case 'addPhotoLoading':
        return (
          <MenuItem
            disabled={action === 'addPhotoLoading'}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
            className="add-photo"
          >
            <span>Add Photo</span>
            <div className="icon">
              <CameraIcon />
            </div>
          </MenuItem>
        );

      case 'restore':
        return (
          <MenuItem
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
          >
            <span>{action.charAt(0).toUpperCase() + action.slice(1)}</span>
            <div className="icon">
              <ReplaceIcon width={'16'} height={'14'} strokeColor="currentColor" />
            </div>
          </MenuItem>
        );
      case 'cutText':
      case 'splitSubtitle':
        return (<MenuItem
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => actionCallback(action)}
        >
          <span>{action === 'cutText' ? 'Cut Text' : 'Split Text'}</span>
          <div className="icon">
            <CutTextIcon width={'16'} height={'15'} strokeColor="currentColor" />
          </div>
        </MenuItem>)
      case 'pasteTextBefore':
      case 'pasteTextAfter':
      case 'joinSubtitle':
        return (
          <MenuItem
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => actionCallback(action)}
          >
            <span>
              {action === 'pasteTextBefore'
                ? 'Paste Text'
                : action === 'pasteTextAfter'
                  ? 'Paste After'
                  : 'Append to previous'}
            </span>
            <div className="icon">
              <PasteTextIcon
                width={'16'}
                height={'14'}
                strokeColor="currentColor"
              />
            </div>
          </MenuItem>
        );
    }
  }
  const contentHeight = clientHeight + CONTENT_PADDING;

  return (
    <MenuWrapper
      positionXY={positionXY}
      contentHeight={contentHeight}
      ref={wrapperRef}
      onMouseUp={(e) => e.stopPropagation()}
    >
      {actions.map((action) => (
        <div key={action}>{getMenuItem(action)}</div>
      ))}
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div.attrs(
  (props: { positionXY: { x: number; y: number }; contentHeight: number }) =>
    props,
)`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;

  left: ${(props) => props.positionXY.x}px;

  ${(props) =>
    props.positionXY.y <= 430 &&
    css`
      top: ${props.positionXY.y}px;
    `}

  ${(props) =>
    props.positionXY.y > 430 &&
    css`
      bottom: ${props.contentHeight - props.positionXY.y}px;
    `}
`;

const MenuItem = styled.div<{
  disabled?: boolean;
}>`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }

  &.add-photo:hover {
    background-color: #17c964;
  }
  &.remove-photo:hover {
    background-color: #ef5d6f;
  }

  &.remove-text:hover {
    background-color: #ef5d6f;
  }

  border-radius: 8px;

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }

  ${(props) => (props.disabled ? 'cursor: wait; opacity: 0.5;' : '')}
`;
