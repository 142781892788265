import { FC, useState } from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Story } from '../../types.ts/story';
import { ImageKey, ImageWithType } from '../../types.ts/general';
import Modal from '../Modal';
import PhotoModal from '../common/PhotosModal';
import PhotoModalTop from '../common/PhotoModalTop';
import { runInAction } from 'mobx';
import SpinningLoading from '../SpinningLoading';
import { saveAssetToDato } from '../../utility/general';

interface ImagePresetProps {
  activeElement: ElementState;
  url: string;
  story?: Story;
}

export const ImagePreset: FC<ImagePresetProps> = (props) => {
  // create local state for openModal
  const [replacePhotoModal, toggleReplacePhotoModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageWithType[ImageKey] | null
  >(null);

  const handleReplacePhoto = async () => {
    toggleReplacePhotoModal(false);
    if (!selectedImage?.url) return
    const url = await saveAssetToDato(selectedImage)

    await videoCreator.renderer?.applyModifications({
      [`${props.activeElement.source.id}.source`]: url,
    });

    //Replace item in punchlist, if it exists.
    if (videoCreator.punchListData?.length) {
      const punchListIdx = videoCreator.punchListData.findIndex(p => p.id === props.activeElement.source.id)
      if (punchListIdx > -1) {
        runInAction(() => {
          videoCreator.punchListData![punchListIdx].artifactSrc = url
        })
      }
    }
  };

  const getDescription = () => {
    if (props.activeElement.source.type === 'image') {
      const photoAssets = [
        ...(videoCreator.story?.storyArtifacts || []),
        ...(videoCreator.story?.storyAssets || []),
        ...(videoCreator.story?.aiPhotos || [])
      ]
      return photoAssets.find(p => p.responsiveImage?.srcSet === props.url ||
        p.url === props.url)?.title
    }
    return ''
  }

  return (
    <>
      <Main>
        {videoCreator.savingStockPhoto && <SpinningLoading
          text="Saving stock image" positionTop="20px" />}
        <ImageWrapper>
          <ImageOverlay />
          <Image src={props.url} alt="image present" />
          <ReplaceButton
            onClick={() => toggleReplacePhotoModal(true)}>Replace</ReplaceButton>
        </ImageWrapper>

        <Description>{getDescription()}</Description>
      </Main>
      {/* {openModal ? (
        <ScrollableArea>
          <ArtifactsAndAssets selectedAssetFunction={setElementSource} />
        </ScrollableArea>
      ) : null} */}

      {replacePhotoModal && (
        <Modal
          isOpen={true}
          onClose={() => toggleReplacePhotoModal(false)}
          paddingHorizontal="0"
        >
          <PhotoModal
            TopComponent={
              <PhotoModalTop
                replaceAction={handleReplacePhoto}
                title="Swap or search for a new image."
                isSelected={!!selectedImage}
                origin="transcript"
              />
            }
            showDescription
            otherFields={['ai', 'stock']}
            onCloseSelf={() => toggleReplacePhotoModal(false)}
            openPrevModal={() => { }}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            from="transcript"
            searchBarRadius="10px"
          />
        </Modal>
      )}
    </>
  );
};

const Main = styled.div`
  position: relative;
  margin: 15px 0;
  // height: 80px;
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 50%)
    );
  }
`;

const Image = styled.img`
  width: 107px;
  height: 70px;
  border-radius: inherit;
  background-size: cover;
  object-fit: cover;
`

const ImageWrapper = styled.div`
  width: 107px;
  height: 70px;
  border-radius: 8px;
  position: relative;
`
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3)
`

const ReplaceButton = styled.button`
  outline: none;
  border: 1px solid #F2D093;
  border-radius: 4px;
  width: 55px;
  height: 28px;
  position: absolute;
  color: #F2D093;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.1px;
  text-align: center;
  background-color: transparent;
  top: 21px;
  left: 26px;
  z-index: 3;
  cursor: pointer;
`

const Description = styled.span`
  font-color: #D7D7E1;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`

const ScrollableArea = styled.div`
  width: 90%;
  padding: 0 10px 15px;
  display: flex;
  flex-direction: column;
`;
