import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { PropertyCaption } from './PropertyCaption';
import PropertyDropdown from '../common/PropertyDropdown';

interface ElementAnimationSettingsProps {
    activeElement: ElementState;
}

export const ElementAnimationSettings: React.FC<ElementAnimationSettingsProps> = (props) => {
    const { animations } = props.activeElement.source;

    const speedLookup = (keyframe: any) => {
        if (!keyframe || !keyframe.arbor_subType) return null;
        switch (keyframe.arbor_subType) {
            case 'zoomOut':
                return keyframe.start_scale || '130%';
            case 'zoomIn':
                return keyframe.end_scale || '130%';
            case 'panLeft':
            case 'panRight':
            case 'panUp':
            case 'panDown':
                return keyframe.end_scale || '130%';
            case 'panLeftWithZoom':
            case 'panRightWithZoom':
            case 'panUpWithZoom':
            case 'panDownWithZoom':
                return keyframe.end_scale || '130%';
            default:
                return null;
        }
    };

    const elementAnimation =
        animations?.find((keyframe: any) => !!keyframe.arbor_subType)
            ?.arbor_subType ?? 'none';
    const elementAnimationSpeed = speedLookup(
        animations?.find((keyframe: any) => !!keyframe.arbor_subType),
    );

    const animationTypeLookup = (subType: string) => {
        switch (subType) {
            case 'zoomIn':
            case 'zoomOut':
                return 'scale';
            case 'none':
                return 'none'
            default:
                return 'pan';
        }
    };

    const setAnimation = async (
        subType: any = null,
        speed: any = null,
    ) => {
        // Remove existing animation from list
        const newAnimations = animations?.filter((keyframe: any) => keyframe.time,
        ) ?? [];

        const type = animationTypeLookup(subType)

        if (type !== 'none') {
            const animation: any = { type };

            if (!!subType) {
                animation.arbor_subType = subType;
                animation.scope = 'element';
                animation.easing = 'linear';
                switch (subType) {
                    case 'zoomIn':
                        animation.start_scale = '100%';
                        animation.end_scale = speed || '130%';
                        animation.easing = 'quadratic-out'
                        animation.fade = false;
                        break;
                    case 'zoomOut':
                        animation.start_scale = speed || '130%';
                        animation.end_scale = '100%';
                        animation.easing = 'quadratic-in'
                        animation.fade = false;
                        break;
                    case 'panLeft':
                        animation.start_x = '100%';
                        animation.start_y = '50%';
                        animation.end_x = '0%';
                        animation.end_y = '50%';
                        animation.fade = false;
                        animation.start_scale = speed || '130%';
                        animation.end_scale = speed || '130%';
                        break;
                    case 'panRight':
                        animation.start_x = '0%';
                        animation.start_y = '50%';
                        animation.end_x = '100%';
                        animation.end_y = '50%';
                        animation.fade = false;
                        animation.start_scale = speed || '130%';
                        animation.end_scale = speed || '130%';
                        break;
                    case 'panUp':
                        animation.start_x = '50%';
                        animation.start_y = '100%';
                        animation.end_x = '50%';
                        animation.end_y = '0%';
                        animation.fade = false;
                        animation.start_scale = speed || '130%';
                        animation.end_scale = speed || '130%';
                        break;
                    case 'panDown':
                        animation.start_x = '50%';
                        animation.start_y = '0%';
                        animation.end_x = '50%';
                        animation.end_y = '100%';
                        animation.fade = false;
                        animation.start_scale = speed || '130%';
                        animation.end_scale = speed || '130%';
                        break;
                    case 'panLeftWithZoom':
                        animation.start_x = '100%';
                        animation.start_y = '50%';
                        animation.end_x = '0%';
                        animation.end_y = '50%';
                        animation.start_scale = '100%';
                        animation.end_scale = speed || '130%';
                        animation.fade = false;
                        break;
                    case 'panRightWithZoom':
                        animation.start_x = '0%';
                        animation.start_y = '50%';
                        animation.end_x = '100%';
                        animation.end_y = '50%';
                        animation.start_scale = '100%';
                        animation.end_scale = speed || '130%';
                        animation.fade = false;
                        break;
                    case 'panUpWithZoom':
                        animation.start_x = '50%';
                        animation.start_y = '100%';
                        animation.end_x = '50%';
                        animation.end_y = '0%';
                        animation.start_scale = '100%';
                        animation.end_scale = speed || '130%';
                        animation.fade = false;
                        break;
                    case 'panDownWithZoom':
                        animation.start_x = '50%';
                        animation.start_y = '0%';
                        animation.end_x = '50%';
                        animation.end_y = '100%';
                        animation.start_scale = '100%';
                        animation.end_scale = speed || '130%';
                        animation.fade = false;
                        break;
                    default:
                        break;
                }
            }

            newAnimations.push(animation);
        }

        await videoCreator.renderer?.applyModifications({
            [`${props.activeElement.source.id}.animations`]: newAnimations,
        });
    };

    return (
        <Fragment>
            <PropertyCaption>Element Animation</PropertyCaption>
            <PropertyDropdown
                value={elementAnimation}
                onChange={async (value) => {
                    await setAnimation(
                        value,
                        elementAnimationSpeed,
                    );
                }}
                defaultValue={elementAnimation}
                values={[
                    { caption: 'None', value: 'none' },
                    ...Object.entries(ElementAnimationTypes).map(([type, caption]) => ({
                        caption,
                        value: type,
                    }))
                ]}
            />

            <>
                <PropertyCaption>Element Animation Speed</PropertyCaption>
                <PropertyDropdown
                    value={elementAnimationSpeed}
                    onChange={async (value) => {
                        await setAnimation(
                            elementAnimation,
                            value,
                        );
                    }}
                    defaultValue={elementAnimationSpeed}
                    values={[
                        { caption: 'None', value: null },
                        ...Object.entries(AnimationSpeed).map(([type, caption]) => ({
                            caption,
                            value: type,
                        }))
                    ]}
                />
            </>

        </Fragment>
    );
};

const ElementAnimationTypes = {
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    panLeft: 'Pan Left',
    panRight: 'Pan Right',
    panUp: 'Pan Up',
    panDown: 'Pan Down',
    panLeftWithZoom: 'Pan Left With Zoom',
    panRightWithZoom: 'Pan Right With Zoom',
    panUpWithZoom: 'Pan Up With Zoom',
    panDownWithZoom: 'Pan Down With Zoom',
};

interface AnimationSpeedProps {
    [index: string]: string;
}

const AnimationSpeed: AnimationSpeedProps = {
    '110%': 'Very Slow',
    '120%': 'Slow',
    '130%': 'Normal',
    '140%': 'Fast',
};
