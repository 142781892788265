type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const CommentsIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    strokeColor = 'currentColor',
    fillColor = 'none',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillColor}>
      <path d="M12.6309 12.3878C12.4165 12.3878 12.2427 12.214 12.2427 11.9996C12.2427 11.7851 12.4165 11.6113 12.6309 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.6309 12.3878C12.8453 12.3878 13.0191 12.214 13.0191 11.9996C13.0191 11.7851 12.8453 11.6113 12.6309 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.36039 12.3878C8.14598 12.3878 7.97217 12.214 7.97217 11.9996C7.97217 11.7851 8.14598 11.6113 8.36039 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.36035 12.3878C8.57476 12.3878 8.74858 12.214 8.74858 11.9996C8.74858 11.7851 8.57476 11.6113 8.36035 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.9014 12.3878C16.687 12.3878 16.5132 12.214 16.5132 11.9996C16.5132 11.7851 16.687 11.6113 16.9014 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.9011 12.3878C17.1155 12.3878 17.2894 12.214 17.2894 11.9996C17.2894 11.7851 17.1155 11.6113 16.9011 11.6113" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.625 2C10.8161 2.00057 9.04128 2.49175 7.48951 3.42125C5.93774 4.35075 4.66715 5.68374 3.81305 7.27826C2.95896 8.87277 2.55336 10.6691 2.63945 12.4759C2.72553 14.2827 3.30009 16.0323 4.30192 17.5384L2.625 22L8.24036 20.9846C9.59253 21.6453 11.0764 21.9924 12.5813 21.9998C14.0863 22.0073 15.5735 21.675 16.9322 21.0276C18.2908 20.3803 19.4859 19.4346 20.428 18.2611C21.3703 17.0877 22.0358 15.7167 22.3743 14.2503C22.713 12.7839 22.7164 11.26 22.3841 9.79218C22.0518 8.32433 21.3925 6.95042 20.4554 5.77286C19.5182 4.59528 18.3273 3.64445 16.9715 2.99119C15.6157 2.33793 14.1299 1.99911 12.625 2Z" stroke={strokeColor} stroke-linecap="round" stroke-linejoin="round" />

    </svg>
  );
};

export default CommentsIcon;
