type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const CutTextIcon = (props: Props) => {
  const {
    width = '16',
    height = '14',
    viewBox = '0 0 16 14',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <g>
        <path
          id="Vector"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.68994 8.57002 13 2.5"
          stroke-width="1"
        ></path>
        <path
          id="Vector_2"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.25 13c1.24264 0 2.25 -1.0074 2.25 -2.25 0 -1.24264 -1.00736 -2.25 -2.25 -2.25S1 9.50736 1 10.75C1 11.9926 2.00736 13 3.25 13Z"
          stroke-width="1"
        ></path>
        <path
          id="Vector_3"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.68994 5.42998 13 11.5"
          stroke-width="1"
        ></path>
        <path
          id="Vector_4"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.25 1C4.49264 1 5.5 2.00736 5.5 3.25S4.49264 5.5 3.25 5.5 1 4.49264 1 3.25 2.00736 1 3.25 1Z"
          stroke-width="1"
        ></path>
      </g>
    </svg>
  );
};

export default CutTextIcon;