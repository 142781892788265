import React, { Dispatch, SetStateAction } from 'react';
import InstagramIcon from '../../svgs/InstagramIcon';
import XIcon from '../../svgs/XIcon';
import FacebookIcon from '../../svgs/FacebookIcon';
import LinkedInIcon from '../../svgs/LinkedInIcon';
import styled from 'styled-components';
import TiktokIcon from '../../svgs/TiktokIcon';

type Props = {
  selectedSocials: string[];
  setSelectedSocials: Dispatch<SetStateAction<string[]>>;
};
const SocialClipButtonList = (props: Props) => {
  const { selectedSocials, setSelectedSocials } = props;
  const onSocialButtonClick = (social: string) => {
    if (selectedSocials.includes(social)) {
      setSelectedSocials((prev) => prev.filter((s) => s !== social));
    } else {
      setSelectedSocials((prev) => [social]); // [ ...prev, social]);
    }
  };
  return (
    <SocialButtons>
      <SocialButton
        active={selectedSocials.includes('linkedin')}
        onClick={() => onSocialButtonClick('linkedin')}
      >
        <LinkedInIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('facebook')}
        onClick={() => onSocialButtonClick('facebook')}
      >
        <FacebookIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('twitter')}
        onClick={() => onSocialButtonClick('twitter')}
      >
        <XIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('tiktok')}
        onClick={() => onSocialButtonClick('tiktok')}
      >
        <TiktokIcon fillColor="currentColor" />
      </SocialButton>
      <SocialButton
        active={selectedSocials.includes('instagram')}
        onClick={() => onSocialButtonClick('instagram')}
      >
        <InstagramIcon
          fillColor={
            selectedSocials.includes('instagram') ? '#F2D093' : '#030419'
          }
          strokeColor="currentColor"
        />
      </SocialButton>
    </SocialButtons>
  );
};

export default SocialClipButtonList;

const SocialButton = styled.div.attrs((props: { active: boolean }) => props)`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #484848;
  color: #484848;
  cursor: pointer;
  align-items: center;
  width: 38px;
  box-sizing: border-box;
  justify-content: center;

  ${(props) =>
    props.active &&
    `
    color: #030419;
    border-color: #F2D093;
    background-color: #F2D093;
  `}
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;
