import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../svgs/CloseIcon';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  customWidth?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
  alwaysShowCancel?: boolean;
};
const Modal = (props: Props) => {
  const {
    isOpen,
    onClose,
    children,
    customWidth = '500px',
    paddingHorizontal = '20px',
    paddingVertical = '20px',
    alwaysShowCancel = false
  } = props;

  if (!isOpen) return null

  return (
    <Main
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyDownCapture={(e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Content
        width={customWidth}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
        id="modal"
      >
        <Close alwaysShowCancel={alwaysShowCancel} onClick={onClose}>
          <CloseIcon />
        </Close>

        {children}
      </Content>
    </Main>
  );
};

export default Modal;

const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
`;

const Content = styled.div<{
  width: string;
  paddingHorizontal: string;
  paddingVertical: string;
}>`
  position: relative;
  background-color: #03041a;
  padding: ${(props) => props.paddingVertical}
    ${(props) => props.paddingHorizontal};
  border-radius: 10px;
  border: 1px solid #484848;
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.4);
  text-align: center;
  width: ${(props) => props.width};
`;

const Close = styled.button<{ alwaysShowCancel: boolean }>`
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  width: fit-content;
  z-index: ${props => props.alwaysShowCancel && '12'};
  &:active svg {
    fill: #fff;
  }
`;
