import { computed, makeAutoObservable, runInAction, toJS } from 'mobx';
import { v4 as uuid } from 'uuid';
import { RenderModalCtx } from 'datocms-plugin-sdk';
import { Renderer } from '../renderer/Renderer';
import { RendererState } from '../renderer/RendererState';
import { ElementState } from '../renderer/ElementState';
import { groupBy } from '../utility/groupBy';
import { deepClone } from '../utility/deepClone';
import { createGQLClient } from '../utility/dato';
import {
  ORIGINAL_VIDEO_TRANSCRIPTION_QUERY,
  VIDEO_RENDERING_STATUS_QUERY,
} from '../utility/gql';
import {
  TalkingPointContent,
  ImageKey,
  ImageWithType,
  SidebarOption,
} from '../types.ts/general';
import _isEqual from 'lodash/isEqual';
import Fuse from 'fuse.js';

import {
  AiGeneratedContent,
  Showcase,
  ContentViewData,
  ExtraElementData,
  Music,
  PhotoAssetData,
  PhotoTab,
  PunchListItem,
  Story,
  StoryDTO,
  Video,
  VideoVersion,
  VolumeKeyPoint,
  VideoClip,
  AssociatedVideo,
  Artifact,
  ShareableImageType,
} from '../types.ts/story';
import WaveformData from 'waveform-data';

import {
  TranscriptElement,
  TranscriptData,
  fetchTranscript,
  getClosestNotRemovedTextIndexToRight,
  getClosestRemovedIndexToLeft,
  getClosestRemovedIndexToRight,
  fetchWaveformData,
  fetchWaveformDataForAudioWithTitle,
  TranscriptChange,
  test_injectTranscriptionElementsInTimeline,
  TranscriptClipboard,
  generateSubtitles,
} from '../videoTranscriptionProcessor/utils';
import VideoTranscriptionProcessor from '../videoTranscriptionProcessor/VideoTranscriptionProcessor';
import {
  buildClient,
  Client,
  LogLevel,
  SimpleSchemaTypes,
} from '@datocms/cma-client-browser';
import { VideoRepository } from '../repositories/VideoRepository';
import { StoryRepository } from '../repositories/StoryRepository';
import { AssetRepository } from '../repositories/AssetRepository';
import { GraphQLClient } from 'graphql-request';
import { generateImagesWithGPT } from '../utility/processGPTData';
import { retry } from '../utility/general';
import ChatGPTService from '../services/ChatGPTService';
import KaraokeProducer, {
  KaraokeConfig,
} from '../videoTranscriptionProcessor/KaraokeProducer';
import { AlbumRepository } from '../repositories/AlbumRepository';
import ApiClient from '../apiClient/ApiClient';
import { MAX_CANVAS_WIDTH } from '../components/timeline/WaveForm';
import { head } from 'lodash';
import CaptionService from '../services/CaptionService';
import { getOriginalVideoTrackSourceDiff } from '../utility/timeline';

export const KARAOKE_TRACK_NUMBER = 32;
const DEBUG_TRANSCRIPTION_TIMELINE = false;
const DEFAULT_PREVIEW_VIDEO_RES = 'low';

type CurrentLoadedVideo = Video & { versionId?: string } & {
  editor?: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
  ai_generated_content?: Array<{
    attributes: { prompt: string; generated_content: string };
  }>;
};

class VideoCreatorStore {
  datoClient?: Client | ApiClient;
  gqlClient?: GraphQLClient;
  renderer?: Renderer = undefined;

  videoRepository?: VideoRepository;
  storyRepository?: StoryRepository;
  assetRepository?: AssetRepository;
  albumRepository?: AlbumRepository;

  videoTranscriptionProcessor: VideoTranscriptionProcessor;
  karaokeProducer: KaraokeProducer;

  state?: RendererState = undefined;
  stateReady = false;

  tracks?: Map<number, ElementState[]> = undefined;

  activeElementIds: string[] = [];
  selectedTrack: number = -1;

  isInitialized = false;

  isLoading = true;
  justLoaded = false;
  isSaving = false;
  isSavingOrPublishing = false;
  savingStockPhoto = false;

  isPlaying = false;
  whenPaused: number | null = null;

  time = 0;
  smootherTime = 0;
  keyPointsLastUpdateTime = 0;
  isShiftKeyDown = false;

  duration = 180;

  timelineScale = 100;

  defaultTimelineScale = 100;
  maxTimelineScale = 400;
  maxCanvasScale = 187;

  isScrubbing = false;

  // startingState?: RendererState = undefined;

  subtitleLoadingStatus: 'loading' | 'loaded' | 'failed' | 'none' = 'none';
  renderingStatus = ''; //todo video.status
  transcriptionLoadingStatus:
    | 'none'
    | 'loading'
    | 'failed'
    | 'loaded'
    | 'generation_failed' = 'none';

  storyId?: string;
  transcriptionId?: string;
  storyName?: string = '';

  datoContext: RenderModalCtx = {} as RenderModalCtx;

  originalVideoUrl?: string;
  originalVideoDuration: string = '0 s';
  originalVideoAudioUrl?: string;

  // transcriptionChanges: TranscriptChange[] = [];
  finalTranscriptionElements?: TranscriptElement[];
  originalTranscription?: TranscriptData;

  sidebarOptions = SidebarOption.photo;

  musicOptions?: Music['collection'] = undefined;
  musicProducerLoading: boolean = false;
  punchListGenerateCount: number = 0;

  selectedPhotoAssets = {
    tab: PhotoTab.artifact,
    resource: undefined,
    lastSelectedStock: undefined,
    lastSelectedAi: undefined,
    selectedId: undefined,
  } as PhotoAssetData;

  organization?: Showcase = undefined;
  story?: Story = undefined;
  originalWaveForm: WaveformData | null = null;
  resampledOriginalWaveForm: WaveformData | null = null;
  maxOriginalWaveformSample: number = 0;
  audioTracksData: Record<
    string,
    | {
        waveform: WaveformData;
        originalTrackDuration: number;
        resampledWaveform?: WaveformData;
      }
    | 'loading'
  > = {};

  audioContext: AudioContext | null = null;
  audioContextReady = false;

  currentVideo?: CurrentLoadedVideo;
  currentVideoVersions?: VideoVersion[];
  punchListLoading = false;
  addedPunchListItemId: string | null = null;
  fuse: any = undefined;

  unsavedShareableImages:
    | Omit<ShareableImageType, '_allReferencingSharedContents'>[]
    | null = null;

  contentStudioGeneratedContent?: ContentViewData;

  karaokeLoading = false;

  isPlayheadDragging: boolean = false;
  tempDragTime: number | null = null;

  replacementImages: {
    blog: Record<
      string,
      {
        value: ImageWithType[ImageKey] | null;
        isRemoved: boolean;
      }
    >;
    email: { value: ImageWithType[ImageKey] | null; isRemoved: boolean };
  } = { email: { value: null, isRemoved: false }, blog: {} };

  savedItemReplacementImages: {
    blogs: Record<
      number,
      Record<
        string,
        {
          value: ImageWithType[ImageKey] | null;
          isRemoved: boolean;
        }
      >
    >;
    emails: Record<
      number,
      { value: ImageWithType[ImageKey] | null; isRemoved: boolean }
    >;
  } = { emails: {}, blogs: {} };
  selectedBlogContent: {
    id?: string;
    type: 'generating' | 'generated' | 'saved';
    content?: string;
  } | null = null;

  toastState: {
    state?: 'success' | 'warning' | 'publishing' | 'loading' | 'error';
    message: string;
  } | null = null;
  showRefreshStoryForSocialProfile: boolean = false;
  pendingSharedContentIds: string[] = [];

  talkingPointContent: TalkingPointContent | null = null;
  videoLoaded: boolean = false;
  cachedAssets: Set<string> = new Set();
  timelineHeight: string = '30%';
  videoTrackGrouped: boolean = false;

  undoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];
  redoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];

  resetUndoRedo() {
    this.undoStack = [];
    this.redoStack = [];
    this.videoTranscriptionProcessor.resetUndoRedo();
  }

  undo() {
    if (this.undoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.undoStack.pop()!;
    undoCommand();
    this.redoStack.push({ undoCommand, redoCommand });
  }

  redo() {
    if (this.redoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.redoStack.pop()!;
    redoCommand();
    this.undoStack.push({ undoCommand, redoCommand });
  }

  //todo refactor
  photoDataForDato: Record<
    string,
    {
      type: 'quotes' | 'stock' | 'ai' | 'artifact';
      url: string;
      title?: string;
      alt?: string;
      cat?: string;
    } & (
      | {
          fileName: string;
        }
      | { uploadId: string }
    )
  > = {};

  punchListData: PunchListItem[] | null = null;

  refreshStory: number = 0;
  disableAiImageGenerate = false;

  constructor() {
    makeAutoObservable(this, {
      canRedo: computed,
      canUndo: computed,
    });
    this.videoTranscriptionProcessor = new VideoTranscriptionProcessor();
    this.karaokeProducer = new KaraokeProducer();
    this.videoTranscriptionProcessor.onFinalTranscriptionElementsChange = (
      elements,
    ) => {
      this.finalTranscriptionElements = elements;
      this.karaokeProducer.setTranscriptionElements(elements);
    };
  }

  async initializeWithStory(
    storyId: string,
    videoId?: string | null,
    showcaseSlug?: string | null,
  ) {
    if (!this.storyRepository)
      throw new Error('Story repository is not initialized');
    const story = await this.storyRepository.findOne(storyId);

    if (!story) throw new Error('Story not found. Cannot initialize data.');
    this.story = story;

    const organization = showcaseSlug
      ? story._allReferencingShowcases.find((s) => (s.slug = showcaseSlug))
      : story._allReferencingShowcases[0];
    this.organization = organization;

    this.storyId = story.id;
    this.storyName = story.title;
    this.originalVideoUrl = story.originalVideo.url;
    this.originalVideoAudioUrl = story.transcription?.audio?.url;
    this.originalVideoDuration = `${story?.originalVideo.video?.duration} s`;
    (this.transcriptionId = story.transcription?.elementsJson?.id),
      (this.isInitialized = true);

    // this.createAudioContext(0);
    if (!this.transcriptionId) {
      setTimeout(this.pollTranscriptionId.bind(this), 10000);
    }

    if (
      videoId &&
      this.story?.otherVideos?.find(
        (v) =>
          v.id === videoId ||
          v.associatedVideos.find((av) => av.id === videoId),
      )
    ) {
      await this.loadVideo(videoId);
    } else if (story.finalVideo?.id) {
      await this.loadVideo(story.finalVideo?.id);
    } else if (this.story?.otherVideos?.length) {
      await this.loadVideo(this.story.otherVideos[0].id!);
    } else {
      await this.createNewVideoFromSource();
    }
    await this.loadTranscription();
    await this.loadOriginalWaveformData();
  }

  async initializeWithShowcase(showcaseSlug: string) {
    if (!this.albumRepository)
      throw new Error('Album repository is not initialized');

    const showcase = await this.albumRepository.findOneBySlug(showcaseSlug);
    if (!showcase)
      throw new Error('Showcase not found. Cannot initialize data.');
    this.organization = showcase;
    const storyWithVideo = showcase.stories.find((s) => s.originalVideo);
    if (storyWithVideo) {
      await this.initializeWithStory(storyWithVideo.id, null, showcaseSlug);
    }
  }

  async initializeData(data: {
    storyId: string;
    videoId?: string | null;
    showcaseSlug?: string | null;
  }) {
    const { storyId, videoId, showcaseSlug } = data;

    if (!storyId && !showcaseSlug)
      throw new Error('Story id or showcase id are required');

    if (storyId) {
      await this.initializeWithStory(storyId, videoId, showcaseSlug);
    } else if (showcaseSlug) {
      await this.initializeWithShowcase(showcaseSlug);
    }
  }

  linkFunctions(ctx: RenderModalCtx & { clerkUserSessionToken?: string }) {
    runInAction(() => (this.datoContext = ctx)); // TODO: try without runInAction
    if (ctx.currentUserAccessToken) {
      runInAction(() => {
        this.datoClient = buildClient({
          apiToken: this.datoContext.currentUserAccessToken || null,
          environment: this.datoContext.environment,
          logLevel: LogLevel.NONE,
        });
      });
    } else {
      runInAction(() => {
        this.datoClient = new ApiClient({
          environment: this.datoContext.environment,
          authToken: ctx.clerkUserSessionToken || null,
        });
      });
    }

    runInAction(
      () =>
        (this.gqlClient = createGQLClient({
          includeDrafts: true,
          excludeInvalid: true,
          environment: this.datoContext.environment,
          authToken: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!, //  this.datoContext.currentUserAccessToken!,
        })),
    );
    runInAction(
      () =>
        (this.videoRepository = new VideoRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () =>
        (this.storyRepository = new StoryRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () =>
        (this.assetRepository = new AssetRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () => (this.albumRepository = new AlbumRepository(this.gqlClient!)),
    );
  }

  setCurrentVersionVideo(versionId: string) {
    const videoVersion = this.currentVideoVersions?.find(
      (videoVersion) => videoVersion.versionId === versionId,
    );
    if (videoVersion) {
      this.currentVideo = videoVersion;
      this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
      this.renderer?.setSource(videoVersion.videoSource);
      this.videoTranscriptionProcessor.applyChanges(
        toJS(videoVersion.transcriptionChanges) || [],
      );
      this.resetUndoRedo();
    }
  }

  async restoreVideoVersion(videoVersionId: string) {
    const itemVersions =
      await this.datoClient?.itemVersions.restore(videoVersionId);
    this.currentVideo = itemVersions?.[0] as unknown as Video & {
      versionId: string;
    };
    this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
    this.renderer?.setSource(this.currentVideo.videoSource);
    this.videoTranscriptionProcessor.applyChanges(
      toJS(this.currentVideo.transcriptionChanges) || [],
    );
    this.resetUndoRedo();
  }

  async cacheVideoSource(sourceUrl: string) {
    if (!this.renderer) {
      throw new Error('Renderer is not initialized');
    }

    console.log('Downloading media', sourceUrl);
    let blob = await fetch(sourceUrl).then((r) => r.blob());
    console.log('Media downloaded', sourceUrl);
    await this.renderer.cacheAsset(sourceUrl, blob);
    console.log('Video source cached', sourceUrl);
  }

  async loadVideo(videoId: string, resetTimeline = true) {
    if (!this.videoRepository) {
      throw new Error('Video repository is not initialized');
    }

    this.isLoading = true;
    try {
      this.currentVideoVersions =
        await this.videoRepository.getVersionsByVideoId(videoId);

      const currentVideoVersion = this.currentVideoVersions.find(
        (videoVersion) => videoVersion.meta.is_current,
      )!; //todo handle error

      // //debugger;

      await this.renderVideo(
        currentVideoVersion,
        resetTimeline,
        DEFAULT_PREVIEW_VIDEO_RES,
      );
      this.resetUndoRedo();
      console.log('Video loaded');
    } catch (err) {
      console.log('error loading video', err);
    } finally {
      this.isLoading = false;
    }
  }

  async loadVideoWithoutRendering(videoId: string, loadElements = false) {
    videoCreator.currentVideoVersions =
      await videoCreator.videoRepository?.getVersionsByVideoId(videoId);

    const currentVideoVersion = videoCreator.currentVideoVersions?.find(
      (videoVersion) => videoVersion.meta.is_current,
    )!;

    if (!currentVideoVersion.videoSource.height) {
      const height =
        this.story?.finalVideo?.videoFilePrimary?.height ||
        this.story?.originalVideo?.height;
      currentVideoVersion.videoSource.height = height;
    }
    this.currentVideo = currentVideoVersion;
    this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
    if (loadElements) {
      this.loadVideoElementsWithoutRendering(this.currentVideo);
    }
  }

  async loadVideoWithAspectRatio(aspectRatio: Video['aspectRatio']) {
    const parentVideo = this.currentVideo!.parentVideo || this.currentVideo;
    const associatedVideo =
      parentVideo?.aspectRatio === aspectRatio
        ? parentVideo
        : parentVideo!.associatedVideos.find(
            (video) => video.aspectRatio === aspectRatio,
          );

    if (associatedVideo) {
      await this.loadVideo(associatedVideo.id!);
      return;
    }

    // todo copy parent
    await this.copyCurrentVideo(this.currentVideo!.title);
    // parentVideo!.associatedVideos.push(this.currentVideo!);
    this.currentVideo!.aspectRatio = aspectRatio;
    this.currentVideo!.parentVideo = parentVideo;
    const [w, h] = aspectRatio.split(':').map((n) => parseInt(n));
    const width = this.currentVideo!.videoSource.height * (w / h);
    this.currentVideo!.videoSource.width = width;
    this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
    await this.renderer?.setSource(this.currentVideo!.videoSource);
  }

  async copyVideo(videoId: string, newTitle: string) {
    await this.loadVideo(videoId);
    this.copyCurrentVideo(newTitle);
  }

  async createNewVideoFromSource(
    newTitle?: string,
    newSource?: any,
    transcriptionChanges?: TranscriptChange[],
    extraElementData?: Video['extraElementData'],
    sourcePlatform: 'creator-studio' | 'content-studio' = 'creator-studio',
  ) {
    // console.log('creating new video');
    const video: Video = {
      title: newTitle || this.story!.title,
      videoSource:
        newSource ||
        this.getDefaultSource({ videoRes: DEFAULT_PREVIEW_VIDEO_RES }),
      videoStatus: 'editing',
      extraElementData: extraElementData || {},
      transcriptionChanges: transcriptionChanges || [],
      associatedVideos: [],
      aspectRatio: '16:9',
      sourcePlatform,
    };
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  async createNewVideoForContentClip(
    originalVideo: Video,
    sourcePlatform: 'creator-studio' | 'content-studio',
    clip: any,
  ) {
    const newTitle = `${originalVideo.title} - ${clip.theme}`;

    const source = originalVideo.videoSource || this.getDefaultSource();
    const element =
      source.elements.find((e: any) => e.type === 'video') ||
      this.getDefaultSource()?.elements[0];

    const video = {
      title: newTitle || this.story!.title,
      videoSource: {
        ...source,
        elements: [
          { ...element, duration: clip.duration, trim_start: clip.startTime },
        ],
      },
      videoStatus: 'editing',
      extraElementData: originalVideo.extraElementData,
      transcriptionChanges: originalVideo.transcriptionChanges,
      associatedVideos: [],
      aspectRatio: '16:9',
      sourcePlatform,
    } as CurrentLoadedVideo;
    this.currentVideo = video;
    this.loadVideoElementsWithoutRendering(video);

    await this.videoTranscriptionProcessor.cutTranscriptElementsForClip(
      clip.transcriptPosition.startIndex,
      clip.transcriptPosition.endIndex,
    );
    this.createUndoPointForTranscription();
  }

  async copyCurrentVideo(newTitle: string) {
    // console.log('copying video');
    const video = toJS(this.currentVideo)!;
    delete video.id;
    video.title = newTitle;
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  async renameVideo(videoId: string, newTitle: string, withRenderer = true) {
    console.log('renaming video', videoId);

    if (videoId === 'original_story') {
      await this.storyRepository?.update({
        id: this.story!.id,
        title: newTitle,
      });
      this.story!.title = newTitle;
      return;
    }

    if (videoId && this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveCurrentAndParentVideos(withRenderer);
    } else if (videoId) {
      await this.videoRepository?.updateVideo({ id: videoId, title: newTitle });
      if (this.currentVideo?.parentVideo?.id === videoId) {
        this.currentVideo!.parentVideo!.title = newTitle;
      }
    } else if (this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveStoryAndVideo(false, withRenderer);
    }

    const updatedVideos = (this.story?.otherVideos?.map((v) => {
      if (videoId === v.id) return { ...v, title: newTitle };
      return v;
    }) || []) as Video[];
    this.story!.otherVideos = updatedVideos;

    // this.resetUndoRedo();
  }

  private lockVideoElement() {
    // //debugger;
    const videoElement = this.currentVideo?.videoSource.elements.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('locking video element');
      videoElement.locked = true;
    }
  }

  private unlockVideoElement() {
    // //debugger;
    console.log('this.video', this.currentVideo);
    const videoElement = this.currentVideo?.videoSource?.elements?.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('unlocking video element');
      videoElement.locked = false;
    }
  }

  private updateCurrentRenderingStatus() {
    this.renderingStatus = this.currentVideo?.videoStatus || 'none';
    if (this.renderingStatus === 'rendering') {
      setTimeout(this.pollRenderingStatus.bind(this), 5000);
    }
  }

  isOriginalVideoElement(elementSource: any) {
    return (
      elementSource.type === 'video' &&
      (elementSource.source === this.originalVideoUrl ||
        elementSource.source.includes(
          this.story!.originalVideo.video.muxPlaybackId,
        ))
    );
  }

  private optimizeCurrentVideoSource(level: 'high' | 'low' | 'medium') {
    if (!this.currentVideo || !this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    this.replaceVideoSourceUrl(this.currentVideo.videoSource, level);
  }

  private getDimensionsForResLevel(
    currentDimensions: { width: number; height: number },
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    const originalVideoHeight = this.story!.originalVideo.height || 720;
    const { width, height } = this.story!.originalVideo; //currentDimensions;
    let newWidth, newHeight, scaleFactor;
    if (resLevel === 'high') {
      scaleFactor = Math.max(1, originalVideoHeight / 720);
    } else if (resLevel === 'low') {
      scaleFactor = Math.max(1, originalVideoHeight / 270);
    } else if (resLevel === 'medium') {
      scaleFactor = Math.max(1, originalVideoHeight / 480);
    } else {
      scaleFactor = 1;
    }
    newWidth = Math.ceil(width / scaleFactor);
    newHeight = Math.ceil(height / scaleFactor);
    return { width: newWidth, height: newHeight };
  }

  private getVideoSourceUrlForResLevel(
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    if (!this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    let sourceVideoUrl;

    if (resLevel === 'high') {
      sourceVideoUrl = this.story.originalVideo.video.mp4Url;
    } else if (resLevel === 'low') {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlLow;
    } else if (resLevel === 'medium') {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlMedium;
    }

    if (!sourceVideoUrl) {
      console.error('No video source found for optimization, using original');
      sourceVideoUrl = this.story.originalVideo.url;
    }
    return sourceVideoUrl;
  }

  private replaceVideoSourceUrl(
    source: any,
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    const videoSourceUrl = this.getVideoSourceUrlForResLevel(resLevel);
    source.elements.forEach((element: any) => {
      if (this.isOriginalVideoElement(element)) {
        element.source = videoSourceUrl;
      }
    });
    return source;
  }

  private async renderVideo(
    video: CurrentLoadedVideo,
    resetTimeline = true,
    resLevel: 'high' | 'low' | 'medium' = 'medium',
  ) {
    this.currentVideo = video;
    console.log('load video', video);
    this.updateCurrentRenderingStatus();
    // this.lockVideoElement();
    this.unlockVideoElement();
    this.punchListData = this.currentVideo!.punchList || [];
    this.loadVideoElementsWithoutRendering(video);
    // render video
    this.optimizeCurrentVideoSource(resLevel);
    const videoSourceUrl = this.getVideoSourceUrlForResLevel(resLevel);

    if (this.renderer?.ready && this.currentVideo) {
      this.stateReady = false;
      if (!this.cachedAssets.has(videoSourceUrl)) {
        await this.cacheVideoSource(videoSourceUrl);
        this.cachedAssets.add(videoSourceUrl);
      }
      await this.renderer?.setSource(this.currentVideo.videoSource);
      if (resetTimeline) {
        this.setTime(0, true);
      }
    }
  }

  private async loadVideoElementsWithoutRendering(video: CurrentLoadedVideo) {
    // apply transcription changes
    if (this.finalTranscriptionElements) {
      this.videoTranscriptionProcessor.applyChanges(
        toJS(this.currentVideo!.transcriptionChanges) || [],
      );
    }

    this.videoTranscriptionProcessor.setOriginalSource(video.videoSource);
    this.karaokeProducer.setKaraokeElementsFromSource(video.videoSource);
    if (video.extraElementData.karaokeConfig) {
      this.karaokeProducer.setConfig(
        video.extraElementData.karaokeConfig as KaraokeConfig,
      );
    }
  }

  async saveCurrentStory() {
    if (!this.story) return;
    if (!this.storyRepository) {
      throw new Error('Story repository is not initialized');
    }
    this.isSaving = true;
    try {
      const storyDTO = await this.attachPunchListPhotosToStory();
      await this.storyRepository.update(storyDTO);
      this.photoDataForDato = {};
    } catch (err) {
      console.log('error saving story', err);
    } finally {
      this.isSaving = false;
    }
  }

  async saveStoryAndVideo(
    asFinal: boolean = false,
    withRenderer = true,
    resetTimeline = true,
  ) {
    await this.saveCurrentAndParentVideos(withRenderer, resetTimeline);
    if (asFinal) {
      this.story!.finalVideo =
        this.currentVideo?.parentVideo ?? this.currentVideo;
    }
    await this.saveCurrentStory();
  }

  prepareCurrentVideoForSave = (withRenderer = true) => {
    if (!this.currentVideo) return;
    if (withRenderer) {
      this.currentVideo.videoSource = this.renderer!.getSource();
    }

    this.currentVideo.extraElementData.karaokeConfig =
      this.karaokeProducer.getKaraokeConfig();
    this.currentVideo.transcriptionChanges =
      this.videoTranscriptionProcessor.getTranscriptionChanges();

    const extraElementData = this.currentVideo.extraElementData || {};
    for (let element of Object.values(extraElementData)) {
      delete (element as ExtraElementData)?.punchListData;
    }

    this.currentVideo.punchList?.forEach((punchListItem) => {
      extraElementData[punchListItem.id!] = {
        ...(extraElementData[punchListItem.id!] || {}),
        punchListData: punchListItem,
      };
    });
    this.currentVideo.extraElementData = extraElementData;
  };

  async saveCurrentAndParentVideos(withRenderer = true, resetTimeline = true) {
    console.log('save video', this.currentVideo);
    // Early returns for exceptional cases
    if (
      !this.currentVideo ||
      !this.story ||
      !this.videoRepository ||
      !this.storyRepository
    ) {
      console.error('Invalid input or repositories not initialized');
      return;
    }

    this.isSaving = true;

    let savedVideoId;

    try {
      // Update current video properties
      this.prepareCurrentVideoForSave(withRenderer);

      // Save or update current video
      savedVideoId = await this.videoRepository.saveOrUpdateVideo({
        ...this.currentVideo,
        transcriptionText:
          this.videoTranscriptionProcessor.getFinalTranscriptionText(),
      });

      if (!this.currentVideo.id) {
        this.currentVideo.id = savedVideoId;

        // Handle parent video or story update (TODO move to saveStoryAndVideo ?)
        if (this.currentVideo.parentVideo) {
          await this.updateParentVideo();
        } else {
          await this.updateStoryVideos();
        }
      }
    } catch (error) {
      console.error('Error saving video', error);
    } finally {
      this.isSaving = false;
    }

    // Load saved video if available
    if (savedVideoId && withRenderer) {
      await this.loadVideo(savedVideoId, resetTimeline);
    }
  }

  // Helper methods for saveCurrentAndParentVideos
  async updateParentVideo() {
    this.currentVideo!.parentVideo!.associatedVideos.push(this.currentVideo!);
    this.currentVideo!.parentVideo!.id =
      await this.videoRepository!.saveOrUpdateVideo(
        this.currentVideo!.parentVideo!,
      );
    const parentStoryVideo = this.story!.otherVideos.find(
      (v) => v.id === this.currentVideo!.parentVideo!.id,
    );
    if (parentStoryVideo) {
      parentStoryVideo.associatedVideos.push(this.currentVideo!);
    }
  }

  // Helper methods for saveCurrentAndParentVideos
  async updateStoryVideos() {
    this.story!.otherVideos = [this.currentVideo!, ...this.story!.otherVideos];
  }

  async loadOriginalWaveformData() {
    if (!this.story?.transcription?.waveformData) return;
    try {
      const waveformData = await fetchWaveformData(
        this.story!.transcription!.waveformData!.id,
      );
      this.originalWaveForm = WaveformData.create(waveformData);
      this.maxTimelineScale = Math.min(
        this.maxTimelineScale,
        Math.floor(
          this.originalWaveForm.sample_rate / this.originalWaveForm.scale,
        ),
      );
      this.timelineScale = Math.min(this.timelineScale, this.maxTimelineScale);

      let maxSample = 0;
      const max_array = this.originalWaveForm.channel(0).max_array();
      for (let i = 0; i < max_array.length; i++) {
        if (max_array[i] > maxSample) {
          maxSample = max_array[i];
        }
      }
      this.maxOriginalWaveformSample = maxSample;

      // console.log('Waveform loaded, channels:', this.originalWaveForm.channels);
    } catch (e) {
      console.log('loadOriginalWaveformData error', e);
      throw e;
    }
  }

  async loadTranscription() {
    // console.log('loading transcription');
    if (!this.transcriptionId) {
      if (this.story?.transcription?.jobStatus === 'FAILED') {
        this.transcriptionLoadingStatus = 'generation_failed';
      }
      return;
    }
    if (!this.currentVideo)
      throw new Error(
        'Current video must be initialized before loading transcription',
      );
    try {
      this.transcriptionLoadingStatus = 'loading';
      const transcript = await fetchTranscript(this.transcriptionId);
      this.originalTranscription = transcript;
      // this.initializeFuse(transcript.elements);

      this.videoTranscriptionProcessor.setTranscriptionElements(
        structuredClone(transcript.elements),
      );

      this.videoTranscriptionProcessor.applyChanges(
        toJS(this.currentVideo.transcriptionChanges || []) || [],
      );
      this.transcriptionLoadingStatus = 'loaded';
    } catch (e) {
      this.transcriptionLoadingStatus = 'failed';
      console.log('loadTranscription error', e);
      throw e;
    }
  }

  async regenerateTranscription() {
    this.transcriptionId = '';
    try {
      await this.saveCurrentStory();
      await this.storyRepository!.deleteTranscription(this.story!.id);
      this.story!.transcription = undefined;
      this.finalTranscriptionElements = undefined;
      this.originalVideoAudioUrl = '';
      this.originalWaveForm = null;
      this.transcriptionLoadingStatus = 'loaded';
      this.pollTranscriptionId();
    } catch (err) {
      this.transcriptionLoadingStatus = 'failed';
    }
  }

  async requestSubtitlesForCurrentVideo() {
    if (!this.currentVideo || !this.finalTranscriptionElements) {
      throw new Error(
        'Current video or transcription elements not initialized',
      );
    }
    const transcriptionLanguage = this.originalTranscription!.language;
    try {
      this.subtitleLoadingStatus = 'loading';
      const subtitles = await generateSubtitles(
        this.finalTranscriptionElements.filter(
          (el) =>
            el.state !== 'removed' &&
            el.state !== 'cut' &&
            el.state !== 'muted',
        ),
        transcriptionLanguage,
      );

      if (subtitles) {
        this.currentVideo.subtitles = subtitles;
        this.subtitleLoadingStatus = 'loaded';
      }
      return subtitles;
    } catch (err) {
      console.log('error requesting subtitles', err);
      this.subtitleLoadingStatus = 'failed';
      return null;
    }
  }

  initializeFuse(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    this.fuse = new Fuse(sentences, {
      includeScore: true,
      keys: ['text'],
    });
  }

  getSentences(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    return sentences;
  }

  async disposeRenderer() {
    if (this.renderer) {
      console.log('dispose renderer');
      this.renderer.dispose();
      this.renderer = undefined;
    }
  }

  async applyChangesToGroupedTracks(
    prevState: RendererState,
    newState: RendererState,
  ) {
    const videoTrackChanges = getOriginalVideoTrackSourceDiff(
      prevState.elements,
      newState.elements,
    );
    console.log('videoTrackChanges', videoTrackChanges);
    if (Object.keys(videoTrackChanges).length !== 1) {
      console.log('No video track changes or more than one, not applying');
      return false;
    }
    const id = Object.keys(videoTrackChanges)[0];
    const trackChanges = videoTrackChanges[id];
    const track = trackChanges.track;
    const modificationObject: any = {};
    for (const element of newState.elements.filter(
      (el) => el.track === track && el.source.id !== id,
    )) {
      for (const key in trackChanges.changes) {
        modificationObject[`${element.source.id}.${key}`] =
          trackChanges.changes[key] || null;
      }
    }
    if (Object.keys(modificationObject).length > 0) {
      await this.renderer?.applyModifications(modificationObject);
      return true;
    }
    return false;
  }

  async initializeVideoPlayer(
    htmlElement: HTMLDivElement,
    mode: 'interactive' | 'player' = 'interactive',
    origin: string = 'creator-studio',
  ) {
    if (this.renderer) {
      this.renderer.dispose();
      this.renderer = undefined;
    }

    const renderer = new Renderer(
      htmlElement,
      mode,
      'public-juuabbc8amz25dcfhribv3ss',
    );

    renderer.onReady = async () => {
      console.log('Renderer ready');
      await renderer.setZoom('auto');
      await renderer.setCacheBypassRules([]);
      this.videoTranscriptionProcessor.setRenderer(renderer);
      this.karaokeProducer.setRenderer(renderer);
      if (this.currentVideo) {
        const videoSourceUrl = this.getVideoSourceUrlForResLevel(
          DEFAULT_PREVIEW_VIDEO_RES,
        );
        if (!this.cachedAssets.has(videoSourceUrl)) {
          await this.cacheVideoSource(videoSourceUrl);
          this.cachedAssets.add(videoSourceUrl);
        }
        await this.renderer!.setSource(this.currentVideo.videoSource);
      }
    };

    renderer.onLoad = async () => {
      runInAction(() => (this.isLoading = true));
    };

    renderer.onLoadComplete = async () => {
      runInAction(() => (this.isLoading = false));
    };

    renderer.onPlay = () => {
      runInAction(() => (this.isPlaying = true));
    };

    renderer.onPause = () => {
      runInAction(() => (this.isPlaying = false));
      runInAction(() => (this.whenPaused = new Date().getTime()));
    };

    renderer.onTimeChange = (time) => {
      if (
        origin === 'clip-player' &&
        this.whenPaused &&
        this.whenPaused - new Date().getTime() === 0
      ) {
        videoCreator.renderer?.play();
      }
      this.onRendererTimeChange(time);
    };

    renderer.onActiveElementsChange = (elementIds) => {
      runInAction(() => (this.activeElementIds = elementIds));
    };

    renderer.onStateChange = async (state) => {
      console.log('Renderer state change', state);
      const maxElementDuration = state?.elements.reduce(
        (acc, el) => Math.max(el.duration, acc),
        0,
      );

      if (this.state && this.videoTrackGrouped) {
        const applied = await this.applyChangesToGroupedTracks(
          this.state,
          state,
        );
        if (applied) return;
      }

      runInAction(() => {
        // populate missing field trimStart
        state.elements.forEach((element) => {
          element.trimStart = element.source.trim_start || 0;
        });
        this.state = state;
        this.stateReady = true;
        const originalVideoElement = this.state.elements.find((e) =>
          this.isOriginalVideoElement(e.source),
        );
        this.duration = originalVideoElement
          ? originalVideoElement.duration
          : state.duration;
        this.tracks = groupBy(state.elements, (element) => element.track);
        if (
          DEBUG_TRANSCRIPTION_TIMELINE &&
          process.env.REACT_APP_API_URL?.startsWith('http://localhost')
        ) {
          this.tracks.set(
            KARAOKE_TRACK_NUMBER,
            test_injectTranscriptionElementsInTimeline(
              this.finalTranscriptionElements || [],
            ),
          );
        }
        this.maxCanvasScale = Math.min(
          this.maxTimelineScale,
          MAX_CANVAS_WIDTH / maxElementDuration, // waveform canvas cannot be wider than 32767px, will not render
        );
      });
      for (const element of state.elements) {
        if (
          element.source.type === 'audio' &&
          !this.audioTracksData[element.source.source]
        ) {
          this.loadWaveformForSource({
            source: element.source.source,
            name: element.source.name,
          });
        }
      }
    };

    this.renderer = renderer;
    return true;
  }

  async loadWaveformForSource(source: { source: string; name: string }) {
    // console.log('loading waveform for source', source);
    this.audioTracksData[source.source] = 'loading';
    try {
      const { waveformJson, duration } =
        await fetchWaveformDataForAudioWithTitle(source.name);
      runInAction(() => {
        // console.log('waveform loaded', waveformJson);
        this.audioTracksData[source.source] = {
          waveform: WaveformData.create(waveformJson),
          originalTrackDuration: duration,
        };
      });
    } catch (err) {
      console.log('error loading waveform', err);
      delete this.audioTracksData[source.source];
    }
  }

  async onRendererTimeChange(time: number) {
    if (this.isScrubbing) return;
    const shouldApplyVolumeKeyPoints =
      Math.abs(time - this.keyPointsLastUpdateTime) > 1;
    if (time < this.time - 1 || Math.abs(time - this.smootherTime) > 1) {
      runInAction(() => (this.smootherTime = time));
    }
    runInAction(() => (this.time = time));
    if (!shouldApplyVolumeKeyPoints) return;
    // change the audio of clips dynamically based on VolumeKeyPoints
    this.state?.elements.forEach((element) => {
      const volumeKeyPoints = (
        this.currentVideo?.extraElementData[
          element.source.id
        ] as ExtraElementData | null
      )?.volumeKeyPoints;
      // console.log('Volume keypoints apply', volumeKeyPoints);
      // check if element is playing and has volumeKeyPoints
      if (
        element.localTime > time ||
        element.localTime + element.duration < time ||
        !volumeKeyPoints
      )
        return;

      // interpolate between two closest volumeKeyPoints to this time
      const relativeTime = time - element.localTime;
      const prevVolumeKeyPoint = volumeKeyPoints.reduce(
        (prev: any, curr: any) => {
          const currTime = parseFloat(curr.time);
          const prevTime = parseFloat(prev.time);
          return currTime < relativeTime &&
            relativeTime - currTime < relativeTime - prevTime
            ? curr
            : prev;
        },
        volumeKeyPoints[0],
      );
      const prevVolumeKeyPointIndex =
        volumeKeyPoints.indexOf(prevVolumeKeyPoint);
      const nextVolumeKeyPoint = volumeKeyPoints[prevVolumeKeyPointIndex + 1];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        prevVolume +
        ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
          (nextTime - prevTime);
      // console.log('element is playing', element.source.id, time, volume);

      if (volume < 100) {
        // console.log('changing volume');
        // todo does it create undo point?
        videoCreator.renderer?.setModifications({
          [`${element.source.id}.volume`]: volume,
        });
      }
    });
    runInAction(() => (this.keyPointsLastUpdateTime = time));
  }

  getMaxTrack(): number {
    return Math.max(
      ...(this.state?.elements.map((element) =>
        element.track < KARAOKE_TRACK_NUMBER ? element.track : 0,
      ) || []),
      1,
    );
  }

  async setTime(time: number, resetAnimations = false): Promise<void> {
    this.time = time;
    // console.log('setting time', time)
    // make sure time is on a single frame
    time = this.snapTime(time);
    // console.log('fixed time', time)

    if (resetAnimations) {
      this.smootherTime = time;
    }
    await this.renderer?.setTime(time);
  }

  setShiftDown(shiftDown: boolean) {
    this.isShiftKeyDown = shiftDown;
  }

  snapTime(time: number): number {
    if (this.isShiftKeyDown) {
      return Math.round(time * 24) / 24;
    } else {
      return time;
    }
  }

  async setDuration(time: number): Promise<void> {
    runInAction(() => (this.duration = time));

    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    const source = renderer.getSource(renderer.state);

    source.duration = time;

    await renderer.setSource(source, true);
    this.createDefaultUndoPoint();
  }

  createDefaultUndoPoint() {
    this.undoStack.push({
      undoCommand: () => this.renderer?.undo(),
      redoCommand: () => this.renderer?.redo(),
    });
  }

  createUndoPointForTranscription() {
    this.undoStack.push({
      undoCommand: () => this.videoTranscriptionProcessor.undo(),
      redoCommand: () => this.videoTranscriptionProcessor.redo(),
    });
  }

  async setActiveElements(...elementIds: string[]): Promise<void> {
    this.activeElementIds = elementIds;
    this.selectedTrack = -1;
    await this.renderer?.setActiveElements(elementIds);
    await this.renderer?.setActiveComposition(null);
  }

  getActiveElement(): ElementState | undefined {
    if (!this.renderer || this.activeElementIds.length === 0) {
      return undefined;
    }

    const id = videoCreator.activeElementIds[0];
    return this.renderer.findElement(
      (element) => element.source.id === id,
      this.state,
    );
  }

  async createElement(
    elementSource: Record<string, any>,
    undo: boolean = true,
  ): Promise<void> {
    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }
    const source = renderer.getSource(renderer.state);
    // check if top track is free
    const currTime = this.time;
    const maxTrack = this.getMaxTrack();
    const id = uuid();

    source.elements.push({
      id,
      track: maxTrack + 1,
      time: currTime,
      ...elementSource,
    });

    await renderer.setSource(source, undo);
    if (undo) {
      this.createDefaultUndoPoint();
    }
    await this.setActiveElements(id);
  }

  async deleteElementWithTranscription(elementId: string): Promise<void> {
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToDelete = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToDelete) {
      return;
    }
    if (this.isOriginalVideoElement(elementToDelete.source)) {
      await this.videoTranscriptionProcessor.deleteOriginalVideoTrack(
        elementId,
      );
      this.refreshKaraokeElements();
      this.createUndoPointForTranscription();
    } else {
      if (elementToDelete?.source.type === 'audio') {
        state.elements.forEach((element) => {
          if (
            element.source.type === 'audio' &&
            element.globalTime >=
              elementToDelete.globalTime + elementToDelete.duration
          ) {
            element.source.time = element.globalTime - elementToDelete.duration;
          }
        });
      }

      // Remove the element
      state.elements = state.elements.filter(
        (element) => element.source.id !== elementId,
      );

      //remove crossfade on element
      await this.removeElementOverlayOnVideo(elementId);

      // Set source by the mutated state
      await this.renderer!.setSource(this.renderer!.getSource(state), true);
    }
  }

  async deleteKaraokeElements() {
    await this.karaokeProducer.deleteKaraokeElements();
  }

  async addPunctuation(code: 'Comma' | 'Period' | 'Space', position: number) {
    this.videoTranscriptionProcessor.addPunctuation(code, position);
    if (this.karaokeProducer.hasElements()) {
      this.karaokeProducer.produceKaraoke();
    }
  }

  async restoreTranscriptAndVideo(fromElement: number, toElement: number) {
    let fromIndex = fromElement;
    // loop through all removed elements in the range
    while (fromIndex >= 0 && fromIndex <= toElement) {
      const nextIndex = getClosestNotRemovedTextIndexToRight(
        fromIndex,
        this.finalTranscriptionElements!,
      );
      const nextRemovedElement =
        nextIndex > -1
          ? getClosestRemovedIndexToLeft(
              nextIndex,
              this.finalTranscriptionElements!,
            )
          : this.finalTranscriptionElements!.length - 1;
      if (nextRemovedElement === -1) break;

      if (
        this.videoTranscriptionProcessor.finalTranscriptionElements![fromIndex]
          .state === 'muted'
      ) {
        await this.videoTranscriptionProcessor.restoreMutedTextElement(
          fromElement,
          Math.min(nextRemovedElement + 1, toElement),
        );
      } else {
        await this.videoTranscriptionProcessor.restoreTextElementsFromOriginal(
          fromIndex,
          Math.min(nextRemovedElement + 1, toElement),
        );
      }

      this.createUndoPointForTranscription();
      fromIndex = getClosestRemovedIndexToRight(
        nextRemovedElement + 1,
        this.finalTranscriptionElements!,
      );
    }
    this.refreshKaraokeElements();
  }

  replaceTranscriptionElement(
    startIndex: number,
    endIndex: number,
    newValue: string,
  ) {
    this.videoTranscriptionProcessor.replaceTextElement(
      startIndex,
      endIndex,
      newValue,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  hideKaraoke(boundaries: { startIndex: number; endIndex: number }) {
    this.videoTranscriptionProcessor.hideKaraoke(boundaries);
    this.refreshKaraokeElements();
  }

  async refetchSubtitlesForCurrentVideo() {
    if (this.currentVideo!.subtitles) {
      await this.requestSubtitlesForCurrentVideo();
    }
    this.refreshKaraokeElements();
  }

  async refreshKaraokeElements() {
    if (this.karaokeProducer.hasElements()) {
      this.karaokeProducer.produceKaraoke();
    }
  }

  async cutTranscriptAndVideo(startIndex: number, endIndex: number) {
    await this.videoTranscriptionProcessor.removeTextElements(
      startIndex,
      endIndex,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async cropTranscriptAndVideoTo(startIndex: number, endIndex: number) {
    await this.videoTranscriptionProcessor.cropVideoToKeepTextElements(
      startIndex,
      endIndex,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async cutSentence(startIndex: number, endIndex: number) {
    await this.videoTranscriptionProcessor.removeTextElements(
      startIndex,
      endIndex + 1,
      true,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async pasteSentence(intoPosition: number) {
    await this.videoTranscriptionProcessor.pasteFromClipboard(intoPosition);
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async moveSentence(
    startIndex: number,
    endIndex: number,
    newStartIndex: number,
  ) {
    await this.videoTranscriptionProcessor.moveTextElements(
      startIndex,
      endIndex,
      newStartIndex,
    );
    this.createUndoPointForTranscription();
  }

  async applyVolumeKeyPoints(
    elementId: string,
    volumeKeyPoints: VolumeKeyPoint[],
  ) {
    const oldVolumeKeyPoints = toJS(
      (
        this.currentVideo!.extraElementData[
          elementId
        ] as ExtraElementData | null
      )?.volumeKeyPoints,
    );
    this.currentVideo!.extraElementData[elementId] = {
      ...(this.currentVideo!.extraElementData[elementId] || {}),
      volumeKeyPoints,
    };
    // todo undo/redo
    // this.undoStack.push({
    //   undoCommand: () => {
    //     this.currentVideo!.extraElementData[elementId].volumeKeyPoints =
    //       oldVolumeKeyPoints;
    //   },
    //   redoCommand: () => {
    //     this.currentVideo!.extraElementData[elementId].volumeKeyPoints =
    //       volumeKeyPoints;
    //   },
    // });
  }

  async applyPlacement(
    element: ElementState,
    placement: {
      time: number;
      trim_start: number;
      duration: number;
    },
  ) {
    //debugger;
    if (
      element.globalTime === placement.time &&
      element.duration === placement.duration
    )
      return;

    if (
      this.isOriginalVideoElement(element.source) &&
      this.finalTranscriptionElements
    ) {
      if (element.source.trim_start !== placement.trim_start) {
        await this.videoTranscriptionProcessor.trimTrackStart(
          element.source.id,
          placement.time,
          placement.trim_start,
          placement.duration,
        );
      } else if (element.globalTime !== placement.time) {
        await this.videoTranscriptionProcessor.moveTrack(
          element.source.id,
          placement.time,
        );
      } else if (element.duration !== placement.duration) {
        await this.videoTranscriptionProcessor.trimTrackDuration(
          element.source.id,
          placement.duration,
        );
      }
      this.createUndoPointForTranscription();
      return;
    }

    const newVideoOverlays = await this.resetCrossfadeOnVideo(
      element.source.id,
      placement.time,
      placement.duration,
    );

    // for non-video elements
    await this.renderer?.applyModifications({
      ...newVideoOverlays,
      [`${element.source.id}.time`]: placement.time,
      [`${element.source.id}.duration`]: placement.duration,
    });
    this.createDefaultUndoPoint();
  }

  async rearrangeTracks(
    track: number,
    direction: 'up' | 'down',
  ): Promise<void> {
    if (track === KARAOKE_TRACK_NUMBER) return;
    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    // The track number to swap with
    const targetTrack = direction === 'up' ? track + 1 : track - 1;
    if (targetTrack < 1) {
      return;
    }

    // Elements at provided track
    const elementsCurrentTrack = renderer.state.elements.filter(
      (element) => element.track === track,
    );
    if (elementsCurrentTrack.length === 0) {
      return;
    }

    // Clone the current renderer state
    const state = deepClone(renderer.state);

    // Swap track numbers
    for (const element of state.elements) {
      if (element.track === track) {
        element.source.track = targetTrack;
      } else if (element.track === targetTrack) {
        element.source.track = track;
      }
    }

    // Set source by the mutated state
    await renderer.setSource(renderer.getSource(state), true);
    this.createDefaultUndoPoint();
  }

  async removeAllPhotoHighlightTranscriptChanges() {
    const newChanges = this.videoTranscriptionProcessor
      .getTranscriptionChanges()
      .filter((change) => {
        return change.type !== 'photo_highlight';
      });
    this.videoTranscriptionProcessor.applyChanges(newChanges);
    this.createUndoPointForTranscription();
  }

  hasPhotoHighlight(id: string) {
    return this.videoTranscriptionProcessor
      .getTranscriptionChanges()
      .some(
        (change) =>
          change.type === 'photo_highlight' &&
          change.newPhotoHighlightId === id,
      );
  }

  removeAPhotoHighlightTranscriptChange(id: string) {
    const newChanges = this.videoTranscriptionProcessor
      .getTranscriptionChanges()
      .filter((change) => {
        if (
          change.type === 'photo_highlight' &&
          change.newPhotoHighlightId === id
        ) {
          return false;
        }
        return true;
      });
    this.videoTranscriptionProcessor.applyChanges(newChanges);
    this.createUndoPointForTranscription();
  }

  handleResetPhotoHighlight(
    element: ElementState,
    start: string | null = null,
    length: string | null = null,
  ) {
    const elementId = element.source.id;
    const hasHighlight = this.hasPhotoHighlight(elementId);
    if (!hasHighlight) return;
    this.removeAPhotoHighlightTranscriptChange(elementId);

    const time = start !== null ? start : element.source.time;
    const duration = length || element.duration;
    let startIndex;
    let endIndex;
    if (time !== null) {
      startIndex = this.videoTranscriptionProcessor.findClosestTimestamp(
        parseFloat(time),
      );
    }

    if (duration) {
      const endTime = parseFloat(time) + parseFloat(duration.toString());
      endIndex = this.videoTranscriptionProcessor.findClosestTimestamp(
        endTime,
        'end_ts',
      );
    }

    if (startIndex !== undefined && endIndex !== undefined) {
      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        elementId,
      );
    }
  }

  //todo move to VideoProcessor
  async cutCurrentTrack() {
    const renderer = this.renderer!;
    const source = renderer.getSource(renderer.state);

    const activeElement = this.getActiveElement()!;
    const elementIndex = source.elements.findIndex(
      (el: any) => el.id === activeElement.source.id,
    );
    const elementSource = source.elements[elementIndex];

    if (
      activeElement.globalTime > this.time ||
      activeElement.globalTime + activeElement.duration < this.time
    )
      return;

    // create head and tail elements
    const tailElementSource = { ...elementSource, id: uuid() };
    const headElementSource = { ...elementSource, id: uuid() };
    headElementSource.duration = this.time - (activeElement.globalTime || 0);
    tailElementSource.time = this.time;
    tailElementSource.duration =
      activeElement.duration - headElementSource.duration;
    tailElementSource.trim_start =
      (parseFloat(elementSource.trim_start || '0') || 0) +
      this.time -
      (activeElement.globalTime || 0);

    // get original volumeKeyPoints
    const volumeKeyPoints = (
      this.currentVideo!.extraElementData[
        elementSource.id
      ] as ExtraElementData | null
    )?.volumeKeyPoints;

    source.elements.splice(
      elementIndex,
      1,
      headElementSource,
      tailElementSource,
    );

    // split volumeKeyPoints if exist
    if (volumeKeyPoints) {
      const headVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) < headElementSource.duration,
      );
      const tailVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) >= headElementSource.duration,
      );

      // figure out the volume at the cut point and add it to the head and the tail at the cut point
      // by interpolating the volume between the two closest volumeKeyPoints to the cut point
      const relativeTime = this.time - activeElement.globalTime;
      const prevVolumeKeyPoint =
        headVolumeKeyPoints[headVolumeKeyPoints.length - 1];
      const nextVolumeKeyPoint = tailVolumeKeyPoints[0];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        prevVolume +
        ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
          (nextTime - prevTime);
      headVolumeKeyPoints.push({
        time: headElementSource.duration,
        value: `${volume} %`,
      });
      tailVolumeKeyPoints.unshift({ time: `${0} s`, value: `${volume} %` });

      this.currentVideo!.extraElementData[headElementSource.id] = {
        ...(this.currentVideo!.extraElementData[headElementSource.id] || {}),
        volumeKeyPoints: headVolumeKeyPoints,
      };
      this.currentVideo!.extraElementData[tailElementSource.id] = {
        ...(this.currentVideo!.extraElementData[tailElementSource.id] || {}),
        volumeKeyPoints: tailVolumeKeyPoints,
      };
    }

    await this.renderer!.setSource(source, true);
    this.createDefaultUndoPoint();
    await this.setActiveElements(tailElementSource.id);
  }

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sleep(fn: Function, delay: number, ...args: any[]) {
    return fn(...args);
  }

  removePosterFromVideoElements(elements: any[]) {
    const POSTER_TRACK = 100;
    return elements.filter((s: any) => s.track !== POSTER_TRACK) || [];
  }

  setVideoDimensionByAspectRatio(source: Record<string, any>) {
    const aspectRatio = this.currentVideo?.aspectRatio || '16:9';

    switch (aspectRatio) {
      case '1:1':
        source.width = source.height;
        break;
      case '9:16':
        source.width = (source.height * 9) / 16;
        break;
    }
    return source;
  }

  async finishVideo(
    resLevel?: 'high' | 'medium' | 'low' | 'original' | 'default',
  ): Promise<any> {
    const renderer = this.renderer;
    if (!renderer) {
      return;
    }

    let url = '';
    let webhook_url = '';
    if (process.env.REACT_APP_API_URL) {
      url = `${process.env.REACT_APP_API_URL}/api/render`;
      webhook_url = `${process.env.REACT_APP_API_URL}/api/webhooks/render`;
    }

    if (!url || !webhook_url) {
      throw Error('Render URL is not defined');
    }
    this.currentVideo!.videoSource!.elements =
      this.removePosterFromVideoElements(
        this.currentVideo!.videoSource.elements,
      );
    const asFinal = false;
    const withRenderer = true;
    const resetTimeline = false;
    await this.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);

    try {
      const captionService = new CaptionService();
      await captionService.generateAllCaptions(
        this.currentVideo!.id!,
        'Social Posts',
      );
    } catch (error) {
      console.log('An error occurred when generating captions');
    }

    // only takes elements with volumeKeyPoints and sends them to the backend
    const filteredExtraElementData = Object.entries(
      this.currentVideo?.extraElementData || {},
    ).filter(
      ([id, element]) =>
        (element as ExtraElementData | null)?.volumeKeyPoints?.length,
    );

    const mappedExtraElementData = filteredExtraElementData.map(
      ([id, element]) => ({
        id,
        volumeKeyPoints: (element as ExtraElementData).volumeKeyPoints,
      }),
    );

    const recreatedElementsData = mappedExtraElementData.reduce(
      (obj, element) => {
        obj[element.id] = element;
        return obj;
      },
      {} as any,
    );

    let source = renderer.getSource(renderer.state);

    //Remove temporary poster if applied
    source.elements = this.removePosterFromVideoElements(source.elements);

    if (resLevel && resLevel !== 'default') {
      source = this.replaceVideoSourceUrl(source, resLevel);
      const { width, height } = this.getDimensionsForResLevel(
        { width: source.width, height: source.height },
        resLevel,
      );
      source.width = width;
      source.height = height;
    }
    source = this.setVideoDimensionByAspectRatio(source);

    // const renderPromises = await Promise.all(
    //   SUPPORTED_ASPECT_RATIO.map(async (aspectRatio) => {
    // const [w, h] = aspectRatio.split(':').map((n) => parseInt(n));
    // const width = source.height * (w / h);

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source,
        extraElementData: recreatedElementsData,
        videoId: this.currentVideo!.id!,
        storyName: this.storyName,
        webhook_url: webhook_url,
        aspectRatio: this.currentVideo!.aspectRatio,
      }),
    });
    //   }),
    // );
    // if (renderPromises.some((p) => p.status !== 200)) {
    //   throw new Error('Error rendering video');
    // }

    this.renderingStatus = 'rendering';
    setTimeout(this.pollRenderingStatus.bind(this), 15000);
    // return await response.json();
  }

  async pollRenderingStatus() {
    try {
      const queryResult: {
        video: Pick<
          Video,
          'videoStatus' | 'id' | 'videoFilePrimary' | 'renderId'
        >;
      } = await videoCreator.gqlClient!.request(VIDEO_RENDERING_STATUS_QUERY, {
        id: this.currentVideo?.id,
      });
      let renderResult = null;
      if (
        queryResult.video.videoStatus === 'rendered' &&
        !queryResult.video.videoFilePrimary
      ) {
        setTimeout(this.pollRenderingStatus.bind(this), 5000);
      } else if (queryResult.video.videoStatus === 'rendering') {
        renderResult = await this.fetchRenderResult({
          renderId: queryResult.video.renderId!,
          videoId: this.currentVideo!.id!,
          storyName: this.storyName!,
          aspectRatio: this.currentVideo!.aspectRatio,
        });
        setTimeout(this.pollRenderingStatus.bind(this), 10000);
      } else {
        this.renderingStatus = queryResult.video.videoStatus;
        let videoPublished: VideoClip | AssociatedVideo | undefined;

        // find video in story other videos or their associated videos with the same id as in queryresult
        for (const storyVideo of this.story?.otherVideos!) {
          if (storyVideo.id === queryResult.video.id) {
            videoPublished = storyVideo;
          } else {
            videoPublished = storyVideo.associatedVideos.find(
              (v) => v.id === queryResult.video.id,
            );
          }
          if (videoPublished) break;
        }

        if (videoPublished) {
          videoPublished.videoFilePrimary = queryResult.video.videoFilePrimary;
        }
        if (this.currentVideo?.id === queryResult.video.id) {
          this.currentVideo!.videoFilePrimary =
            queryResult.video.videoFilePrimary;
        }
      }
    } catch (e) {
      console.error('Error polling rendering status', e);
      setTimeout(this.pollRenderingStatus.bind(this), 15000);
    }
  }

  async fetchRenderResult(params: {
    renderId: string;
    videoId: string;
    storyName: string;
    aspectRatio: string;
  }) {
    const url = `${process.env.REACT_APP_API_URL}/api/render/update-dato`;
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
    } catch (err: any) {
      console.error('Error fetching render result', err);
    }
  }

  async pollTranscriptionId() {
    try {
      const queryResult: {
        story: Pick<Story, 'transcription' | 'id'>;
      } = await videoCreator.gqlClient!.request(
        ORIGINAL_VIDEO_TRANSCRIPTION_QUERY,
        {
          id: this.story?.id,
        },
      );

      if (!queryResult.story.transcription) {
        this.transcriptionLoadingStatus = 'none';
      } else if (
        !queryResult.story.transcription.elementsJson &&
        queryResult.story.transcription.jobStatus === 'FAILED'
      ) {
        this.transcriptionLoadingStatus = 'generation_failed';
      } else if (!queryResult.story.transcription.elementsJson) {
        setTimeout(this.pollTranscriptionId.bind(this), 10000);
      } else {
        this.transcriptionId = queryResult.story.transcription.elementsJson.id;
        await this.loadTranscription();
        await this.loadOriginalWaveformData();
      }
    } catch (e) {
      console.error('Error polling transcription', e);
      setTimeout(this.pollTranscriptionId.bind(this), 10000);
    }
  }

  get canUndo() {
    return this.undoStack.length > 0;
  }

  get canRedo() {
    return this.redoStack.length > 0;
  }

  //TODO move punchlist related functions to separate class (AIProducer?)
  async attachPunchListPhotosToStory() {
    const storyDTO: StoryDTO = { ...this.story! };
    const srcUrls = (
      this.state?.elements?.map((e) => {
        if (e.source.type === 'image') return e.source.source;
      }) || []
    ).filter(Boolean);

    if (this.photoDataForDato && Object.values(this.photoDataForDato)) {
      for (let [id, blobData] of Object.entries(this.photoDataForDato)) {
        if (!srcUrls?.includes(blobData.url)) {
          continue;
        }

        const idx = this.punchListData?.findIndex((p) => p.id === id);
        if (idx && idx > -1) {
          if (!this.punchListData?.length) continue;
          //todo add to story

          if ('uploadId' in blobData && blobData.uploadId) {
            storyDTO.aiPhotos?.push({ id: blobData.uploadId });
            continue;
          }

          const line = this.punchListData[idx].line;
          const fileName = line.split(' ').join('_').toLowerCase().slice(0, 30);

          const newPhotoData = {
            ...blobData,
            fileName,
            alt: line,
            title: line,
            metaData: {
              alt: line,
              title: line,
              custom_data: {},
            },
          };

          const newUpload =
            await this.assetRepository?.uploadFile(newPhotoData);

          this.punchListData[idx] = {
            ...this.punchListData[idx],
            artifactSrc: newUpload!.url,
          };

          if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          } else if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          }
        } else {
          //todo add to story
          if (!('fileName' in blobData)) continue;

          const newPhotoData = {
            ...blobData,
            alt: blobData.alt || '',
            title: blobData.title || '',
          };

          const newUpload =
            await this.assetRepository?.uploadFile(newPhotoData);

          if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          } else if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          }
        }
      }
    }

    return storyDTO;
  }

  async generateMissingPunchListPhotos() {
    //create snapshot before generating
    if (!this.punchListData?.length) return;
    const bareList = this.punchListData?.filter((item) => !item.artifactSrc);
    const gpt_service = new ChatGPTService();
    const arborStylePrompt =
      await gpt_service.fetchAiPrompts('Arbor Photo Style');
    const ai_generated = await Promise.all(
      //TODO refactor, upload image on save only?
      bareList.map(async (item) => {
        let dallePrompt = `${item.dallePrompt} ${
          arborStylePrompt?.description || ''
        }`;

        const data = await retry(
          () => generateImagesWithGPT(dallePrompt, 1),
          10,
        );

        if (data.length) {
          const title = item?.line?.slice(0, 30) || '';
          const fileName = title?.toLowerCase()?.split(' ').join('_') || '';

          const upload = await this.assetRepository?.uploadFile({
            url: data[0].url,
            type: 'ai',
            fileName,
            alt: title,
            title,
          });

          if (upload) {
            return {
              id: item.id,
              url: upload.url,
              uploadId: upload.id,
            };
          }
        }
      }),
    );

    const filtered_ai_generated = ai_generated.filter(Boolean);
    const maxTrack = this.getMaxTrack();
    let foundItemsCounter = 0;

    const newList: PunchListItem[] = await Promise.all(
      this.punchListData.map(async (punchListItem) => {
        const item = filtered_ai_generated.find(
          (c) => c!.id === punchListItem.id,
        );

        if (item) {
          const { id, url, uploadId } = item;
          runInAction(async () => {
            this.photoDataForDato = {
              ...this.photoDataForDato,
              [id!]: {
                type: 'ai',
                url: url,
                cat: 'feeling_lucky',
                uploadId,
              },
            };
          });
          let time = punchListItem.startTime || foundItemsCounter * 30; //todo clarify

          await this.findOrReplaceInTimeline(id!, url, maxTrack + 1, time);
          foundItemsCounter++;
          return {
            ...punchListItem,
            artifactSrc: item.url,
          };
        }
        return punchListItem;
      }),
    );

    runInAction(() => {
      this.punchListData = newList;
    });
  }

  findOrReplaceInTimeline = async (
    id: string,
    source: string,
    track: number | null = null,
    time: number | null = null,
  ) => {
    const hasId = this.state!.elements?.some((e) => e.source.id === id);
    if (hasId) {
      await this.renderer?.applyModifications({
        [`${id}.source`]: source,
      });
      this.createDefaultUndoPoint();
    } else {
      await this.createElement({
        id,
        type: 'image',
        source,
        duration: '8 s',
        ...(time && { time }),
        ...(track && { track }),
        autoplay: true,
      });
    }
  };

  toggleVideoTrackGroup = () => {
    this.videoTrackGrouped = !this.videoTrackGrouped;
  };

  removePunchListItem = async (punchListId: string) => {
    const newPunchListData = this.punchListData?.filter(
      (item) => item.id !== punchListId,
    );
    if (newPunchListData) {
      this.punchListData = newPunchListData;
    }
    videoCreator.removeAPhotoHighlightTranscriptChange(punchListId);
    await this.deleteElementWithTranscription(punchListId);
  };

  insertPunchListAtIndex = (
    newPunchListData: PunchListItem[],
    punchListData = this.punchListData,
  ) => {
    const sortedPunchList = newPunchListData.sort(
      (a, b) => a.transcriptPosition.endIndex - b.transcriptPosition.endIndex,
    );
    const lastPunchList = sortedPunchList[sortedPunchList.length - 1];
    const endIndex = lastPunchList.transcriptPosition.endIndex;

    const punchListLength = punchListData!.length;
    if (
      !punchListLength ||
      endIndex > punchListData![punchListLength - 1].transcriptPosition.endIndex
    ) {
      const existingPunchListData = punchListData || [];
      punchListData = [...existingPunchListData, ...sortedPunchList];
    } else {
      const indexToInsert = punchListData?.findIndex(
        (p) => p.transcriptPosition.endIndex > endIndex,
      );
      const punchListBefore = punchListData?.slice(0, indexToInsert!) || [];
      const punchListAfter = punchListData?.slice(indexToInsert!) || [];
      punchListData = [
        ...punchListBefore,
        ...sortedPunchList,
        ...punchListAfter,
      ];
    }
    return punchListData;
  };

  getPunchListTrack = () => {
    let punchListItemId: string | undefined = undefined;
    let extraElementData = this.currentVideo?.extraElementData || {};
    if (this.punchListData?.length) {
      punchListItemId = this.punchListData[0].id;
    } else if (Object.keys(extraElementData).length) {
      punchListItemId = Object.keys(extraElementData).find(
        (key) =>
          (extraElementData[key] as ExtraElementData | null)?.punchListData?.id,
      );
    }

    if (punchListItemId) {
      const element = videoCreator.renderer?.state?.elements?.find(
        (el) => el.source.id === punchListItemId,
      );

      return element?.track;
    }
  };

  addPhotoToPunchList = async (
    text: string,
    photo: string,
    description: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) => {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;

      const duration = endTime - startTime;
      const maxTrack = this.getMaxTrack();
      const currTrack = maxTrack + 1;
      const punchListTrack = this.getPunchListTrack() || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        description,
        line: text,
        artifactSrc: photo,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  };

  addItemToPunchList = async (
    text: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) => {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;
      this.sidebarOptions = SidebarOption.aiProducer;

      const duration = Math.min(8, endTime - startTime);
      const maxTrack = this.getMaxTrack();
      const currTrack = maxTrack + 1;
      const punchListTrack = this.getPunchListTrack() || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        description: '',
        line: text,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );

      const gptService = new ChatGPTService();

      const punchListItem: PunchListItem =
        await gptService.generatePunchListItem(text);

      if (punchListItem) {
        const updatedPunchList = this.punchListData!.map((p) =>
          p.id === punchListId
            ? {
                ...p,
                ...punchListItem,
              }
            : p,
        );
        this.punchListData = updatedPunchList;
      }

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  };

  async addPunchListItemToTimeline(
    punchListItem: PunchListItem,
    duration: number,
    startTime: number,
    endTime: number,
    currTrack: number,
  ) {
    await videoCreator.createElement({
      id: punchListItem.id,
      type: 'image',
      source: punchListItem.artifactSrc || null,
      duration: duration,
      autoplay: true,
      fit: 'cover',
      smart_crop: true,
      track: currTrack, //todo remove hardcoding
      time: startTime,
      animations: [
        {
          start_scale: '130%',
          end_scale: '100%',
          x_anchor: '50%',
          y_anchor: '50%',
          fade: false,
          scope: 'element',
          easing: 'linear',
          type: 'scale',
          arbor_subType: 'zoomOut',
        },
      ],
    });
    punchListItem.startTime = startTime;
    punchListItem.endTime = endTime;
    punchListItem.duration = duration;
  }

  setAiImageFeatureFlag(flagString: string) {
    if (!this.story?.id) return;
    if (this.datoContext.environment !== 'production') return;
    const flags = flagString?.trim()?.split(',') || [];
    if (!flags.includes(this.story.id)) {
      this.disableAiImageGenerate = true;
    }
  }

  getDefaultSource(params?: { videoRes: 'low' | 'medium' | 'high' }) {
    const id = uuid();
    const videoRes = params?.videoRes;

    let sourceUrl = this.getVideoSourceUrlForResLevel(videoRes || 'original');

    return {
      output_format: 'mp4',
      width: 1280,
      height: 720,
      frame_rate: '24 fps',
      // duration: this.originalVideoDuration, - let duration autoadjust
      elements: [
        {
          id,
          duration: this.originalVideoDuration,
          track: 1,
          time: 0,
          type: 'video',
          source: sourceUrl,
          autoplay: true,
          // locked: true,
        },
      ],
    };
  }

  async resetCrossfadeOnVideo(
    id: string,
    time: number,
    duration: number,
    applyChanges = false,
  ) {
    const elements = this.renderer?.getElements();
    const originalVideo = elements?.find((e) =>
      this.isOriginalVideoElement(e.source),
    );
    const element = elements?.find((e) => e.source.id === id);

    if (originalVideo?.source.id === id) return {};

    const elementOverlays = element?.source?.color_overlay || [];
    const elementExitValue = elementOverlays?.find((o: any) =>
      o.easing.includes('ease-out'),
    );
    let newElementOverlays = elementOverlays?.filter(
      (o: any) => !o.easing.includes('ease-out'),
    );

    const videoOverlays = originalVideo?.source?.color_overlay || [];

    const entryValues = videoOverlays.filter((v: any) => {
      return v.easing === `${id}-cross-fade-start`;
    });

    const exitValues = videoOverlays.filter((v: any) => {
      return v.easing === `${id}-cross-fade-end`;
    });

    const existingVideoOverlays =
      originalVideo?.source?.color_overlay?.filter((v: any) => {
        return (
          v.easing !== `${id}-cross-fade-start` &&
          v.easing !== `${id}-cross-fade-end` &&
          v.easing !== `${id}-cross-fade-start-inc` &&
          v.easing !== `${id}-cross-fade-end-inc`
        );
      }) || [];

    const entryOverlayValues = [
      {
        time:
          time -
          entryValues.reduce(
            (acc: number, curr: any) => Math.abs(curr.time - acc),
            0,
          ),
        easing: `${id}-cross-fade-start`,
        value: 'rgba(0,0,0,0)',
      },
      {
        time: time,
        easing: `${id}-cross-fade-start`,
        value: 'rgba(0,0,0,1)',
      },
      {
        time: time + 0.1,
        easing: `${id}-cross-fade-start-inc`,
        value: 'rgba(0,0,0,0)',
      },
    ];

    const exitOverlayValues = [
      {
        time: time + duration - 0.1,
        easing: `${id}-cross-fade-end-inc`,
        value: 'rgba(0,0,0,0)',
      },
      {
        time: time + duration,
        easing: `${id}-cross-fade-end`,
        value: 'rgba(0,0,0,1)',
      },
      {
        time:
          time +
          duration +
          exitValues.reduce(
            (acc: number, curr: any) => Math.abs(curr.time - acc),
            0,
          ),
        easing: `${id}-cross-fade-end`,
        value: 'rgba(0,0,0,0)',
      },
    ];

    let newOverlays = [...existingVideoOverlays];

    if (entryValues?.length) {
      newOverlays = [...entryOverlayValues, ...newOverlays];
    }

    if (exitValues?.length) {
      newOverlays = [...newOverlays, ...exitOverlayValues];
    }

    if (elementExitValue?.easing) {
      const animationDuration = elementExitValue.easing.replace('ease-out', '');
      const startTime = Math.min(
        Math.max(duration - (parseFloat(animationDuration) || 1), 1),
        duration,
      );

      newElementOverlays = [
        ...newElementOverlays,
        {
          time: (startTime + duration) / 2,
          easing: `ease-out${animationDuration || 1}`,
          value: 'rgba(0,0,0,0)',
        },
        {
          time: 'end',
          easing: `ease-out${animationDuration || 1}`,
          value: 'rgba(0,0,0,1)',
        },
      ];
    }

    newOverlays = newOverlays.sort((a, b) => a.time - b.time);
    console.log('exitOverlayValues', exitOverlayValues, exitValues);
    console.log('newOverlays', newOverlays);
    console.log('newElementOverlays', newElementOverlays);
    const modification = {
      ...(newOverlays.length && {
        [`${originalVideo!.source.id}.color_overlay`]: newOverlays,
      }),
      ...(newElementOverlays.length && {
        [`${id}.color_overlay`]: newElementOverlays,
      }),
    };

    if (applyChanges && newOverlays.length) {
      await this.renderer?.applyModifications({ ...modification });
    }
    return modification;
  }

  async removeElementOverlayOnVideo(id: string) {
    const originalVideo = this.renderer
      ?.getElements()
      .find((e) => this.isOriginalVideoElement(e.source));
    if (originalVideo?.source.id === id) return;
    const newVideoOverlays =
      originalVideo?.source?.color_overlay?.filter((v: any) => {
        return (
          v.easing !== `${id}-cross-fade-start` &&
          v.easing !== `${id}-cross-fade-end`
        );
      }) || [];

    if (newVideoOverlays.length) {
      await this.renderer?.applyModifications({
        [`${originalVideo!.source.id}.color_overlay`]: newVideoOverlays,
      });
    }
  }
}

export const videoCreator = new VideoCreatorStore();
