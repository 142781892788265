import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../../stores/VideoCreatorStore';

import AIGenerateCard from '../common/AIGenerateCard';
import SpinningLoading from '../SpinningLoading';
import {
  Action,
  Align,
  AlignValues,
  Bold,
  BoldValues,
  Readability,
  TextSettingsUI,
} from './TextSettingsUI';
import CheckIcon from '../../svgs/CheckIcon';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import { observer } from 'mobx-react-lite';
import {
  DEFAULT_KARAOKE_CONFIG,
  KaraokeConfig,
} from '../../videoTranscriptionProcessor/KaraokeProducer';

const AIKaraokeTextProducer = observer(() => {
  const isKaraokeGenerated = videoCreator.tracks?.has(KARAOKE_TRACK_NUMBER);

  const [config, setConfig] = useState<KaraokeConfig>(
    videoCreator.karaokeProducer.getKaraokeConfig() || DEFAULT_KARAOKE_CONFIG,
  );

  useEffect(() => {
    const newConfig =
      videoCreator.karaokeProducer.getKaraokeConfig() || DEFAULT_KARAOKE_CONFIG;
    const selectedActions: (Action | Readability)[] = [];
    if (newConfig.font_style === 'italic') {
      selectedActions.push(Action.italic);
    }
    if (newConfig.text_transform === 'uppercase') {
      selectedActions.push(Action.capitalize);
    }

    if (newConfig.hideComma && !selectedActions.includes(Readability.comma)) {
      selectedActions.push(Readability.comma);
    }

    if (newConfig.hidePeriod && !selectedActions.includes(Readability.period)) {
      selectedActions.push(Readability.period);
    }

    if (newConfig.hideFillers && !selectedActions.includes(Readability.filler)) {
      selectedActions.push(Readability.filler);
    }

    setConfig(newConfig);
    setSelectedActions(selectedActions);
  }, [videoCreator.karaokeProducer.getKaraokeConfig()]);

  const [selectedActions, setSelectedActions] = useState<(Action | Readability)[]>([
    Action.align,
  ]);
  // const [alignType, setAlignType] = useState<Align>(Align.midAlign);
  // const [selectedBold, setSelectedBold] = useState<Bold | null>(Bold.bold);
  const selectedBold = Object.keys(BoldValues).find(
    (k: any) => BoldValues[k as Bold] === Number(config.font_weight),
  ) as Bold;

  const alignType = Object.keys(AlignValues).find(
    (k) => AlignValues[k as Align] === config.x_alignment,
  ) as Align;

  const toggleAction = (action: Action) => {
    const hasAction = selectedActions.find((a) => a === action);

    let updatedActions = [...selectedActions];
    if (hasAction) {
      updatedActions = updatedActions.filter((a) => a !== action);
    } else {
      updatedActions = [...updatedActions, action];
    }
    setSelectedActions(updatedActions);
  };

  const modifyProperty = async (propertyMap: Partial<KaraokeConfig>) => {
    const newConfig = { ...config, ...propertyMap };
    setConfig(newConfig);
    videoCreator.karaokeProducer!.setConfig(newConfig);
    const isInstagram = newConfig.instagramEffect;
    videoCreator.karaokeLoading = true;
    if (!isInstagram) {
      await videoCreator.karaokeProducer!.renderKaraokeElements();
    } else {
      await videoCreator.karaokeProducer!.renderInstagramElements();
    }
    videoCreator.karaokeLoading = false;
  };

  const setAnimation = (
    time: string | number | null,
    type: any,
    subType: any = null,
    speed: any = null,
    duration: number | null = null,
    background_effect: string | null = null,
  ) => {
    const isInstagram = type === 'instagram';
    // Remove existing animation from list
    const newAnimations =
      config.animations?.filter(
        (keyframe: any) => !(!keyframe.time && !time) && keyframe.time !== time,
      ) ?? [];

    if (type !== 'none') {
      if (isInstagram) {
        type = 'text-appear';
      }
      const animation: any = { type };

      if (!!time) {
        animation.time = time;
        animation.duration = duration || 1;
      }

      if (background_effect) {
        animation.background_effect = background_effect;
      }

      // Reverse animation when used as exit animation
      if (time === 'end') {
        animation.reversed = true;
      }

      newAnimations.push(animation);
    }
    const newConfig: KaraokeConfig = {
      ...config,
      instagramEffect: isInstagram,
      language: isInstagram ? 'original' : config.language,
      animations: newAnimations,
    };
    setConfig(newConfig);
    // TODO revisit
    // if (!isInstagram && !hasInstagramEffect) {
    //   videoCreator.karaokeProducer!.renderKaraokeElements(newConfig);
    // }
  };

  const handleToggleAction = (
    action: Action,
    propertyName: keyof KaraokeConfig,
    normalState: string,
    requiredState: string,
  ) => {
    // debugger;
    toggleAction(action);
    const isSelected = selectedActions.includes(action);
    const state = isSelected ? normalState : requiredState;
    modifyProperty({ [propertyName]: state });
  };

  const handleSanitizePunctuation = async (action: Readability) => {
    const newConfig = { ...config }
    if (action === Readability.comma) {
      newConfig.hideComma = !config.hideComma
    }
    if (action === Readability.period) {
      newConfig.hidePeriod = !config.hidePeriod
    }

    if (action === Readability.filler) {
      newConfig.hideFillers = !config.hideFillers
    }
    setConfig(newConfig);
    videoCreator.karaokeProducer!.setConfig(newConfig);

    await produceKaraokeText(
      newConfig.hideComma,
      newConfig.hidePeriod,
      newConfig.hideFillers
    )
  }

  const toggleAlign = (align: Align) => {
    modifyProperty({
      x_alignment: AlignValues[align],
      x: '50%', //default position to keep it visible
    });
  };

  const handleToggleBold = (bold: Bold) => {
    const state = bold === selectedBold ? null : bold;
    let value = state ? BoldValues[state] : BoldValues[Bold.normal];
    modifyProperty({ font_weight: value.toString() });
  };

  //todo add config for text elements

  const produceKaraokeText =
    async (
      hideComma = !!config.instagramEffect || !!config.hideComma,
      hidePeriod = !!config.instagramEffect || !!config.hidePeriod,
      hideFillers = !!config.hideFillers,
    ) => {
      videoCreator.karaokeLoading = true;
      await videoCreator.karaokeProducer!.produceKaraoke({
        ...config,
        hideComma,
        hidePeriod,
        hideFillers
      });
      videoCreator.karaokeLoading = false;
    };

  return (
    <Main>
      {videoCreator.renderer?.ready && (
        <>
          {isKaraokeGenerated && (
            <TextSettingsUI
              skipFields={['text']}
              karaokeElement={config}
              selectedBold={selectedBold}
              selectedActions={selectedActions}
              selectedFontFamily={config.font_family}
              selectedFontSize={parseInt(config.font_size)}
              selectedFillColor={config.fill_color}
              selectedBackgroundColor={config.background_color}
              modifyProperty={modifyProperty}
              handleToggleBold={(bold: Bold) => handleToggleBold(bold)}
              handleToggleAction={handleToggleAction}
              handleSanitizePunctuation={handleSanitizePunctuation}
              alignType={alignType}
              toggleAlign={toggleAlign}
              setAnimation={setAnimation}
              animations={
                config.animations?.reduce((acc, animation) => {
                  if (animation.time === 'start') {
                    acc.enter = config.instagramEffect
                      ? 'instagram'
                      : animation.type;
                  } else if (animation.time === 'end') {
                    acc.exit = animation.type;
                  }
                  return acc;
                }, {}) || {}
              }
            />
          )}
          <GenerateButton onClick={() => produceKaraokeText()}>
            <CircleCheckIcon strokeColor={'black'} />
            Generate Karaoke Text
          </GenerateButton>
        </>
      )}

      {videoCreator.karaokeLoading && (
        <SpinningLoading
          positionTop="20px"
          text={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Generating karaoke text</span>
            </div>
          }
        />
      )}
      {videoCreator.isLoading && (
        <SpinningLoading
          positionTop="20px"
          text={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span></span>
            </div>
          }
        />
      )}
    </Main>
  );
});

export default AIKaraokeTextProducer;

const Main = styled.div`
  padding-top: 16px;
`;

const GenerateButton = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 10px;
`;
