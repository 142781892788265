import { FC, useEffect, useRef, useState } from 'react';
import { AiGeneratedContent, Story, StoryVideo, Video } from '../types.ts/story';
import { observer } from 'mobx-react-lite';
import styled, { CSSProperties } from 'styled-components';
import ContentView from './content-components/ContentView';
import MuxPlayer from '@mux/mux-player-react';
import type MuxPlayerElement from '@mux/mux-player';
import CopyToClipboard from './common/CopyToClipboard';
import { videoCreator } from '../stores/VideoCreatorStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SocialShareStatus from './content-components/SocialShareStatus';
import SocialStats from './content-components/SocialStats';
import { getPosterUrl } from '../utility/general';
import useInitializeDatoUser from './common/useInitializeDatoUser';
import { useSearchParams } from 'react-router-dom';
import { useFlagsCombination } from '../utility/useFlagsCombination';
import ToastNotification from './common/ToastNotification';
import QuerySubscriptionComponent from './content-components/QuerySubscriptionComponent';

type Props = {
  params: {
    [k: string]: string;
  };
};

const playerStyle = {
  height: '373px',
  width: '100%',
  minWidth: '500px',
  maxWidth: '683px',
  borderRadius: 10,
  overflow: 'hidden',
  border: '1px solid #484848',
  aspectRatio: 4 / 3,
  '--pip-button': 'none',
  '--center-controls': 'none',
} as CSSProperties;

const ContentStudio: FC<Props> = observer((props) => {
  const videoPlayerRef = useRef<HTMLDivElement>(null);
  const story = videoCreator?.story;
  const { enableAiImageGeneration } = useFlags();
  const { enableSharing } = useFlagsCombination();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const { storyId, playbackId } = Object.fromEntries(urlSearchParams.entries()) || {};

  // const [currentVideo, setCurrentVideo] = useState<StoryVideo["video"] | undefined>()
  // const [posterUrl, setPosterUrl] = useState<string>()

  useInitializeDatoUser();

  useEffect(() => {
    videoCreator.setAiImageFeatureFlag(enableAiImageGeneration);
  }, [storyId, enableAiImageGeneration]);

  const aiContent = story?.aiResponse?.responses;
  const storyDescription = aiContent?.find(
    (c) => c.title.toLowerCase() === 'description',
  ) as AiGeneratedContent<'Description'> | undefined;

  const currentVideo =
    (story?.finalVideo as Video | undefined)?.videoFilePrimary?.video ||
    story?.originalVideo?.video;

  const posterUrl = getPosterUrl(
    (story?.finalVideo as Video | undefined)?.thumbnail?.url ||
    story?.thumbnail?.url ||
    currentVideo?.thumbnailUrl!,
    780,
    440,
  );

  async function refreshStory() {
    // update story in store, so that the new profile is added to the list
    if (storyId) {
      const story = await videoCreator.storyRepository?.findOne(storyId);

      if (story) {
        videoCreator.story = story;
      }
    }
  }

  return (
    <Main>
      <TopContent>
        <Title>
          <h2>{story?.title || ''}</h2>
          <h5>{story?.storyTeller?.name || ''}</h5>
        </Title>
        <StoryContent>
          <MuxPlayer
            style={playerStyle}
            streamType="on-demand"
            playbackId={playbackId || currentVideo?.muxPlaybackId}
            poster={posterUrl}
          />

          <DescriptionAndAnalytis>
            {enableSharing && <Analytics>
              <AnalyticsTop>
                <span>Aggregate Analytics</span>
                <SocialShareStatus
                  padding="0"
                  sharedContents={story?._allReferencingSharedContents}
                />
              </AnalyticsTop>

              <SocialStats
                type="story"
                id={story?.id}
                allReferencingSharedContents={story?._allReferencingSharedContents}
                layout='studio-main'
              />
            </Analytics>}
            {/* {enableSharing && (<SocialShareStatus sharedContents={story?._allReferencingSharedContents}></SocialShareStatus>)} */}
            {/* {enableSharing && (<SocialStats
              type="story"
              id={story?.id}
              allReferencingSharedContents={story?._allReferencingSharedContents}
            ></SocialStats>)} */}


            <Description>
              <span className="description-title">
                <h3>Description</h3>{' '}
                <CopyToClipboard text={storyDescription?.response || ''} />
              </span>
              <p className='description-content'>{storyDescription?.response || ''}</p>
            </Description>
          </DescriptionAndAnalytis>
        </StoryContent>
      </TopContent>
      <ContentView />
      {Boolean(videoCreator.pendingSharedContentIds?.length) && (
        <QuerySubscriptionComponent
          sharedContentIds={videoCreator.pendingSharedContentIds}
          onDataReceived={async (sharedContent) => {
            videoCreator.toastState = {
              state: 'success', message: "Story successfully published"
            }
            videoCreator.pendingSharedContentIds = videoCreator.pendingSharedContentIds.filter(id => !sharedContent.find((content: any) => content.id === id));
            refreshStory();
          }}
          onError={() => {
            console.error('QuerySubscriptionComponent error');
          }} />
      )}
      {videoCreator.toastState &&
        <ToastNotification />
      }
    </Main>
  );
});

export default ContentStudio;

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  padding-bottom: 20px;
  @media only screen and (max-width: 1020px){
    padding: 0 60px;
  }
`;

const TopContent = styled.div`
  margin-top: 30px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    color: #f3e9d7;
    line-height: 1.3;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #a9a9a9;
  }
`;

const Analytics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 568px;
`

const AnalyticsTop = styled.div` 
  display: flex; 
  justify-content: space-between;

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24.2px;
    color: #F3E9D7;
  }
`

const StoryContent = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 20px;
  justify-content: space-between;
  max-height: 373px;

  @media only screen and (max-width: 1200px){
    flex-direction: column;
    max-height: 746px;
  }
`;

const DescriptionAndAnalytis = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
  color: #f3e9d7;

  @media only screen and (max-width: 1200px){
    margin: 0 auto;
  }
`;

const Description = styled.div`
  .description-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fix-content;
    border-top: 1px solid #484848;
    margin-top: 10px;
    padding-top: 15px;
    button {
      color: transparent;
      background-color: transparent;
      cursor: pointer;
      border: 0;
      outline: 0;
      &:active {
        svg {
          fill: white;
        }
      }
    }
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 20px;
    font-weight: 700;
  }

  .description-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #BDBDBD;
    width: 568px;
    max-height: 120px;
    overflow-y: auto;
    margin-bottom: 0;
  }
`
