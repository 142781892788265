import React, { Dispatch, SetStateAction } from 'react'
import styled, { css } from 'styled-components';
import QuoteStartIcon from '../../svgs/QuoteStartIcon';
import { videoCreator } from '../../stores/VideoCreatorStore';

type Props = {
    width?: string;
    height?: string;
    imageRef?: React.RefObject<HTMLImageElement> | null;
    background?: string | undefined;
    quote?: string;
    fontSize?: string;
    lineHeight?: string;
    quoteSize?: Record<'width' | 'height', string>;
    storyTellerDim?: number;
    aspectRatio?: string;
    divWidth?: number;
    setDivWidth?: Dispatch<SetStateAction<number>>
}
const ShareableImageView = (props: Props) => {
    const storyTeller = videoCreator.story?.storyTeller?.name;
    const {
        width,
        height,
        imageRef,
        background,
        quote,
        aspectRatio,
        divWidth,
        fontSize = "24px",
        lineHeight = "29.4px",
        quoteSize = { width: '28px', height: '24px' },
        storyTellerDim = 1
    } = props

    React.useEffect(() => {
        const handleResize = () => {
            if (props.setDivWidth) {
                props.setDivWidth(document.getElementById('modal')?.offsetWidth!);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const quoteIconWidth = divWidth ? `${divWidth / 45}px` : quoteSize.width;
    const quoteIconHeight = divWidth ? `${divWidth / 52}px` : quoteSize.height;

    const getStoryTellerFontSize = () => {
        if (divWidth) return `${(divWidth / 104)}px`
        return `${storyTellerDim * 12}px`
    }

    const getStoryTellerPadding = () => {
        if (divWidth) return `${(divWidth / 313)}px  ${(divWidth / 156.5)}px`
        return `${storyTellerDim * 4}px ${storyTellerDim * 8}px`
    }
    return (
        <ContentWrapper
            width={width}
            height={height}
        >
            <Content
                className='image-content'
                ref={imageRef}
                background={background}
                width={width}
                height={height}
                aspectRatio={aspectRatio}
                onClick={(e) => {
                    const rect = e.currentTarget.getBoundingClientRect()
                    console.log(rect.width / rect.height)
                }}
            >
                <Quote marginBottom={divWidth ? divWidth / 84 : 15}>
                    <QuoteIcon>
                        <QuoteStartIcon
                            fillColor={background ? '#FFF' : '#03041a'}
                            width={quoteIconWidth}
                            height={quoteIconHeight}
                        />
                    </QuoteIcon>
                    <Text
                        className='quote-element'
                        background={background}
                        lineHeight={divWidth ? `${(divWidth / 42.585)}px` : lineHeight}
                        fontSize={divWidth ? `${divWidth / 52}px` : fontSize}

                    >
                        {quote}
                    </Text>
                    {storyTeller &&
                        <StoryTeller
                            fontSize={getStoryTellerFontSize()}
                            padding={getStoryTellerPadding()}
                        >{storyTeller}</StoryTeller>}
                </Quote>
            </Content>
        </ContentWrapper>
    )
}

export default ShareableImageView

const ContentWrapper = styled.div<{
    width?: string;
    height?: string;
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: 8px;
    overflow: hidden;
  `;

const Content = styled.div<{
    background?: string;
    width?: string;
    height?: string;
    aspectRatio?: string;
}>`
    display: flex;
    align-items: center;
    gap: 40px;
    background-color: #fff;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    // width: ${(props) => props.width};
    // height: ${(props) => props.height};
    aspect-ratio: ${props => props.aspectRatio};
  
    ${(props) =>
        props.background &&
        css`
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 100%
          ),
          url(${props.background});
  
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
      `}
  `;

const Quote = styled.div<{ marginBottom: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 90%;
    margin-top: auto;
    margin-bottom: ${props => props.marginBottom}px;
  `;

const QuoteIcon = styled.div``;

const Text = styled.div<{ background?: string; fontSize: string; lineHeight: string }>`
    color: ${(props) => (props.background ? '#fff' : '#030419')};
    font-size: ${props => props.fontSize};
    font-weight: 800;
    line-height: ${props => props.lineHeight};
  
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  `;

const StoryTeller = styled.div<{ fontSize: string; padding: string }>`
    padding: ${({ padding }) => padding};
    border-radius: 4px;
    background-color: #fff;
    width: fit-content;
    color: #03041a;
    font-size: ${({ fontSize }) => fontSize};
    font-weight: 700;
    line-height: 16.8px;
  `;