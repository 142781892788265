import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Select } from '../Select';
import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import styled from 'styled-components';

interface AnimationSettingsProps {
  activeElement: ElementState;
  manualKaraoke?: boolean;
}

export const AnimationSettings: React.FC<AnimationSettingsProps> = (props) => {
  const { animations } = props.activeElement.source;

  const speedLookup = (keyframe: any) => {
    if (!keyframe || !keyframe.arbor_subType) return null;
    switch (keyframe.arbor_subType) {
      case 'zoomIn':
        return keyframe.start_scale;
      case 'zoomOut':
        return keyframe.end_scale;
      case 'panLeft':
      case 'panRight':
      case 'panUp':
      case 'panDown':
        return null;
      case 'panLeftWithZoom':
      case 'panRightWithZoom':
      case 'panUpWithZoom':
      case 'panDownWithZoom':
        return keyframe.end_scale;
      default:
        return null;
    }
  };

  const enterAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'start',
  );
  const exitAnimationData = animations?.find(
    (keyframe: any) => keyframe.time === 'end',
  );

  const enterAnimation = enterAnimationData?.type ?? 'none';
  const exitAnimation = exitAnimationData?.type ?? 'none';

  const enterAnimationDuration = enterAnimationData?.duration;
  const exitAnimationDuration = exitAnimationData?.duration;

  const elementAnimation =
    animations?.find((keyframe: any) => !!keyframe.arbor_subType)
      ?.arbor_subType ?? 'none';
  const elementAnimationSpeed = speedLookup(
    animations?.find((keyframe: any) => !!keyframe.arbor_subType),
  );

  const animationTypeLookup = (subType: string) => {
    switch (subType) {
      case 'zoomIn':
      case 'zoomOut':
        return 'scale';
      default:
        return 'pan';
    }
  };

  const setAnimation = async (
    time: string | number | null,
    type: any,
    subType: any = null,
    speed: any = null,
    duration: number | null = null,
    background_effect: string | null = null,
  ) => {
    // Remove existing animation from list
    const newAnimations =
      animations?.filter(
        (keyframe: any) => !(!keyframe.time && !time) && keyframe.time !== time,
      ) ?? [];

    if (type !== 'none') {
      const animation: any = { type };

      if (!!time) {
        animation.time = time;
        animation.duration = duration || 1;
      }

      if (background_effect) {
        animation.background_effect = background_effect;
      }

      if (!!subType) {
        animation.arbor_subType = subType;
        animation.scope = 'element';
        animation.easing = 'linear';
        switch (subType) {
          case 'zoomIn':
            animation.start_scale = '100%';
            animation.end_scale = speed || '130%';
            animation.x_anchor = '50%';
            animation.y_anchor = '50%';
            animation.fade = false;
            break;
          case 'zoomOut':
            animation.start_scale = speed || '130%';
            animation.end_scale = '100%';
            animation.x_anchor = '50%';
            animation.y_anchor = '50%';
            animation.fade = false;
            break;
          case 'panLeft':
            animation.start_x = '100%';
            animation.start_y = '50%';
            animation.end_x = '0%';
            animation.end_y = '50%';
            break;
          case 'panRight':
            animation.start_x = '0%';
            animation.start_y = '50%';
            animation.end_x = '100%';
            animation.end_y = '50%';
            break;
          case 'panUp':
            animation.start_x = '50%';
            animation.start_y = '100%';
            animation.end_x = '50%';
            animation.end_y = '0%';
            break;
          case 'panDown':
            animation.start_x = '50%';
            animation.start_y = '0%';
            animation.end_x = '50%';
            animation.end_y = '100%';
            break;
          case 'panLeftWithZoom':
            animation.start_x = '100%';
            animation.start_y = '50%';
            animation.end_x = '0%';
            animation.end_y = '50%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '130%';
            break;
          case 'panRightWithZoom':
            animation.start_x = '0%';
            animation.start_y = '50%';
            animation.end_x = '100%';
            animation.end_y = '50%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '130%';
            break;
          case 'panUpWithZoom':
            animation.start_x = '50%';
            animation.start_y = '100%';
            animation.end_x = '50%';
            animation.end_y = '0%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '130%';
            break;
          case 'panDownWithZoom':
            animation.start_x = '50%';
            animation.start_y = '0%';
            animation.end_x = '50%';
            animation.end_y = '100%';
            animation.start_scale = '100%';
            animation.end_scale = speed || '130%';
            break;
          default:
            break;
        }
      }
      // Reverse animation when used as exit animation
      if (time === 'end') {
        animation.reversed = true;
      }

      newAnimations.push(animation);
    }

    await videoCreator.renderer?.applyModifications({
      [`${props.activeElement.source.id}.animations`]: newAnimations,
    });
  };

  const animationTypes =
    props.activeElement.source.type === 'text'
      ? TextAnimationTypes
      : GenericAnimationTypes;

  const getInputValue = async (time: string, duration: string) => {
    let type = time === 'start' ? enterAnimation : exitAnimation;
    let itemDuration = Number(duration);

    if (isNaN(itemDuration)) itemDuration = 1;
    await setAnimation(time, type, null, null, itemDuration);
  };

  if (props.manualKaraoke) {
    return (
      <>
        <ManualKaraoke>
          <ItemDropdownSelect
            action={async (propertyName, value) => {
              await setAnimation(
                'start',
                value,
                null,
                null,
                enterAnimationDuration,
                'animated',
              );
            }}
            propertyName="start"
            defaultValue="text-appear"
            values={[
              { caption: 'None', value: 'none' },
              ...Object.entries(animationTypes).map(([type, caption]) => ({
                caption,
                value: type,
              })),
            ]}
          />
          {enterAnimationDuration ? (
            <div className="animation-length">
              <p>Animation Length (s)</p>
              <PropertyNumericalInput
                activeElement={props.activeElement}
                propertyName="start"
                manualKaraoke={true}
                defaultValue={
                  props.activeElement?.source?.animations?.find(
                    ({ time }: any) => time === 'start',
                  )?.duration ?? '1'
                }
                unit="s"
                getInputValue={getInputValue}
              />
            </div>
          ) : null}
        </ManualKaraoke>
      </>
    );
  }

  return (
    <Fragment>
      <PropertyCaption>Enter Animation</PropertyCaption>
      <Select
        value={enterAnimation}
        onChange={async (e) => {
          await setAnimation(
            'start',
            e.target.value,
            null,
            null,
            enterAnimationDuration,
          );
        }}
      >
        <option key="none" value="none">
          None
        </option>
        {Object.entries(animationTypes).map(([type, caption]) => (
          <option key={type} value={type}>
            {caption}
          </option>
        ))}
      </Select>
      {enterAnimationDuration ? (
        <>
          <PropertyCaption>Enter Animation Length (s)</PropertyCaption>
          {/* todo: rework setting current value */}
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="start"
            defaultValue={
              props.activeElement?.source?.animations?.find(
                ({ time }: any) => time === 'start',
              )?.duration ?? '1'
            }
            unit="s"
            getInputValue={getInputValue}
          />
        </>
      ) : null}
      <PropertyCaption>Exit Animation</PropertyCaption>
      <Select
        value={exitAnimation}
        onChange={async (e) => {
          await setAnimation(
            'end',
            e.target.value,
            null,
            null,
            enterAnimationDuration,
          );
        }}
      >
        <option value="none">None</option>
        {Object.entries(animationTypes).map(([type, caption]) => (
          <option key={type} value={type}>
            {caption}
          </option>
        ))}
      </Select>
      {exitAnimationDuration ? (
        <>
          <PropertyCaption>Exit Animation Length (s)</PropertyCaption>
          {/* todo: rework setting current value */}
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="end"
            defaultValue={
              props.activeElement?.source?.animations?.find(
                ({ time }: any) => time === 'end',
              )?.duration ?? '1'
            }
            unit="s"
            getInputValue={getInputValue}
          />
        </>
      ) : null}
      <PropertyCaption>Element Animation</PropertyCaption>
      <Select
        value={elementAnimation}
        onChange={async (e) => {
          await setAnimation(
            null,
            animationTypeLookup(e.target.value),
            e.target.value,
            elementAnimationSpeed,
          );
        }}
      >
        <option key="none" value="none">
          None
        </option>
        {Object.entries(ElementAnimationTypes).map(([type, caption]) => (
          <option key={type} value={type}>
            {caption}
          </option>
        ))}
      </Select>
      {elementAnimationSpeed ? (
        <>
          <PropertyCaption>Element Animation Speed</PropertyCaption>
          <Select
            value={elementAnimationSpeed}
            onChange={async (e) => {
              await setAnimation(
                null,
                animationTypeLookup(elementAnimation),
                elementAnimation,
                e.target.value,
              );
            }}
          >
            {Object.entries(AnimationSpeed).map(([type, caption]) => (
              <option key={type} value={type}>
                {caption}
              </option>
            ))}
          </Select>
        </>
      ) : null}
    </Fragment>
  );
};

const ElementAnimationTypes = {
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  panLeft: 'Pan Left',
  panRight: 'Pan Right',
  panUp: 'Pan Up',
  panDown: 'Pan Down',
  panLeftWithZoom: 'Pan Left With Zoom',
  panRightWithZoom: 'Pan Right With Zoom',
  panUpWithZoom: 'Pan Up With Zoom',
  panDownWithZoom: 'Pan Down With Zoom',
};

interface AnimationSpeedProps {
  [index: string]: string;
}

const AnimationSpeed: AnimationSpeedProps = {
  '110%': 'Very Slow',
  '120%': 'Slow',
  '130%': 'Normal',
  '140%': 'Fast',
};

// Each of these animations has its own options
// For reference: https://github.com/Creatomate/creatomate-node/tree/main/src/animations
const GenericAnimationTypes = {
  fade: 'Fade',
  scale: 'Scale',
  slide: 'Slide',
  // 'rotate-slide': 'Rotate Slide',
  pan: 'Pan',
  // wipe: 'Wipe',
  // 'color-wipe': 'Color Wipe',
  // 'circular-wipe': 'Circular Wipe',
  'film-roll': 'Film Roll',
  // squash: 'Squash',
  // spin: 'Spin',
  // stripe: 'Stripe',
  flip: 'Flip',
  // shake: 'Shake',
  // bounce: 'Bounce',
  // wiggle: 'Wiggle',
  shift: 'Shift',
};

const TextAnimationTypes = {
  'text-appear': 'Text Appear',
  // 'text-scale': 'Text Scale',
  'text-slide': 'Text Slide',
  'text-reveal': 'Text Reveal',
  'text-fly': 'Text Fly',
  // 'text-spin': 'Text Spin',
  // 'text-wave': 'Text Wave',
  // 'text-counter': 'Text Counter',
  'text-typewriter': 'Text Typewriter',
  ...GenericAnimationTypes,
};

const ManualKaraoke = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;

  .animation-length {
    display: flex;
    flex-direction: column;
  }
`;
