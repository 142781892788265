// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS, runInAction } from 'mobx';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';

interface KeyPoint {
  time: string;
  value: string;
}

interface VolumeKeyPointsProps {
  id: string;
  height: number;
  type: string;
  element: ElementState;
}

const CONTAINER_PADDING_AND_BORDER_WIDTH = 6;

export const VolumeKeyPoints: React.FC<VolumeKeyPointsProps> = observer(
  (props) => {
    const active = videoCreator.activeElementIds.includes(props.id);
    const volumeKeyPoints =
      videoCreator.currentVideo?.extraElementData[props.element.source?.id]
        ?.volumeKeyPoints;

    // console.log('volumeKeyPoints top', volumeKeyPoints)
    const timelineScale = videoCreator.timelineScale;

    const [volume, setVolume] = useState(
      props.element?.source?.volume || '100 %',
    );
    const [volumeKeyPointsLocal, setVolumeKeyPointsLocal] = useState(
      toJS(volumeKeyPoints),
    );

    useEffect(() => {
      setVolume(props.element?.source?.volume || '100 %');
    }, [props.element?.source?.volume]);

    useEffect(() => {
      setVolumeKeyPointsLocal(toJS(volumeKeyPoints));
    }, [volumeKeyPoints]);

    const applyVolume = async () => {
      //todo move to state, push to undo/redo
      // console.log('applying volume', props.id, volume);
      await videoCreator.renderer?.applyModifications({
        [`${props.id}.volume`]: volume,
      });
    };

    const applyVolumeKeyPoints = async (newVolumeKeyPoints: any) => {
      if (newVolumeKeyPoints) {
        videoCreator.applyVolumeKeyPoints(props.id, newVolumeKeyPoints);
      }
    };

    const scaleVolumeY = (volume: string) => {
      if (volume) {
        const volumeValue = parseFloat(volume.replace('%', ''));
        const padding = props.type === 'audio' ? 24 : 16;
        return ((props.height - padding) * volumeValue) / 100 + 10;
      } else {
        return props.height / 2;
      }
    };

    const addNewKeyPoint = async (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      const x = e.clientX - e.currentTarget.getBoundingClientRect().left;
      const y = e.clientY - e.currentTarget.getBoundingClientRect().top;
      const time = x / timelineScale;
      const value = Math.max(
        0,
        Math.min(100, ((props.height - y) * 100) / (props.height - 15)),
      );
      const newKeyPoint = {
        time: `${time.toString()} s`,
        value: `${value.toString()} %`,
      };
      let newVolumeKeyPoints = [];
      if (!volumeKeyPointsLocal) {
        const startKeyPoint = {
          time: '0 s',
          value: props.element?.source?.volume || '100 %',
        };
        const endKeyPoint = {
          time:
            props.element?.source?.duration || `${props.element.duration} s`,
          value: props.element?.source?.volume || '100 %',
        };
        newVolumeKeyPoints = [startKeyPoint, newKeyPoint, endKeyPoint];
      } else {
        newVolumeKeyPoints = [...volumeKeyPointsLocal, newKeyPoint].sort(
          (a, b) => parseFloat(a.time) - parseFloat(b.time),
        );
      }

      runInAction(() => {
        setVolumeKeyPointsLocal(newVolumeKeyPoints);
        applyVolumeKeyPoints(newVolumeKeyPoints);
      });
    };

    const keyPointController = (index: number, x: number, y: number) => {
      return (
        <Draggable
          onStart={(e, data) => {
            return { startY: data.y, startX: data.x };
          }}
          onDrag={(e, data, context) => {
            const volumeMovement = context.startY - data.y;
            const timeMovement = data.x - context.startX;
            const timeOffset = timeMovement / timelineScale;
            const volumeOffset = (volumeMovement * 100) / props.height;
            if (volumeKeyPoints?.length > 0) {
              const origVolume = parseFloat(
                volumeKeyPoints[index].value
              );
              const origTime = parseFloat(
                volumeKeyPoints[index].time
              );
              // console.log('orig volume', origVolume, 'orig time', origTime, 'volume offset', volumeOffset, 'time offset', timeOffset)
              runInAction(() => {
                // only move time if not first or last key point
                if (index !== 0 && index !== volumeKeyPoints.length - 1) {
                  const minTime = parseFloat(
                    volumeKeyPoints[index - 1].time
                  );
                  const maxTime = parseFloat(
                    volumeKeyPoints[index + 1].time
                  );
                  volumeKeyPointsLocal[index].time = `${Math.min(
                    maxTime,
                    Math.max(origTime + timeOffset, minTime),
                  )} s`;
                }
                volumeKeyPointsLocal[index].value = `${Math.min(
                  100,
                  Math.max(origVolume + volumeOffset, 0),
                )} %`;
                setVolumeKeyPointsLocal([...volumeKeyPointsLocal]);
              });
            }
          }}
          onStop={() => {
            applyVolumeKeyPoints(volumeKeyPointsLocal);
          }}
        >
          {(ref) => (
            <Dot
              className="volume-key-point"
              ref={ref}
              cx={x}
              cy={y}
              r="5"
              color={active ? 'white' : 'black'}
              fill={active ? 'white' : 'gray'}
            />
          )}
        </Draggable >
      );
    };

    if (volumeKeyPointsLocal && volumeKeyPointsLocal.length) {
      const elementDuration =
        parseFloat(props.element?.source?.duration) ||
        props.element?.duration;
      const svgWidth =
        elementDuration * timelineScale - CONTAINER_PADDING_AND_BORDER_WIDTH;
      // key points
      return (
        <Fragment>
          <Container onDoubleClick={addNewKeyPoint}>
            <svg
              width={svgWidth}
              height={props.height}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                {volumeKeyPointsLocal.map((point, index) => {
                  const y = props.height - scaleVolumeY(point.value); // svg coordinates are y inverted
                  const x =
                    (parseFloat(point.time) * svgWidth) / elementDuration; //  * timelineScale;
                  let prevY =
                    props.height -
                    (index > 0
                      ? scaleVolumeY(volumeKeyPointsLocal[index - 1].value)
                      : 0); // svg coordinates are y inverted
                  let prevX =
                    index > 0
                      ? (parseFloat(volumeKeyPointsLocal[index - 1].time) *
                        svgWidth) /
                      elementDuration
                      : 0;
                  return (
                    <>
                      {index > 0 ? (
                        <line
                          x1={prevX}
                          y1={prevY}
                          x2={x}
                          y2={y}
                          stroke={active ? 'white' : 'gray'}
                        />
                      ) : null}
                      {keyPointController(index, x, y)}
                    </>
                  );
                })}
              </g>
            </svg>
          </Container>
        </Fragment>
      );
    } else {
      // straight line
      return (
        <Fragment>
          <Draggable
            onStart={(e, data) => {
              return { startY: data.y };
            }}
            onDrag={(e, data, context) => {
              const volumeMovement = context.startY - data.y;
              const volumeOffset = (volumeMovement * 100) / props.height;
              let origVolume = 100;
              if (props.element?.source?.volume) {
                origVolume = parseFloat(
                  props.element?.source?.volume.replace('%', '').trim(),
                );
              }
              setVolume(
                `${Math.min(100, Math.max(origVolume + volumeOffset, 0))} %`,
              );
            }}
            onStop={() => {
              applyVolume();
            }}
          >
            {(ref) => (
              <Container ref={ref} onDoubleClick={addNewKeyPoint}>
                <Line
                  className="volume-key-point"
                  text={parseFloat(volume).toFixed(0) + ' %'}
                  volumeheight={scaleVolumeY(volume)}
                  active={active}
                  size="10px"
                />
              </Container>
            )}
          </Draggable>
        </Fragment>
      );
    }
  },
);

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
`;

const Dot = styled.circle`
  pointer-events: auto;
  cursor: resize;
`;

const Line = styled.hr<{ volumeheight: number; text?: string }>`
  width: 100%;
  position: absolute;
  bottom: ${(props) => props.volumeheight}px;
  margin: 0px;
  pointer-events: auto;
  cursor: ns-resize;
  overflow: visible;
  color: white;
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  ${(props) =>
    props.text &&
    `
      &:after {
        content: '${props.text}';
        position: absolute;
        left: -1px;
        bottom: 0;
        top: 0;
        font-size: 10px;
        color: white;
        white-space: nowrap;
      }
    `}
`;
