import ApiUpload from './ApiUpload';
import ApiItem from './ApiItem';
import ApiItemType from './ApiItemType';
import ApiItemVersion from './ApiItemVersions';
import ApiUploadRequest from './ApiUploadRequest';

type RequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  body?: unknown;
  queryParams?: Record<string, any>;
};

export default class ApiClient {
  static readonly defaultBaseUrl = `${process.env.REACT_APP_API_URL}/api/dato`;

  async request<T>(options: RequestOptions): Promise<T> {
    try {
      const queryString =
        options.queryParams && Object.keys(options.queryParams).length > 0
          ? `?${new URLSearchParams(
              buildNormalizedParams(options.queryParams),
            ).toString()}`
          : '';

      const response = await fetch(
        `${ApiClient.defaultBaseUrl}${options.url}${queryString}`,
        {
          method: options.method,
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: options.body ? JSON.stringify(options.body) : undefined,
        },
      );
      return (await response.json()) as T;
    } catch (err: any) {
      console.error(err);
      throw new Error('Failed to fetch');
    }
  }

  authToken: string | null = null;
  environment: string | null = null;
  items: ApiItem;
  uploads: ApiUpload;
  itemTypes: ApiItemType;
  itemVersions: ApiItemVersion;
  uploadRequest: ApiUploadRequest;

  constructor(config: { authToken: string | null; environment: string }) {
    this.items = new ApiItem(this);
    this.uploads = new ApiUpload(this);
    this.itemTypes = new ApiItemType(this);
    this.itemVersions = new ApiItemVersion(this);
    this.uploadRequest = new ApiUploadRequest(this);

    this.authToken = config.authToken;
    this.environment = this.environment;
  }
}

export function buildNormalizedParams(
  input: Record<string, unknown>,
  path: string[] = [],
): [string, string][] {
  const result: [string, string][] = [];

  for (const [key, value] of Object.entries(input)) {
    if (typeof value === 'number' || typeof value === 'string') {
      result.push([buildKey([...path, key]), value.toString()]);
    } else if (value === true) {
      result.push([buildKey([...path, key]), 'true']);
    } else if (value === false) {
      result.push([buildKey([...path, key]), 'false']);
    } else if (typeof value === 'object') {
      if (Array.isArray(value)) {
        for (const innerValue of value) {
          result.push([`${buildKey([...path, key])}[]`, innerValue.toString()]);
        }
      } else if (value) {
        for (const param of buildNormalizedParams(
          value as Record<string, unknown>,
          [...path, key],
        )) {
          result.push(param);
        }
      }
    }
  }

  return result;
}

function buildKey(path: string[]) {
  return path.reduce(
    (result, chunk, index) => (index === 0 ? chunk : `${result}[${chunk}]`),
    '',
  );
}
