import { Client } from '@datocms/cma-client-browser';
import {
  ALBUM_QUERY,
  STORY_QUERY,
  SHARABLE_SHARED_CONTENT_QUERY,
} from '../utility/gql';
import { GraphQLClient } from 'graphql-request';
import {
  AiGeneratedContent,
  AlbumQueryResult,
  Artifact,
  PunchListItem,
  Story,
  StoryDTO,
  StoryQueryResult,
  SupportedGeneratedContentTypes,
  Video,
} from '../types.ts/story';
import TurndownService from 'turndown';
import { TalkingPointContent } from '../types.ts/general';
import ApiClient from '../apiClient/ApiClient';
const turndownService = new TurndownService();

export class StoryRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async findMany(albumId: string) {
    const response = (await this.gqlClient.request(ALBUM_QUERY, {
      id: albumId,
    })) as Record<'showcase', AlbumQueryResult>;
    if (!response?.showcase?.stories) return [];
    return response.showcase.stories;
  }

  async findOne(id: string): Promise<Story | null> {
    const response = (await this.gqlClient.request(STORY_QUERY, {
      id,
    })) as StoryQueryResult;
    if (!response.story) return null;

    // separate punchlist out of other ai generated content to work with it easier
    const punchList = response.story.aiResponse?.responses?.find(
      (item) => item.title === 'Photo Punchlist',
    ) as AiGeneratedContent<'Photo Punchlist'> | undefined;

    const followUpPunchList =
      response.story.aiResponse?.followUpResponses?.find(
        (item) => item.title === 'Photo Punchlist',
      ) as AiGeneratedContent<'Photo Punchlist'> | undefined;

    const storyClips = response.story.otherVideos?.map((clip) => ({
      ...clip,
    }));

    // get sharedContent for all sharableIMages
    // const sharableImages: SavedSharableImage[] = await Promise.all(
    //   (response.story.sharableImages ?? []).map(
    //     async (sharable: SavedSharableImage) => {
    //       // get sharedConent
    //       const sharedContents: any = await this.gqlClient.request(
    //         SHARABLE_SHARED_CONTENT_QUERY,
    //         {
    //           id: sharable.id,
    //         },
    //       );
    //       if (!sharedContents || !sharedContents.allSharedContents) {
    //         return sharable;
    //       }

    //       return {
    //         ...sharable,
    //         _allReferencingSharedContents: sharedContents.allSharedContents,
    //       };
    //     },
    //   ),
    // );

    const story: Story = {
      ...response.story,
      otherVideos: storyClips || [],
      punchList: punchList
        ? (punchList.response as unknown as PunchListItem[])
        : [],
      followUpPunchList: followUpPunchList
        ? (followUpPunchList.response as unknown as PunchListItem[])
        : [],
      // sharableImages: sharableImages || [],
    };
    return story;
  }

  async update(story: Partial<StoryDTO> & { id: string }): Promise<void> {
    if (!story.id) {
      throw new Error('Story id is required');
    }

    await this.dClient.items.update(story.id, {
      ...(story.title && {
        title: { en: story.title },
      }),

      ...(story.otherVideos && {
        other_videos: story.otherVideos.map((video) => video.id),
      }),

      ...(story.finalVideo && {
        final_video: story.finalVideo.id,
      }),

      ...(story.shareableImages && {
        shareable_images: story.shareableImages.map((s) => s.id),
      }),

      // Updating AI Generated Content is not implemented because the creator studio doesn't update it anywhere
      // if we decide to update AiGeneratedContent we need to take into account that punchList should be included it it
      // ...(story.aiGeneratedContent && {
      //   ai_generated_individual_content: await Promise.all(
      //     story.aiGeneratedContent.map(async (contentBlock) => {
      //       if (!contentBlock.id) {
      //         const itemType =
      //           await this.dClient.itemTypes.find('content_generation');
      //         const itemTypeId = itemType.id as string;
      //         const newBlock = buildBlockRecord({
      //           item_type: { type: 'item_type', id: itemTypeId as string },
      //           generated_content: JSON.stringify(
      //             contentBlock.generatedContent,
      //             null,
      //             2,
      //           ),
      //           prompt: contentBlock.prompt,
      //         });
      //         return newBlock;
      //       } else {
      //         return contentBlock.id;
      //       }
      //     }),
      //   ),
      // }),

      ...(story.aiPhotos && {
        ai_photos: await Promise.all(
          story.aiPhotos.map((photo) => ({ upload_id: photo.id })),
        ),
      }),

      ...(story.storyAssets && {
        story_assets: await Promise.all(
          story.storyAssets.map((photo) => ({ upload_id: photo.id })),
        ),
      }),

      ...(story.storyArtifacts && {
        story_artifacts: await Promise.all(
          story.storyArtifacts.map((photo) => ({ upload_id: photo.id })),
        ),
      }),

      ...(story.storyArtifactsVideo && {
        story_artifacts_video: await Promise.all(
          story.storyArtifactsVideo.map((video) => ({ upload_id: video.id })),
        ),
      }),
    });
    await this.dClient.items.publish(story.id);
  }

  async handleSaveAiGeneratedContent(
    storyId: string,
    data: any,
    type: 'saved_blog' | 'saved_email' | 'saved_talking_point_content',
  ) {
    await this.dClient.items.update(storyId, {
      [`${type}`]: JSON.stringify(data, null, 2),
    });
    await this.dClient.items.publish(storyId);
  }

  async saveTalkingPointContent(
    story: Pick<Story, 'id' | 'savedTalkingPointContent'>,
    data: Record<
      keyof TalkingPointContent,
      Record<'title' | 'content' | 'prompt', string>
    >,
    title: string,
  ) {
    const savedData = story?.savedTalkingPointContent || {};
    let existingKey: number | undefined = undefined;

    for (let [key, content_data] of Object.entries(savedData)) {
      const hasMatch = Object.entries(content_data.content).every(
        ([key, value]) => {
          const k = key as keyof TalkingPointContent;
          const enterContentMk = turndownService.turndown(data[k].content);
          const existingMk = turndownService.turndown(value.content);

          return existingMk === enterContentMk && value.title === data[k].title;
        },
      );
      if (hasMatch) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    savedData[new Date().getTime()] = { title, content: data };
    await this.handleSaveAiGeneratedContent(
      story.id,
      savedData,
      'saved_talking_point_content',
    );
    return savedData;
  }

  async saveBlogOrEmail(
    story: Pick<Story, 'id' | 'savedBlog' | 'savedEmail'>,
    data: string,
    title: string,
    userName: string | null | undefined,
    type: 'saved_blog' | 'saved_email',
  ) {
    let savedData = type === 'saved_email' ? story.savedEmail : story.savedBlog;

    savedData = savedData || {};
    const currDataMarkdown = turndownService.turndown(data);

    let existingKey: number | undefined = undefined;

    for (let [key, data] of Object.entries(savedData)) {
      const dataMarkdown = turndownService.turndown(data.content);
      if (currDataMarkdown === dataMarkdown) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    const username = userName ?? 'Arbor Admin';
    savedData[new Date().getTime()] = { title, content: data, username };
    await this.handleSaveAiGeneratedContent(story.id, savedData, type);
    return savedData;
  }

  public async createShareableImage(
    story: Pick<Story, 'id' | 'title'>,
    quote: string,
    imagefile?: Artifact,
  ) {
    const itemType = await this.dClient.itemTypes.find('shareable_image');
    const savedShareableImage = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType!.id },
      story_id: story.id,
      title: `${story.title} - ${story.id}`,
      quote,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
        },
      }),
    });
    return savedShareableImage.id;
  }

  public async updateShareableImage(
    id: string,
    quote: string,
    imagefile?: Artifact,
  ) {
    await this.dClient.items.update(id, {
      quote,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
          alt: imagefile.responsiveImage?.alt,
        },
      }),
    });
    return id;
  }

  async deleteTranscription(storyId: string) {
    await this.dClient.items.update(storyId, {
      transcription: null,
    });
    await this.dClient.items.publish(storyId);
  }
}
