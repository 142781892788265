import React, { useState, useRef } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';

type Props = {
  action: (propertyName: string, value: string | null) => void;
  propertyName: string;
  strokeColor: string;
  strokeWidth: number;
};
const StrokeSelect = (props: Props) => {
  const { strokeColor, strokeWidth } = props;
  const [showDropdown, toggleDropdown] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    toggleDropdown(false);
  });

  const renderDropdownItem = (line: number) => {
    const itemStrokeWidth = line * 0.25;
    return (
      <DropdownItem
        key={line}
        onClick={() => {
          props.action(props.propertyName, `${itemStrokeWidth} vmin`);
          toggleDropdown(false);
        }}
      >
        <StrokeLine
          height={line}
          color={
            strokeColor !== 'transparent' && strokeWidth === itemStrokeWidth
              ? '#f2d093'
              : undefined
          }
        />
      </DropdownItem>
    );
  };

  return (
    <Main ref={contentRef}>
      <StrokeLines onClick={(e) => toggleDropdown(!showDropdown)}>
        <StrokeLine
          height={1}
          color={strokeWidth === 0.25 ? '#f2d093' : undefined}
        />
        <StrokeLine
          height={2}
          color={strokeWidth === 0.5 ? '#f2d093' : undefined}
        />
        <StrokeLine
          height={3}
          color={strokeWidth === 0.75 ? '#f2d093' : undefined}
        />
      </StrokeLines>
      {showDropdown && (
        <Dropdown>{[1, 2, 3].map((line) => renderDropdownItem(line))}</Dropdown>
      )}
    </Main>
  );
};

export default StrokeSelect;

const Main = styled.div<{
  width?: string;
}>`
  position: relative;
`;

const StrokeLines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  border: 1px solid #484848;
  border-radius: 4px;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  padding: 5px;
`;

const StrokeLine = styled.span<{ height?: number; color?: string }>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.color || '#484848'};
`;

const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 12px 5px;
  width: calc(100% - 10px);
  overflow: auto;
  position: absolute;
  gap: 15px;
  z-index: 1000;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.div`
  // padding: 6px;
  display: flex;
  width: 100%;
  height: 8px;
  align-items: center;
  cursor: pointer;
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;
