import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Timeline } from './timeline/Timeline';
import { Stage } from './stage/Stage';
import { videoCreator } from '../stores/VideoCreatorStore';
import { MUSIC_QUERY } from '../utility/gql';
import { Music } from '../types.ts/story';
import { request } from '../utility/dato';
import { LeftSidePanel } from './sidepanel/LeftSidePanel';
import { RightSidePanel } from './sidepanel/RightSidePanel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';

export const VideoCreator: React.FC<{}> = observer((props) => {
  const story = videoCreator.story;
  const [music, setMusic] = useState<Music[] | undefined>();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const { enableAiImageGeneration } = useFlags();
  const { storyId, videoId } =
    Object.fromEntries(urlSearchParams.entries()) || {};
  const auth = useAuth();
  const authToken = videoCreator.datoContext.currentUserAccessToken;

  useEffect(() => {
    return () => {
      videoCreator.disposeRenderer();
    }
  }, []);

  useEffect(() => {
    videoCreator.setAiImageFeatureFlag(enableAiImageGeneration);
  }, [story?.id, enableAiImageGeneration]);

  useEffect(() => {
    videoCreator.justLoaded = true;
  }, [videoCreator.currentVideo?.id]);

  useEffect(() => {
    if (
      authToken &&
      auth.isLoaded &&
      (story?.id !== storyId ||
        (videoId && videoCreator.currentVideo?.id !== videoId))
    ) {
      videoCreator.initializeData({
        storyId,
        videoId,
        showcaseSlug: null,
      });
    }
  }, [auth.isLoaded, authToken, storyId, videoId]);

  useEffect(() => {
    (async () => {
      const response = (await request({
        query: MUSIC_QUERY,
        variables: undefined,
        environment: videoCreator.datoContext.environment,
      })) as { allMusics: Music[] };
      setMusic(response.allMusics);
    })();
  }, [story?.id]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space') {
      videoCreator.isPlaying ? videoCreator.renderer?.pause() : videoCreator.renderer?.play();
    }
  };

  return (
    <Main
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      <MainView>
        <LeftSidePanel />
        <Stage storyTellerName={story?.storyTeller?.name || ''} />
        <RightSidePanel story={story} music={music} />
      </MainView>
      <Timeline story={story} />
    </Main>
  );
});

const Main = styled.div`
  width: 100%;
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;

const MainView = styled.div`
  flex: 1 1 0%;
  display: flex;
  overflow: hidden;
`;

const SideView = styled.div`
  display: flex;
`;
