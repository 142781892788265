import React, {
  useRef,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import { Music } from '../../types.ts/story';
import styled from 'styled-components';
import { getSongDuration } from '../../utility/timeFormat';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { SidebarOption } from '../../types.ts/general';
import { WaveForm } from '../timeline/WaveForm';
import PlayIcon from '../../svgs/PlayIcon';
import { observer } from 'mobx-react-lite';
import WaveformData from 'waveform-data';

type CollectionProps = {
  song: Music['collection'][0];
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  handleAddToTrack?: (url: string, songName: string) => void;
  currentSong: string;
  setCurrentSong: Dispatch<SetStateAction<string>>;
  mood: string;
  customTimelineScale: number;
};

const MusicSongItem = observer((props: CollectionProps) => {
  const { song, audioRef, currentSong, setCurrentSong, customTimelineScale } =
    props;

  const [resampledWaveform, setResampledWaveform] = useState<WaveformData | null>(null)
  //@ts-ignore
  const waveform: WaveformData | undefined | null = videoCreator.audioTracksData[song.url]?.waveform;

  useEffect(() => {
    const currentAudio = audioRef.current;
    currentAudio?.addEventListener('ended', handleAudioEnd);

    return () => {
      currentAudio?.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  useEffect(() => {
    const currentAudio = audioRef.current;
    if (!currentAudio) return;

    const handleCanPlayThrough = () => {
      currentAudio.play();
    };

    currentAudio.addEventListener('canplaythrough', handleCanPlayThrough);

    return () => {
      currentAudio.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, [audioRef]);

  useEffect(() => {
    resampleWaveform()
  }, [customTimelineScale, waveform])

  const resampleWaveform = () => {
    if (!waveform) {
      console.log('No waveform', song.url);
      return;
    }

    const maxTimelineScale = 180;
    let timelineScale = Math.min(
      customTimelineScale,
      maxTimelineScale,
      Math.floor(waveform.sample_rate / waveform.scale)
    )
    // MAX_WAVEFORM_SCALE = 256 is max scale for waveform

    let scale = Math.floor(waveform.sample_rate / timelineScale);
    setResampledWaveform(waveform.resample({ scale }))
  };

  const handleAudioEnd = (e: Event) => {
    const currentAudio = e.target as HTMLAudioElement;
    if (!currentAudio) return;
    currentAudio.pause();
    currentAudio.src = '';
    currentAudio.id = '';
    setCurrentSong('');
  };

  const pauseAudio = (
    url: string,
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      const currentAudio = audioRef.current;
      if (!currentAudio) return;

      if (id === currentAudio.id && !currentAudio.paused) {
        currentAudio.pause();
        currentAudio.src = '';
        currentAudio.id = '';
        setCurrentSong('');
      }
    } catch (error) {
      console.log('err:', error);
    }
  };

  const playAudio = (
    url: string,
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    try {
      const currentAudio = audioRef.current;
      if (!currentAudio) return;

      if (id === currentAudio.id && !currentAudio.paused) return;
      currentAudio.src = url;
      currentAudio.play();
      currentAudio.id = id;
      setCurrentSong(id);
    } catch (error) {
      console.log('err:', error);
    }
  };

  const PauseIcon = () => (
    <svg viewBox="0 0 24 24" width="24" height="24" fill="#F2D093">
      <path d="M14,19H18V5H14M6,19H10V5H6V19Z" />
    </svg>
  );

  return (
    <Song
      onClick={() => {
        if (props.handleAddToTrack) {
          props.handleAddToTrack(song.url, song.customData?.songName);
        } else {
          videoCreator.sidebarOptions = SidebarOption.editing;
        }
      }}
    >
      <Content>
        <TextContent>
          <h5>{song.customData.songName || ''}</h5>
          <span className="song-duration">
            {getSongDuration(song.customData.duration)}
          </span>
          <span className="mood-tag">{props.mood}</span>
        </TextContent>
        <Play>
          {song.id !== currentSong ? (
            <ActionButton onClick={(e) => playAudio(song.url, song.id, e)}>
              <PlayIcon />
            </ActionButton>
          ) : (
            <ActionButton onClick={(e) => pauseAudio(song.url, song.id, e)}>
              <PauseIcon />
            </ActionButton>
          )}
        </Play>
      </Content>
      <WaveWrapper>
        <WaveForm
          id={`waveform-canvas-${song.customData.songName
            ?.split(' ')
            .join('_')}`}
          url={song.url}
          type={'audio'}
          trim_start={'0'}
          duration={song.customData.duration}
          active={true}
          height={40}
          isInMusicProducer={true}
          customColor="#C9A9E9"
          defaultCustomBrightness="48%"
          customResampleWaveform={resampledWaveform}
        />
      </WaveWrapper>
    </Song>
  );
});
export default MusicSongItem;

const Song = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  width: 320px;

  cursor: pointer;
  padding: 10px;
`;

const ActionButton = styled.button`
  background: transparent;
  cursor: pointer;
  outline: 0;
  border: 0;
`;

const Play = styled.div`
  z-index: 2;
`;

const Thumbnail = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #484848;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  align-items: end;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  h5 {
    color: #f3e9d7;
    font-size: 10px;
    font-weight: 700;
    margin: 0;
    width: 180px;
    text-overflow: ellipsis;
    text-transform: capitalize;
    width: 100%;
  }

  .song-duration {
    color: #17c964;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 4px;
  }

  .mood-tag {
    display: flex;
    padding: 4px 8px;
    margin: 2px 0;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #484848;
    color: #484848;
    font-size: 10px;
    font-weight: 500;
  }
`;

const WaveWrapper = styled.div`
  height: 40px;
  margin-top: -5px;
  width: 100%;
`;
