import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import useInitializeDatoUser from './common/useInitializeDatoUser';
import { videoCreator } from '../stores/VideoCreatorStore';
import { AlbumQueryResult, PhotoTab, Video } from '../types.ts/story';
import { convertTimeToMMSS } from '../utility/timeFormat';
import { Link } from 'react-router-dom';
import { Studio } from '../types.ts/general';
import SearchInput from './sidepanel/SearchInput';
import SocialShareStatus from './content-components/SocialShareStatus';
import SocialStats from './content-components/SocialStats';
import { gptService } from '../services/ChatGPTService';

type Props = {
  params: {
    [k: string]: string;
  };
};

const getThumbnailUrl = (story: AlbumQueryResult['stories'][0]) => {
  if (story.thumbnail?.url) return story.thumbnail?.url;
  if (story?.finalVideo?.thumbnail?.url) {
    return story?.finalVideo?.thumbnail?.url;
  }

  return story?.originalVideo?.video?.thumbnailUrl;
};

const videoDuration = (story: AlbumQueryResult['stories'][0]) => {
  const finalVideoDuration =
    story?.finalVideo?.videoFilePrimary?.video?.duration;
  if (finalVideoDuration) {
    return convertTimeToMMSS(finalVideoDuration);
  }

  const originalVideoDuration = story?.originalVideo?.video?.duration;

  if (originalVideoDuration) {
    return convertTimeToMMSS(Number(originalVideoDuration));
  }
};

const getDescription = (story: AlbumQueryResult['stories'][0]) => {
  const descriptionResponse = story?.aiResponse?.responses?.find(
    (response) => response.title === 'Description',
  );
  return descriptionResponse?.response?.toString() || '';
};

const StoryDashboard: FC<Props> = observer((props) => {
  useInitializeDatoUser();
  const album = videoCreator.organization;
  const [stories, setStories] = useState<AlbumQueryResult['stories']>([]);
  const [allStories, setAllStories] = useState<AlbumQueryResult['stories']>([]);
  const urlSearchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      const stories = (await videoCreator.storyRepository?.findMany(
        album.id,
      )) as AlbumQueryResult['stories'];
      setAllStories(stories);
      setStories(stories);
    })();
  }, [album]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId')
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoTab.artifact,
        resource: undefined
      }
      videoCreator.talkingPointContent = null
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const Caret = () => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M1 0.5L5 4.5L9 0.5" stroke="#848484" />
    </svg>
  );

  const renderTableContent = (story: AlbumQueryResult['stories'][0]) => {
    const currentVideo =
      (story?.finalVideo as Video | undefined)?.videoFilePrimary?.video ||
      story?.originalVideo?.video;
    return (
      <>
        <HorizontalLine />
        <Row>
          <ThumbnailColumn>
            <Link
              style={{ height: '100%' }}
              to={`/${Studio.content}?storyId=${story.id
                }&env=${urlSearchParams.get('env')}&playbackId=${currentVideo.muxPlaybackId || ''}`}
              onClick={() => resetStoryStates(story.id)}
            >
              <Thumb src={getThumbnailUrl(story)} />
            </Link>
            <Info>
              <span className="story-title">{story.title}</span>
              <span className="teller-name">{story.storyTeller.name}</span>
              <span className="duration">{videoDuration(story)}</span>
            </Info>
          </ThumbnailColumn>

          {/* <StoryTeller>{story.storyTeller.name}</StoryTeller> */}

          <StoryDescription>{getDescription(story)}</StoryDescription>
          <Platform>
            <SocialShareStatus
              showOnlyShared={true}
              sharedContents={story?._allReferencingSharedContents}
            />
          </Platform>
          <Analytics>
            <SocialStats
              type="story"
              id={story?.id}
              allReferencingSharedContents={story?._allReferencingSharedContents}
              layout='dashboard'
            />
          </Analytics>

          <PublishedDate>
            {new Date(story._publishedAt).toLocaleDateString()}
          </PublishedDate>
        </Row>
      </>
    );
  };

  const renderTableHeader = () => (
    <>
      <RowHeader>
        <ThumbnailHeader>
          <span>Story</span>
        </ThumbnailHeader>
        {/* <StoryTellerHeader>
          <span>Storyteller</span>
        </StoryTellerHeader> */}
        <SummaryHeader>
          <span>Summary</span>
        </SummaryHeader>
        <PlatformHeader><span>Platform</span></PlatformHeader>
        <AnalyticsHeader><span>Analytics</span></AnalyticsHeader>

        <DateHeader>
          <span>Published</span>
        </DateHeader>
      </RowHeader>
    </>
  );

  const storyContent = stories?.filter((s) => s.finalVideo || s.originalVideo);
  const storyHeader = {} as AlbumQueryResult['stories'][0];
  const storyData = [storyHeader, ...storyContent];

  const handleSearchContent = (data: string) => {
    if (!data.length) {
      setStories(allStories);
      return;
    }
    const value = data.toLowerCase();
    const stories = allStories.filter((s) => {
      const summary = getDescription(s);
      return (
        s.storyTeller.name.toLowerCase().includes(value) ||
        s.title.toLowerCase().includes(value) ||
        summary.toLowerCase().includes(value)
      );
    });
    setStories(stories);
  };

  return (
    <Main>
      <TopContent>
        <Title>Story Dashboard</Title>
        <SearchInput
          iconRight={true}
          placeholder="Search your stories"
          radius="10px"
          width="300px"
          handleAction={handleSearchContent}
          autoSubmitDelay={500}
        />
      </TopContent>

      <Table>
        {storyData.map((story, index) => {
          return (
            <div key={story.id}>
              {index === 0 ? renderTableHeader() : renderTableContent(story)}
            </div>
          );
        })}
      </Table>
    </Main>
  );
});

export default StoryDashboard;

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: 20px;
`;

const HorizontalLine = styled.div`
  min-width: 1190px;
  width: 100%;
  height: 1px;
  background-color: #848484;
`;
const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const Row = styled.div`
  display: flex;
  // border-top: 1px solid #848484;
  height: 90px;
  gap: 30px;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowHeader = styled(Row)`
  border-top: unset;
  height: unset;
  margin-top: 20px;
  padding-bottom: 10px;
  text-align: left;
`;
const Column = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 183px;
  width: 183px;
`;

const ThumbnailColumn = styled(Column)`
  gap: 15px;
  min-width: 350px;
`;

const ThumbnailHeader = styled(ThumbnailColumn)`
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  align-items: center;
  width: 350px;
`;
const ColumnHeader = styled(Column)`
  text-align: left;
  min-width: 200px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
`;

const SummaryHeader = styled(ColumnHeader)`
  width: 300px;
  min-width: 300px;
`;

const Thumb = styled.img`
  width: 140px;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1,
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  .story-title {
    font-weight: 700;
    font-size: 14px;
    color: #f3e9d7;
    text-overflow: ellipsis;
  }
  .teller-name,
  .duration {
    font-weight: 500;
    font-size: 12px;
    color: #848484;
  }
`;

const StoryDescription = styled(Column)`
  font-weight: 400;
  font-size: 12px;
  width: 300px;
  min-width: 300px;

  line-height: 15px;
  color: #f3e9d7;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  max-height: 90px;
`;

const StoryTeller = styled(Column)`
  width: 190px;
  min-width: 200px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const StoryTellerHeader = styled(StoryTeller)``;

const Platform = styled(Column)`
  width: 170px;
  min-width: 170px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const PlatformHeader = styled(Platform)``;

const Analytics = styled(Column)`
  width: 100px;
  min-width: 100px;
  // font-weight: 500;
  
`;

const AnalyticsHeader = styled(Analytics)`
  font-size: 12px;
  color: #848484;
`;


const PublishedDate = styled(Column)`
  font-weight: 400;
  text-align: right;
  color: #f3e9d7;
  margin-left: 50px;
  justify-content: flex-end;
  width: 80px;
  min-width: 80px;
`;

const DateHeader = styled(PublishedDate)`
  font-size: 12px;
  font-weight: 600;
  color: #848484;
`;
