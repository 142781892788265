import { InstagramAnimationLines } from '../components/sidepanel/AnimationSettingsUI';
import { Renderer } from '../renderer/Renderer';
import {
  KARAOKE_TRACK_NUMBER,
  videoCreator,
} from '../stores/VideoCreatorStore';
import {
  TranscriptElement,
  TranscriptPunctElement,
  TranscriptTextElement,
  getClosestNotRemovedTextIndexToRight,
} from './utils';
import { v4 as uuid } from 'uuid';

export type KaraokeElement = {
  id: string;
  ts: number;
  endTs: number;
  text: string;
};

export type KaraokeConfig = {
  width: string;
  font_size: string;
  font_weight: string;
  fill_color: string;
  background_color: string | null;
  text_wrap: boolean;
  x_alignment: string;
  x: string | null;
  y: string;
  line_height: string;
  font_family: string;
  font_style?: string;
  text_transform?: string;
  stroke_color: string;
  stroke_width: string;
  animations: any[];
  // maxCharactersPerElement: number; hardcoded in produceKaraokeElements
  hideComma: boolean;
  hidePeriod: boolean;
  hideFillers: boolean;
  instagramEffect: boolean;
  instagramLines: keyof typeof InstagramAnimationLines;
  language: 'original' | 'english';
};

const MAX_GAP_DURATION = 1; // in second
export const MAX_CHARS_INSTAGRAM = 12;
export const MAX_CHARS_DEFAULT = 45;
const FILLER_WORDS = [
  'Umm',
  'Um',
  'Uh',
  'Uhh',
  'Eh',
  'Just',
  'You know',
  'Ya know',
  'Well',
  'So',
  'Actually',
  'Basically',
  'I mean',
  'Really',
  'Hmm',
  'Hm',
  'Ah',
  'Ahh',
  'Er',
];

export const DEFAULT_KARAOKE_CONFIG = {
  width: '80%',
  font_size: '36',
  font_weight: '600',
  fill_color: 'white',
  background_color: 'rgba(0,0,0,0.8)',
  text_wrap: true,
  x_alignment: '50%',
  y: '80%',
  x: '50%',
  line_height: '125%',
  font_family: 'Inter',
  font_style: 'normal',
  stroke_color: 'transparent',
  stroke_width: '0.25 vmin',
  animations: [] as any[],
  // maxCharactersPerElement: MAX_CHARS_DEFAULT, hardcoded in produceKaraokeElements
  hideComma: false,
  hidePeriod: false,
  hideFillers: false,
  instagramEffect: false,
  instagramLines: 5,
  language: 'original',
} as KaraokeConfig;

const getParts = (element: TranscriptElement): TranscriptElement[] => {
  let part;
  const text = element.value!;
  if (text.trim().length === 0) {
    part = element;
    part.value = ' ';
    return [part];
  }

  if (text.trim().split(' ').length === 1 || element.type !== 'text')
    return [element];

  const parts = text.split(' ');
  const elementDuration = element.end_ts! - element.ts!;
  const partDuration = elementDuration / parts.filter((p) => !!p).length;
  return parts
    .flatMap((e, ind) => [
      e
        ? ({
            type: 'text',
            value: e,
            ts: element.ts + ind * partDuration,
            end_ts: element.ts! + (ind + 1) * partDuration,
          } as TranscriptTextElement)
        : null,
      {
        type: 'punct',
        value: ' ',
      } as TranscriptPunctElement,
    ])
    .filter((p) => p && p.value) as TranscriptElement[];
};

export default class KaraokeProducer {
  private transcriptionElements?: TranscriptElement[];
  private renderer?: Renderer;
  private karaokeElements?: KaraokeElement[];
  private karaokeConfig: KaraokeConfig | null = DEFAULT_KARAOKE_CONFIG;

  hasElements() {
    return this.karaokeElements && this.karaokeElements.length > 0;
  }

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  getKaraokeConfig() {
    return this.karaokeConfig;
  }

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    this.transcriptionElements = transcriptionElements;
  }

  async deleteKaraokeElements() {
    const source = this.renderer!.getSource();
    source.elements = source.elements.filter(
      (el: any) => el.track < KARAOKE_TRACK_NUMBER,
    );
    await this.renderer!.setSource(source);
    this.karaokeElements = [];
  }

  public karaokeElement() {
    const source = this.renderer!.getSource();
    const element = source?.elements?.find(
      (el: any) => el.track === KARAOKE_TRACK_NUMBER,
    );
    return element;
  }

  setKaraokeElementsFromSource(source: any) {
    // parse source elements to restore karaoke elements
    const sourceElements = source?.elements?.filter(
      (el: any) => parseInt(el.track) >= KARAOKE_TRACK_NUMBER,
    );

    if (sourceElements?.length === 0) {
      this.karaokeElements = [];
      return;
    }

    if (sourceElements?.[0]?.type === 'composition') {
      this.karaokeElements = sourceElements.flatMap((el: any) => {
        const compositionTs = parseFloat(el.time);
        return el.elements.map((subEl: any) => ({
          id: subEl.id,
          ts: compositionTs + parseFloat(subEl.time),
          endTs:
            compositionTs + parseFloat(subEl.time) + parseFloat(subEl.duration),
          text: subEl.text,
        }));
      });
    }

    if (sourceElements?.[0]?.type === 'text') {
      this.karaokeElements = sourceElements.map((el: any) => ({
        id: el.id,
        ts: parseFloat(el.time),
        endTs: parseFloat(el.time) + parseFloat(el.duration),
        text: el.text,
      }));
    }
  }

  setConfig(config: Partial<KaraokeConfig>) {
    this.karaokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...config,
    };
  }

  async produceKaraoke(config?: KaraokeConfig) {
    videoCreator.karaokeLoading = true;
    const originalLanguage = videoCreator.originalTranscription?.language;

    this.karaokeConfig = config || this.karaokeConfig;
    if (!this.karaokeConfig) throw new Error('No config provided');

    if (
      !originalLanguage?.includes('en') &&
      this.karaokeConfig.language.includes('en')
    ) {
      await this.produceKaraokeElementsFromSubtitles();
    } else {
      this.produceKaraokeElements(
        this.karaokeConfig.instagramEffect
          ? MAX_CHARS_INSTAGRAM
          : MAX_CHARS_DEFAULT,
      );
    }

    if (this.karaokeConfig.instagramEffect) {
      await this.renderInstagramElements();
    } else {
      await this.renderKaraokeElements();
    }
    videoCreator.karaokeLoading = false;
    // this.saveExtraElementsData();
  }

  async produceKaraokeElementsFromSubtitles() {
    let subtitles = videoCreator.currentVideo?.subtitles;
    if (!subtitles) {
      const generatedSubtitles =
        await videoCreator.requestSubtitlesForCurrentVideo();
      if (!generatedSubtitles) {
        throw new Error('No subtitles generated');
      }
      subtitles = generatedSubtitles;
    }
    this.karaokeElements = subtitles.lines.map((line) => ({
      id: uuid(),
      ts: line.start,
      endTs: line.end,
      text: line.translated,
    }));
  }

  produceKaraokeElements(maxCharsInLine: number = 12) {
    const resultedKaraokeElements: KaraokeElement[] = [];
    let currentKaraokeElement: KaraokeElement = {
      id: uuid(),
      ts: -1,
      endTs: -1,
      text: '',
    };
    let isStartOfSentence = true;

    for (let i = 0; i < this.transcriptionElements!.length; i++) {
      const element = this.transcriptionElements![i];

      if (
        element.state === 'removed' ||
        element.state === 'cut' ||
        element.state === 'muted' ||
        !element.value
      )
        continue;

      const parts = getParts(element);
      // in case of one transcription element contains multiple words
      for (const part of parts) {
        if (
          part.type === 'text' &&
          part.value &&
          currentKaraokeElement.text.trim().length > 0 &&
          (currentKaraokeElement.text.length + part.value!.length >
            maxCharsInLine ||
            element.ts! - currentKaraokeElement.endTs > MAX_GAP_DURATION ||
            (isStartOfSentence &&
              currentKaraokeElement.text.length >
                Math.round(0.7 * maxCharsInLine)))
        ) {
          currentKaraokeElement.text = currentKaraokeElement.text.trim();
          if (currentKaraokeElement.ts > currentKaraokeElement.endTs) {
            // todo temp hotfix
            const endTs = currentKaraokeElement.ts;
            currentKaraokeElement.endTs = currentKaraokeElement.ts;
            currentKaraokeElement.ts = endTs;
          }
          resultedKaraokeElements.push(currentKaraokeElement);
          currentKaraokeElement = {
            id: uuid(),
            ts: -1,
            endTs: -1,
            text: '',
          };
        }

        isStartOfSentence =
          element.value === '.' ||
          (isStartOfSentence && element.type !== 'text');

        currentKaraokeElement.text += part.value;
        if (part.type === 'text') {
          currentKaraokeElement.ts =
            currentKaraokeElement.ts < 0 ? part.ts! : currentKaraokeElement.ts;
          currentKaraokeElement.endTs = part.end_ts!;
        }
      }
    }
    if (currentKaraokeElement.text.trim().length > 0) {
      if (currentKaraokeElement.ts > currentKaraokeElement.endTs) {
        // todo temp fix
        const endTs = currentKaraokeElement.ts;
        currentKaraokeElement.endTs = currentKaraokeElement.ts;
        currentKaraokeElement.ts = endTs;
      }
      resultedKaraokeElements.push(currentKaraokeElement);
    }

    this.karaokeElements = resultedKaraokeElements;
  }

  estimateTextWidth(text: string, fontSize: number) {
    return text.length * fontSize * 0.6;
  }

  private sanitizedText(rawText: string, config: KaraokeConfig) {
    let text = rawText;
    if (config.hideFillers) {
      text = this.capitalizeAfterPeriods(this.removeFillerWords(text).trim());
    }

    if (config.hideComma) {
      text = text.replaceAll(',', '');
    }
    if (config.hidePeriod) {
      text = text.replaceAll('.', '');
    }
    return text;
  }

  private removeFillerWords(text: string) {
    const fillerRegex = new RegExp(
      `(?:\\b|^)(?:${FILLER_WORDS.join('|')})(?:,\\s*)?(?=\\b|$)`,
      'gi',
    );
    return text.replace(fillerRegex, '');
  }

  private capitalizeAfterPeriods(text: string): string {
    let capitalizeNext = true;
    return text.replace(
      /([.!?])\s*(\w)/g,
      (match: string, punctuation: string, letter: string): string => {
        if (capitalizeNext) {
          capitalizeNext = false;
          return punctuation + ' ' + letter.toUpperCase();
        } else {
          return match;
        }
      },
    );
  }

  transformToInstagramElements(
    elements: KaraokeElement[],
    config: KaraokeConfig,
  ) {
    const source = this.renderer!.getSource();
    const videoWidth = source.width;
    const videoHeight = source.height;
    const avgFontSize = Number(config.font_size);
    const lines = Number(config.instagramLines) || 5;
    const largeLines = Math.floor((2 * lines) / 5);

    const instagramElements: (KaraokeElement & {
      fontSize?: number;
      size?: 'large' | 'small';
    })[][] = [];

    let currentInstagramElement = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (
        currentInstagramElement.length === lines ||
        (i > 0 && element.ts - elements[i - 1].endTs > MAX_GAP_DURATION)
      ) {
        instagramElements.push(currentInstagramElement);
        currentInstagramElement = [];
      }

      currentInstagramElement.push(element);
    }
    // last one
    if (currentInstagramElement.length > 0) {
      instagramElements.push(currentInstagramElement);
    }

    const resultedKaraokeElements = [];
    for (const instagramElement of instagramElements) {
      const karaokeLines = [];
      const elementTs = instagramElement[0].ts;
      const elementEndTs = instagramElement[instagramElement.length - 1].endTs;

      const secondsPerCharInLine = instagramElement.map((el, index) => ({
        index,
        tsPerChar: (el.endTs - el.ts) / el.text.length,
      }));

      secondsPerCharInLine.sort((a, b) => b.tsPerChar - a.tsPerChar);
      secondsPerCharInLine.slice(0, largeLines).forEach((el) => {
        instagramElement[el.index].fontSize = Math.round(
          1.15 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramElement[el.index].size = 'large';
      });
      secondsPerCharInLine.slice(largeLines).forEach((el) => {
        instagramElement[el.index].fontSize = Math.round(
          0.75 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramElement[el.index].size = 'small';
      });

      // TODO replace with width and height params
      // + composition position
      let linePosY = Math.round((parseFloat(config.y) * videoHeight) / 100);
      let linePosX = videoWidth / 2;

      for (let k = 0; k < instagramElement.length; k++) {
        const karaokeLine = instagramElement[k];
        const nextLine = instagramElement[k + 1];
        const time = Math.max(elementTs, karaokeLine.ts - 0.2);
        const duration = elementEndTs - time;
        karaokeLines.push({
          id: uuid(),
          time,
          duration,
          type: 'text',
          text: this.sanitizedText(karaokeLine.text, config),
          background_y_padding: '5%',
          background_x_padding:
            Math.round((avgFontSize / karaokeLine.fontSize!) * 16) + '%',
          ...config,
          // IMPORTANT: values below override config
          text_transform: 'uppercase',
          font_size: null, //currentWordElement.fontSize!,
          height: Math.ceil(1.15 * karaokeLine.fontSize!),
          y: linePosY,
          x: linePosX,
          animations: config.animations.map((anim: any) => ({
            ...anim,
            duration: 0.1, // Math.max(karaokeLine.endTs! - karaokeLine.ts!, 0.1),
            fade: false,
            background_effect: 'animated',
          })),
        });

        linePosY += Math.ceil(
          0.55 * karaokeLine.fontSize! + 0.55 * (nextLine?.fontSize || 0),
        );
      }
      resultedKaraokeElements.push(karaokeLines);
    }

    return resultedKaraokeElements;
  }

  getCompositionElement(elements: any[]): {
    time: any;
    type: string;
    track: number;
    elements: any[];
    [key: string]: any; // Index signature
  } {
    const relativeTime = elements[0].time;
    return {
      time: relativeTime,
      type: 'composition',
      track: KARAOKE_TRACK_NUMBER,
      elements: elements.map((el, index) => {
        el.time -= relativeTime;
        el.track = index + 1; // track inside composition
        return el;
      }),
    };
  }

  async renderInstagramElements() {
    if (!this.karaokeConfig) throw new Error('No karaoke config');

    const source = this.renderer!.getSource();
    let track = KARAOKE_TRACK_NUMBER; //karaoke is fixed on top track

    source.elements = source.elements.filter((el: any) => el.track < track);
    source.elements = [
      ...source.elements,
      ...this.transformToInstagramElements(
        this.karaokeElements!,
        this.karaokeConfig,
      ).map((karaokeElement: any[]) => {
        let composition = this.getCompositionElement(karaokeElement);
        composition.x = this.karaokeConfig!.x;
        return composition;
      }),
    ];
    await this.renderer!.setSource(source);
  }

  async renderKaraokeElements() {
    const config = this.karaokeConfig;
    if (!config) throw new Error('No karaoke config');
    const source = this.renderer!.getSource();
    // check if top track is free
    let track = KARAOKE_TRACK_NUMBER; //fix karaoke on top track
    console.log('config', config);
    source.elements = source.elements.filter((el: any) => el.track < track);

    for (let i = 0; i < this.karaokeElements!.length; i++) {
      const element = this.karaokeElements![i];
      const duration =
        element.endTs - element.ts > 0 ? element.endTs - element.ts : 0.1; //MIN DURATION
      source.elements.push({
        id: element.id,

        track: track,
        time: element.ts,
        duration,
        type: 'text',
        text: this.sanitizedText(element.text, config),
        ...config,

        animations: config.animations.map((anim: any) => ({
          ...anim,
          duration: Math.max(0.1, duration - 0.2),
          fade: false,
          background_effect: 'animated',
        })),
      });
    }

    await this.renderer!.setSource(source);
  }
}
