import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  Artifact,
  PhotoAsset,
  FileData,
  PhotoTab,
} from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';

type Props = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  Button?: React.ReactNode;
  showToggle?: boolean;
  width?: string;
  onClick: () => void;
  type?: 'storyArtifacts' | 'storyArtifactsVideo' | 'all'
  callback?: (artifacts: Artifact[]) => void;
};

const FileUpload = (props: Props) => {
  const { setIsLoading, showToggle = true, type = "all" } = props;
  const [showDropdown, toggleDropdown] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(false);
  });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files || [])];
  
    if (!files.length) return;
    try {
      setIsLoading(true);
      const uploadResults = await Promise.all(
        files.map(async (file) => {
          const fileName = file.name.split('.')[0];
          const newFileData: FileData & { fileName: string } = {
            type: 'artifact',
            file,
            fileName,
            alt: '',
            title: '',
          };

          const newUpload =
            await videoCreator.assetRepository?.uploadFile(newFileData);
          return newUpload;
        }),
      );

      const photoUploadIds = uploadResults.filter(u => u?.is_image)
      .map((upload) => upload?.id)
      .filter(Boolean);

      const videoUploadIds = uploadResults.filter(u => !u?.is_image)
      .map((upload) => upload?.id)
      .filter(Boolean);

      const storyData = videoCreator.story;

      if (photoUploadIds?.length) {
        const existingArtifactsIds = storyData?.storyArtifacts?.map((a) => a.id) || [];
        const artifactIds = [...existingArtifactsIds, ...photoUploadIds].map(
          (id) => ({ id }),
        );

        storyData!.storyArtifacts! = artifactIds as Artifact[];
      }

      if (videoUploadIds?.length) {
        const existingArtifactsIds = storyData?.storyArtifactsVideo?.map((a) => a.id) || [];
        const artifactIds = [...existingArtifactsIds, ...videoUploadIds].map(
          (id) => ({ id }),
        );

        storyData!.storyArtifactsVideo! = artifactIds as Artifact[];
      }
      await videoCreator.storyRepository?.update(storyData!);
      const story = await videoCreator.storyRepository!.findOne(storyData!.id)!
      if (story) videoCreator.story = story

      if (props.callback) props.callback(videoCreator.story!.storyArtifactsVideo!)

      if (type === "storyArtifacts") {
        const photoArtifactsData = videoCreator.story!.storyArtifacts
          ?.filter((a) => a.responsiveImage)
          .map((a) => ({
            id: a.id,
            title: a.title,
            src: a.responsiveImage?.srcSet,
            type: 'artifact',
            ...a.customData,
          })) as PhotoAsset[];

        videoCreator.selectedPhotoAssets = {
          tab: PhotoTab.artifact,
          resource: photoArtifactsData,
          lastSelectedStock: videoCreator.selectedPhotoAssets.lastSelectedStock,
          lastSelectedAi: videoCreator.selectedPhotoAssets.lastSelectedAi,
          selectedId: videoCreator.selectedPhotoAssets.selectedId,
        };
      }

    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const picture_formats = ["image/png", "image/gif", "image/jpeg", "image/jpg"]
  const video_formats = ["video/mp4"]
  function getFormats(){
    switch(type){
      case 'storyArtifacts':
        return picture_formats
      case 'storyArtifactsVideo':
        return video_formats
      default: 
        return picture_formats.concat(video_formats)
    }
  }
  const formats = getFormats()
  return (
    <Main width={props.width}>
      <Kebab
        ref={buttonRef}
        onClick={() => {
          toggleDropdown(!showDropdown);
          props.onClick();
        }}
      >
        {props.Button}

        {showToggle ? (
          <Actions isVisible={showDropdown}>
            <AddMedia>
              <span>Add Artifact</span>
              <File
                type="file"
                multiple
                accept={formats.join(",")}
                onChange={handleFileChange}
              />
            </AddMedia>
          </Actions>
        ) : (
          <File
            type="file"
            multiple
            accept={formats.join(",")}
            onChange={handleFileChange}
          />
        )}
      </Kebab>
    </Main>
  );
};

export default FileUpload;

const Main = styled.div<{ width?: string }>`
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const Actions = styled(ActionsWrapper)`
  // right: unset;
`;

const AddMedia = styled(ActionButton) <{ showToggle?: boolean }>`
  border-radius: 8px !important;
`;

const File = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const Kebab = styled.button`
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 0;
`;
