import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutsideAlerter } from './useClickOutside';

export const RegenerateTranscriptionModal: React.FC<{
  type: 'transcription' | 'subtitles';
  confirm: () => void;
  discard: () => void;
}> = ({ type, confirm, discard }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => discard());
  const [isRegenerating, setIsRegenerating] = useState(false);

  return (
    <Wrapper ref={wrapperRef}>
      <Title>Regenerate {type === 'transcription' ? 'Transcription' : 'Subtitles'}</Title>
      <div
        style={{
          fontSize: '14px',
          fontWeight: 600,
        }}
      >
        <p>
          {`This will regenerate the ${type}${type === 'transcription' ? ' and will affect contents of the feature story and all social clips.'
            : ', all the edits to it will be lost. Original transcription will not change.'}`}
        </p>
        <p>Are you sure you want to regenerate?</p>
      </div>
      {!isRegenerating && (
        <Buttons>
          <CancelButton onClick={discard}>Cancel</CancelButton>
          <SubmitButton
            onClick={async () => {
              setIsRegenerating(true);
              confirm();
            }}
          >
            Regenerate
          </SubmitButton>
        </Buttons>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 380px;
  height: 200px;
  z-index: 1000;
  margin: auto;
  inset: 0;

  background: #292b2e;
  padding: 10px 25px 20px;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 16px;
`;

const Title = styled.p`
  margin: 0;
`;

const TextInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 6px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled.button`
  width: 120px;
  font-size: 16px;
  padding: 10px 0;
  background-color: #2a85ff;
  color: white;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
`;

const CancelButton = styled.button`
  width: 120px;
  font-size: 16px;
  padding: 10px 0px;
  color: white;
  border-radius: 6px;
  background-color: transparent;
  border: 0.5px solid gray;
  cursor: pointer;
}
`;
