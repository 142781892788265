import React from 'react';
import styled, { css } from 'styled-components';
import { ActionButton, ActionsWrapper } from '../../../styles/mainStyle';
import ExpandIcon from '../../../svgs/ExpandIcon';
import RotatingSquare from '../../../svgs/RotatingSquare';
import DeleteIcon from '../../../svgs/DeleteIcon';

const CircleEllipsis = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="20" fill="#17C964" />
    <circle cx="12" cy="20" r="2" fill="#03041A" />
    <circle cx="20" cy="20" r="2" fill="#03041A" />
    <circle cx="28" cy="20" r="2" fill="#03041A" />
  </svg>
);

type Props = {
  src: string;
  alt: string;
  counter: number;
  uuid: string | null;
  clickedImageId: string | null;
  imageSize: Record<string, Record<'width' | 'height', number>> | null;
  openDropdown: number | null;
  isResizing: string | null;
  width?: number | null;
  height?: number | null;
  defaultWidth?: string | null;
  defaultHeight?: string | null;
  dragPositions: Record<string, Record<'xAxis' | 'yAxis', string>> | null;
};

const ImageComponent = (props: Props) => {
  const {
    src,
    alt,
    counter,
    uuid,
    clickedImageId,
    imageSize,
    openDropdown,
    isResizing,
    width,
    height,
    defaultWidth,
    defaultHeight,
    dragPositions,
  } = props;

  const newUUid = `${src}-${alt}-${counter}`
    .split(' ')
    .join('-')
    .toLocaleLowerCase();
  const currUuid = uuid || newUUid;
  const dragPos = dragPositions?.[currUuid];
  const isSelected = currUuid === clickedImageId!;

  return (
    <Main
      className="image-wrapper"
      isSelected={isSelected}
      id={uuid || newUUid}
      data-uuid={uuid || newUUid}
      width={imageSize?.[currUuid]?.width || width}
      height={imageSize?.[currUuid]?.height || height}
      isResizing={isResizing === currUuid}
      data-default-width={defaultWidth}
      data-default-height={defaultHeight}
    >
      {isSelected && (
        <>
          <div className="circle-ellipsis">
            <CircleEllipsis />
          </div>
          <div className="resize-handle right">
            <ExpandIcon />
          </div>

          <div className="resize-handle left">
            <ExpandIcon />
          </div>
        </>
      )}

      <Kabeb className="kabeb-action">
        <Actions className="actions" isVisible={openDropdown === counter}>
          <AddPhoto className="add-photo-action">
            <span>Replace image</span>
            <div className="icon">
              <RotatingSquare strokeColor="currentColor" />
            </div>
          </AddPhoto>
          <HorizontalLine />
          <DeleteButton className="delete-action">
            <span>Delete image</span>
            <div className="icon">
              <DeleteIcon width="20" height="20" strokeColor="currentColor" />
            </div>
          </DeleteButton>
        </Actions>
      </Kabeb>

      <Image
        dragPos={dragPos}
        data-counter={counter}
        className="image"
        src={src!}
        alt={alt!}
      />
    </Main>
  );
};

export default ImageComponent;

const Main = styled.div.attrs(
  (props: {
    isSelected?: boolean;
    width?: number | null;
    height?: number | null;
    isResizing?: boolean;
  }) => props,
)`
  position: relative;
  width: ${(props) => (props.width ? `min(${props.width}px, 100%)` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  margin: 0 auto;

  .circle-ellipsis,
  .resize-handle {
    position: absolute;
    z-index: 2;
    cursor: se-resize;
  }
  .circle-ellipsis {
    top: -20px;
    right: -20px;
    cursor: pointer;
  }

  .resize-handle.right {
    bottom: -20px;
    right: -20px;
    cursor: nw-resize;
  }

  .resize-handle.left {
    top: -20px;
    left: -20px;
    right: unset;
    bottom: unset;
    cursor: se-resize;
  }

  ${(props) =>
    props.isResizing &&
    css`
      pointer-events: none; // Prevent pointer events during resizing
    `}

  ${(props) =>
    props.isSelected &&
    css`
      border: 2px solid #17c964;
    `}
`;

const Image = styled.img<{
  dragPos: Record<'xAxis' | 'yAxis', string> | undefined;
}>`
  // max-height: 400px;
  vertical-align: top;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: ${(props) => props.dragPos?.xAxis || '0%'}
    ${(props) => props.dragPos?.yAxis || '0%'};
`;

const Kabeb = styled.div`
  position: absolute;
  top: 3px;
  right: -20px;
  cursor: pointer;
  z-index: 3;
`;

const HorizontalLine = styled.div`
  width: 90%;
  height: 1px;
  background-color: #484848;
`;

const Actions = styled(ActionsWrapper)`
  align-items: center;
  justify-content: center;
  width: 170px;
`;

const AddPhoto = styled(ActionButton)`
  gap: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
`;

const DeleteButton = styled(ActionButton)`
  padding-top: 13px;
  padding-bottom: 13px;
  border-top: 0 !important;
  &:hover {
    background-color: #ef5d6f;
  }
`;
