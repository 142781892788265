import { ContentViewData } from './../types.ts/story';
import { AIPrompt } from '../types.ts/ai_prompts';
import axios from 'axios';
import { AIPROMPT_QUERY } from '../utility/gql';
import { SupportedGeneratedContentTypes } from '../types.ts/story';
import _ from 'lodash';
import TurndownService from 'turndown';
import { TalkingPointContent } from '../types.ts/general';
import { videoCreator } from '../stores/VideoCreatorStore';

const turndownService = new TurndownService();

type SupportedKey = 'Email' | 'Quotes' | 'Blog';

export default class ChatGPTService {
  protected aiPrompt: AIPrompt | undefined = undefined;
  private allPrompts: AIPrompt[] = [];
  public streamProcessDone = false;
  private controller: AbortController | null = null;
  public talkingPointController: AbortController | null = null;

  fetchAiPrompts = async (key: string) => {
    const prompts = (await videoCreator.gqlClient!.request(
      AIPROMPT_QUERY,
    )) as unknown as { allAiPrompts: AIPrompt[] };

    const { allAiPrompts } = prompts || {};
    this.allPrompts = allAiPrompts;
    const prompt = allAiPrompts.find((p) => p.title === key);
    if (prompt) {
      this.aiPrompt = prompt;
      return prompt;
    }
  };

  public async regenerateResponse(
    keyToGenerate: SupportedGeneratedContentTypes,
    existingResponse: any,
  ) {
    const transcript = videoCreator.finalTranscriptionElements
      ?.map((e) => e.value || '')
      .join('');
    await this.fetchAiPrompts(keyToGenerate);
    const storyId = videoCreator.story?.id;

    if (!this.aiPrompt || !transcript || !storyId) return;
    const aiResponse = this.aiPrompt as AIPrompt & { response: any };

    let requiredResponse: any = existingResponse;
    if (keyToGenerate === 'Photo Punchlist') {
      requiredResponse = existingResponse?.map((response: any) => ({
        line: response.line,
        description: response.description,
        evocative: response.evocative,
        metadata: response.metadata,
      }));
    }
    aiResponse.response = requiredResponse;

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/regenerate-gpt-response`,
      {
        transcript,
        existingResponse: aiResponse,
        storyId,
        keyToGenerate,
      },
    );
    return data;
  }

  private getExistingResponseData = (key: SupportedKey) => {
    return (
      videoCreator.contentStudioGeneratedContent?.[key]?.content?.response || ''
    );
  };

  private getResponseInputData = async (
    key: SupportedKey,
    brandText: string | null,
  ) => {
    const transcript = videoCreator.finalTranscriptionElements
      ?.map((e) => e.value || '')
      .join('');
    await this.fetchAiPrompts(key);

    if (!this.aiPrompt || !transcript || !videoCreator.story?.id) return;
    const existingResponse = this.getExistingResponseData(key);

    let currQuestion = this.aiPrompt.followUp;
    const prevQuestion = this.aiPrompt.description;

    if (brandText) {
      currQuestion += `. ${brandText}`;
    }

    return {
      prevQuestion,
      currQuestion,
      transcript,
      existingResponse,
    };
  };

  private updateGeneratedContent = (
    key: SupportedKey,
    response: string = '',
  ) => {
    let generatedContent = videoCreator.contentStudioGeneratedContent;
    videoCreator.contentStudioGeneratedContent = {
      ...(generatedContent || {}),
      [key]: {
        ...(generatedContent?.[key] || {}),
        content: {
          ...(generatedContent?.[key]?.content || {}),
          response,
        },
      },
    } as ContentViewData;
  };

  public async regenerateStreamResponse(
    key: SupportedKey,
    brandText: string | null = null,
    setLoading: (e: boolean) => void,
  ) {
    try {
      if (this.controller) {
        this.controller.abort();
      }
      this.controller = new AbortController();
      const signal = this.controller.signal;

      const items = await this.getResponseInputData(key, brandText);
      if (!items) return;
      const { transcript, prevQuestion, currQuestion, existingResponse } =
        items;

      const storyId = videoCreator.story?.id!;

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/aiprompts/regenerate-gpt-response-stream`,
        {
          signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transcript,
            existingResponse,
            storyId,
            prevQuestion,
            currQuestion,
          }),
        },
      );

      this.updateGeneratedContent(key, '');

      const data = res.body;

      if (!data) return;
      const reader = data.getReader();
      const decoder = new TextDecoder();
      videoCreator.contentStudioGeneratedContent![key].hasBeenGenerated = true;

      await this.processChunk(reader, decoder, setLoading, key);
    } catch (err: any) {
      if (err.name === 'AbortError') {
        console.log('Request aborted', err, err.message);
      } else {
        console.error('Error occurred:', err);
      }
    }
  }

  public async generatePunchListItem(line: string) {
    const storyId = videoCreator.story?.id;
    if (!line || !storyId) return;

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/generate-punchlist-item`,
      { line, storyId },
    );
    return data;
  }

  private async processChunk(
    reader: any,
    decoder: TextDecoder,
    setLoading: (e: boolean) => void,
    key: SupportedKey,
  ) {
    let i = 0;
    while (!this.streamProcessDone) {
      const { value, done: doneReading } = await reader.read();
      this.streamProcessDone = doneReading;
      let chunk = decoder.decode(value);
      setLoading(false);
      let addition = '';

      if (key === 'Email') {
        if (i === 0 && !chunk.includes('##')) addition = '## ';
        if (chunk === ' ') chunk = '\n\n';
        if (chunk.includes('Subject:')) {
          chunk = chunk.replace('Subject:', '').trim();
        }
      } else if (key === 'Blog') {
        videoCreator.selectedBlogContent = {
          type: doneReading ? 'generated' : 'generating',
        };
      } else if (key === 'Quotes') {
      }

      const existingResponse = this.getExistingResponseData(key);
      const text = existingResponse + addition + chunk;
      this.updateGeneratedContent(key, text);
      i++;
    }
    if (this.streamProcessDone) {
      this.controller = null;
      this.streamProcessDone = false;
    }
  }

  async generateTalkingPoint(
    setLoading: (e: boolean) => void,
    brandText: string = '',
    key: (keyof TalkingPointContent & 'all') | null = null,
  ) {
    try {
      if (this.talkingPointController) {
        this.talkingPointController.abort();
      }
      this.talkingPointController = new AbortController();
      const signal = this.talkingPointController.signal;

      const transcript = videoCreator.finalTranscriptionElements
        ?.map((e) => e.value || '')
        .join('');

      const prompts = await this.fetchAiPrompts('Fundraising prompt');
      if (!prompts?.promptFields || !transcript) return;

      const model = 'gpt-4'; //'gpt-4-turbo-preview'; //
      const temperature = 0;

      if (!key || key === 'all') {
        if (!key || !videoCreator.talkingPointContent) {
          videoCreator.talkingPointContent = {} as TalkingPointContent;
        } else {
          videoCreator.talkingPointContent = Object.fromEntries(
            Object.entries(videoCreator.talkingPointContent).map(
              ([key, value]) => [key, { prompt: value.prompt, content: '' }],
            ),
          ) as TalkingPointContent;
        }

        for (let prompt of prompts.promptFields) {
          await this.runTalkingPointAi(
            transcript,
            prompts.description,
            setLoading,
            prompt,
            temperature,
            model,
            key,
            brandText,
            signal,
          );
        }
      } else {
        const prompt = prompts.promptFields.find((f) => f.name === key);
        if (!prompt) return;

        await this.runTalkingPointAi(
          transcript,
          prompts.description,
          setLoading,
          prompt,
          temperature,
          model,
          key,
          brandText,
          signal,
        );
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request aborted', error, error.message);
      } else {
        console.error('Error occurred:', error);
      }
    }
  }

  getPrevContent(content: TalkingPointContent | null, promptName: string) {
    try {
      if (content && promptName in content) {
        return content[promptName as keyof TalkingPointContent];
      }
      return { prompt: '', content: '' };
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async runTalkingPointAi(
    transcript: string,
    description: string,
    setLoading: (e: boolean) => void,
    prompt: AIPrompt['promptFields'][0],
    temperature: number,
    model: string,
    key: (keyof TalkingPointContent & 'all') | null,
    brandText: string,
    signal: AbortSignal,
  ) {
    let prevContent = '';
    let prevPrompt = '';
    if (key) {
      const prevData = this.getPrevContent(
        videoCreator.talkingPointContent,
        prompt.name,
      );
      prevContent = turndownService.turndown(prevData?.content ?? '');
      prevPrompt = prevData?.prompt ?? '';
    }

    const promptDescription = `${prompt.description}. In markdown format.`;
    const currentPrompt = `${promptDescription} ${brandText}`;

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/stream-talking-point-response`,
      {
        signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transcript,
          systemMessage: description,
          prevPrompt: prevPrompt || promptDescription,
          currentPrompt,
          temperature,
          model,
          prevContent,
        }),
      },
    );

    const data = res.body;
    if (!data) return;
    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    if (key && videoCreator.talkingPointContent?.hasOwnProperty(key)) {
      videoCreator.talkingPointContent[
        key as keyof TalkingPointContent
      ].content = '';
    }

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      let chunk = decoder.decode(value);
      setLoading(false);

      const key = prompt.name as keyof TalkingPointContent;
      const existingContent = (videoCreator.talkingPointContent ||
        {}) as TalkingPointContent;

      videoCreator.talkingPointContent = {
        ...(existingContent as TalkingPointContent),
        [key]: {
          content: (existingContent[key]?.content || '') + chunk,
          prompt: currentPrompt,
        },
      };
    }
  }
}

export const gptService = new ChatGPTService();
