import React, { useState } from 'react'
import styled from 'styled-components';
import { videoCreator } from '../../stores/VideoCreatorStore';
import LinkIcon from '../../svgs/LinkIconAlt';
import SpinningLoading from '../SpinningLoading';
import { runInAction } from 'mobx';


const SocialProfileRefreshOverlay = () => {
    const storyId = videoCreator.storyId
    const [loading, setLoading] = useState(false)

    async function refreshStory() {
        // update story in store, so that the new profile is added to the list
        if (storyId) {
            const originallyLoading = loading;
            if (!originallyLoading) {
                setLoading(true);
            }
            const story = await videoCreator.storyRepository?.findOne(storyId);

            if (story) {
                videoCreator.story = story;
            }

            if (!originallyLoading) {
                setLoading(false);
            }

            runInAction(() => {
                videoCreator.showRefreshStoryForSocialProfile = false
            })
        }
    }

    if (!videoCreator.showRefreshStoryForSocialProfile) return null

    return <RefreshOverlay>
        {loading && <SpinningLoading
            customStyle={{
                top: 0,
                position: 'fixed',
                alignItems: 'center',
            }}
            text="Refreshing..."
        />}
        <Heading>
            When your account has been linked
        </Heading>
        <SubHeading>
            Please hit the button below to refresh your window
        </SubHeading>

        <Button onClick={refreshStory}>
            <LinkIcon /> Refresh link
        </Button>
    </RefreshOverlay>
}

export default SocialProfileRefreshOverlay

const RefreshOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #030419d9;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Heading = styled.span`
    font-weight: 700;
    font-size: 32px;
    line-height: 38.73px;
    color: #F3E9D7;
`

const SubHeading = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    color: #F3E9D7;
    margin-top: 5px;
    margin-bottom: 30px;
`

const Button = styled.button<{
    borderColor?: string;
    color?: string;
    backgroundColor?: string;
    width?: string;
}>`
    display: flex;
    width: 240px;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    background-color: #17C964;
    color: #03041A;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: #03041A;
  `;