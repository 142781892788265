type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};
const XIcon = (props: Props) => {
  const {
    width = '20',
    height = '20',
    viewBox = '0 0 20 20',
    fillColor = '#F3E9D7',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path
        d="M15.2726 1.58658H18.0838L11.9421 8.60616L19.1673 18.1582H13.51L9.07901 12.3649L4.00894 18.1582H1.19601L7.76518 10.65L0.833984 1.58658H6.63491L10.6401 6.88186L15.2726 1.58658ZM14.2859 16.4756H15.8436L5.78848 3.18086H4.11687L14.2859 16.4756Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default XIcon;
