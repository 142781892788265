import { Video, VideoClip } from '../types.ts/story';

export function timeFormat(inputTime: number): string {
  // Calculate the number of frames
  const framesPerSecond = 24; // Assuming 24 frames per second
  const totalFrames = Math.floor(inputTime * framesPerSecond);

  // Calculate hours, minutes, seconds, and frames
  const frames = totalFrames % framesPerSecond;
  const totalSeconds = Math.floor(totalFrames / framesPerSecond);
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  // Format the time with leading zeros
  const formattedTime: string = `${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
}

export function convertTimeToMMSS(time: number) {
  const minutes = Math.floor(time / 60);
  const remainingSeconds = time % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

export const getSongDuration = (duration: string): string => {
  if (!duration) return '00';
  const [timeString] = duration.split(' ');
  const time = Math.round(Number(timeString));
  let hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const getDurationFromSource = (source: Video['videoSource']) => {
  const elements = source?.elements;
  const videoElement = elements?.find((e: any) => e.type === 'video');
  if (videoElement?.duration) {
    const duration = Math.round(parseFloat(videoElement.duration));
    return {
      formattedDuration: convertTimeToMMSS(duration),
      duration,
    };
  }
};

export const getFormattedDurationForClip = (video: VideoClip) => {
  const videoWithElements = video as Video & {
    videoJson: Video['videoSource'];
  };
  const duration = video.videoFilePrimary?.video?.duration;
  if (duration)
    return {
      formattedDuration: convertTimeToMMSS(Math.round(duration)),
      duration: Math.round(duration),
    };

  const { videoJson, videoSource } = videoWithElements || {};
  const data = videoJson || videoSource;

  if (data) {
    const duration = getDurationFromSource(data);
    if (duration) return duration;
  }

  return {
    formattedDuration: '--:--',
    duration: null,
  };
};
