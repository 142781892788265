import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { videoCreator } from '../../../stores/VideoCreatorStore';
import DocumentIcon from '../../../svgs/DocumentIcon';
import EllipsisIcon from '../../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../../transcript/useClickOutside';
import {
  handleDeleteBlogContent,
  handleLoadBlogContent,
  handleRenameBlogContent,
} from '../../../utility/general';
import { BlogOrEmailContent } from '../../../types.ts/general';
import ContentSelectorModal from '../../common/ContentSelectorModal';
import Modal from '../../common/Modal';
import CopyContentModal from '../../stage/CopyContentModal';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { BlogProducer } from './BlogProducer';

enum TableHeaders {
  name = 'Type',
  blogName = 'Blog Name',
  createdBy = 'Created By',
  modifiedAt = 'Last Modified',
  action = '',
}

type Props = {
  type: 'savedBlog' | 'savedEmail';
  entries: BlogOrEmailContent[];
  currentContent: BlogOrEmailContent | null;
  setCurrentContent: Dispatch<SetStateAction<BlogOrEmailContent | null>>;
};

const SavedBlogList = observer((props: Props) => {
  const { entries, setCurrentContent } = props;
  const savedBlogData = videoCreator.story?.savedBlog;
  const buttonRef = useRef<HTMLDivElement>(null);

  const { contentStudioEnableBlogSave } = useFlagsCombination();

  const [options, setOptionsModal] = useState<{
    index: number | null;
    position: { top?: number; bottom?: number } | null;
    content: BlogOrEmailContent | null;
  } | null>(null);
  const [contentToEdit, toggleContentToEditModal] = useState<{
    type: 'save_as' | 'rename';
    content: BlogOrEmailContent | null;
  } | null>(null);

  useOutsideAlerter(buttonRef, () => {
    setOptionsModal(null);
  });

  if (!savedBlogData) return null;

  const loadContent = (content: BlogOrEmailContent) => {
    handleLoadBlogContent(content, setCurrentContent);
  };

  async function deleteContent(contentId: number) {
    const setDropdownOpen = () => { };
    const currContentId = contentId;
    await handleDeleteBlogContent(
      contentId,
      currContentId,
      setOptionsModal,
      setDropdownOpen,
      setCurrentContent,
    );
  }

  async function renameContent(contentId: number, title: string) {
    const setDropdownOpen = () => { };
    await handleRenameBlogContent(
      contentId,
      title,
      props.type,
      'saved_blog',
      toggleContentToEditModal,
      setOptionsModal,
      setDropdownOpen,
      setCurrentContent,
      false,
    );
  }

  const calculateDropdownPosition = (buttonRect: {
    top: number;
    bottom: number;
  }) => {
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - buttonRect.bottom;
    const popupHeight = 76;
    if (spaceBelow <= popupHeight) {
      return { right: 0, bottom: 15 };
    } else {
      return { right: 0, top: 25 };
    }
  };

  const renderHeader = () => (
    <Head>
      <TypeSection>
        <Header>{TableHeaders.name}</Header>
      </TypeSection>
      <BlogName>
        <Header>{TableHeaders.blogName}</Header>
      </BlogName>

      <CreatedBy>
        <Header>{TableHeaders.createdBy}</Header>
      </CreatedBy>

      <LastModified>
        <Header>{TableHeaders.modifiedAt}</Header>
      </LastModified>

      <Action></Action>
    </Head>
  );

  const renderContent = (index: number, data: BlogOrEmailContent) => (
    <Content onClick={() => loadContent(data)}>
      <TypeSection>
        <span className="icon">
          <DocumentIcon width="24" height="24" />
        </span>
        <span className="text">Blog</span>
      </TypeSection>
      <BlogName>
        <Text>{data.title}</Text>
      </BlogName>

      <CreatedBy>
        <Text>{data.username}</Text>
      </CreatedBy>

      <LastModified>
        <Text>{BlogProducer.getModifiedAt(data.id) || ''}</Text>
      </LastModified>
      {contentStudioEnableBlogSave ? (
        <Action
          onClick={(e) => {
            e.stopPropagation();
            const buttonRect = e.currentTarget.getBoundingClientRect();
            if (buttonRect) {
              const position = calculateDropdownPosition(buttonRect);
              setOptionsModal({
                index,
                content: data,
                position,
              });
            }
          }}
        >
          <EllipsisIcon width="16" />
        </Action>
      ) : (
        // preserve padding
        <Action />
      )}

      {options?.index === index && options?.content && (
        <ContentSelectorModal
          ref={buttonRef}
          pos={options.position}
          content={options?.content}
          toggleContentToEditModal={toggleContentToEditModal}
          setOptionsModal={(content) =>
            setOptionsModal({ index: null, position: null, content })
          }
          deleteContent={deleteContent}
        />
      )}
    </Content>
  );
  const entriesData = [{}, ...entries] as BlogOrEmailContent[];

  return (
    <Main>
      <SubTitle>Saved Blogs</SubTitle>
      <Container>
        {entriesData?.map((data, index) => {
          if (index === 0) {
            return renderHeader();
          }
          return renderContent(index, data);
        })}
      </Container>
      {contentToEdit && (
        <Modal
          isOpen={contentToEdit !== null}
          closeModal={() => toggleContentToEditModal(null)}
        >
          <CopyContentModal
            name={contentToEdit.content?.title! ?? ''}
            tag="Blog"
            type={contentToEdit.type}
            onCancel={() => toggleContentToEditModal(null)}
            onSave={async (title) => {
              if (contentToEdit?.type === 'rename') {
                await renameContent(contentToEdit.content?.id!, title);
              }
            }}
          />
        </Modal>
      )}
    </Main>
  );
});

export default SavedBlogList;

const Main = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin-top: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (max-width: 1250px) {
    overflow: auto;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  cursor: pointer;
  position: relative;
`;
const Head = styled(Content)`
  border-bottom: 1px solid #484848;
  width: 100%;
  padding-bottom: 15px;
  cursor: unset;
`;

const Text = styled.span`
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-align: left;
`;

const BlogName = styled.div`
  text-align: left;
  width: 600px;
`;
const CreatedBy = styled.div`
  flex-grow: 1;
  width: 200px;
  text-align: left;
`;
const LastModified = styled.div`
  width: 120px;
  text-align: left;
`;
const Action = styled.div`
  width: 100px;
  cursor: pointer;
  text-align: right;
`;

const SubTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  text-align: left;
  color: #f3e9d7;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
`;

const TypeSection = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  text-align: left;
  gap: 5px;
  .icon {
    color: #f2d093;
  }
  .text {
    color: #484848;
    font-size: 10px;
    font-weight: 400;
  }
`;

const Header = styled.span`
  color: #484848;
  font-size: 10px;
  font-weight: 600;
  width: 100%;
`;
