import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import ColorPicker from './ColorPicker';

type Props = {
  values: { caption: string; value: string | null }[];
  styleFont?: boolean;
  showColor?: boolean;
  showBorderColor?: boolean;
  width?: string;
  action: (propertyName: string, value: string | null) => void;
  propertyName: string;
  defaultValue: string | undefined | null;
  getValue?: (value: string) => void;
};
const ItemDropdownSelect = (props: Props) => {
  const { width = undefined } = props;
  const [selectedItem, setSelectedItem] = useState<string | undefined | null>(
    props.defaultValue,
  );
  const [showDropdown, toggleDropdown] = useState<boolean>(false);
  const [colorPicker, togglePicker] = useState<DOMRect | null>(null)

  useEffect(() => {
    setSelectedItem(props.defaultValue);
  }, [props.defaultValue]);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    toggleDropdown(false);
    togglePicker(null)
  });

  const ArrowDownIcon = (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path d="M2 4.33008L6 8.33008L10 4.33008" stroke="#F2D093" />
    </svg>
  );

  const selectedValue = props.values.find((v) => v.value === selectedItem);

  const renderDropdownItem = (item: {
    caption: string;
    value: string | null;
  }) => (
    <DropdownItem
      styleFont={props.styleFont}
      item={item.value}
      key={item.value}
      showColor={props.showColor}
      showBorderColor={props.showBorderColor}
      onClick={() => {
        setSelectedItem(item.value);
        if (props.getValue) {
          props.getValue(item.value!);
        }

        props.action(props.propertyName, item.value);
        toggleDropdown(false);
      }}
    >
      {!props.showColor && !props.showBorderColor && item.caption}
    </DropdownItem>
  );

  return (
    <Main width={width} ref={contentRef}>
      <Selected width={width} onClick={(e) => {
        if (props.showBorderColor || props.showColor) {
          if (colorPicker) return togglePicker(null);
          return togglePicker(e.currentTarget.getBoundingClientRect())
        }
        toggleDropdown(!showDropdown)
      }}>
        <SelectedContent
          styleFont={props.styleFont}
          showColor={props.showColor}
          selectedItem={selectedItem}
          showBorderColor={props.showBorderColor}
        >
          {(!props.showColor &&
            !props.showBorderColor &&
            selectedValue?.caption) ||
            ''}
        </SelectedContent>{' '}
        {!props.showColor && !props.showBorderColor && <ToggleIcon>{ArrowDownIcon}</ToggleIcon>}
      </Selected>

      {(props.showColor || props.showBorderColor) && colorPicker &&
        <ColorPicker
          defaultValue={props.defaultValue}
          getValue={(color) => {
            props.action(props.propertyName, color);
          }}
          pos={colorPicker}
          onClose={() => { togglePicker(null) }}
        />
      }

      {(!props.showColor && !props.showBorderColor) && showDropdown && (
        <Dropdown width={width}>
          {props.values.map((item) => renderDropdownItem(item))}
        </Dropdown>
      )}
    </Main>
  );
};

export default ItemDropdownSelect;

const Main = styled.div<{
  width?: string;
}>`
  position: relative;
`;
const Selected = styled.div<{
  width?: string;
}>`
  border: 1px solid #484848;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  cursor: pointer;

  padding: 8px 16px;
  border-radius: 8px;
`;

const SelectedContent = styled.span<{
  styleFont?: boolean;
  selectedItem?: string | null;
  showColor?: boolean;
  showBorderColor?: boolean;
}>`
  ${(props) =>
    props.styleFont &&
    props.selectedItem &&
    css`
      font-family: ${props.selectedItem};
      font-size: 16px;
    `}

  ${(props) =>
    props.showColor &&
    props.selectedItem &&
    css`
      width: 15px;
      height: 15px;
      background-color: ${props.selectedItem};
      border: ${(props.selectedItem === 'black' || props.selectedItem === 'rgba(0, 0, 0, 1)') && '1px solid #484848'};
      border-radius: 50%;
    `}

    ${(props) =>
    props.showBorderColor &&
    props.selectedItem &&
    css`
      width: 15px;
      height: 15px;
      border: 1px solid ${props.selectedItem};
      background: ${props.selectedItem === 'black' ||
        props.selectedItem === 'rgba(0, 0, 0, 1)'
        ? '#484848'
        : 'transparent'};
      border-radius: 50%;
    `}

    ${(props) =>
    props.showBorderColor &&
    props.selectedItem === 'transparent' &&
    css`
      background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) calc(50% - 0.8px),
        rgb(255, 0, 0) 50%,
        rgba(255, 255, 255, 1) calc(50% + 0.8px),
        rgba(255, 255, 255, 1) 100%
      );
    `}
`;
const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 5px 10px;
  max-height: 200px;
  width: calc(100% - 20px);
  overflow: auto;
  position: absolute;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{
  styleFont?: boolean;
  showColor?: boolean;
  showBorderColor?: boolean;
  item?: string | null;
}>`
  padding: 12px;
  ${(props) =>
    props.styleFont &&
    props.item &&
    css`
      font-family: ${props.item};
      font-size: 16px;
      padding: 12px;
    `}

  ${(props) =>
    (props.showColor || props.showBorderColor) &&
    props.item &&
    css`
      height: 20px;
      background-color: ${props.item};
      margin: 3px 0;
      padding: 6px;
      border: ${props.item === 'rgba(0, 0, 0, 1)' && '1px solid #484848'};
    `}

    ${(props) =>
    props.item === 'transparent' &&
    css`
      background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) calc(50% - 0.8px),
        rgb(255, 0, 0) 50%,
        rgba(255, 255, 255, 1) calc(50% + 0.8px),
        rgba(255, 255, 255, 1) 100%
      );
    `}

  &:not(:last-child) {
    border-bottom: ${(props) => !props.showColor && '1px solid #484848'};
  }
  &:hover {
    color: #f2d093;
  }
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;
